import { authState } from 'src/auth';

export function getTagLink(tagName) {
  return `/tags/${tagName}`;
}

export function getReelLink(uid) {
  return `/reel/${uid}`;
}

export function getMyReelHref() {
  return authState.uid && getReelLink(authState.uid);
}

export function getShareLink(tagName) {
  return `${window.location.origin}/share/${tagName}`;
}