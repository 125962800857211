import firebase from 'firebase/app';
import merge from 'lodash/merge';
import pull from 'lodash/pull';

import State from 'src/util/State';
import db, { NotLoaded } from 'src/db';
import { getLocalStorageItem, setLocalStorageItem } from '../util/localStorage';
import EmptyObject from '../util/EmptyObject';
import NanoEvents from '../util/NanoEvents';
import EmptyArray from '../util/EmptyArray';
import perfDefault from './FirestorePerformanceCounter';
import { logInstrumentAllMethodCalls } from '../util/traceLog';
import { docToSimpleObject } from './firebaseUtil';
import { sleep } from 'src/util/sleep';
import { perfLog } from 'src/util/perf';


// const Verbose = true;
const Verbose = false;
const TraceLog = false;


// ###########################################################################
// query util
// ###########################################################################

const snapshotCfg = {
  // listen for offline events as well: https://cloud.google.com/firestore/docs/manage-data/enable-offline
  includeMetadataChanges: true
};

function buildStateUpdate(docId, obj) {
  return { [docId]: obj };
}

function get__data() {
  return this.___data;
}

function applyTransformsToQuery(query, transforms) {
  // let msg = 'building query...\n\n';
  // debugger;
  for (const t of transforms) {
    if (t) {
      query = t(query);
      // msg = `${msg}\n\n${t}`;
    }
  }
  // console.info(msg);
  return query;
}


class FirestoreContainer {
  /**
   * @type {State}
   */
  state;
  _caching = false;
  /**
   * TODO: get rid of this (it's already stored in this.state)
   */
  docsById = {};
  _docPromisesById = {};

  /**
   * Array of array of docs
   */
  _pages = [];

  // /**
  //  * Store cached (early arriving) items for a page in this temporarily.
  //  */
  // _perPageTmp = [];

  /**
   * Array of snapshots
   */
  _pageSnapshots = [];

  _unsubscribeCallbacks = {};
  _unsubscribeCallbacksArray = [];
  _nextPage = 0;
  _hasLoaded = false;
  _started = false;
  _qualifiedName = null;
  _busy = 0;

  _lastDoc = null;
  _activePagePromise = null;

  _docsArray = null; // cache

  emitter = new NanoEvents();

  constructor(collectionName, state) {
    this.collectionName = collectionName;
    this.collection = db.collection(collectionName);

    this.state = state || new State();

    if (TraceLog) {
      logInstrumentAllMethodCalls(`FSC[${collectionName}]`, this);
    }
  }

  // ########################################
  // Getters
  // ########################################

  getDebugTag() {
    const name = this._qualifiedName || this.constructor.name;
    return `[${name}]`;
  }

  enableCache() {
    this._caching = true;
  }

  get started() {
    return this._started;
  }

  hasLoaded() {
    // has finished loading the first page
    return this._hasLoaded;
  }



  isLoadingPage() {
    return !!this._activePagePromise;
  }

  /**
   * TODO: possibly get docs from `_pages` instead of `docsById`
   */
  getNonNullIds() {
    return Object.keys(this.docsById).filter(k => !!this.docsById[k]);
  }

  count() {
    return Object.keys(this.docsById).filter(k => !!this.docsById[k]).length;
  }

  clear() {
    for (const cb of this._unsubscribeCallbacksArray) {
      cb();
    }
    this._activePagePromise = null;
    this.docsById = {};
    this._docPromisesById = {};
    this._pages = [];

    /**
     * Contains arrays of docs from snapshots from the DB.
     * Might contain `null` entries in case of overrides.
     */
    this._pageSnapshots = [];
    this._docsArray = null;
    this._unsubscribeCallbacks = {};
    this._unsubscribeCallbacksArray = [];
    this._nextPage = 0;
    this._started = false;
    this._hasLoaded = false;
    this._reachedLastPage = false;
    this._lastDoc = null;
    this._queryArgs = null;
    this.state.clear();

    this.emitter.emit('clear');
  }

  getLoadedPageCount() {
    return this._nextPage;
  }

  hasLoadedPage(iPage) {
    return !!this._pages[iPage];
  }

  hasLoadedFirstPage() {
    return !!this._pages[0];
  }

  hasReachedLastPage() {
    return this._reachedLastPage;
  }

  /**
   * Builds and caches array of all non-null (simple object representations of) docs.
   * Returns previously build array, if no changes were made in the menatime.
   * 
   * NOTE: Keeps the initial order of documents queried using `query`, `where` etc.
   */
  getAllNotNull = () => {
    if (!this._hasLoaded) {
      return NotLoaded;
    }
    if (!this._docsArray) {
      // cache it
      this._docsArray = this._buildNonNullValuesArray();
      this.handleDocsArrayChanged();
    }
    return this._docsArray;
  }

  handleDocsArrayChanged() {
    // allows for invalidating other cache that depends on `docsArray`
  }

  /**
   * 
   */
  _buildNonNullValuesArray() {
    let arr;
    if (this._pages.length) {
      // if we queried objects one page at a time, get them
      const docs = this._pages.flat();
      arr = docs.map(doc => this.docsById[doc.id]);
    }
    else {
      // get array of all individual objects
      arr = Object.values(this.docsById);
    }
    return arr.filter(simpleDoc => !!simpleDoc);
  }

  getDocOrLoadFromCache(docId) {
    let entry = this.docsById[docId];
    if (entry === NotLoaded) {
      // get from cache for optimistic UI
      entry = this.loadEntryFromCache(docId);
      if (entry !== NotLoaded) {
        this.overrideDoc(docId, entry);
      }
    }
    return entry;
  }

  getDocById(docId) {
    return this.docsById[docId];
  }

  isDocLoaded(docId) {
    return this.docsById[docId] !== NotLoaded;
  }

  getFirstDoc() {
    if (!this._hasLoaded) {
      return NotLoaded;
    }

    let firstId;
    if (this._pages?.length) {
      const doc = this._pages[0][0];

      // hackfix: currently, there is some inconsistency as to whether we store `doc` or `entry` in pages
      firstId = doc?.id || doc?._id || null;
    }
    else {
      for (const docId in this.docsById) {
        firstId = docId;
        break;
      }
    }

    return firstId ? this.docsById[firstId] : NotLoaded;
  }

  /**
   * Contains null values (results of queries that are empty or deleted objects)
   */
  getAllNow = () => {
    if (!this._hasLoaded) {
      return NotLoaded;
    }
    return this.docsById;
  }

  /**
   * Copy of docsById but with null values removed.
   * TODO: get rid of this. Use `getAllNotNull` instead
   */
  getAllNowNotNull = () => {
    if (!this._hasLoaded) {
      return NotLoaded;
    }
    const notNullDocsById = {};
    for (const key in this.docsById) {
      const value = this.docsById[key];
      if (value !== null) {
        notNullDocsById[key] = value;
      }
    }
    return notNullDocsById;
  }

  getQueryArgs() {
    return this._queryArgs;
  }

  /**
   * Returns the reference to given doc
   */
  doc = docId => {
    // if (!isString(docId) || !docId) {
    //   debugger;
    //   console.error('docId', docId);
    //   throw new Error('invalid docId must be non-empty string: ' + docId);
    // }
    return this.collection.doc(docId);
  }

  newDocRef() {
    return this.collection.doc();
  }

  // ###########################################################################
  // queries
  // ###########################################################################

  /**
   * Send out query to get first page of all documents in collection.
   */
  all = async () => {
    if (this._hasLoaded) {
      return this.getAllNow();
    }
    return this.query(); // `all()` is the same as `query()` with no args
  }

  /**
   * Send out query to get first page of given filter.
   */
  where = async (...whereArgs) => {
    return this._query({
      where: whereArgs,
      limit: undefined
    });
  }

  /**
   * Send out query to get first page of given args.
   */
  async query(queryArgs) {
    return this._query(queryArgs);
  }

  /**
   * Will override current queryArgs.
   * Useful when behavior of query should vary between different pages.
   */
  overrideQueryArgs = (queryArgsOverride) => {
    if (!this._queryArgs) {
      throw new Error('Must call `query()` before calling `overrideQueryArgs()` or `loadNextPage()`!');
    }

    // apply new queryArgs
    const newQueryArgs = merge({}, this._queryArgs, queryArgsOverride);

    if (!(newQueryArgs.limit > 0)) {
      throw new Error('Tried to call `nextPage()` without having a proper limit set. Make sure to call `query()` instead of `where()`, or pass a limit in your call to `nextPage`!');
    }

    // store result
    this._queryArgs = newQueryArgs;
  }

  /**
   * If currently fetching a page of data (after `query` or `loadNextPage` function),
   * wait for that to finish.
   */
  async waitForCurrentPage() {
    if (this._activePagePromise) {
      await (this._activePagePromise = this._activePagePromise.then(() => { }));
    }
  }

  async loadNextPage(queryArgsOverride = null) {
    if (this._reachedLastPage) {
      // already done!
      return null;
    }

    // make sure previous query finished before doing this (so lastDoc is set!)
    await this.waitForCurrentPage();

    // validate & apply new queryArgs
    queryArgsOverride && this.overrideQueryArgs(queryArgsOverride);

    // console.debug(this.getDebugTag(), 'nextPage');

    // run query
    return this._runQuery(false);
  }

  _query = async (queryArgs) => {
    if (this._started) {
      throw new Error('[INTERNAL ERROR] cannot call where or all more than once, or after calling getDoc, as it will override most stuff (won\'t override `docsById`). Call `clear()` first.');
    }

    this._queryArgs = queryArgs = queryArgs || EmptyObject;
    this._buildQualifiedName();
    this._started = true;

    // load from cache for optimistic UI
    const cachedResult = this.loadEntryPageFromCache();

    // start querying
    const queryPromise = this._runQuery(true);

    // console.debug('_query', this.getDebugTag(), queryArgs);

    // if was cached, we can return early, else let caller wait for query to return result
    return cachedResult || queryPromise;
  }

  _buildQualifiedName() {
    const {
      where,
      orderBy,
      ...other
    } = this._queryArgs;
    const whereString = JSON.stringify(where || EmptyArray);
    const orderString = JSON.stringify(orderBy || EmptyArray);
    const otherString = other && '#' + JSON.stringify(other) || '';
    this._qualifiedName = `db.collection('${this.collectionName}').where(${whereString}).orderBy(${orderString})${otherString}`;
  }

  buildQuery(query, queryArgs) {
    const {
      where: whereArgs,
      limit,
      orderBy: orderByArgs,
      startAfter,
      transforms
    } = queryArgs;

    if (whereArgs && whereArgs.length % 3 !== 0) {
      throw new Error('[INTERNAL ERROR] Call to `where` must have 3, 6, 9, ... arguments.');
    }

    try {
      // start building query
      if (whereArgs) {
        for (let i = 0; i < whereArgs.length; i += 3) {
          query = query.where(whereArgs[i], whereArgs[i + 1], whereArgs[i + 2]);
        }
      }
      if (transforms) {
        query = applyTransformsToQuery(query, transforms);
      }
      if (orderByArgs) {
        if (Array.isArray(orderByArgs)) {
          query = query.orderBy(...orderByArgs);
        }
        else {
          query = query.orderBy(orderByArgs);
        }
      }

      if (limit) {
        query = query.limit(limit);
      }

      if (startAfter) {
        query = query.startAfter(startAfter);
      }
    }
    catch (err) {
      this._onError('could not build query', queryArgs, err);

      throw new Error('QUERY CANCELLED due to previous error');
    }

    return query;
  }

  /**
   * Runs a query to get (and continuously update) pages of docs from collection
   */
  _runQuery = async (isFirstPage) => {
    // perfLogCategory('firestoreQuery', '_runQuery', '[START]', this._queryArgs);
    // console.log('FirestoreContainer', '_runQuery', '[START]', this._queryArgs);
    let query = this.buildQuery(this.collection, this._queryArgs);
    // console.debug('_runQuery', this.getDebugTag(), this._queryArgs);

    // add paging
    if (!isFirstPage && this._lastDoc) {
      if (this._queryArgs?.startAfter) {
        throw new Error(this.getDebugTag() + ' Tried to retrieve next page but paging not supported because `startAfter` was already supplied as query argument. All query arguments: \n' + JSON.stringify(this._queryArgs, null, 2));
      }

      // start after last doc
      query = query.startAfter(this._lastDoc);
    }

    const iPage = this._nextPage++;
    // this._perPageTmp[iPage] = null;
    const {
      limit
    } = this._queryArgs;

    // console.debug('_runQuery (before wait)', this.getDebugTag());
    // console.debug('_runQuery (after wait)', this.getDebugTag());

    // send out query
    ++this._busy;
    const newPagePromise = new Promise((resolve, reject) => {
      // console.debug(this.getDebugTag(), 'query.onSnapshot', '[START]');
      const onSnapshot = snap => {
        let { docs } = snap;
        // console.debug(this.getDebugTag(), 'query.onSnapshot', snap.metadata.fromCache);

        if (snap.metadata.fromCache) {
          // console.warn('FirestoreContainer.onSnapshot [fromCache]', docs);
          // ignore cached results (for now)
          // see: https://groups.google.com/forum/#!topic/google-cloud-firestore-discuss/wEVPfgvLCFs
          // const tmp = this._perPageTmp[iPage] = this._perPageTmp[iPage] || new Set();
          // docs.forEach(tmp.add.bind(tmp)); // add docs to tmp
          return;
        }
        // console.warn('FirestoreContainer.onSnapshot', docs);

        // if (this._perPageTmp[iPage]) {
        //   // add objects that arrived early from the cache
        //   docs = [
        //     ...this._perPageTmp[iPage],
        //     ...docs
        //   ];
        //   this._perPageTmp[iPage] = null;
        // }

        // if (this.collectionName === 'ecm') {
        //   perfLog('ecm snapshot 1');
        // }

        if (isFirstPage) {
          // only write first page to cache
          this.writePageToCache(docs);
        }

        // if (this.collectionName === 'ecm') {
        //   perfLog('ecm snapshot 2');
        // }

        // remember `_lastDoc` and `_reachedLastPage` for paging purposes
        if (iPage === this.getLoadedPageCount() - 1) {
          this._lastDoc = docs[docs.length - 1];
          this._reachedLastPage = docs.length < limit;
        }

        // unset promise (also updates loading state)
        const pendingPromise = this._activePagePromise;
        this._activePagePromise = null;
        --this._busy;

        // store result
        const res = this._onDocsUpdate(iPage, docs, snap, false);
        // console.debug('data', this.getDebugTag(), res);


        // if (this.collectionName === 'ecm') {
        //   perfLog('ecm snapshot 3');
        // }

        if (pendingPromise) {
          // received first snapshot -> resolve promise
          resolve(res);
        }
      };

      if (this.collectionName === 'ecm') {
        perfLog('ecm snapshot 0');
      }
      const unsubscribe = query.onSnapshot(snapshotCfg, onSnapshot, this._onSnapshotError);

      this._unsubscribeCallbacksArray.push(unsubscribe);
    });

    // Race condition avoidance: Make sure to only run this after previous page promise has finished.
    if (this._activePagePromise) {
      this._activePagePromise = this._activePagePromise.then(newPagePromise);
    }
    else {
      this._activePagePromise = newPagePromise;
    }
    return this._activePagePromise;
  }

  // ########################################
  // synchronization stuff
  // ########################################

  // async _addPromise() {
  //   while (this._activePromise) {
  //     // prevent race-condition (this Container class is very limited; can only cache page at a time anyway)
  //     await this._activePromise;
  //   }


  // }

  // ########################################
  // error handling (if you wanna call this that)
  // ########################################

  _onSnapshotError = (err) => {
    --this._busy;
    return this._onError(`db onSnapshot failed\n`, err);
  }

  _onError = (msg, ...args) => {
    console.error(`#####################\n${this.getDebugTag()} ${msg}\n#####################\n`, ...args);
    debugger
    this.clear();
  }

  // ###########################################################################
  // caching
  // ###########################################################################

  // ########################################
  // caching util
  // ########################################

  _serializeDoc = (doc) => {
    return {
      id: doc.id,
      ___data: doc.data()
    };
  }

  _deserializeDoc = (doc) => {
    const data = doc.___data;
    if (!data) {
      return null;
    }
    doc.data = get__data;
    for (const key in data) {
      const val = data[key];
      if (val && val.nanoseconds !== undefined) {
        // probably a firestore Timestamp
        // see: https://firebase.google.com/docs/reference/js/firebase.firestore.Timestamp
        data[key] = new firebase.firestore.Timestamp(val.seconds, val.nanoseconds);
      }
    }
    return doc;
  }

  // ########################################
  // caching docs
  // ########################################

  _getUniqueEntryKey(id) {
    return `${this.collectionName}.${id}`;
  }

  loadEntryFromCache(id) {
    if (!this._caching) {
      return NotLoaded;
    }

    const key = this._getUniqueEntryKey(id);
    const serialized = getLocalStorageItem(key);
    if (!serialized) {
      return NotLoaded;
    }

    // console.debug('loaded doc from cache', key);

    const doc = this._deserializeDoc(serialized);
    const entry = doc && this.overrideDoc(doc.id, doc.data(), false);
    return entry;
  }

  writeDocToCache(doc) {
    const key = this._getUniqueEntryKey(doc.id);
    const serialized = this._serializeDoc(doc);
    setLocalStorageItem(key, serialized);
  }

  // ########################################
  // caching pages
  // ########################################

  _getUniquePageKey() {
    const { _qualifiedName, _lastDoc, _queryArgs } = this;
    return `${_qualifiedName}//${_lastDoc?.id || '0'}//${_queryArgs.limit || -1}`;
  }

  loadEntryPageFromCache = () => {
    if (!this._caching) {
      return null;
    }
    if (!this._qualifiedName) {
      throw new Error('Cannot load from cache before call to all() or where()');
    }
    if (this._queryArgs?.transforms?.length) {
      console.error(this.getDebugTag(), 'Cache ignored - cannot load from cache on queries that have transforms (all query args need to be observable for cache to be accurate)');
      return null;
    }

    const pageKey = this._getUniquePageKey();
    const cachedDocs = getLocalStorageItem(pageKey);
    if (cachedDocs) {
      this._hasLoaded = true;
      this._started = true;
      cachedDocs.forEach(entry => {
        // bring all entries back to life
        this._deserializeDoc(entry);
      });
      console.debug('loaded cache -', `${pageKey}`);
      return this.overrideDocs(cachedDocs);
    }
    return undefined;
  }

  writePageToCache = (docsArray) => {
    if (!this._caching) {
      return;
    }
    if (!this._qualifiedName) {
      throw new Error('Cannot write to cache before call to all() or where()');
    }
    if (this._queryArgs?.transforms?.length) {
      console.error(this.getDebugTag(), 'Cache ignored - cannot write to cache on queries that have transforms (all query args need to be observable for cache to be accurate)');
      return;
    }

    const serialized = docsArray.map(this._serializeDoc);
    setLocalStorageItem(this._getUniquePageKey(), serialized);

    // console.debug('wrote cache:', `${this.getDebugTag()}`); //, cachedEntries);
  }

  // ########################################
  // overrides
  // ########################################


  async saveDoc(reelId, entry = this.getDocById(reelId)) {
    if (!entry) {
      throw new Error(`Cannot save collection. Reel of id not loaded or does not exist: ${reelId}`);
    }

    // override in store
    this.overrideDoc(reelId, entry);

    // send to DB
    await this.doc(reelId).set(entry);
  }

  /**
   * Apply the given update to doc of given docId in container.
   * NOTE: Does not save to database.
   */
  overrideDoc = (docId, newData, merge = false) => {
    if (merge) {
      const data = this.getDocById(docId);
      if (data === NotLoaded) {
        throw new Error(`${this.getDebugTag()} in "overrideDoc" cannot "merge" doc that has not yet loaded: ` + docId);
      }
      newData = newData === null ? null : Object.assign({}, this.docsById[docId], newData);
    }
    this._onDocUpdate(docId, newData, true);
  }

  overrideDocs = (docsArray) => {
    // TODO: can only override first page for now
    // TODO: docsArray is mostly assumed to be an array of FirestoreDocs, not of replacement objects; will bug out eventually
    return this._onDocsUpdate(0, docsArray, null, true);
  }


  // ########################################
  // events
  // ########################################

  /**
   * Register a listener to be called on updates for given doc.
   * `listener` is called with the doc's data as first argument.
   */
  addListener(docId, listener) {
    return this.state.addListener(docId, listener);
  }

  /**
   * Register a listener to be called on any update.
   * `listener` is called with an object representing the changed data.
   */
  onUpdate = (listener) => {
    const wrapped = listener;
    // const wrapped = (...args) => {
    //   // for debugging purposes: wrap callback
    //   console.debug(listener.name, `${this.getDebugTag()}.onUpdate`);
    //   return listener(...args);
    // }
    return this.state.onUpdate(wrapped);
  }

  onClear = listener => {
    return this.emitter.on('clear', listener);
  }

  // ########################################
  // querying
  // ########################################

  getDocNowOrQuery = docId => {
    const result = this.docsById[docId];
    if (result === NotLoaded) {
      this.queryDoc(docId); // register listener
    }

    // TODO: should not need docsById, state should be enough;
    //         but in some instances we are sharing the same state for multiple...
    //          Fix: use a global "FLUX-like" "scheduler" system, instead of having each state use it's own "scheduler"
    return result;
  }

  onDoc = (docId, cb) => {
    const unsubscribeCb = this.onUpdate(changed => {
      Verbose && console.debug(this.getDebugTag(), 'onDoc->onUpdate', changed);
      if (docId in changed) {
        cb(docId, this.docsById[docId]);
      }
    });

    const existing = this.getDocOrLoadFromCache(docId);
    if (existing !== NotLoaded) {
      // already loaded
      cb(docId, existing);
    }

    // send out query
    this.queryDoc(docId, true);

    return unsubscribeCb;
  }

  async waitForDoc(docId) {
    const result = await this._docPromisesById[docId];
    await sleep(10);    // hackfix: this is because `onDoc` will be invoked later by `state.scheduleUpdate`
    return result;
  }

  /**
   * Registers snapshot listener (if not listening already) on given doc and returns promise of doc.data().
   */
  queryDoc = async (docId, ignoreCache = false) => {
    this._started = true;

    let doc;
    if (!ignoreCache) {
      // get cached (but still send out query)
      doc = this.getDocOrLoadFromCache(docId);
      if (doc !== NotLoaded) {
        Verbose && console.debug('queryDoc -> loadFromCache', doc?.id || doc, this.getDebugTag());
      }
    }

    if (this._docPromisesById[docId]) {
      // already sent out query
      if (doc === NotLoaded) {
        await this._docPromisesById[docId];
      }
    }
    else {
      // start new query
      ++this._busy;
      this._docPromisesById[docId] = new Promise((resolve, reject) => {
        // did not query yet -> send out query now
        const onSnapshot = doc => {
          // perfDefault.onSnapshot(doc, this.getDebugTag());
          --this._busy;
          Verbose && console.debug('queryDoc -> onSnapshot', doc.id, this.getDebugTag());
          const entry = this._onDocSnapshot(doc);
          this.writeDocToCache(doc);
          resolve(entry);
        };
        const unsubscribe = this.doc(docId).onSnapshot(snapshotCfg,
          onSnapshot,
          err => {
            this._onSnapshotError(err);
            reject(err);
          }
        );

        this._unsubscribeCallbacks[docId] = unsubscribe;
        this._unsubscribeCallbacksArray.push(unsubscribe);
      });
    }

    return doc !== NotLoaded ? doc : this._docPromisesById[docId];
  }

  /**
   * @param {string[]} ids
   */
  async queryDocs(ids) {
    if (this._started) {
      throw new Error('[INTERNAL ERROR] Tried to call queryDocs after already sending out a previous query. Did you mean to call `queryMissingDocs` instead?');
    }

    return this._queryDocs(ids);
  }

  /**
   * @param {string[]} ids
   */
  async queryMissingDocs(ids) {
    const missingIds = ids?.filter(id => !this._docPromisesById[id]);
    await this._queryDocs(missingIds);

    return ids?.map(id => this.getDocById(id));
  }

  async _queryDocs(ids) {
    if (!ids?.length) {
      // nothing to do, but trigger events anyway
      this._forceUpdate();
      return EmptyArray;
    }
    else {
      // TODO: this currently triggers an update for every doc query -
      //        can we accumulate all promises into a single "page update"?
      //      Alternatively: can we get the renderer to render changes, rather than everything.
      return Promise.all(ids.map(id => this.queryDoc(id)));
    }
  }

  stopListen(docId) {
    const unsubscribe = this._unsubscribeCallbacks[docId];
    if (unsubscribe) {
      // TODO: 
      //    1. call this._unsubscribeCallbacks[docId]()
      //    2. remove corresponding listeners from state as well?
      // unsubscribe();
      // if (!this._emitter.getListenerCount()) {
      //   // no one interested anymore -> stop listening for this content item
      //   // delete from cache and fully unsubscribe after a minute
      //   setTimeout(() => {
      //     if (!this._emitter.getListenerCount()) {
      //       this._unsubscribeCallbacks[contentId]();
      //       delete this._unsubscribeCallbacks[contentId];
      //       delete this.docsById[contentId];
      //     }
      //   }, 60 * 1000);
      // }
    }
  }

  // ###########################################################################
  // write docs
  // ###########################################################################

  async addDoc(data) {
    const docRef = this.newDocRef();
    return this.setDoc(docRef.id, data);
  }

  async addToDocArray(id, fieldName, newArrayItem) {
    let entry = this.getDocById(id);

    if (entry !== NotLoaded) {
      // merge locally
      entry = entry || {};
      const arr = entry[fieldName] || (entry[fieldName] = []);
      arr.push(newArrayItem);
      this._onDocUpdate(id, entry, true);
    }

    // add to DB (arrayUnion)
    await this.doc(id).update({
      [fieldName]: firebase.firestore.FieldValue.arrayUnion(newArrayItem)
    })

    return entry;
  }

  async deleteDocArray(id, fieldName, arrayItem) {
    // make sure, its loaded before trying to merge it locally (TODO: don't merge locally if not loaded instead)
    let entry = this.getDocById(id);

    if (entry !== NotLoaded && entry?.[fieldName]) {
      // merge locally
      const arr = entry[fieldName];
      arr && pull(arr, arrayItem);
      this._onDocUpdate(id, entry, true);
    }

    // delete remotely
    await this.doc(id).update({
      [fieldName]: firebase.firestore.FieldValue.arrayRemove(arrayItem)
    })

    return entry;
  }

  /**
   * 
   */
  async setDoc(docId, upd, merge = false) {
    if (merge) {
      // make sure, its loaded before trying to merge it
      await this.queryDoc(docId);
    }
    this.overrideDoc(docId, upd, merge);

    // sanity check: if there is `undefined` in the values, the update will fail
    Object.values(upd).includes(undefined) && console.error(this.getDebugTag(), 'invalid update must not contain `undefined`:', upd);

    try {
      await this.doc(docId).set(upd, { merge });
    }
    catch (err) {
      this._onError(`could not setDoc - \ndoc('${docId}').set`, upd, '\n\n', err);
    }

    // NOTE: returned object has _id prop fused in with `data` (see `_registerDoc`)
    return this.getDocById(docId);
  }

  async deleteDoc(docId) {
    this.overrideDoc(docId, null, false);

    try {
      return this.doc(docId).delete();
    }
    catch (err) {
      this._onError(`could not deleteDoc - \ndoc('${docId}').delete()\n\n`, err);
    }
  }

  // ########################################################################
  // private methods
  // ########################################################################

  _onDocSnapshot = (doc) => {
    return this._onDocUpdate(doc.id, doc.data() || null);
  }

  _registerDoc(docId, docData) {
    if (docData !== null && docData !== undefined) {
      docData = docToSimpleObject(docId, docData);
    }
    // if (docData === null) {
    //   delete this.docsById[docId];
    // }
    // else 
    {
      this.docsById[docId] = docData;
    }
    return docData;
  }

  _onDocUpdate = (docId, newData, isOverride = false) => {
    // clear cache
    this._docsArray = null;

    // we are considered as "having loaded" now
    this._hasLoaded = true;

    // commit data
    const oldData = this.docsById[docId];
    newData = this._registerDoc(docId, newData);

    if (!isOverride) {
      // remove from pages
      //  TODO: don't remember why; feels like a hackfix for `contentObjects.trackedObjects`
      //    NOTE: it should probably come back via `onSnapshot` later if its still there
      // this._removeDocFromPages(docId);
    }
    else {
      // add to pages as well? -> cannot add to pages like this.
    }

    // console.warn('[FirestoreContainer update]', docId, newData);

    // hooks
    if (this.onBeforeDocUpdateCb) {
      this.onBeforeDocUpdateCb(this, docId, newData, oldData, isOverride);
    }
    if (this.onBeforePageUpdateCb) {
      const isFirstTimeForThisPage = null;  // NOTE: does not apply to individual docs
      this.onBeforePageUpdateCb(this, null, null, isOverride, isFirstTimeForThisPage);
    }

    this._setState(docId, newData);

    this.emitter.emit('update');

    return newData;
  }

  _removeDocFromPages(docId) {
    for (let i = 0; i < this._pages?.length; ++i) {
      const page = this._pages[i];
      if (page.find(entry => entry.id === docId)) {
        // remove doc from this page
        this._pages[i] = this._pages[i].filter(entry => entry.id !== docId);
        break;
      }
    }
  }


  _onDocsUpdate = (iPage, addedOrModifiedDocsArray, snapshot, isOverride = false) => {
    // clear cache
    this._docsArray = null;

    // we are considered as "having loaded" now
    this._hasLoaded = true;

    // determine whether this is the first time we receive data for this page
    const isFirstTimeForThisPage = !this._pageSnapshots[iPage];

    // console.debug('onDocsUpdate', this._getUniquePageKey(), '\n ', addedOrModifiedDocsArray.map(d => d.id), isFirstTimeForThisPage);

    // set all old docs to null (TODO: better manage deleted docs)
    const missingIds = new Set();
    const oldPageDocs = this._pages[iPage];
    if (oldPageDocs) {
      for (const doc of oldPageDocs) {
        missingIds.add(doc.id);
      }
    }

    // store in page array
    this._pages[iPage] = addedOrModifiedDocsArray;
    this._pageSnapshots[iPage] = snapshot; // NOTE: could be null in case of override

    // TODO: properly commit snapshot.docChanges() (if `snapshot` is not null)

    // commit changes
    const upd = {};
    for (const doc of addedOrModifiedDocsArray) {
      const docId = doc.id;
      let newData = doc.data();

      const oldData = this.docsById[docId];
      missingIds.delete(docId);
      // if (newData) {
      newData = this._registerDoc(docId, newData);
      // }

      upd[docId] = newData; // new state update

      if (this.onBeforeDocUpdateCb) {
        // WARNING: If this hook triggers successive render calls, it might render stale data, since not all data has been committed yet.
        this.onBeforeDocUpdateCb(this, docId, newData, oldData, isOverride);
      }
    }

    // handle deletions
    for (const missingId of missingIds) {
      if (this.onBeforeDocUpdateCb) {
        this.onBeforeDocUpdateCb(this, missingId, null, this.docsById[missingId], isOverride);
      }
      // this doc was around before but has been deleted -> remove from `docsById`
      // NOTE: it is already ensured to not be in `_pages` anymore
      this.docsById[missingId] = null;
      // this._removeDocFromPages(missingId);
    }
    // console.warn('[FirestoreContainer._onDocsUpdate]', docId, newData);

    // hooks
    if (this.onBeforePageUpdateCb) {
      this.onBeforePageUpdateCb(this, iPage, snapshot, isOverride, isFirstTimeForThisPage);
    }

    this._triggerPageEvents(upd);

    return upd;
  }

  _forceUpdate() {
    this._triggerPageEvents(EmptyObject);
  }

  _triggerPageEvents(upd) {
    // NOTE: this is somewhat of a hackfix to make sure that listeners are informed about state updates, even though there wasn't really one, due to lack of hydration (we mostly use this in case the FirestoreContainer is a slave to another to get data that depends on another. If that other container is empty, we won't have anything happen here, but listeners still must be informed, so we have this.)
    this._hasLoaded = true;
    this._started = true;

    // TODO: get rid of mostly unused "emitter"
    this.state.setState(upd);

    this.emitter.emit('update');
  }

  _setState = (docId, obj) => {
    this.state.setState(buildStateUpdate(docId, obj));
  }
}

export default FirestoreContainer;