import { decorateClasses } from '../util/domUtil';
import { showNotLoggedInUserActionWarning } from './userActions';
import ECMFlags from '../api/ECMFlags';
import { ecmContainer } from '../api/ecm';
import { TagRenderContext } from 'src/api/tags/TagConfig';

const Verbose = true;

// ##################################################################################################################
// Config + render constants
// ##################################################################################################################

/**
 * Buttons inside of 'div.ecm-buttons-ctn'
 */
function tagEcmButtonSelector(ecmName) {
  return `.add-${ecmName.toLowerCase()}-button`;
}

// ##################################################################################################################
// ToggleECMButtons
// ##################################################################################################################

/**
 * Render the buttons to add/remove ECM associations inside of $tagEl.find('.ecm-buttons-ctn')
 */
export function renderTagToggleECMButtons(tagRenderContext, $tagEl, tagName) {
  // only re-render ECM buttons if buttons are shown
  const $ecmCont = $tagEl.find('.ecm-buttons-ctn,.containerbuttons');
  if (!$ecmCont.is(':visible')) {
    return;
  }

  for (let ecmFlag of ECMFlags.values) {
    // render button
    const ecmName = ECMFlags.getName(ecmFlag);
    const selector = tagEcmButtonSelector(ecmName);
    const $btn = $tagEl.find(selector);
    decorateClasses($btn, {
      active: !!ecmContainer.hasECM(tagName, ecmFlag)
    });

    // hook up click events: add/remove ECM
    $btn.off('click').on('click', async evt => {
      if (showNotLoggedInUserActionWarning()) {
        return;
      }

      Verbose && console.debug('before `toggleECM`', tagName, ecmFlag, Array.from(ecmContainer.getECMFlagsForTag(tagName)));

      await ecmContainer.toggleECM(tagName, ecmFlag);
    });
  }

  Verbose && console.debug('renderTagToggleECMButtons', tagName, TagRenderContext.nameFromForce(tagRenderContext), Array.from(ecmContainer.getECMFlagsForTag(tagName)));
}