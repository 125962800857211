import { prepareTemplateElement } from '../util/domUtil';
import { isWorldName, initWorlds as initWorldData } from '../api/worlds';
import { TagRenderContext } from '../api/tags/TagConfig';
import { initRenderTagSearch } from './tags/wikiSearch';
import { isHomePage } from 'src/pages';

export let $worldListCont;

async function initRenderWorldListNow() {
  if ($worldListCont) {
    // don't try to init more than once
    return;
  }

  // prepareTemplateElement($worldList, '.wiki-title-object');
  $worldListCont = $('.container-list-worlds');

  try {
    await initWorldData();
    initRenderTagSearch(TagRenderContext.WorldSearch, $worldListCont);
  }
  catch (err) {
    console.error('could not load worlds:', err);
  }
}

export function initRenderWorldList() {
  const $worldMenuBtn = $('.world-menu-btn,.search-button');
  $worldMenuBtn.on('click', initRenderWorldListNow);

  // if ($worldListCont.is(':visible')) {
  if (isHomePage()) {
    // load + render worlds right away
    initRenderWorldListNow();
  }
}