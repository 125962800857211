import { waitForGoogleMapsInit } from 'src/web-apis/gapi/maps';

// /**
//  * @see https://developers.google.com/maps/documentation/javascript/reference/geocoder#GeocoderAddressComponent
//  */
// function componentsToString(addressComponents) {
//   return addressComponents.map(comp => comp.long_name).join(', ');
// }

// function getCountry(addressComponents) {
//   const comp = addressComponents.find(comp => comp.types?.includes('country'));
//   if (!comp) {
//     return null;
//   }
  
//   const {
//     long_name: name,
//     short_name: code
//   } = comp;
  
//   return { name, code };
// }

/**
 * API - @see https://developers.google.com/maps/documentation/javascript/places-autocomplete
 * Example1 - @see https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete
 */
export async function createCityAutocomplete($input, config) {
  await waitForGoogleMapsInit();

  // Create the autocomplete object, restricting the search predictions to
  // geographical location types.
  config = Object.assign({
    language: 'en',   // make sure that we can parse the country by enforcing English

    // see: https://developers.google.com/maps/documentation/javascript/places-autocomplete?hl=zh-tw#restrict-the-search-to-a-specific-country
    types: [
      '(cities)',
      // 'administrative_area2'   // NOTE: only one type is allowed
    ]
  }, config);
  const autocomplete = new window.google.maps.places.Autocomplete($input[0], config);

  autocomplete.forget = () => {
    autocomplete.place = autocomplete.placeRaw = null;
  };

  // Data to be contained in our call to `getPlace`
  // for all fields, see: https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
  autocomplete.setFields([
    // 'address_component', // this rakes up the bill by a factor of 6+x (since it sends a details query)
    'place_id'
  ]);

  // When the user selects an address from the drop-down, populate the
  // address fields in the form.
  autocomplete.addListener('place_changed', () => {
    const placeRaw = autocomplete.getPlace();

    const placeName = $input.val();
    const {
      place_id: placeId,
      // address_components: addressComponents
    } = placeRaw;

    // NOTE: we are not querying the address_components
    // const {
    //   name: countryName,
    //   code: countryCode
    // } = getCountry(addressComponents || EmptyArray) || EmptyObject;
    const comps = placeName.split(',');
    const countryName = comps[comps.length-1].trim();
    // const countryCode = ;

    const place = {
      placeId,
      // placeName: componentsToString(addressComponents),
      placeName,
      // countryCode,
      countryName
    };

    autocomplete.placeRaw = placeRaw;
    autocomplete.place = place;

    console.debug('[Google Autocomplete] place changed', placeRaw, '\n', place);

    config?.placeChanged?.(place);
  });

  return autocomplete;
}

export async function search(searchTerm) {

}