import { decorateClasses, decorateAttr } from 'src/util/domUtil';

const WebflowSelectedClass = 'w--redirected-checked';

/**
 * NOTE: works on checkboxes as well as radio buttons
 */
export function getSelectedCheckboxEl($cont) {
    // const $radio = this.$tab.find('.individual-collection-settings [type="radio"]:checked');
  return $cont.find('.' + WebflowSelectedClass);
}

/**
 * NOTE: works on webflow-styled checkboxes as well as radio buttons
 */
export function setSelectedCheckboxEl($checkbox, selected) {
  const $targetEl = $checkbox.prev();  // weird webflow stuff
  decorateClasses($targetEl, {
    [WebflowSelectedClass]: selected
  });
}

export function setDisabledCheckboxEl($checkbox, disabled, dontApplyStyles = false) {
  decorateAttr($checkbox, { disabled });

  if (!dontApplyStyles || !disabled) {
    // styles are applied to/removed from parent
    decorateClasses($checkbox.parent(), { disabled });
  }
}

export function isWebflowInputEl($el) {
  return $el.parent().hasClass('w-checkbox');
}

export function isSelectedCheckboxEl($checkbox) {
  const $targetEl = $checkbox.prev();  // weird webflow stuff
  return $targetEl.hasClass(WebflowSelectedClass);
}