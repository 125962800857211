import firebase from 'firebase/app';
import 'firebase/storage';

const baseUrl = 'https://firebasestorage.googleapis.com/v0/b/discovereel-994a6.appspot.com/o/';

export function getStorageUrl(relativePath) {
  const path = encodeURIComponent(relativePath);
  return `${baseUrl}${path}`;
}

export function getStorageImageUrl(relativePath) {
  return `${getStorageUrl(relativePath)}?alt=media`;
}

// export function getUserFilePath(uid, fname) {
//   return `users/${uid}/${fname}`;
// }

export function getReelFileOfSizePath(reelId, fname, config) {
  const size = `${config.width}x${config.height}`;

  // TODO: [brand-reel] fix path `users` -> `reel`
  return `users/${reelId}/${size}/${fname}`;
}

export function getStorageRef(path) {
  var storageRef = firebase.storage().ref();

  return storageRef.child(path);
}

export async function checkIfExists(path) {
  try {
    const ref = getStorageRef(path);
    const downloadUrl = await ref.getDownloadURL();
    return true;
  }
  catch (err) {
    if (err?.code === 'storage/object-not-found') {
      return false;
    }
    throw err;
    // return NotLoaded;
  }
}

export async function putFile(path, fileOrBlob) {
  const ref = getStorageRef(path);
  return ref.put(fileOrBlob);
}