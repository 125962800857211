import { SaveChangesButton } from 'src/render/userSettings/SubmitButton';
import State from 'src/util/State';
import { updatePublicUserData } from 'src/api/users/users';
import reelInfoContainer from '../../api/reel/reelInfo';
import { authState, onUIDChanged } from '../../auth';
import userProfessionsContainer from '../../api/users/reelProfessions';
import { serializeForm2Object, populateForm } from '../../util/formUtil';
import { decorateClasses, addInputTextChangedEvent } from 'src/util/domUtil';
import { createCityAutocomplete } from 'src/web-apis/gapi/placesAutocomplete';
import { isSelectedCheckboxEl, setSelectedCheckboxEl, getSelectedCheckboxEl, setDisabledCheckboxEl } from 'src/webflow/webflowFormHelpers';
import { sleep } from 'src/util/sleep';
import UserStatus, { isUserStatusNew } from 'src/api/users/UserStatus';
import ReelManager from 'src/api/reel/ReelManager';
import { startWebflowAnimation } from 'src/renderUtil/animUtil';
import { trackUserReelUpdate } from 'src/api/third-party/mixpanel';
import { getPageReelId } from 'src/render/pages/reel/reelPageUtils';


/**
 * First page of user settings
 */
export default class ReelSettingsRenderer {
  $cont;

  $firstNameInput;
  $lastNameInput;
  $publicEl;
  $professionButtonsCont;
  $professionDetailsCont;
  saveBtn;

  constructor(reelId, $parent) {
    this.reelId = reelId;
    this.$parent = $parent;
    this.state = new State();
  }

  init() {
    this.$cont = this.$parent.find('.personal-details-ctn');

    this.initRender();
    this.initDataBinding();
  }

  isVisible() {
    return this.$cont.is(':visible');
  }

  initDataBinding() {
    onUIDChanged((_uid) => {
      this.clear();

      const { reelId } = this;

      reelInfoContainer.onDoc(reelId, this._onNewReelInfo);
      userProfessionsContainer.onDoc(reelId, this._onNewProfessions);
    }, false);
  }

  async initRender() {
    const { $cont } = this

    // console.debug('UserProfileRenderer.initRender()');

    this.initRenderReelInfo();
    this.initRenderProfessions();

    this.saveBtn = new SaveChangesButton($cont, this.state, this.submit);

    this.render();
  }


  // ###########################################################################
  // Reel basic info
  // ###########################################################################

  _onNewReelInfo = reelId => {
    const data = reelInfoContainer.getDocById(reelId);

    // NOTE: we cannot easily fill in name from authentication system, because its just one displayName, and it does not discern between first + last name
    // const userPublic = authState.userInfo;
    // defaultsDeep(data, userPublic);

    if (!data) {
      return;
    }

    if (data.firstName) {
      this.$firstNameInput.val(data.firstName);
    }
    if (data.lastName) {
      this.$lastNameInput.val(data.lastName);
    }
    if ('public' in data) {
      setSelectedCheckboxEl(this.$publicEl, data.public || false);
    }
    if (data.place?.placeName) {
      this.$cityInput.val(data?.place?.placeName);
    }

    this.place = data.place;

    this.renderPlace();
  }

  initRenderReelInfo() {
    this.$firstNameInput = this.$cont.find('.first-name');
    this.$lastNameInput = this.$cont.find('.last-name');
    this.$publicEl = this.$cont.find('[name="public"]');
    this.$checkPublicEl = this.$cont.find('.anim-list-public-steps');

    this.initPlaceInput();
    this.initPublicCheck();
  }


  // ###########################################################################
  // make reel public
  // ###########################################################################

  initPublicCheck() {
    this.$publicEl.off('click').on('click', async (evt) => {
      const isPublic = isSelectedCheckboxEl(this.$publicEl);
      if (isPublic) {
        // is public -> make private again (just leave unchecked)
        return;
      }

      // do not check checkbox right away
      evt.preventDefault();

      // reset results
      this.resetCheckPublicConditionsPromise();

      // start getting results
      this.getCheckPublicConditionsPromise();

      setTimeout(() => {
        if (this.results?.ok) {
          // user is ok, and it did not take too long
          // -> just check checkbox right away!
          setSelectedCheckboxEl(this.$publicEl, true);
        }
        else {
          // data is taking a bit of time, or the user does not fulfill or constraints
          // -> open modal, if we are not getting results right away
          startWebflowAnimation('list-public-steps');

          // render modal contents
          this.renderMakeReelPublicModal();
        }
      }, 50);
    });
    this.$checkPublicEl.off('click').on('click', evt => {
      // NOTE: button already opens the modal

      // reset results
      this.resetCheckPublicConditionsPromise();

      // render modal contents
      this.renderMakeReelPublicModal();
    })
  }

  resetCheckPublicConditionsPromise() {
    // reset results
    this.checkPromise = null;
    this.results = null;
  }

  getCheckPublicConditionsPromise() {
    if (!this.reelManager) {
      // init ReelManager
      this.reelManager = new ReelManager(this.reelId);
      this.reelManager.initECM();
    }
    if (!this.checkPromise) {
      this.checkPromise = this.reelManager.checkReelPublicConditions().then(results => {
        return this.results = results;
      });
    }
    return this.checkPromise;
  }

  async renderMakeReelPublicModal() {
    const $modal = $('.steps-public-reel');
    const $loading = $modal.find('.loading');
    $loading.removeClass('hidden');

    // render submit button
    const $submitBtn = $modal.find('.submit-button');
    $submitBtn.off('click');
    decorateClasses($submitBtn, {
      disabled: true
    });

    // get results!
    const results = await this.getCheckPublicConditionsPromise();

    // render results!
    decorateClasses($submitBtn, {
      disabled: !results.ok
    });

    // button click handler
    $submitBtn.off('click').on('click', evt => {
      if (!results.ok) {
        return;
      }

      // select! done!
      setSelectedCheckboxEl(this.$publicEl, true);

      // close modal
      $modal.hide();
    });

    // render results
    const $form = $modal.find('form');
    $loading.addClass('hidden');
    populateForm($form, results.resultsByName);

    // disable all checkboxes (but don't style them as disabled)
    setDisabledCheckboxEl($form.find('input'), true, true);

    // log to console
    console.log(`Can make reel public: ${results.ok} - Conditions:\n${JSON.stringify(results.resultsByName, null, 2)}`);
  }

  // ###########################################################################
  // places
  // ###########################################################################

  async initPlaceInput() {
    this.$cityInput = this.$cont.find('.city');
    addInputTextChangedEvent(this.$cityInput.off('keyup'), this.forgetPlace);
    this.$cityResultCont = this.$cont.find('.city-result-ctn');

    this.cityAutoComplete = await createCityAutocomplete(this.$cityInput, {
      placeChanged: this.handlePlaceChanged
    });
  }

  handlePlaceChanged = () => {
    this.place = this.cityAutoComplete.place;

    this.renderPlace();
  }

  forgetPlace = () => {
    // this.cityAutoComplete.forget();
    this.place = null;

    this.renderPlace();
  }

  renderPlace = () => {
    decorateClasses(this.$cityInput, {
      good: !!this.place
    });
    decorateClasses(this.$cityInput, {
      bad: !this.place
    });
  }

  // ###########################################################################
  // professions
  // ###########################################################################

  _onNewProfessions = reelId => {
    const data = userProfessionsContainer.getDocById(reelId);

    populateForm(this.$professionsForm, data, 'data-profession');

    // render forms tate based on toggle state 
    // NOTE: will not do anything for brand reels
    Array.from(this.$toggles).forEach(toggle => this.renderProfessionInputTabForToggleState($(toggle)));
  }

  initRenderProfessions() {
    // const $professionButtonsCont = this.$professionButtonsCont = this.$cont.find();
    // const $professionBtns = $professionButtonsCont.find('.w-tab-menu .w-tab-link');
    // const $professionPanels = this.$cont.find('.add-role-panel,.profession-panel');
    // const $inputs = $professionPanels.find('input');
    // console.debug('initRenderProfessions');
    this.$professionsForm = this.$cont.find('form[data-name="form-user-professions"]');

    const $toggles = this.$toggles = this.$professionsForm.find('input[name="toggle"]');
    $toggles.
      off('change').
      on('change', async evt => {
        // toggle has been clicked

        // hackfix: webflow checkboxes use classes, and they update them after this event handler...
        await sleep();

        const $toggle = $(evt.target);
        // const enabled = $toggle.prop('checked');
        const enabled = isSelectedCheckboxEl($toggle);
        if (!enabled) {
          // clear
          const $tab = $toggle.closest('[data-profession]');
          const $inputs = $tab.find('input:not([name="toggle"])'); // all other inputs
          // $inputs.val('');
        }
        this.renderProfessionInputTabForToggleState($toggle);

        evt.preventDefault();
      });

    const $allActiveEls = this.$professionsForm.find('input[name="active"]');
    $allActiveEls.off('change').on('change', evt => {
      // active has been clicked
      const $active = $(evt.target);
      const enabled = $active.prop('checked');

      // if (enabled) {
      // }

      // disable all other actives
      $allActiveEls.prop('checked', false);

      // cannot disable, only enable
      $active.prop('checked', true);

      evt.preventDefault();
    });
  }

  renderProfessionInputTabForToggleState = ($toggle) => {
    // get current state
    const $tabContent = $toggle.closest('[data-profession]');
    // const enabled = $toggle.prop('checked');
    const enabled = isSelectedCheckboxEl($toggle);
    // const profession = $tabContent.attr('data-profession');

    // identify tab name -> tab menu -> tab menu button
    const $tabPane = $toggle.closest('.w-tab-pane');

    this.renderProfessionInputTab($tabPane, enabled, $tabContent);
  }

  renderProfessionInputTab = ($formCont, enabled = true, $tabContent = null) => {
    const tabName = $formCont.attr('data-w-tab');
    const $tabsCont = $formCont.closest('.w-tabs');
    const $tabsMenu = $tabsCont.find('.w-tab-menu');
    const $menuBtn = $tabsMenu.find(`[data-w-tab="${tabName}"]`);
    decorateClasses($menuBtn, {
      selected: enabled
    });

    // enable/disable input fields based on toggle state
    if ($tabContent) {
      const $inputs = $tabContent.find('input:not([name="toggle"])'); // all other inputs
      $inputs.prop('disabled', !enabled);
    }
  }

  render() {
    // nothing to do...
  }

  // ###########################################################################
  // Submit + clear
  // ###########################################################################

  submit = async () => {
    const firstName = this.$firstNameInput.val();
    const lastName = this.$lastNameInput.val();
    const isPublic = isSelectedCheckboxEl(this.$publicEl);
    const { place = null, reelId } = this;

    // const userPublic = authState.userInfo;

    // if (!userPublic) {
    //   console.error('submitting without proper login');
    //   return;
    // }

    // TODO: no-promise-arrays
    const promises = [];

    // `userInfo` collection update
    const reelInfo = {
      public: isPublic,
      firstName,
      lastName,
      place,
      uid: authState.uid
    };

    let userPublicUpdate;
    let professionData;

    if (authState.uid === reelId) {
      // personal reel
      const displayName = `${firstName} ${lastName}`;  // NOTE: in some languages, this would be the other way around
      userPublicUpdate = {
        displayName
      };
      if (isUserStatusNew(authState.status) && reelInfoContainer.isBasicReelInfoComplete(reelInfo)) {
        userPublicUpdate.status = UserStatus.FilledOutBasicInformation
      }
    }

    // professions update
    professionData = serializeForm2Object(this.$professionsForm, 'data-profession');
    // professionData = pickBy(professionData, val => val.toggle);  // get rid of all that have been "unchecked"

    // save!
    promises.push(
      professionData && userProfessionsContainer.saveMyProfessions(reelId, professionData),

      // store user info in userInfo collection
      reelInfoContainer.saveReelInfo(reelId, reelInfo),

      // also save to displayName
      userPublicUpdate && updatePublicUserData(authState, userPublicUpdate)
    );

    await Promise.all(promises);

    // save to mixpanel
    trackUserReelUpdate({
      reelId,
      ...reelInfo,
      professions: professionData
    });
  }

  clear() {
    // TODO: might not be necessary though
  }
}