// import FirestoreContainer from '../../firebase/FirestoreContainer';
// import { authState } from 'src/auth';
// import { buildBrandReelId } from 'src/api/reel/reelUtil';

// class ReelContainer extends FirestoreContainer {
//   constructor() {
//     super('reels');
//     this.enableCache();
//   }
// }

// const reelContainer = new ReelContainer();
// export default reelContainer;