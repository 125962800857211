import EmptyArray from 'src/util/EmptyArray';
import Enum from 'src/util/Enum';
import GlobalConfig from 'src/GlobalConfig';
import CollectionAccessMode from 'src/api/reel/CollectionAccessMode';
import { isContentTypeReelCategory } from 'src/api/content/ContentType';

/**
 * @type {Enum}
 */
export const ModerationStatus = new Enum({
  NotReviewed: 0,
  ReviewedNotPublished: 1,
  ReviewedPublished: 2,
  Private: 3
});
export function isStatusPrivate(moderationStatus) {
  return moderationStatus === ModerationStatus.Private;
}


export const ContentActionType = new Enum({
  Publish: 1,
  Unpublish: 2,
  Feature: 3,
  StopFeature: 4,
  Delete: 5
});


/**
 * 
 */
export const ReasonsForNegativeActions = [
  {
    id: 1,
    label: 'I don\'t like it! :('
  },
  {
    id: 2,
    label: 'Some other really good reason'
  },
  {
    id: 3,
    label: 'Reason 3'
  }
];


/**
 * All parameters that decide the nature and process of content reviews.
 */
const ContentActionVoteCounts = Object.freeze({
  Publish: 3,
  Unpublish: 3,
  Feature: 3,
  StopFeature: 3,
  Delete: 10
});

/**
 * The privilege levels required to vote on each action.
 * NOTE: This refers to the privilege levels defined in privileges.js
 */
const ContentActionVotingPrivilege = {
  Publish: 'voteNewContent',
  Unpublish: 'voteNewContent',
  Feature: 'voteFeature',
  StopFeature: 'voteFeature',
  Delete: 'voteDelete'
};

const ContentActionRequiresFeedback = {
  Publish: 0,
  Unpublish: 1,
  Feature: 0,
  StopFeature: 1,
  Delete: 1
};

/**
 * For each action, a function is provided that determines whether or not 
 *  the given content action is available in given context.
 * Assumes moderator privileges.
 */
const ContentActionAllowed = {
  Publish: (moderationStatus, isFeatured) => {
    switch (moderationStatus) {
      case ModerationStatus.Private:
      case ModerationStatus.NotReviewed:
      case ModerationStatus.ReviewedNotPublished:
        return true;
    }
    return false;
  },

  /**
   * NOTE: if `Private` or `NotReviewed`, "unpublish" actually means "voting against publishing".
   */
  Unpublish: (moderationStatus, isFeatured) => {
    switch (moderationStatus) {
      case ModerationStatus.Private:
      case ModerationStatus.NotReviewed:
      case ModerationStatus.ReviewedPublished:
        return true;
    }
    return false;
  },

  Feature: (moderationStatus, isFeatured, contentId, contentReviews) => {
    return !isFeatured && (
      moderationStatus === ModerationStatus.ReviewedPublished ||    // either: published
      contentReviews.getCurrentUserHasVoted(ContentActionType.Publish, contentId) // or: this user has voted to publish
    );
  },

  StopFeature: (moderationStatus, isFeatured) => {
    return isFeatured;
  },

  Delete: (moderationStatus, isFeatured) => {
    return true;
  }
};


/**
 * The inverse actions are all actions that will be undone when doing the given action.
 */
const InverseActions = {
  Publish: [
    'Unpublish',
    'StopFeature'
  ],
  Unpublish: [
    'Publish',
    'Feature'
  ],
  Feature: [
    'Unpublish',
    'StopFeature'
  ],
  StopFeature: [
    'Feature'
  ]
  // Delete: [
  //   'Publish',
  //   'Feature'
  // ]
};

/**
 * When revoking a given action, also revoke these other actions.
 */
const RevokeActions = {
  Publish: [
    'Feature'
  ],
  Unpublish: [
  ],
  Feature: [
  ],
  StopFeature: [
  ]
};

// /**
//  * Opposite actions are the action that happens when "toggling off" given action
//  */
// const OppositeActions = {
//   Publish: 'Unpublish',
//   Unpublish: 'Publish'
// };


// ################################################################################################
// Public functions
// ################################################################################################


export function getContentActionVoteCount(actionType) {
  const actionName = ContentActionType.nameFromForce(actionType);
  return ContentActionVoteCounts[actionName];
}


export function getContentActionVotingPrivilege(actionType) {
  const actionName = ContentActionType.nameFromForce(actionType);
  return ContentActionVotingPrivilege[actionName];
}


export function doesContentActionRequireFeedback(actionType) {
  const actionName = ContentActionType.nameFromForce(actionType);
  return ContentActionRequiresFeedback[actionName];
}


export function isContentActionAllowed(actionType, moderationStatus, isFeatured, contentId, contentReviews) {
  const actionName = ContentActionType.nameFromForce(actionType);
  const fn = ContentActionAllowed[actionName];
  return fn && fn(moderationStatus, isFeatured, contentId, contentReviews);
}

/**
 * When voting for an action, your votes for the "inverse actions" will be revoked.
 */
export function getInverseActionTypes(actionType) {
  const actionName = ContentActionType.nameFromForce(actionType);
  const inverseActions = InverseActions[actionName];
  return inverseActions && inverseActions.map(ContentActionType.valueFromForce) || EmptyArray;
}

/**
 * When revoking a vote for an action, your votes for the "revoke inverse actions" will also be revoked.
 */
export function getRevokeActionTypes(actionType) {
  const actionName = ContentActionType.nameFromForce(actionType);
  const inverseActions = RevokeActions[actionName];
  return inverseActions && inverseActions.map(ContentActionType.valueFromForce) || EmptyArray;
}

/**
 * 
 */
export function mayAddObjectToCollection(contentObject, accessMode) {
  if (isContentTypeReelCategory(contentObject.type)) {
    // reels
    // return contentObject.public;
    return true;
  }

  if (accessMode === CollectionAccessMode.Private) {
    // can always add objects to private collections
    return true;
  }
  const nTags = contentObject?.tags?.length || 0;
  return nTags >= GlobalConfig.MinTagsForPublicCollections;
}

export function mayAddObjectToCollectionName(collectionName, contentObject, accessMode) {
  const { moderationStatus } = contentObject;
  if (doesCollectionNameRequireApproval(collectionName)) {
    // special treatment: can only submit for approval if not added before
    // return ModerationStatus.is.Private(moderationStatus);
    return !moderationStatus || ModerationStatus.is.Private(moderationStatus);
  }
  return mayAddObjectToCollection(contentObject, accessMode);
}


export function doCollectionNamesRequireApproval(collectionNames) {
  return collectionNames.some(doesCollectionNameRequireApproval);
}

/**
 * The 'MySubmissions' collection is special and is a way to publish content objects (but requiring approval for it).
 */
export function doesCollectionNameRequireApproval(collectionName) {
  return collectionName === 'MySubmissions';
}