import ReelSettingsRenderer from 'src/render/userSettings/ReelSettingsRenderer';
import ReelCollectionSettingsRenderer from 'src/render/userSettings/ReelCollectionSettingsRenderer';
import { showNotLoggedInUserActionWarning } from 'src/renderUtil/userActions';
import ReelHubSettingsRenderer from 'src/render/userSettings/ReelHubSettingsRenderer';
import { authState } from 'src/auth';
import { buildBrandReelId, getReelType } from 'src/api/reel/reelUtil';
import { getSelectedReelId, onReelSelected } from 'src/api/reel/reelSelection';
import ReelType from 'src/api/reel/ReelType';
import { startWebflowAnimation } from 'src/renderUtil/animUtil';


function getPersonalReelId() {
  return authState.uid;
}

function getBrandReelId() {
  const { uid } = authState;
  return buildBrandReelId(uid);
}


const tabSelectorsByReelType = {
  [ReelType.Personal]: '.settings-user',
  [ReelType.Brand]: '.settings-brand-reel'
};

const openAnimByReelType = {
  [ReelType.Personal]: 'settings-personal-reel',
  [ReelType.Brand]: 'settings-brand-reel'
}


export function initSettingsTabs() {
  const $settingsBtn = $('.settings-btn');
  $settingsBtn.on('click', async () => {
    if (showNotLoggedInUserActionWarning()) {
      return;
    }
    openReelSettings();
  });

  onReelSelected(handleReelUpdate);
}


function handleReelUpdate(reelId, oldReelId) {
  const oldReelType = getReelType(oldReelId);

  const tabSelector = tabSelectorsByReelType[oldReelType];
  const $oldSettingsTabsCont = $(tabSelector);

  if ($oldSettingsTabsCont.is(':visible')) {
    // currently showing old reel's settings -> show new reel's settings instead
    $oldSettingsTabsCont.hide();
    openReelSettings();
  }
}

function openReelSettings() {
  // settings (probably) have been opened
  const reelId = getSelectedReelId();
  const reelType = getReelType(reelId);

  // init settings tab
  const tabSelector = tabSelectorsByReelType[reelType];
  const $settingsTabsCont = $(tabSelector + ' .settings-info-ctn');
  tryInitSettingsRender(reelId, $settingsTabsCont);


  // show tab
  const openAnim = openAnimByReelType[reelType];
  startWebflowAnimation(openAnim);
}


const SettingsTabClasses = [
  ReelSettingsRenderer,
  ReelHubSettingsRenderer,
  ReelCollectionSettingsRenderer
];


let settingsTabs = {};

export function tryInitSettingsRender(reelId, $settingsTabsCont) {
  if (!reelId) {
    alert('(internal error) Could not initialize settings tab. Please retry or contact us for technical support.');
    return;
  }
  if (settingsTabs[reelId]) {
    // already inited
    return;
  }

  // start rendering all tabs, once setting has been clicked
  settingsTabs[reelId] = SettingsTabClasses.map(TabClazz => {
    const tab = new TabClazz(reelId, $settingsTabsCont);
    tab.init?.();
    return tab;
  });
}