import isFunction from 'lodash/isFunction';
import EmptyObject from 'src/util/EmptyObject';

export class WebflowTab {
  constructor(tabView, buttonEl, tabEl) {
    this.tabView = tabView;
    this.buttonEl = buttonEl;
    this.$btn = $(buttonEl);

    this.tabEl = tabEl;
    this.$tab = $(tabEl);

    this.isSelected = false;
    this.hasSelectedBefore = false;

    this.name = this.buttonEl.textContent;
  }

  isVisible() {
    return this.$tab.is(':visible');
  }

  notifySelected(isSelected) {
    if (isSelected === this.isSelected) {
      return;
    }

    this.isSelected = isSelected;
    if (isSelected) {
      if (!this.hasSelectedBefore) {
        this.selectedFirstTime();
        this.hasSelectedBefore = true;
      }
      this.selected();
    }
    else {
      this.deselected();
    }
  }

  init() {
  }

  selectedFirstTime() {
  }

  selected() {
  }

  deselected() {
  }
}


/**
 * NOTE: this was written without jquery, before porting it to this project. That's why there is a mix between jquery and vanilla
 */
export default class WebflowTabView {
  selectedTab;
  tabs = [];
  tabsByClass = new Map();

  constructor(cfg) {
    let { parent, contSelector } = cfg || EmptyObject;
    parent = parent || this.constructor.parent;
    contSelector = contSelector || this.constructor.contSelector;

    const contCont = parent || document;
    const cont = contCont.querySelector(contSelector);
    if (!contSelector) {
      throw new Error(`missing "static contSelector" property (make sure it is static!)`);
    }
    if (!cont) {
      const msg = `could not find Webflow tab "${contSelector}" in `;
      console.error(msg, contCont);
      throw new Error(msg + 'container');
    }

    // store cont + $cont
    this.$cont = $(this.cont = cont);
  }

  init() {
    const { getWebFlowTabClass } = this.constructor;
    const { cont } = this;
    const buttonEls = this.buttonEls = Array.from(cont.querySelector('.w-tab-menu').children);
    const tabEls = Array.from(cont.querySelector('.w-tab-content').children);

    const tabs = this.tabs = tabEls.map((tabEl, i) => {
      const buttonEl = buttonEls[i];
      // buttonEl.addEventListener('click', evt => {
      //   this.selectTabIndex(i);
      // });

      // var visibilityObserver = new IntersectionObserver((entries, observer) => {
      //   entries.forEach(entry => {
      //     if (entry.intersectionRatio > 0) {
      //       this.selectTabIndex(i);
      //     }
      //   });
      // });
      // visibilityObserver.observe(tabEl);

      // construct tab
      const Clazz = getWebFlowTabClass(buttonEl, tabEl);
      if (!Clazz) {
        console.warn('getWebFlowTabClass did not return anything for tab', tabEl);
        return null;
      }

      const tab = new Clazz(this, buttonEl, tabEl);

      // add tab
      this.tabs.push(tab);
      let classTabs = this.tabsByClass.get(Clazz);
      if (!classTabs) {
        this.tabsByClass.set(Clazz, classTabs = []);
      }
      classTabs.push(tab);

      // init tab
      tab.init();

      return tab;
    }).filter(t => !!t);

    // hackfix: IntersectionObserver doesn't work properly here
    const updateSelection = () => {
      setTimeout(() => {
        tabs.forEach((tab, i) => {
          if (tab.isVisible()) {
            this.selectTabIndex(i);
          }
        });
      }, 300);
    };
    buttonEls.forEach(btn => btn.addEventListener('click', updateSelection));


    // // by default, first tab is selected
    // this.selectTabIndex(0);

    // automatically select the currenlty visible tab (if it is visible)
    updateSelection();
  }

  getTabsOfClass(Clazz) {
    return this.tabsByClass.get(Clazz);
  }

  selectTabIndex(i) {
    const newTab = this.tabs[i];
    if (this.selectedTab === newTab) {
      return;
    }

    console.log('[tab]', 'selected', i + 1, this.buttonEls[i].textContent);
    if (this.selectedTab) {
      this.selectedTab.notifySelected(false);
    }
    this.selectedTab = newTab;
    this.selectedTab.notifySelected(true);
  }
}