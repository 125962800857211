import NanoEvents from 'src/util/NanoEvents';
import {
  ContentActionType,
  ModerationStatus,
  getContentActionVoteCount,
  doesContentActionRequireFeedback,
  isContentActionAllowed,
  getContentActionVotingPrivilege
} from '../../api/content/ContentActionConfig';

import { authState } from 'src/auth';

import { doesUserHavePriv } from 'src/api/privileges';
import { getContentById } from '../../api/content/contentObjects';

import { genRandomString } from 'src/util/misc';

import {
  debugResetAll,
  toggleContentActionVote
} from '../../api/content/contentModeration';

import {
  startDevMode,
  stopDevMode,
  isDevMode,
  onDebugToggle
} from 'src/api/devtools';

import {
  getOrCreateToolCont,
  DevClass,
  DevHidden
} from '../../renderUtil/devtools';
import { getPageName } from '../../pages';


function toggleDevMode(evt) {
  // prevent multi-click weirdness
  // https://stackoverflow.com/questions/8238599/jquery-click-fires-twice-when-clicking-on-label
  evt.stopPropagation();
  evt.preventDefault();
  evt.stopImmediatePropagation();


  if (!isDevMode()) {
    startDevMode();
  }
  else {
    stopDevMode();
  }
}

/**
 * Make sure, given element will be toggled with the developer control toggle.
 */
export function decorateDebugFeature($el, onToggleCb) {
  if (!$el.hasClass(DevHidden)) {
    $el.addClass(DevHidden);
    $el.removeClass('moderator-toggle');
    $el.toggle(isDevMode());
    onDebugToggle(() => onToggleCb($el, isDevMode()));
  }
}

/**
 * Add global debug tools to the sidebar.
 */
export function initDebugFeatures() {
  if (getPageName() !== 'approval') {
    // no debug features anywhere but on approval page
    return;
  }
  const $toolbar = $('.div-iconos-second-bar');
  let $debugControl = $toolbar.find('.debug-control-buttons'); // debug main control button
  if (!$debugControl.length) {
    $debugControl = $(`<div class="debug-control-buttons" 
    style="display: flex; justify-content: center; align-items: center; margin-top: 26px; flex-direction: column;">
<!--div style="flex-grow: 1;"></div-->
<label class="switch tooltip">
  <input type="checkbox">
  <span class="slider round"></span>
  <span class="tooltiptext">Debug Mode</span>
</label>
</div>`);
    $toolbar.append($debugControl);
    // $toolbar.append($(`<div><img src="http://erg7.com/content/4.projects/10.network-device-status-icons/03%20icon_offline.png" style="width: 100%" title="offline" ></div>`));
    $debugControl.on('click', toggleDevMode);
  }
}

export function removeDebugFeatures() {
  stopDevMode();
}

function addDebugActionButton(actionType, contentId, $contentEl, isVote, label) {
  // get configuration
  const actionName = ContentActionType.nameFromForce(actionType);
  // const maxVoteCount = getContentActionVoteCount(actionType);
  // const requiresFeedback = doesContentActionRequireFeedback(actionType);


  // get more context data
  //const thisState = state.contentItems[contentId] || (state.contentItems[contentId] = {});
  const { uid } = authState;
  const content = getContentById(contentId);
  label = label || `Force ${actionName}`;
  // const { contentType, moderationStatus, isFeatured } = content;

  // const hasVoted = contentReviewCache.getHasVotedNow(actionType, contentId, uid);
  // const voteCount = contentReviewCache.getVoteCountNow(actionType, contentId);

  const $toolCont = $contentEl.find(`.${DevClass}`);

  const btnSel = `debug-action-${actionType}-btn`;
  const $btn = $(`<button class="debug-feature ${btnSel}">${label}</button>`);
  $btn.on('click', () => {
    const feedback = {};
    // always cast vote by simply using a randomized version as uid
    const randomUid = `${uid}!${genRandomString(8)}`;
    const myReasons = [1];

    if (isVote) {
      // only cast a vote
      toggleContentActionVote(randomUid, actionType, contentId, myReasons, false);
    }
    else {
      // force apply the action
      toggleContentActionVote(randomUid, actionType, contentId, myReasons, true);
    }
  });
  $toolCont.append($btn);
}


/**
 * Add some developer tools to given content element.
 */
export function debugDecorateContent(contentId, $contentEl) {
  // console.log('debugDecorateContent', isDevMode());
  
  if (!isDevMode()) {
    return;
  }

  const [existedBefore, $toolCont] = getOrCreateToolCont($contentEl, 'buttons-object-div', '.moderator-fields');
  if (!existedBefore) {
    // add force action buttons
    addDebugActionButton(ContentActionType.Publish, contentId, $contentEl);
    addDebugActionButton(ContentActionType.Unpublish, contentId, $contentEl);
    addDebugActionButton(ContentActionType.Feature, contentId, $contentEl);
    addDebugActionButton(ContentActionType.StopFeature, contentId, $contentEl);

    // add force vote buttons to 
    addDebugActionButton(ContentActionType.Publish, contentId, $contentEl, true, "Vote Publish");
    addDebugActionButton(ContentActionType.Unpublish, contentId, $contentEl, true, "Vote Unpublish");
    addDebugActionButton(ContentActionType.Feature, contentId, $contentEl, true, "Vote Feature");
    addDebugActionButton(ContentActionType.StopFeature, contentId, $contentEl, true, "Vote Stop Feature");

    // add reset button
    (() => {
      $toolCont.append(createResetButton(contentId));
    })();
  }
}

function createResetButton(contentId) {
  const btnSel = `debug-action-reset-all-btn`;
  const $btn = $(`<button class="debug-feature ${btnSel}">RESET!</button>`);
  $btn.on('click', () => {
    if (window.confirm("Are you sure?")) {
      debugResetAll(contentId);
    }
  });
  return $btn;
}
