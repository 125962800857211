import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';

function customizedMergeArray(objValue, srcValue) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}

export function mergeWithArrays(dst, ...sources) {
  return mergeWith(dst, ...sources, customizedMergeArray);
}

/**
 * If `p` returns boolean, `true` values are moved to the front, `false` to the back.
 * If `p` returns number, that is used instead for prioritizing amongst another.
 */
export function sortArrayEz(arr, p) {
  return arr.sort((a, b) => p(b) - p(a));
}