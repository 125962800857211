import lscache from 'lscache';

/**
 * @file
 * Just some basic utilities to deal with localStorage.
 * Uses lscache to automatically expire items, and also work around the max time limit.
 * 
 * @see https://github.com/pamelafox/lscache
 */

export function initLocalStorageCache() {
  lscache.setBucket('dr');
  lscache.setExpiryMilliseconds(5 * 24 * 60 * 60 * 1000); // 5 days

  setTimeout(1 * 60 * 1000, () => {
    // flush expired cache items after 3 minutes
    console.warn('flushing expired cache items');
    lscache.flushExpired();
  });
}

export function setLocalStorageItem(key, obj) {
  // window.localStorage.setItem(key, JSON.stringify(obj));
  lscache.set(key, JSON.stringify(obj));
}

export function getLocalStorageItem(key) {
  // const res = window.localStorage.getItem(key);
  const res = lscache.get(key);
  if (res) {
    return JSON.parse(res);
  }
  return null;
}
