import { getContentById } from '../content/contentObjects';



// ##################################################################################################################
// remember tags we added during the lifetime of this browser window
// ##################################################################################################################

/**
 * Keep track of all newly added tags (while this website is open).
 */
const addedTags = {};

export function rememberAddedTag(contentId, tagName) {
  const newTags = addedTags[contentId] = (addedTags[contentId] || []);
  newTags.push(tagName);
}

export function isTagNew(contentId, tagName) {
  return addedTags[contentId] && addedTags[contentId].includes(tagName);
}


// ##################################################################################################################
// content tag management
// ##################################################################################################################

export function getTags(contentId) {
  const contentData = getContentById(contentId);
  return contentData && contentData.tags;
}

export function hasTag(contentId, tagName) {
  const tags = getTags(contentId);
  // tagName = getRealTagNameFromCache(tagName);
  return tags && tags.length && tags.includes(tagName);
}
