import { authState } from 'src/auth';
import { ModerationStatus, doesCollectionNameRequireApproval } from 'src/api/content/ContentActionConfig';
import CollectionFilterQueryInterface from 'src/api/content/CollectionFilterQueryInterface';


// ###########################################################################
// Collection query interface
// ###########################################################################

export default class ReelFilterQueryInterface extends CollectionFilterQueryInterface {
  async _loadMissingDocs(contentIds) {
    // query all docs that haven't been queried before
    // console.debug(this.getDebugTag(), `before _loadMissingDocs`, contentIds, entries);

    let entries = await this.contentObjects.queryMissingDocs(contentIds);
    // console.debug(this.getDebugTag(), `_loadMissingDocs`, contentIds, entries);

    // handle invalid entries
    if (entries.some(e => !e)) {
      const deletedIds = contentIds.filter((id, i) => !entries[i]);
      console.error(this.getDebugTag(), `Found ${deletedIds.length} reference(s) to deleted contentIds ${deletedIds.join(', ')}`);
      entries = entries.filter(e => !!e);
    }

    // apply filter
    entries = this.filterArray(entries);

    return entries;
  }

  filterArray(objects) {
    return objects;
    
    // const {
    //   reelId,
    //   collectionName
    // } = this;

    // if (!doesCollectionNameRequireApproval(collectionName)) {
    //   // no approval necessary
    //   return objects;
    // }

    // // this collection requires approval (only show "high quality content" that was approved)

    // const isOwn = reelId === authState.uid;
    // // const isOwn = false;

    // // can only see objects if its your own list, or the object has been published
    // return objects.filter(
    //   obj => isOwn ||
    //     obj.moderationStatus === ModerationStatus.ReviewedPublished
    // );
  }
}
