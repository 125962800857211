import { sleep } from 'src/util/sleep';

const Verbose = false;

export default class SerialTaskQueue {
  _pendingSet = new Set();
  _queue = [];
  _donePromise;
  _running;

  // ###########################################################################
  // init
  // ###########################################################################

  constructor(debugTag) {
    this._debugTag = debugTag;

    this._reset();
  }

  _reset() {
    Verbose && this._log(this._debugTag, '_reset');
    this._donePromise = new Promise(resolve => {
      this._resolveDone = resolve;
    });
  }

  // ###########################################################################
  // public getters
  // ###########################################################################

  /**
   * Amount of pending elements
   */
  get length() {
    return this._pendingSet.size;
  }

  isEmpty() {
    return !this.length;
  }

  // ###########################################################################
  // queue methods
  // ###########################################################################

  enqueue(...cbs) {
    return Promise.all(cbs.map(cb => this._enqueueOne(cb)));
  }

  enqueueWithPriority(priority, ...cbs) {
    return Promise.all(cbs.map(cb => this._enqueueOne(cb, priority)));
  }

  enqueueLowPriority(...cbs) {
    return this.enqueueWithPriority(0, ...cbs);
  }

  enqueueMediumPriority(...cbs) {
    return this.enqueueWithPriority(100, ...cbs);
  }

  enqueueHighPriority(...cbs) {
    return this.enqueueWithPriority(1000, ...cbs);
  }

  /**
   * Enqueue only if the given callback is not already enqueued.
   * WARNING: Does not work with inline functions. Must be pre-defined functions, or else identity check cannot succeed.
   */
  enqueueIfNotInQueue(priority, cb) {
    if (this._pendingSet.has(cb)) {
      return;
    }
    this._enqueueOne(cb, priority);
  }

  // ###########################################################################
  // misc public methods
  // ###########################################################################


  /**
   * Wait until queue is empty.
   * Multiple calls to `waitUntilFinished` will resolve in the order they came in.
   */
  async waitUntilFinished() {
    this._donePromise = this._donePromise.then(() => {
      // add no-op to ensure that calls to `waitUntilFinished` are resolved in FIFO order
    });
    await this._donePromise;
  }

  /**
   * Pause until `start` is called again.
   */
  pauseAfterCurrent() {
    throw new Error("NYI");
  }

  // ###########################################################################
  // enqueue (private)
  // ###########################################################################

  /**
   * Enqueue a callback
   */
  _enqueueOne(cb, priority = 0) {
    Verbose && this._log(this._debugTag, 'add', cb.name, priority);
    if (!this._running) {
      this._running = true;
      setTimeout(this._run);
    }
    // console.debug('> task', cb.name);
    return new Promise((resolve, reject) => {
      const wrappedCb = async () => {
        try {
          // execute task
          const res = await cb();

          // resolve
          resolve(res);
        }
        catch (err) {
          reject(err);
        }
      };

      wrappedCb.__priority = priority; // hackfix
      wrappedCb.__name = cb.name;

      this._addCb(wrappedCb);
    });
  }

  // async enqueueWhenFinished(cb) {
  //   return this._donePromise = this._donePromise.then(() => {
  //     return this.enqueue(cb);
  //   });
  // }

  _addCb(cb) {
    this._queue.push(cb);
    this._pendingSet.add(cb);
    // return this._promiseChain = this._promiseChain.then(cb);
  }

  // ###########################################################################
  // run
  // ###########################################################################

  _run = async () => {
    this._running = true;
    try {
      while (!this.isEmpty()) {
        // make sure, higher priority items come before lower piority items
        this._queue.sort((a, b) => {
          // hackfix
          const ap = a.__priority;
          const bp = b.__priority;

          return bp - ap;
        });

        // dequeue next task
        const cb = this._queue.shift();
        Verbose && this._log(this._debugTag, 'run', cb.__name, cb.__priority);
        this._pendingSet.delete(cb);

        // execute task
        await cb();
      }
    }
    finally {
      this._reset();
      this._running = false;
      this._resolveDone();
    }
  }

  _log(...args) {
    console.debug(...args);
  }


  // ###########################################################################
  // synchronized
  // ###########################################################################

  synchronizedFunction(priority, cb) {
    return async function synchronized(...args) {
      const boundCb = cb.bind(null, ...args);
      boundCb.__name = cb.name.trim() || cb.toString.substring(0, 100);
      return this.enqueueWithPriority(priority, boundCb);
    }.bind(this);
  }
}