import { startWebflowAnimation } from 'src/renderUtil/animUtil';
import State from 'src/util/State';
import reelContainer from 'src/api/reel/reelContainer';
import { initRenderModal } from 'src/renderUtil/ModalBase';
import { prepareTemplateElement, cloneTemplateElement, decorateClasses } from 'src/util/domUtil';
import { selectReel, getSelectedReelId } from 'src/api/reel/reelSelection';
import reelInfoContainer, { getReelDisplayName } from 'src/api/reel/reelInfo';
import { authState } from 'src/auth';

const modalName = 'change-reel-modal';
let $template;


export function initReelSelectionButtons(cb = null) {
  const $btns = $('.change-reel-btn');

  $btns.off('click').on('click', async evt => {
    startWebflowAnimation(modalName);

    const reelId = await showReelSelectionModal();
    cb?.(reelId);
  });
}


class ReelSelectionModal {
  state = new State();
  _promise;

  async load() {
    return reelInfoContainer.queryReelsOfUser(authState.uid);
  }

  init($cont) {
    this._promise = this.load().
      then(reels => new Promise(resolve => {
        const $list = $cont.find('.reel-list');

        // prepare template
        $template = $template || prepareTemplateElement($list, '.reel');
        $template.removeClass('hidden');

        // clear
        $list.empty();

        for (const reelInfo of reels) {
          const { _id: reelId } = reelInfo;
          const name = getReelDisplayName(reelInfo);
          const isActive = getSelectedReelId() === reelId;
          const $reel = cloneTemplateElement($list, '.reel');
          const $btn = $reel.find('.reel-btn');
          const $label = $reel.find('.reel-name');

          decorateClasses($btn, {
            active: isActive,
            disabled: isActive
          });

          $label.text(name);
          $btn.on('click', evt => {
            selectReel(reelId);

            if (this._promise) {
              this._promise = null;
              resolve(reelId);
            }
          });

          $list.append($reel);
        }
      })).
      catch(err => {
        const msg = 'something went wrong - unable to load reels :(';
        console.error(msg, '\n', err);
        alert(msg);
      });
  }

  waitForModalSelection() {
    return this._promise;
  }
}


async function showReelSelectionModal() {
  const $cont = $('.' + modalName);
  const cbs = {
    createRenderer() {
      return new ReelSelectionModal();
    }
  }

  const modal = await initRenderModal($cont, cbs);
  const result = await modal.renderer.waitForModalSelection();
  modal.hide();
  return result;
}