import isFunction from 'lodash/isFunction';


class IntermediateResult {
  constructor(result, nextTask) {
    this.result = result;
    this.nextTask = nextTask;
  }
}


export function intermediate(result, nextTask) {
  return new IntermediateResult(result, nextTask);
}

/**
 * The problem: We are querying a list of data entries, and for each entry we need to
 * do more queries. If we waited for all queries to finish, it could vastly delay first
 * render time of any results.
 * 
 * Idea: Ideally, we want to render whatever data we have as soon
 * as it is ready and then let all remaining queries keep on running in the background.
 * 
 * This solution: Use async generator functions in combination with `Promise.race` to get whatever we can
 * at any point, and keep iterating until it has all finished.
 * 
 * @see https://technology.amis.nl/2019/04/30/javascript-pipelining-using-asynchronous-generators-to-implement-running-aggregates/
 */
export default class ParallelTaskQueue {
  /**
   * @type {Set}
   */
  _remainingTasks;

  /**
   * @param {Promise[]} tasks
   */
  constructor(tasks) {
    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });

    this.hasFinished = false;
    this._remainingTasks = new Set();
    tasks.forEach(this.addTask);
  }

  get hasMoreTasks() {
    return this._remainingTasks.size;
  }

  /**
   * Doing the work
   */
  [Symbol.asyncIterator] = async function* () {
    try {
      while (this.hasMoreTasks) {
        let result = await Promise.race(this._remainingTasks);
        // console.debug('PTQ', this._remainingTasks.size);

        if (isFunction(result)) {
          result = result();
        }

        if (result instanceof Promise) {
          // more work todo
          this.addTask(result);
          continue; // nothing to yield
        }
        // TODO: if (Symbol.asyncIterator in result) {  }
        else if (result instanceof IntermediateResult) {
          // something to return, and more work todo
          this.addTask(result.nextTask);
          result = result.result;
        }

        yield result;
      }
    }
    catch (err) {
      // fail :(
      // NOTE: should not happen, since we cussion each task with it's own error handler
      this.hasFinished = true;
      this._reject(err);
      return;
    }

    // done!
    this.hasFinished = true;
    this._resolve();
  }.bind(this);

  _taskToPromise(promiseOrCb) {
    const promise = isFunction(promiseOrCb) ? promiseOrCb() : promiseOrCb;
    const name = promiseOrCb?.name;
    if (promise) {
      // console.debug('adding task', name, promiseOrCb);
      promise.then(() => {
        // console.debug('finished task', name);
      });
    }
    else {
      // console.debug('non-async task', name);
    }
    return promise;
  }

  addTask = (promiseOrCb) => {
    if (this.hasFinished) {
      throw new Error('Racer has already finished. Failed to add task: ' + promiseOrCb);
    }

    let promise = this._taskToPromise(promiseOrCb);
    // console.log('addTask', promise, promiseOrCb)
    if (!promise?.then) {
      // non-asynchronous tasks
      return;
      // throw new Error('Invalid task; must be thenable: ' + promise);
    }

    /*task =*/
    const resultPromise = promise.then(result => {
      this._remainingTasks.delete(promise);
      return result;
    }, err => {
      console.error('task failed', err);
      this._remainingTasks.delete(promise);
      return null;
    });

    this._remainingTasks.add(promise);

    return resultPromise;
  }

  async drain() {
    let lastResult;
    for await (const result of this) {
      // nothing to do for now
      // console.debug('_processQueue', result);
      lastResult = result;
    }
    return lastResult;
  }
}


// hackfix for extending `Promise` and yet being able to success resolve + reject functions
// see: https://stackoverflow.com/a/41797215
const _proto = ParallelTaskQueue.prototype;
ParallelTaskQueue.prototype = Object.assign(_proto, Object.create(Promise.prototype),
  {
    then(...args) {
      return this._promise.then(...args);
    },
    catch(...args) {
      return this._promise.catch(...args);
    },
    finally(...args) {
      return this._promise.finally(...args);
    }
  }
);