import FirestoreContainer from '../../firebase/FirestoreContainer';
import { onAuthStateChanged } from '../../auth';
import { getFirebaseDate, docToSimpleObject } from '../../firebase/firebaseUtil';

const limit = 20;

/**
 * Stores notifications sent to users
 */
class NotificationsContainer extends FirestoreContainer {
  constructor() {
    super('notifications');

    // perfLog('[init] shortcuts');
    onAuthStateChanged(authState => {
      const { uid, lastUid } = authState;

      if (!uid || uid === lastUid
        //&& !authState.isFromCache() // this will make things slower, but will not risk a race condition where we try to query data that we need to be logged in for, accordnig to firestore rules
      ) {
        return;
      }

      this.clear();

      // only get shortcuts if logged in
      // perfLog('[query] notifications');
      this.query({
        where: ['uids', 'array-contains', uid],
        orderBy: ['createdAt', 'desc'],
        limit
      });
    });
  }

  onBeforePageUpdateCb() {
    // clear!
    this._sortedNotifications = null;
  }

  getSortedArray() {
    if (!this._sortedNotifications) {
      let docs = this._pages.flat();
      this._sortedNotifications = docs.map(d => docToSimpleObject(d));
      this._sortedNotifications.sort((a, b) => getFirebaseDate(b) - getFirebaseDate(a));
    }
    return this._sortedNotifications;
  }
}


const notificationsContainer = new NotificationsContainer();
export default notificationsContainer;