import { fetchGET } from '../../util/fetch';
import EmptyObject from '../../util/EmptyObject';
import ParallelTaskQueue, { intermediate } from '../../util/ParallelTaskQueue';
import { sortArrayEz } from 'src/util/arrayUtil';
import { isKnownStreamingPlatform } from 'src/api/third-party/networks';
import { postProcessContent } from 'src/api/content/contentPostProcessing';

const defaultQueryArgs = Object.freeze({
  // TODO: safer storage for API key
  api_key: 'c5fe2b072776b7e4c8c59238cf9f05bb',
  // language: 'en-US'
});

function makeQuery(args) {
  return !args &&
    defaultQueryArgs ||     // no customization, just the default
  {                       // merge custom + default args
    ...defaultQueryArgs,
    ...(args || {})
  };
}

// ###########################################################################
// Search
// ###########################################################################

export async function* searchMovies(searchTerm) {
  return yield* searchTMDB('movie', searchTerm);
}

export async function* searchTv(searchTerm) {
  return yield* searchTMDB('tv', searchTerm);
}

// /**
//  * Prevent quota + rate limits (actually not necessary anymore)
//  * @see https://developers.themoviedb.org/3/getting-started/request-rate-limiting
//  */
// export async function UNUSED_throttledSearchMovies(searchTerm) {
//   return await searchMovies(searchTerm);
// }

/**
 * @see https://developers.themoviedb.org/3/search/search-movies
 */
export async function* searchTMDB(category, searchTerm) {
  const res = await sendQuery('/search/' + category, {
    query: searchTerm
  });
  if (!res?.results) {
    return null;
  }

  // return Promise.all(res.results.map(async result => {
  //   const movieId = result.id;
  //   const director = await queryDirector(movieId);
  //   const author = normalizeAuthor(director);
  //   return normalizeSearchResult(result);
  // }));


  const { results: rawResults } = res;
  const results = rawResults.map(rawResult => normalizeSearchResult(category, rawResult));
  yield results;

  yield* new ParallelTaskQueue(results.map(async (result, i) => {
    const {
      id,
      genre_ids: genreIds
    } = rawResults[i];

    try {
      const details = await queryNormalizedDetails(category, id, genreIds);

      result = {
        ...result,
        ...details
      };
    }
    catch (err) {
      console.error('failed to fetch movie details', err);
    }
    finally {
      result = {
        ...result,
        _complete: true // mark as complete
      };
    }
    results[i] = result;

    return results;
  }));
}

async function queryNormalizedDetails(category, id, genreIds) {
  const [
    director,
    trailerUrl,
    genreNames,
    tvDetails
  ] = await Promise.all([
    queryDirector(category, id),
    queryTrailerUrl(category, id),
    getOrQueryGenreNames(category, genreIds),

    // tv only
    category === 'tv' && queryNormalizedTvDetails(id)
  ]);

  const author = normalizeAuthor(director);

  // add author + trailerUrl to result
  return {
    trailerUrl,
    ...author,
    genreNames,
    ...tvDetails
  };
}

async function queryNormalizedTvDetails(id) {
  return await queryTvDetails(id) || EmptyObject;
}


function normalizeSearchResult(category, result) {
  let {
    id,
    title,
    name,
    overview,
    poster_path,
    release_date
  } = result;

  const year = new Date(release_date).getFullYear();

  // normalize results over different categories
  title = title || name;    // title for "movies, name for "tv"

  return {
    contentDate: release_date,
    title: `${title}${!isNaN(year) ? ` (${year})` : ''}`,
    description: overview,
    site: 'themoviedb.org',
    thumbnail_url: poster_path && `https://image.tmdb.org/t/p/w185_and_h278_bestv2${poster_path}` || '',
    url: `https://www.themoviedb.org/${category}/${id}`,
    [`${category}Id`]: id
  }
}

// ###########################################################################
// Credits (cast + crew)
// ###########################################################################

export async function queryDirector(category, id) {
  const credits = await queryCredits(category, id);
  const crew = credits?.crew;
  if (crew) {
    const director = crew.find(person => person.job === 'Director');
    if (director) {
      const {
        id: directorId,
        name,
        profile_path: photoPath
      } = director;

      return {
        name: name,
        link: `https://www.themoviedb.org/person/${directorId}`,
        photoUrl: `https://image.tmdb.org/t/p/w600_and_h900_bestv2${photoPath}`
      };
    }
  }
  return null;
}

/**
 * @see https://developers.themoviedb.org/3/movies/get-movie-credits
 */
export async function queryCredits(category, id) {
  const res = await sendQuery(`/${category}/${id}/credits`);
  return res;
}


function normalizeAuthor(firstAuthor) {
  if (!firstAuthor) {
    return EmptyObject;
  }
  const { name, link, photoUrl } = firstAuthor;

  return {
    author: name,
    author_url: link
  };
}


// ###########################################################################
// Trailers
// ###########################################################################

const TrailerURLBySite = {
  YouTube: 'https://www.youtube.com/watch?v=',
  Vimeo: 'https://vimeo.com/'
};

export async function queryTrailerUrl(category, id) {
  const trailer = await queryTrailer(category, id);
  if (trailer) {
    const {
      site,
      key
    } = trailer;

    let url = TrailerURLBySite[site] + key;
    if (!url) {
      url = `https://www.google.com/search?q=${site}+${key}`;
    }
    return url;
  }
  return '';
}

export async function queryTrailer(category, id) {
  const videos = await queryVideos(category, id);
  return videos?.find(v => v.type === 'Trailer') || null;
}

/**
 * Returns all videos reslated to given movie.
 * 
 * @see https://developers.themoviedb.org/3/movies/get-movie-videos
 * @example `{
  "id": 64682,
  "results": [
    {
      "id": "5ae531490e0a26792e000110",
      "iso_639_1": "en",
      "iso_3166_1": "US",
      "key": "4w8lohkQtbY",
      "name": "The Great Gatsby - Official Trailer #1 [HD]",
      "site": "YouTube",
      "size": 1080,
      "type": "Trailer"
    }
  ]
}`
 */
export async function queryVideos(category, id) {
  const res = await sendQuery(`/${category}/${id}/videos`);
  return res?.results;
}

// ###########################################################################
// Genres
// ###########################################################################

const genrePromises = {};

async function getOrQueryGenreNames(category, genreIds) {
  return (await getOrQueryGenres(category, genreIds)).map(g => g.name);
}

async function getOrQueryGenres(category, genreIds) {
  const genresById = await getOrQueryAllGenres(category);
  return genreIds.map(id => genresById[id]);
}

async function getOrQueryAllGenres(category) {
  if (!genrePromises[category]) {
    genrePromises[category] = (async () => {
      const genres = await queryAllGenres(category);
      const genresById = Object.fromEntries(genres.map(g => [g.id, g]));
      return genresById;
    })();
  }
  return genrePromises[category];
}

async function queryAllGenres(category) {
  const res = await sendQuery(`/genre/${category}/list`);
  return res.genres;
}


// ###########################################################################
// Details
// ###########################################################################


async function queryTvDetails(id) {
  // 1. get network ids from details
  const details = await queryDetails('tv', id);
  const { networks, homepage: originUrl } = details;
  if (!networks?.length) {
    return null;
  }

  // select first network (with Netflix + Amazon at the top)
  sortArrayEz(networks, network => isKnownStreamingPlatform(network.name));
  const {
    id: networkId,
    // logo_path
  } = networks[0];

  // 2. query the actual network
  //    NOTE: all network props are prefixed with the word `network`
  const network = await queryNetwork(networkId);

  return {
    originUrl,
    ...network
  };
}

/**
 * @see https://developers.themoviedb.org/3/tv/get-tv-details
 */
async function queryDetails(category, id) {
  return await sendQuery(`/${category}/${id}`);
}

// ###########################################################################
// Networks
// ###########################################################################

async function queryNetwork(networkId) {
  const res = await sendQuery(`/network/${networkId}`);

  if (!res) {
    return null;
  }

  const {
    name: networkName,
    homepage: networkUrl
  } = res;

  return {
    networkId,
    networkName,
    networkUrl
  };
}


// ###########################################################################
// utilities
// ###########################################################################

async function sendQuery(path, query) {
  const url = `https://api.themoviedb.org/3${path}`;
  query = makeQuery(query);
  const res = await fetchGET(url, query);
  return res;
}

window.searchTv = async (...args) => {
  // only get last status of object
  // see: https://stackoverflow.com/questions/58668361/how-can-i-convert-an-async-iterator-to-an-array
  let results = null;
  for await (const item of searchTv(...args)) {
    results = await (item?.drain?.()) || item;
  }
  results.forEach(postProcessContent);
  return results;
};

window.searchFilms = async (...args) => {
  // only get last status of object
  // see: https://stackoverflow.com/questions/58668361/how-can-i-convert-an-async-iterator-to-an-array
  let results = null;
  for await (const item of searchMovies(...args)) {
    results = await (item?.drain?.()) || item;
  }
  results.forEach(postProcessContent);
  return results;
};