import firebase from 'firebase/app';
import difference from 'lodash/difference';
import FirestoreContainer from 'src/firebase/FirestoreContainer';
import { authState } from 'src/auth';
import EmptyArray from 'src/util/EmptyArray';
import { mayAddObjectToCollectionName } from 'src/api/content/ContentActionConfig';
import reelCollectionsContainer, { getCollectionAccessMode, getCollectionLabel } from 'src/api/reel/reelCollectionContainer';
import { uniqWith } from 'lodash';

export function matchCollectionIdRefs(a, b) {
  // TODO: rename `uid` to `reelId`
  return a.collectionName === b.collectionName &&
    (a.reelId || a.uid) === (b.reelId || b.uid);
}

export class ContentCollectionEntryContainer extends FirestoreContainer {
  constructor() {
    super('contentCollectionEntries');

    this.enableCache();
  }

  async addItems(contentId, contentType, newCollectionIdRefs) {
    const { uid } = authState;
    if (!uid) {
      // should not happen
      return null;
    }

    // add all items
    const items = newCollectionIdRefs.map(({ collectionName, reelId }) => ({
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      uid: reelId,  // TODO: rename `uid` to `reelId`
      reelId: reelId,
      collectionName,
      type: contentType,
      contentId,
    }));

    const [newCollections, ...addedItems] = await Promise.all([
      reelCollectionsContainer.addSystemCollections(newCollectionIdRefs),
      ...items.map(item => this.addDoc(item))
    ]);

    return addedItems;
  }

  async setItems(contentId, content, oldEntries, collectionIdRefs) {
    const { uid } = authState;
    const { type: contentType } = content;
    if (!uid) {
      // should not happen
      return null;
    }
    oldEntries = oldEntries || EmptyArray;

    // split all entries into 3 categories: 1. toAdd, 2. toDelete, 3. unchanged

    // new reelCollection ids (that are in collectionIdRefs, but did not exist before)
    let toAddCollectionIdRefs = collectionIdRefs.filter(collectionIdRef => !oldEntries.some(oldEntry => matchCollectionIdRefs(collectionIdRef, oldEntry)));
    // NOTE: system collections might have duplicates in this set
    toAddCollectionIdRefs = uniqWith(toAddCollectionIdRefs, (a, b) => a.collectionName === b.collectionName);
    
    // old contentCollectionEntries (that existed before, but are not in collectionIdRefs)
    const toDeleteEntries = oldEntries.filter(oldEntry => !collectionIdRefs.some(collectionIdRef => matchCollectionIdRefs(collectionIdRef, oldEntry)));
    // oldRemaining = oldEntries - toDeleteEntries
    const oldRemaining = difference(oldEntries, toDeleteEntries);

    // validation
    const firstInvalidRef = toAddCollectionIdRefs.find(ref => !mayAddObjectToCollectionName(ref.collectionName, content, getCollectionAccessMode(ref)));
    if (firstInvalidRef) {
      // something was invalid
      const { collectionName, reelId } = firstInvalidRef;
      const label = getCollectionLabel(collectionName, reelId, contentType);
      const msg = 'Error - may not add to collection: ' + label;
      alert(msg);
      throw new Error(msg);
    }

    console.debug();

    // (1) add new entries and (2) delete those that have been removed
    const [newEntries, _deleted] = await Promise.all([
      this.addItems(contentId, contentType, toAddCollectionIdRefs),
      toDeleteEntries.map(entry => this.deleteDoc(entry._id))
    ]);

    // return complete set of all now existing collections
    return newEntries.concat(oldRemaining);
  }
}

const contentCollectionEntryContainer = new ContentCollectionEntryContainer();

export default contentCollectionEntryContainer;
