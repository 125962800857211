import { isMobile } from 'src/util/responsive';
import { getReelFileOfSizePath, getStorageImageUrl, checkIfExists } from 'src/api/storage/index';
import { decorateClasses } from 'src/util/domUtil';
import { fixPageRelativeResources, loadElImage } from 'src/renderUtil/pageUtil';
import { isFunction } from 'lodash';
import { preloadImage } from 'src/renderUtil/imageUtil';

/**
 * One array of multi-configs per file type (one multi-config per target device, at least 1).
 * Aspect-ratio is determined from the `min` entry.
 * When uploading, it will upload one file per item in `exports` array.
 * If there is no `exports`, it creates a array with `min` being the only entry.
 * The first item in `exports` should thus always correspond to `min`.
 */
export const ReelImageConfig = {
  background: [
    {
      fileName: 'reel-background.jpg',
      title: 'Background (desktop)',
      isMobile: false,
      min: {
        width: 800,
        height: 720
      },
      exports: [
        { width: 800 },
        { width: 1600 }
      ]
    },

    {
      fileName: 'reel-background.jpg',
      title: 'Background (mobile)',
      isMobile: true,
      min: {
        width: 480,
        height: 830
      }
    },
  ],

  profile: [
    {
      fileName: 'reel-small.jpg',
      title: 'Profile',
      min: {
        width: 198,
        height: 256
      },
      exports: [
        { width: 198 },
        { width: 4 * 198 }
      ]
    }
  ]
};


const imageSizeConfigs = {
  'reel-background.jpg': [
    {
      // high res
      query: 'min-height: 1200px',
      width: 1600,
      height: 1440
    },
    {
      // mobile
      query: () => isMobile(),
      ...ReelImageConfig.background.find(cfg => cfg.isMobile === true).min
    },
    {
      // anything else
      ...ReelImageConfig.background.find(cfg => cfg.isMobile === false).min
    }
  ],

  'reel-small.jpg': [
    // {
    //   // high res
    //   query: 'min-height: 800px',
    //   width: 192*4,
    //   height: 256*4
    // },

    // profile image: just one setting
    ReelImageConfig.profile[0].min
  ]
};

export function getUserImageSizeConfigs() {
  return imageSizeConfigs;
}

export async function doesUserHaveImage(uid, imageName) {
  const multiConfig = ReelImageConfig[imageName][0];

  const {
    fileName,
    min: sizeConfig
  } = multiConfig;
  const path = getReelFileOfSizePath(uid, fileName, sizeConfig);
  return checkIfExists(path);
}


// ###########################################################################
// render reel images
// ###########################################################################

// export function getReelImageUrl(reelId,) {
//   const path = getReelFileOfSizePath(reelId, 'reel-small.jpg', config);
//   const url = getStorageImageUrl(path) + suffix;
//   return ;
// }

export async function loadValidReelImageUrl(reelId, fileName = 'reel-small.jpg', suffix = '') {
  const configs = getUserImageSizeConfigs()[fileName];
  if (!configs) {
    console.error('Invalid fileName is not configured:', fileName);
    return {};
  }

  // find best matching config (where the image actually exists)
  for (const config of configs) {
    if (!config.query ||
      (isFunction(config.query) && config.query()) ||
      window.matchMedia(`(${config.query})`).matches) {
      const path = getReelFileOfSizePath(reelId, fileName, config);
      const url = getStorageImageUrl(path) + suffix;
      const doesImageExist = await preloadImage(url);
      if (doesImageExist) {
        return url;
      }
    }
  }

  // no existing image
  return '';
}

export function getReelImageSettings(reelId, suffix) {
  // perfLog('reel.updateReelImages');
  async function makeUrl(fileName, $el) {
    try {
      return await loadValidReelImageUrl(reelId, fileName, suffix);
    }
    catch (err) {
      console.error('unable to load user image', err);
      return null;
    }
  }
  async function decorate($el, url) {
    decorateClasses($el, {
      'no-image': !url
    });
  }

  return {
    makeUrl,
    decorate
  };
}

export async function applyPageReelImages(reelId, suffix = '', predicate = null) {
  const settings = getReelImageSettings(reelId, suffix);
  await fixPageRelativeResources(settings, predicate);
}

export async function applyReelImage($el, reelId, resourceName, attrName, suffix = '') {
  const settings = getReelImageSettings(reelId, suffix);
  return loadElImage($el, attrName, resourceName, settings);
}