import { makeElVisible, makeElInvisible } from '../util/domUtil';

export function renderVoteLines($btnCont, voteCount, maxVoteCount) {
  const $voteLinesCont = $btnCont.find('.vote-lines');
  let lines = Array.from($voteLinesCont.children());
  const $lineTempl = $(lines[0]);

  // first: add all lines (and hide them)
  let i;
  for (i = lines.length; i < maxVoteCount; ++i) {
    const $line = $lineTempl.clone();
    $voteLinesCont.append($line);
  }
  lines = Array.from($voteLinesCont.children());

  // then make all visible up to vote count
  for (i = 0; i < voteCount; ++i) {
    const $line = $(lines[i]);
    makeElVisible($line);
  }
  for (; i < maxVoteCount; ++i) {
    // hide extra lines
    const $line = $(lines[i]);
    makeElInvisible($line);
  }
}