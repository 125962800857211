import SerialTaskQueue from 'src/util/SerialTaskQueue';
import { waitUntilNextFrame } from 'src/util/sleep';

const MinFps = 80;

class RenderQueue extends SerialTaskQueue {
  version = 0;

  /**
   * 1. Waits a frame between any two tasks.
   * 2. When enqueued, invalidate all previous "render tasks".
   */
  async enqueueRenderTasks(cbs) {
    // TODO: process multiple chunks per frame, rather than only one per frame

    // const timePerFrame = 1000 / MinFps;

    // let time = performance.now();
    // let nChunks = 0;
    // const startVersion = ++this.version;

    for (const cb of cbs) {
      // TODO: need one renderQueue per "list" to make this work
      // if (startVersion !== this.version) {
      //   // already started another set of render tasks, invalidating this set
      //   return;
      // }
      await this.enqueueMediumPriority(cb);

      await waitUntilNextFrame();
      // ++nChunks;

      // const renderTime = performance.now();
      // const dt = - time;
      // if (dt > timePerFrame) {
      //   const waitTime = await waitUntilNextFrame();    // wait a frame before rendering the next chunk
      //   // console.debug('render', nChunks, dt/1000);
      //   time = renderTime;
      //   nChunks = 0;
      // }
    }
  }
}

/**
 * @type {RenderQueue}
 */
let renderQueue;

export function initRenderQueue() {
  if (!renderQueue) {
    renderQueue = new RenderQueue('[renderQueue]');
  }
}

export function getRenderQueue() {
  initRenderQueue();
  return renderQueue;
}