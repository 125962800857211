import { isMobile } from 'src/util/responsive';

function _playAnim(elName, $el) {
  if ($el.length) {
    $el[0].click()
  }
  else {
    console.error('could not find animation element:', elName);
  }
}

export function startWebflowAnimNotResponsive(name) {
  const elName = `.anim-${name}`;
  const $el = $(elName);
  _playAnim(elName, $el);
}

/**
 * Start animation (different animation based on whether it's mobile or not).
 */
export function startWebflowAnimation(name) {
  let elName;
  let $el;
  if (isMobile()) {
    // mobile
    elName = `.anim-${name}-mobile`;
    $el = $(elName);
    if ($el.length) {
      // there is a mobile version of this anim -> play that!
      _playAnim(elName, $el);
      return;
    }
  }

  // desktop or default
  elName = `.anim-${name}`;
  $el = $(elName);
  _playAnim(name, $el);
}

// [debug-global]
window.startWebflowAnimation = startWebflowAnimation;