const PerfLog = true;


let lastStart;
let i = 0;

const enabledCategories = {
  firestoreQuery: 1
};

export function perfLog(...args) {
  if (!PerfLog) {
    return;
  }
  const t = performance.now();
  let delta = lastStart ? ` - ${Math.floor(t-lastStart)}` : '';
  console.debug('' + (++i), args[0], ...args.slice(1), `[${Math.floor(t)} ms${delta}]`);
  lastStart = t;
}

export function perfLogCategory(category, ...args) {
  if (enabledCategories) {
    perfLog(`[${category}]`, ...args);
  }
}