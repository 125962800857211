import { decorateClasses } from 'src/util/domUtil';
import State from 'src/util/State';
import { showNotLoggedInUserActionWarning } from 'src/renderUtil/userActions';
import EmptyObject from 'src/util/EmptyObject';



export default class SubmitButton {
  /**
   * @type {State}
   */
  state;

  constructor($cont, state, saveCb, cfg) {
    this.$cont = $cont;
    this.state = state;
    this.saveCb = saveCb;
    this.cfg = cfg || EmptyObject;

    this.$btn = $cont.find(cfg.selector);
    this.$status = $cont.find('.status');

    this.$btn.on('click', this.handleClick);

    this.$status.addClass('hidden');
    this.$status.children().eq(0).text('Saved ✓');

    this.state.addListener('saving', this.render);
    this.state.addListener('saved', this.render);
    this.state.addListener('busy', this.render);
    this.state.addListener('disabled', this.render);
  }

  isDisabled() {
    const { saving, busy, disabled } = this.state.get;
    return saving || busy || disabled;
  }

  render = () => {
    const { 
      hideIfNotSaved: hideStatusIfNotSaved,
      submittingText,
      submitText
    } = this.cfg;
    const { saving, saved } = this.state.get;

    // console.warn('SubmitButton.render', this.state.get);
    decorateClasses(this.$btn, {
      disabled: this.isDisabled()
    });

    if (hideStatusIfNotSaved) {
      decorateClasses(this.$status, {
        hidden: !saved
      });
    }

    if (saving) {
      this.$btn.text(submittingText || 'Submitting');
    }
    else {
      this.$btn.text(submitText || 'Submit');
    }
  }

  handleClick = async (evt) => {
    if (!this.saveCb) {
      // nothing to do here
      return;
    }
    
    if (showNotLoggedInUserActionWarning()) {
      // can't save anything if not logged in
      return;
    }

    if (this.state.busy) {
      return;
    }

    this.state.setState({
      saving: true,
      saved: false
    });

    let result;
    try {
      result = await this.setBusyWhile(this.saveCb());
    }
    finally {
      this.state.setState({
        saving: false,
        saved: result !== false
      });
    }
  }

  async setBusyWhile(promise) {
    this.state.inc('busy');

    try {
      return await promise;
    }
    finally {
      this.state.dec('busy');
    }
  }
}


export class SaveChangesButton extends SubmitButton {
  constructor($cont, state, saveCb, cfg) {
    cfg = Object.assign({
      selector: '.submit-user-settings-button',
      hideIfNotSaved: true,
      submittingText: 'Saving...',
      submitText: 'Save Changes'
    }, cfg);
    super($cont, state, saveCb, cfg);
  }
}