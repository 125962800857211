import ContentType from '../../api/content/ContentType';
import { FilmInputForm, SeriesInputForm } from 'src/render/content/SearchInputForm';
import CourseInputForm from 'src/render/content/contentTypes/CourseInputForm';
import UrlInputForm from 'src/render/content/UrlInputForm';
import { ProductInputForm } from 'src/render/content/contentTypes/otherInputForms';


let $contentEditPanel;

/**
 * @type {{ [string]: ContentInputForm }}
 */
let contentEditors;

const CustomInputFormClasses = {
  Film: FilmInputForm,
  Series: SeriesInputForm,
  Course: CourseInputForm,
  App: ProductInputForm
};

const InputFormConfigs = {
  App: {
    unrestrictedContentType: true
  },
  Product: {
    unrestrictedContentType: true
  }
}



// ###########################################################################
// init
// ###########################################################################


/**
 * Content input: initialize the content editor.
 * This is the menu behind the "+" button, but it's also used to highlight
 * selected content items, such as on the `share` page.
 */
export function initRenderGlobalContentEditors() {
  $('.add-link-button').on('click', () => {
    runInit();
  });
}

function runInit() {
  if (contentEditors) {
    return;
  }
  contentEditors = {};

  // const $forms = $($('.add-link-panel')[0]);
  $contentEditPanel = $('.add-link-panel');
  $contentEditPanel.map((i, el) => {
    const $el = $(el);
    const contentTypeName = $el.attr('data-content-type');
    const contentType = ContentType.valueFrom(contentTypeName);
    if (!contentType) {
      console.error('invalid data-content-type attribute on contentEditor tab:', contentType);
    }
    else {
      // valid content-type
      let FormClazz = CustomInputFormClasses[contentTypeName];
      if (!FormClazz) {
        // URL by default
        FormClazz = UrlInputForm;
      }

      const cfg = InputFormConfigs[contentTypeName];

      contentEditors[contentType] = new FormClazz();
      contentEditors[contentType].init(contentType, $el, cfg);
    }
  });
}
