/* global gapi */

/**
 * @see https://github.com/Domiii/project-empire/blob/master/src/core/multimedia/youtube/YouTubeAPI.js
 */

import map from 'lodash/map';
import flatten from 'lodash/flatten';
import isArray from 'lodash/isArray';

// TODO: better way of organizing these.
const ApiKey = 'AIzaSyBykrEO7gH8msyJVuTvPJgdZychqqMznLw';
const OauthClientId = '801544956728-tbhc8k729d6kdtpkhqtle399sq8j6v4h.apps.googleusercontent.com'

import { waitForGapiClientJs } from 'src/web-apis/gapi/gapi';
import EmptyObject from 'src/util/EmptyObject';


export const GapiStatus = {
  None: 0,
  Initializing: 1,
  Initialized: 2,
  Authorizing: 3,
  PopupBlocked: 4,
  NeedUserConsent: 5,
  Authorized: 6
};

// see: https://developers.google.com/api-client-library/javascript/reference/referencedocs
// see: https://github.com/youtube/api-samples/tree/master/javascript

// 1) auth: https://github.com/youtube/api-samples/blob/master/javascript/auth.js
// 2) upload: https://developers.google.com/youtube/v3/code_samples/javascript#do_resumable_uploads_with_cors

const DefaultMaxIdsPerQueryChunk = 30;
const YOUTUBE_UPLOAD_SCOPES = [
  'https://www.googleapis.com/auth/youtube.readonly',
  'https://www.googleapis.com/auth/youtube.force-ssl',
  'https://www.googleapis.com/auth/youtube.upload'
].join(' ');

const DEBUGG = true;

/**
 * ######################################################
 * YouTube API Core
 * ######################################################
 */

const ContentRequestFunctions = {
  get videoList() {
    return gapi.client.youtube.videos.list.bind(gapi.client.youtube.videos);
  },
  get channelList() {
    return gapi.client.youtube.channels.list.bind(gapi.client.youtube.channels);
  },
  get search() {
    return gapi.client.youtube.search.list.bind(gapi.client.youtube.search);
  },
  get playlists() {
    return gapi.client.youtube.playlists.list.bind(gapi.client.youtube.playlists);
  },
  get playlistItems() {
    return gapi.client.youtube.playlistItems.list.bind(gapi.client.youtube.playlistItems);
  }
};

let initializePromise;

export async function gapiInit() {
  initializePromise = initializePromise || (async () => {
    await waitForGapiClientJs(); // load client.js first

    return new Promise((resolve, reject) => {
      // see https://github.com/youtube/api-samples/blob/47c49fed14859957ed74fd2706259935937eb885/javascript/quickstart.html#L33
      return gapi.load('client:auth2', {
        callback: function () {
          // Handle gapi.client initialization.
          resolve(_gapiInit());
        },
        onerror: function (err) {
          reject(new Error('gapi.client failed to load - ' + (err.stack || JSON.stringify(err))));
        },
        timeout: 5000, // 5 seconds.
        ontimeout: function () {
          // Handle timeout.
          reject(new Error('gapi.client timeout'));
        }
      });
    });
  })();
  return initializePromise;
}

async function _gapiInit() {
  const discoveryDocs = ['https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest'];
  const clientId = OauthClientId;

  return await gapi.client.init({
    apiKey: ApiKey,
    clientId,
    discoveryDocs,
    //scope: 'https://www.googleapis.com/auth/youtube.readonly'
    scope: YOUTUBE_UPLOAD_SCOPES
  })
    // return await gapi.client.init({
    //   //apiKey,
    //   client_id: clientId,
    //   scope: 'https://www.googleapis.com/auth/youtube.readonly'
    // })
    .then(() => {
      function updateSigninStatus(...args) {
        console.warn('updateSigninStatus', ...args);
      }

      // Listen for sign-in state changes.
      gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

      // Handle the initial sign-in state.
      return gapi.auth2.getAuthInstance().isSignedIn.get();

      //return gapi.auth2.getAuthInstance();
      // return gapi.auth2.init({
      //   clientId
      // });
    });
}

/**
 * @see https://developers.google.com/youtube/v3/guides/auth/client-side-web-apps
 */
export async function gapiAuth(prompt) {
  await gapiInit();
  return gapi.auth2.getAuthInstance().signIn();
  // gapi.auth2.authorize(cfg, function(response) {
  //   if (response.error) {
  //     // An error happened!
  //     return reject(response);
  //   }
  //   // The user authorized the application for the scopes requested.
  //   // You can also now use gapi.client to perform authenticated requests.
  //   resolve(response);
  // });
}

export async function gapiLogout() {
  await gapiInit();
  return await gapi.auth2.getAuthInstance().signOut();
}

/**
 * @see https://developers.google.com/youtube/v3/guides/auth/client-side-web-apps#incrementalAuth
 */
export async function gapiGrantScopes(newScopes) {
  await gapiInit();
  newScopes = newScopes || YOUTUBE_UPLOAD_SCOPES;
  const user = gapi.auth2.getAuthInstance().currentUser.get();
  return await user.grant({ 'scope': newScopes });
}

function prepareRequest(endpoint, requestArgs) {
  const fn = ContentRequestFunctions[endpoint];
  console.assert(fn, 'invalid request type: ' + endpoint);
  console.info('[YT Request]', endpoint, requestArgs);
  return fn.bind(null, requestArgs);
}

export async function sendYtRequest(contentType, requestArgs) {
  await gapiInit();
  const fn = prepareRequest(contentType, requestArgs);
  console.assert(fn, 'invalid request type: ' + contentType);
  // TODO: make sure, we don't accidentally spam requests?

  return fn();
}

/**
 * Autmoatically batch the request.
 * Handles any arbitrary amount of ids by batching requests so as to respect the API limits.
 * @see https://stackoverflow.com/questions/36370821/does-youtube-v3-data-api-have-a-limit-to-the-number-of-ids-you-can-send-to-vide 
 */
export async function sendYtRequestBatched(apiEndpoint, ids, part, maxResourcesPerQuery, idArg = 'id', args) {
  await gapiInit();

  if (!isArray(ids)) {
    throw new Error('expected array of ids, not a string');
  }
  const nChunks = Math.ceil(ids.length / maxResourcesPerQuery);
  const allRequestArgs = [];
  for (let iChunk = 0; iChunk < nChunks; ++iChunk) {
    const iStart = iChunk * maxResourcesPerQuery;
    const iEnd = Math.min((iChunk + 1) * maxResourcesPerQuery, ids.length);
    const chunkIds = ids.slice(iStart, iEnd);
    const requestArgs = {
      ...(args || EmptyObject),
      [idArg]: chunkIds.join(','),
      part
    };
    allRequestArgs.push(requestArgs);
  }

  const requests = map(allRequestArgs, req => prepareRequest(apiEndpoint, req));

  const allResults = await Promise.all(map(requests, request =>
    new Promise((resolve, reject) => {
      request().then(response => {
        if (response.error) {
          console.error(`fetching "${apiEndpoint}" data failed for: `, allRequestArgs, '\n\n', response.error);
          reject(response.error);
        }
        else {
          const items = response.result?.items;

          resolve(
            items
          );
        }
      });
    })
  ));

  return flatten(allResults);
}


// ###########################################################################
// Channel queries
// ###########################################################################

export async function ytFetchChannels(...channelIds) {
  return sendYtRequestBatched('channelList', channelIds, 'snippet,statistics', DefaultMaxIdsPerQueryChunk);
}

// ###########################################################################
// Playlist queries
// ###########################################################################

export async function ytFetchPlaylists(...listIds) {
  return sendYtRequestBatched('playlists', listIds, 'snippet,contentDetails,status', DefaultMaxIdsPerQueryChunk);
}

export async function ytFetchPlaylistItems(args, ...listIds) {
  return sendYtRequestBatched('playlistItems', listIds, 'snippet,contentDetails,status', DefaultMaxIdsPerQueryChunk, 'playlistId', args);
}

// ###########################################################################
// Video queries
// ###########################################################################

export async function ytFetchVideos(...videoIds) {
  // get basic video info
  // see: https://developers.google.com/youtube/v3/docs/videos
  // see: https://developers.google.com/apis-explorer/?hl=en_US#p/youtube/v3/youtube.videos.list?part=snippet&id=0QB9JP2l6tM%252C+9oGfI4o6Xfs&_h=4&
  return sendYtRequestBatched('videoList', videoIds, 'snippet,statistics', DefaultMaxIdsPerQueryChunk);
}

// ###########################################################################
// Search queries
// ###########################################################################

const searchPromises = new Map();

/**
 * Response example:
 * @see https://developers.google.com/youtube/v3/docs/search#properties_1
```json
{
 // ...
 "items": [
  {
   "kind": "youtube#searchResult",
   "etag": "\"ksCrgYQhtFrXgbHAhi9Fo5t0C2I/cmtCgOD0tmq4l7vRu58jfQ9e4cg\"",
   "id": {
    "kind": "youtube#video",
    "videoId": "Ywgpv1pYvb4"
   },
   "snippet": {
    "title": "Hello Kitty Greatest Hits (Song Medley)",
    // ...
    },
    "channelTitle": "Hello Kitty Online (Sanrio Digital)",
    "liveBroadcastContent": "none"
   }
  }
 ]
}
```
 * @see https://developers.google.com/youtube/v3/docs/search/list
 */
export async function ytSearch(queryTerm, config) {
  const cacheKey = JSON.stringify([queryTerm, config]);
  let promise = searchPromises.get(cacheKey);
  if (promise) {
    return promise;
  }

  const requestArgs = Object.assign({
    part: 'snippet',
    q: queryTerm
  }, config);

  promise = sendYtRequest('search', requestArgs);
  searchPromises.set(cacheKey, promise);

  const res = await promise;
  return res;
}

export async function ytSearchItems(queryTerm, config) {
  const res = await ytSearch(queryTerm, config);
  return res?.result?.items;
}

// TODO: Uploading
// see: https://developers.google.com/youtube/v3/docs/videos/insert
// see: https://github.com/youtube/api-samples/tree/master/javascript/cors_upload.js
// see: https://github.com/youtube/api-samples/tree/master/javascript/upload_video.js
// see: https://developers.google.com/youtube/v3/code_samples/javascript#do_resumable_uploads_with_cors
