import ContentType from './ContentType';
import EmptyObject from 'src/util/EmptyObject';
import { queryIframely } from '../third-party/iframely';


// ##################################################################################################################
// Content identification
// ##################################################################################################################

/**
 * @see https://stackoverflow.com/a/3561711
 */
function regexEscape(s) {
  return s.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
}

function execRepeatableRe(re, input) {
  const match = re.exec(input);

  // don't forget to reset the regex!
  // see https://stackoverflow.com/questions/11477415/why-does-javascripts-regex-exec-not-always-return-the-same-value
  re.exec("");

  return match;
}

/**
 * @see https://stackoverflow.com/a/25661346
 */
export function convertYoutubeToEmbedUrl(url) {
  const videoId = contentUrlAnalyzer.urlParsers.youtubeVideo.getVideoId(url);
  if (videoId) {
    return `https://www.youtube.com/embed/${videoId}`;
  }
  return null;
}

/**
 * The different content validators are used to determine whether the given data represents content of given type.
 */
class ContentUrlAnalyzer {

  // url whitelisting
  whitelistedDomains = [
    'nfx.com'
  ];


  // content items must pass the metadata check, if they are not whitelisted
  contentTypeOverride = {
    // [ContentType.Video]: url => true,
    // [ContentType.Article]: url => true,//url => !!meta.author || !!meta.site, // && !!data.author_url,
    // [ContentType.Film]: url => true,
    // [ContentType.Series]: url => true,
    [ContentType.Course]: url => true,
    [ContentType.App]: url => true,
    [ContentType.Product]: async url => {
      const data = await queryIframely(url);
      return data?.price !== undefined;
    }
  };

  urlParsers = {
    youtubeVideo: {
      contentType: ContentType.Video,
      // https://gist.github.com/Glurt/ea11b690ba4b1278e049
      // https://stackoverflow.com/questions/7693218/youtube-i-d-parsing-for-new-url-formats
      re: new RegExp([
        /(?:https?:\/\/|\/\/)?/, // Optional URL scheme. Either http, or https, or protocol-relative.
        /(?:www\.|m\.)?/, //  Optional www or m subdomain.
        /(?:youtu\.be\/|youtube\.com\/)/,     // domain
        /(?:(?:embed\/|v\/|watch\?.*v=)?([\d\w-]{11}))/, // video id (in any order)
        /[^\s]*/   // remainder
      ].map(exp => exp.source).join(''), 'gm'),

      getVideoId(url) {
        const match = execRepeatableRe(this.re, url);
        return match?.[1] || null;
      },

      getCanonicalUrl: (match) => {
        const id = match[1] || null;
        if (!id) {
          return null;
        }

        // const originalUrl = match[0];
        // const channelId = match[2] || null;
        // const params = new URLSearchParams(originalUrl);
        // const listId = params.get('list');
        const canonicalUrl = `https://www.youtube.com/watch?v=${id}`;
        return canonicalUrl;
        // return {
        //   canonicalUrl,
        //   id,
        //   listId,
        //   channelId
        // };
      }
    },

    dailymotionVideo: {
      contentType: ContentType.Video,

      // https://gist.github.com/Glurt/ea11b690ba4b1278e049
      // https://stackoverflow.com/questions/7693218/youtube-i-d-parsing-for-new-url-formats
      re: new RegExp([
        /^(?:https?:\/\/|\/\/)?/, // Optional URL scheme. Either http, or https, or protocol-relative.
        /(?:www\.|m\.)?/, //  Optional www or m subdomain.
        /(?:dailymotion\.com)\//,     // domain (add any amount, separated by "|")
      ].map(exp => exp.source).join(''), 'm'),

      // parseURL(match) {
      //   const url = match[0];
      //   const id = match[1] || null;
      //   const userId = match[2] || null;
      //   const params = new URLSearchParams(url);
      //   const listId = params.get('list');
      //   return {
      //     url,
      //     id,
      //     listId,
      //     userId
      //   };
      // }
    },

    article: {
      contentType: ContentType.Article,

      // res == regular expressions
      re:
        // no question mark (query string)
        ///^((?!\?).)*$/,     // https://stackoverflow.com/questions/406230/regular-expression-to-match-a-line-that-doesnt-contain-a-word

        /^http[s]?:\/\/([^/]+)\/.*-.*/, // one dash

      // /^http[s]?:\/\/([^/]+)\/.*-.*-.*/, // two dashes

      // two (non-consecutive) slashes (after domain name) or "blog" in domain name
      ///^http[s]?:\/\/([^\/]+)\/[^\/]+\/|(^http[s]?:\/\/(.*blog.*))/,


      // parseURL() {
      //   throw new Error('NYI');
      // }
    },

    // film: {
    //   contentType: ContentType.Film,

    //   re: new RegExp([
    //     /^(?:https:\/\/|\/\/)?/, // Optional URL scheme. Either http, or https, or protocol same as source.
    //     /(?:www\.|m\.)?/, //  Optional www or m subdomain.
    //     /(?:imdb\.com)\//,     // domain (add any amount, separated by "|")
    //   ].map(exp => exp.source).join(''), 'm'),
    // }
  }

  // /**
  //  * Whitelisted urls do not need metadata or other validation.
  //  */
  // getWhitelistedDomain = url => {
  //   for (const domain of this.whitelistedDomains) {

  //     const match = re.exec(url);

  //     if (match) {
  //       // url matches a whitelisted url
  //       return domain;
  //     }
  //   }
  //   return null;
  // }

  /**
   * Get first matching ContentType for given URL or null if none matched.
   */
  analyze = async (contentType, url) => {
    const overrideCheck = this.contentTypeOverride[contentType];

    if (overrideCheck && /^.+\...+/.test(url) && await overrideCheck(url)) {    // only needs to be a URL (has one dot and at least a 2-letter top-level domain)
      return {
        contentType,
        url
      };
    }
    else if (!overrideCheck) {
      for (let parserName in this.urlParsers) {
        const parser = this.urlParsers[parserName];
        const { re, getCanonicalUrl, contentType } = parser;

        const match = execRepeatableRe(re, url);

        if (match) {
          // match found!
          // TODO: allow for multiple matches and select only of matching `contentType`
          const canonicalUrl = getCanonicalUrl && getCanonicalUrl(match) || url;
          return {
            contentType,
            canonicalUrl,
            strictMatch: true
          };
        }

        //console.warn(`URL "${url}" is not "${parserName}" because it violates rule #${idx+1}: ${res[idx].source}`);
      }
    }

    // no parser matched
    return EmptyObject;
  }

  // /**
  //  * @return { [parserName]: [resultArray] }
  //  * @see https://codepen.io/Domiii/pen/YxzNgX
  //  */
  // matchAllUrls(text) {
  //   const results = {};
  //   for (let parserName in this.urlParsers) {
  //     const parser = this.urlParsers[parserName];
  //     let match;
  //     const matches = results[parserName] = [];
  //     while (match = parser.re.exec(text)) {
  //       const result = parser.parseURL(match);
  //       matches.push(result);
  //     }
  //   }
  //   return results;
  // }
}


const contentUrlAnalyzer = new ContentUrlAnalyzer();
export default contentUrlAnalyzer;

export function getYoutubeVideoIdFromUrl(youtubeUrl) {
  return contentUrlAnalyzer.urlParsers.youtubeVideo.getVideoId(youtubeUrl);
}

export function isYoutubeVideoUrl(url) {
  return !!getYoutubeVideoIdFromUrl(url);
}

export function getYoutubeListIdFromUrl(url) {
  const re = new RegExp([
    /(?:https?:\/\/|\/\/)?/, // Optional URL scheme. Either http, or https, or protocol-relative.
    /(?:www\.|m\.)?/, //  Optional www or m subdomain.
    /(?:youtu\.be\/|youtube\.com\/)/,     // domain
    /.*?(?:[?]?list=)([\d\w-]+)/,       // list id
    /[^\s]*/   // remainder
  ].map(exp => exp.source).join(''), 'gm');

  const match = re.exec(url);
  return match?.[1] || null;

  // return contentUrlAnalyzer.urlParsers.youtubeVideo.getListId(youtubeUrl);
}
