import { ecmContainer as defaultEcmContainer } from 'src/api/ecm';
import postUpdate from 'src/postUpdate';

import { TagRenderContext } from 'src/api/tags/TagConfig';
import { renderTagList, clearTagList } from 'src/render/tags/tags';
import { initRenderTagSearch } from 'src/render/tags/wikiSearch';

import { DefaultSlideDelay } from '../util/domUtil';
import ECMFlags from '../api/ECMFlags';
import { getRenderQueue } from './renderQueue';
import { onReelSelected, getSelectedReelId, getSelectedReelIdOrNull } from 'src/api/reel/reelSelection';
import reelInfoContainer, { getReelDisplayName, getReelShortDisplayName } from 'src/api/reel/reelInfo';


// ##################################################################################################################
// ECM render-related config
// ##################################################################################################################

const EcmTabSelectorDict = {
  // Search: '.ecm-search-tab-panel',
  // Alignment: '.ecm-alignment-tab-panel',
  Experience: '.ecm-experience-tab-panel',
  Curiosity: '.ecm-curiosity-tab-panel',
  Mindset: '.ecm-mindset-tab-panel'
};

// ##################################################################################################################
// ECMTabRenderer
// ##################################################################################################################

class ECMTabRenderer {
  ecmName;
  ecmFlag;
  ecmContainer;
  tabSelector;
  $tab;
  $tagList;
  $tagTemplateEl;

  constructor(ecmName, ecmContainer, tabSelector, $tabBtn, $tab) {
    this.ecmName = ecmName;
    this.ecmFlag = ECMFlags.valueFrom(ecmName);
    this.ecmContainer = ecmContainer;
    this.tabSelector = tabSelector;
    this.$tabBtn = $tabBtn;
    this.$tab = $tab;
    this.$tagList = this.$tab.find('.container-tags .wikipedia-output');
    this.$message = this.$tab.find('.message-ctn');

    // console.log(this.$tagList[0]);

    // this.$tagTemplateEl = this.$tab.find('.div-template-wiki');

    // this.$tagTemplateEl.remove();  // remove
    $tabBtn.on('click', evt => {
      this.renderTab(true);
    });
  }

  renderTab(force = false) {
    if (!force && !this.isVisible()) {
      // nothing to do
      // console.debug('不', this.tabSelector);
      return;
    }

    const { ecmContainer } = this;

    // console.debug('render ECM tab', this.tabSelector);

    if (this.ecmFlag) {
      // E, C and M
      const tags = ecmContainer.getTagsByECMFlag(this.ecmFlag);
      renderTagList(tags, this.$tagList, TagRenderContext.ECM);
    }
    else {
      // Search tab
      // -> nothing to do!
    }

    if (!ecmContainer.count()) {
      // show empty message
      this.$message.slideDown(DefaultSlideDelay);
    }
    else {
      // hide empty message
      this.$message.slideUp(DefaultSlideDelay);
    }
  }

  clearTab() {
    clearTagList(this.$tagList);
  }

  isVisible() {
    return this.$tab.is(':visible');
  }

}


// ##################################################################################################################
// ECMRenderers
// ##################################################################################################################

// function initRenderGlobalECMControls() {
//   const $ecmBtn = $('.ecm-button');

//   // 
//   $ecmBtn.off('click').on('click', () => {
//     //setTimeout(() => {
//     // const startShowing = !this.isVisible();  // not visible yet -> meaning we are about to show it
//     if (startShowing) {
//       // load and render relevant data
//       //ecmCache.startRenderingECM();
//     }
//     else {
//       // unsubscribe from database
//       //ecmCache.stopRenderingECM();
//     }
//     //});
//   });
// }

class ECMPanelRenderer {
  ecmSearchTabRenderer;
  /**
   * @type {Object.<string, ECMTabRenderer>}
   */
  ecmRendererDict = {};
  currentRenderer;

  constructor(divSelector, ecmContainer) {
    this.$ecmDiv = $(divSelector);
    this.$menu = this.$ecmDiv.find('.tabs-menu');
    this.ecmContainer = ecmContainer;
    this.divSelector = divSelector;

    for (let ecmName in EcmTabSelectorDict) {
      const tabSelector = EcmTabSelectorDict[ecmName];
      const $tabBtn = this.$menu.find(`[data-ecm-role="${ecmName}"]`);
      const $tab = this.$ecmDiv.find(tabSelector);
      this.ecmRendererDict[ecmName] = new ECMTabRenderer(ecmName, this.ecmContainer, tabSelector, $tabBtn, $tab);
    }
  }

  exists() {
    return this.$ecmDiv.length > 0;
  }

  isVisible() {
    return this.$ecmDiv.is(':visible');
  }

  getRenderer(ecmFlag) {
    const ecmName = ECMFlags.nameFromForce(ecmFlag);
    const renderer = this.ecmRendererDict[ecmName];
    if (!renderer) {
      throw new Error(`Could not get renderer for ECM "${ecmName}"`);
    }
    return renderer;
  }

  renderNow = () => {
    if (!this.exists()) {
      // no ECM here -> don't try to render anything
      console.error('no ecm div for [renderFromSnapshot]');
      return;
    }

    // render title (asynchronously)
    this.renderTitle();

    // re-render visible tabs
    for (const renderer of Object.values(this.ecmRendererDict)) {
      renderer.renderTab(); // render tab (if it is visible)
    }

    postUpdate();
  }

  /**
   * hackfix: async render functions are a sin!
   */
  async renderTitle() {
    const $reelTitle = this.$ecmDiv.find('.reel-name');
    const reelId = getSelectedReelIdOrNull();
    if (!reelInfoContainer.isDocLoaded(reelId)) {
      $reelTitle.text('(loading...)');
    }
    const reelInfo = await reelInfoContainer.queryReel(reelId);
    $reelTitle.text(getReelShortDisplayName(reelInfo));
  }

  _initSearch() {
    // hook up tag search in the search tab
    const $searchTab = this.$ecmDiv.find('.ecm-search-tab-panel');
    if (!$searchTab.length) {
      // this page has no search tab
      return;
    }

    try {
      initRenderTagSearch(TagRenderContext.ECMSearch, $searchTab, null);
    }
    catch (err) {
      // work-around for incorrectly titled "Alignment" tab
      console.error('could not initialize ECM search:', err);
    }
  }

  clear = () => {
    for (const renderer of Object.values(this.ecmRendererDict)) {
      renderer.clearTab();
    }
  }

  initRender() {
    this.renderNow();
    this.ecmContainer.onUpdate(this.renderNow);
    this.ecmContainer.onClear(this.clear);
    this._initSearch();
  }
}



// ##################################################################################################################
// misc exports
// ##################################################################################################################

let defaultEcmRenderers;

export function renderECMExplainer($container, score, countsByFlags) {
  const $ecmScore = $container.find('.ecm-score');
  const $ecmShapeBtn = $container.find('.ecm-explainer-button');

  if (score === undefined) {
    // no basis for comparison -> hide score
    $ecmScore.text('');
    return;
  }

  // show ECM score in GUI
  $ecmScore.text(score);

  // const minTagCountForMaxStrength = 1;  // you need at least this many matching tags to get full strength color

  const colorTiers = [
    {
      minCount: 0,
      color: '#cccccc'
    },
    {
      minCount: 1,
      color: '#00b0b0'
    },
    {
      minCount: 5,
      color: 'red'
    }
  ];
  colorTiers.sort((a, b) => b.minCount - a.minCount);

  // style the ECM shapes: square, circle, triangle
  const ecmShapes = Array.from($ecmShapeBtn.children());
  // const maxCount = Math.max(...Object.values(counts), minTagCountForMaxStrength);

  for (let i = 0; i < ecmShapes.length; ++i) {
    const ecmFlag = 1 << i;
    const $child = $(ecmShapes[i]);
    const count = countsByFlags[ecmFlag] || 0;
    const tier = colorTiers.find(t => count >= t.minCount);
    //const ratio = count/maxCount;
    //const color = lerpColor(minStrengthColor, maxStrengthColor, ratio);
    const color = tier.color;
    $child.attr('data-ecm-tags', count);

    //$child.attr('data-ecm-ratio', ratio);
    $child.attr('style', `background-color: ${color} !important`);
  }
}

export function initRenderECMTabs(divSelector, ecmContainer) {
  const ecmRenderers = new ECMPanelRenderer(divSelector, ecmContainer);
  ecmRenderers.initRender();
  return ecmRenderers;
}

export function initRenderOwnECMTabs() {
  if (defaultEcmRenderers) {
    return;
  }

  defaultEcmRenderers = initRenderECMTabs('.ecm-div', defaultEcmContainer);
  window.defaultEcmRenderers = defaultEcmRenderers; // [debug-global] for debugging purposes

  // return promise;
}

let renderQueue;

export function initRenderECM() {
  // NOTE: don't return a promise because it would delay other tasks in the TaskQueue
  (async () => {
    renderQueue = getRenderQueue();
    const $ecmMenuBtn = $('.ecm-menu-btn');
    $ecmMenuBtn.on('click', initRenderOwnECMTabs);
    // await waitUntilAuthStateInitialized();

    // initTasks.addBackgroundTask(initRenderOwnECMContainer);
  })();
}