import FirestoreContainer from '../../firebase/FirestoreContainer';
import { authState } from 'src/auth';
import EmptyArray from 'src/util/EmptyArray';
import { getContentCollectionQueryInterface } from 'src/api/contentCollections/contentCollections';
import SystemCollectionsConfig, { isSystemCollection, getSystemCollectionAccessMode, getSystemCollectionNames } from 'src/api/contentCollections/SystemCollectionConfig';
import { ContentCollectionEntryContainer } from 'src/api/contentCollections/contentCollectionEntryContainer';
import CollectionAccessMode from 'src/api/reel/CollectionAccessMode';
import { NotLoaded } from 'src/db';
import { groupBy, uniqWith } from 'lodash';
import { getSystemCollectionLabel } from 'src/api/contentCollections/systemCollectionLabels';


/**
 * Stores by reelId:
 * ```
 * {
 *  collections: [
 *    {
 *      collectionName: string,
 *      accessMode: CollectionAccessModeObj
 *    }
 *  ],
 *  defaultCollections: [ collectionName: string ] // indexed by ContentType
 * }
 * ```
 */
class ReelCollectionsContainer extends FirestoreContainer {
  constructor() {
    super('reelCollections');
    this.enableCache();
  }

  hasCollection(collectionIdRef) {
    const collectionEntry = this.getCollectionEntry(collectionIdRef);
    if (collectionEntry === NotLoaded) {
      return NotLoaded;
    }
    return !!collectionEntry;
  }

  getAccessMode(collectionIdRef) {
    const collectionEntry = this.getCollectionEntry(collectionIdRef);
    if (collectionEntry === NotLoaded) {
      return NotLoaded;
    }
    return collectionEntry && collectionEntry.accessMode;  // returns null if does not exist
  }

  getCollectionEntry(collectionIdRef) {
    const { reelId, collectionName } = collectionIdRef;

    const reelEntry = this.getDocById(reelId);
    if (reelEntry === NotLoaded) {
      return NotLoaded;
    }

    return this.getReelCollections(reelId)?.find(col => col.collectionName === collectionName) || null;
  }

  hasCollectionAccessMode(collectionIdRef, accessMode) {
    const entryAccessMode = this.getAccessMode(collectionIdRef);
    if (entryAccessMode === NotLoaded) {
      return NotLoaded;
    }
    return accessMode === entryAccessMode;
  }

  isCollectionPublic(collectionIdRef) {
    return this.hasCollectionAccessMode(collectionIdRef, CollectionAccessMode.Public);
  }

  isCollectionPrivate(collectionIdRef) {
    return this.hasCollectionAccessMode(collectionIdRef, CollectionAccessMode.Private);
  }

  isCollectionProtected(collectionIdRef) {
    return this.hasCollectionAccessMode(collectionIdRef, CollectionAccessMode.Protected);
  }

  getCollectionsOfAccessMode(reelId, accessMode) {
    const reelEntry = this.getDocById(reelId);
    if (reelEntry === NotLoaded) {
      return NotLoaded;
    }

    return this.getReelCollections(reelId)?.filter(col => col.accessMode === accessMode) || null;
  }


  async queryMatchingReelCollectionEntries(reelId, collectionEntries) {
    await this.queryReelProtectedCollections(reelId);
    return this.getMatchingReelCollectionEntries(reelId, collectionEntries);
  }

  /**
   * NOTE: this requires `this.queryDoc(reelId)` to have been completed before use.
   */
  getMatchingReelCollectionEntries(reelId, collectionEntries) {
    const results = Object.entries(collectionEntries).
      map(([collectionName]) => {
        return this.getReelCollections(reelId)?.
          find(entry => entry.collectionName === collectionName);
      });

    return results.filter(entry => !!entry)
  }

  // ########################################
  // defaultCollections
  // ########################################

  getDefaultCollections(reelId) {
    const reelCollectionSettings = this.getDocById(reelId);
    return reelCollectionSettings?.defaultCollections || null;
  }

  getDefaultCollection(reelId, contentType) {
    const defaultCollections = this.getDefaultCollections(reelId);
    return defaultCollections?.[contentType];
  }

  isDefaultCollection({ reelId, collectionName }, contentType) {
    return this.getDefaultCollection(reelId, contentType) === collectionName;
  }

  // ########################################
  // old code
  // ########################################


  getPrivateCollections(reelId) {
    return this.getCollectionsOfAccessMode(reelId, CollectionAccessMode.Private) || EmptyArray;
  }

  getPublicCollections(reelId) {
    return this.getCollectionsOfAccessMode(reelId, CollectionAccessMode.Public) || EmptyArray;
  }

  getProtectedCollections(reelId) {
    return this.getCollectionsOfAccessMode(reelId, CollectionAccessMode.Protected) || EmptyArray;
  }

  getMissingSystemCollectionNames(reelId) {
    const reelEntry = this.getDocById(reelId);
    if (reelEntry === NotLoaded) {
      return NotLoaded;
    }

    const collections = reelEntry?.collections || [];
    const collectionNames = new Set(collections.map(collection => collection.collectionName));
    return getSystemCollectionNames().filter(name => !collectionNames.has(name));
  }

  getReelCollections(reelId) {
    const reelEntry = this.getDocById(reelId);
    if (reelEntry === NotLoaded) {
      return NotLoaded;
    }

    return reelEntry?.collections || [];
  }

  getReelAndSystemCollections(reelId) {
    const collections = this.getReelCollections(reelId);

    // pad w/ missing system collections
    return [
      ...this.getMissingSystemCollectionNames(reelId).map(collectionName => ({
        collectionName,
        accessMode: getSystemCollectionAccessMode(collectionName)
      })),
      ...collections,
    ];
  }


  // ###########################################################################
  // read collection sets
  // ###########################################################################

  async queryDefaultCollections(reelid) {
    await this.queryDoc(reelid);
    return this.getDefaultCollections(reelid);
  }

  async queryDefaultCollection(reelId, contentType) {
    await this.queryDoc(reelId);
    return this.getDefaultCollection(reelId, contentType);
  }

  async queryReelCollections(reelId) {
    await this.queryDoc(reelId);
    return this.getReelCollections(reelId);
  }

  async queryReelAndSystemCollections(reelId) {
    await this.queryDoc(reelId);
    return this.getReelAndSystemCollections(reelId);
  }

  async queryPublicCollections(reelId) {
    await this.queryDoc(reelId);
    return this.getPublicCollections(reelId);
  }

  async queryReelProtectedCollections(reelId) {
    await this.queryDoc(reelId);
    return this.getProtectedCollections(reelId);
  }

  async queryCollectionEntry(collectionIdRef) {
    await this.queryDoc(collectionIdRef.reelId);
    return this.getCollectionEntry(collectionIdRef);
  }

  async queryAccessMode(collectionIdRef) {
    await this.queryDoc(collectionIdRef.reelId);
    return this.getAccessMode(collectionIdRef);
  }

  // ###########################################################################
  // write actions
  // ###########################################################################

  async addCollection(collectionIdRef, accessMode) {
    let { collectionName, reelId } = collectionIdRef;
    const { uid } = authState;

    // encode name
    collectionName = encodeURIComponent(collectionName);

    // generate new unique id
    const collectionId = this.collection.doc().id;

    // add collection to array
    let entry = await this.queryDoc(reelId);
    entry = {
      ...(entry || {}),
      uid               // last user to have written to this reel
    };
    entry.collections = entry.collections || [];
    entry.collections.push({
      collectionId,
      collectionName,
      accessMode
    });

    await this.saveDoc(reelId, entry);
  }

  /**
   * Get all system collections from the given set of collectionIdRefs and add them to `reelCollections`
   */
  async addSystemCollections(newCollectionIdRefs) {
    const newSystemRefs = newCollectionIdRefs.filter(ref => isSystemCollection(ref.collectionName));
    const systemRefsByReelId = groupBy(newSystemRefs, 'reelId');
    await Promise.all(
      Object.entries(systemRefsByReelId).map(async ([reelId, refs]) => {
        const { uid } = authState;

        // get reel entry
        let entry = await this.queryDoc(reelId);
        entry = {
          ...(entry || {}),
          uid               // last user to have written to this reel
        };
        const storedCollectionRefs = entry.collections = entry.collections || [];

        storedCollectionRefs.push(...refs.map(
          ({ collectionName }) => ({
              // collectionId: this.collection.doc().id, // generate new unique id
              collectionName: encodeURIComponent(collectionName),
              accessMode: getSystemCollectionAccessMode(collectionName)
          })
        ));

        // make sure, we don't add any collection twice
        entry.collections = uniqWith(storedCollectionRefs, (a, b) => a.collectionName === b.collectionName);

        // if (storedCollectionRefs.length !== entry.collections.length) {
        //   console.error('sanity check failed: risk of adding duplicate collections avoided', entry.collections, storedCollectionRefs);
        // }

        // store result back
        await this.saveDoc(reelId, entry);
      })
    );
  }

  async setAccessMode(collectionIdRef, accessMode) {
    const collectionEntry = await this.queryCollectionEntry(collectionIdRef);
    if (collectionEntry) {
      collectionEntry.accessMode = accessMode;
    }

    // save (NOTE: will throw meaningful error if collection does not exist)
    await this.saveDoc(collectionIdRef.reelId);
  }

  async deleteCollection(collectionIdRef) {
    if (!await this.queryIsCollectionEmpty(collectionIdRef)) {
      if (!confirm('Collection is not empty - are you absolutely sure, you want to delete all its contents?')) {
        return;
      }
    }

    const { reelId, collectionName } = collectionIdRef;
    const { uid } = authState;

    let entry = await this.queryDoc(reelId);
    entry = {
      ...entry,
      uid               // last user to have written to this reel
    };
    if (!entry?.collections) {
      throw new Error(`Cannot delete collection. Does not exist - ${JSON.stringify(collectionIdRef)}`);
    }

    // 1. first delete all entries
    await getContentCollectionQueryInterface().deleteAllEntries(collectionIdRef);

    // 2. delete collection itself (if previous step succeeded)
    entry.defaultCollections && entry.defaultCollections.map(name => name === collectionName ? null : name);
    entry.collections = entry.collections.filter(col => col.collectionName !== collectionName);

    await this.saveDoc(reelId, entry);
  }

  async updateDefaultCollections(reelId, defaultCollections) {
    const { uid } = authState;
    let entry = await this.queryDoc(reelId);
    entry = {
      ...entry,
      defaultCollections,
      uid               // last user to have written to this reel
    };

    await this.saveDoc(reelId, entry);
  }

  // ###########################################################################
  // read collection entries
  //  TODO: move to better place?
  // ###########################################################################

  async filterNonEmptyCollections(reelId, collectionNames, contentType) {
    // filter out empty collections
    collectionNames = await Promise.all(
      collectionNames.map(async collectionName => {
        const isEmpty = await this.queryIsCollectionEmpty({ reelId, collectionName }, contentType);
        return isEmpty ? null : collectionName;
      })
    );
    return collectionNames.filter(col => !!col);
  }

  // NOTE: this currently checks if there is at least one item for that user/collection
  async queryIsCollectionEmpty(collectionIdRef, contentType) {
    return !await getContentCollectionQueryInterface().queryHasCollectionEntries(collectionIdRef, contentType);
  }

  async queryHasPublicCollectionWithAtLeastNEntries(reelId, n) {
    const limit = 20; // query in chunks of this size
    const entryContainer = new ContentCollectionEntryContainer();
    const counts = {};

    // sum up all counts
    const addCount = (entry) => {
      const {
        collectionName,
        type: contentType
      } = entry;

      if (!this.isCollectionPublic(reelId, collectionName)) {
        return;
      }

      const key = `${collectionName}####${contentType}`;
      counts[key] = (counts[key] || 0) + 1;
    };

    // get first page
    let entries = await entryContainer.query({
      where: ['uid', '==', reelId],
      limit
    });
    Object.values(entries).forEach(addCount);

    // go through all following pages
    let enough;
    while (
      !(enough = (Math.max(...Object.values(counts)) >= n)) &&
      !entryContainer.hasReachedLastPage()
    ) {
      const entries = await entryContainer.loadNextPage();
      Object.values(entries).forEach(addCount);
    }
    return enough;
  }
}

// ###########################################################################
// utility functions
// ###########################################################################


export async function queryCollectionAccessMode(collectionIdRef) {
  if (isSystemCollection(collectionIdRef.collectionName)) {
    return getSystemCollectionAccessMode(collectionIdRef.collectionName);
  }

  return reelCollectionsContainer.queryAccessMode(collectionIdRef);
}

export function getCollectionAccessMode(collectionIdRef) {
  if (isSystemCollection(collectionIdRef.collectionName)) {
    return getSystemCollectionAccessMode(collectionIdRef.collectionName);
  }

  return reelCollectionsContainer.getAccessMode(collectionIdRef);
}

export function getCollectionLabel(collectionName, reelId, contentType) {
  if (isSystemCollection(collectionName)) {
    return getSystemCollectionLabel(collectionName, reelId, contentType);
  }
  return decodeURIComponent(collectionName);
}

const reelCollectionsContainer = new ReelCollectionsContainer();
export default reelCollectionsContainer;

// [debug-global]
window.reelCollectionsContainer = reelCollectionsContainer;
