import SingleFirebaseUploadButton from 'src/renderUtil/uploads/SingleFirebaseUploadButton';
import { authState } from 'src/auth';
import { putFile, getStorageImageUrl, getReelFileOfSizePath } from 'src/api/storage/index';
import ImageCropper from 'src/renderUtil/imageProcessing/ImageCropper';
import { waitForClick, decorateClasses, cloneTemplateElement } from 'src/util/domUtil';
import State from 'src/util/State';
import { ReelImageConfig } from 'src/api/reel/reelImages';

// ###########################################################################
// Configuration
// ###########################################################################

function normalizeConfig(multiConfig) {
  return multiConfig.map(config => {
    // create a copy
    config = { ...config };

    const {
      min
    } = config;

    // aspectRatio (if not given, base it on min)
    config.aspectRatio = config.aspectRatio || (min.width / min.height);
    if (isNaN(config.aspectRatio)) {
      throw new Error(`aspectRatio not set and also not computable from config.min: ${JSON.stringify(config)}`);
    }

    // exports (if not given, base it on min)
    if (!config.exports) {
      config.exports = [
        {
          width: min.width
        }
      ];
    }

    return config;
  });
}

// ###########################################################################
// core
// ###########################################################################

/**
 * Structure (HTML):
1. <div class="profile-upload-modal"></div>

2. <div class="background-upload-modal"></div>

3. <div class="upload-modal-body-template">
  <div class="dropzone"></div>

  <div class="croppers-ctn">
    <!--
      * use flex layout for croppers-list (rows on desktop, columns on mobile);
      * position on top of the dropzone;
      * contains 1 or 2 .cropper-template instances
    -->
    <div class="croppers-list"></div>
    <button class="submit-btn">Submit</button>
  </div>
</div>

4. <div class="cropper-template">
  <h3 class="title">title</h3>
  <div class="crop-body"></div> <!-- 300x300 -->
</div>
 */

export default class ReelImageUploader {
  state = new State();

  /**
   * @type {ImageCropper[]}
   */
  croppers;

  constructor(reelId, configName, upladBtnSelector, modalSelector, handleUploadCompleted) {
    //  const dropzoneContentTemplateSelector = '.upload-modal-body-template';
    this.reelId = reelId;

    // setup config
    this.handleUploadCompleted = handleUploadCompleted;
    let multiConfig = ReelImageConfig[configName];
    this.multiConfig = multiConfig = normalizeConfig(multiConfig);
    this.fileName = this.multiConfig[0].fileName;

    // elements
    this.$modal = $(modalSelector);
    const $modalContent = this.$modal.find('.popup-content-ctn');
    let $modalBody = this.$modalBody = $modalContent.find('.upload-modal-body-template');
    if (!$modalBody.length) {
      $modalBody = cloneTemplateElement('.upload-modal-body-template')
        .removeClass('hidden');
      $modalContent.append($modalBody);
    }

    // dropzone
    const dropzoneSelector = modalSelector + ' .reel-dropzone';
    this.$dropzone = $(dropzoneSelector);

    // uploadBtn
    const $uploadBtnEl = $(upladBtnSelector);
    $uploadBtnEl.removeClass('hidden');
    this.uploadBtn = new SingleFirebaseUploadButton($uploadBtnEl, dropzoneSelector,
      this.handleDropzoneOpen, null, this.transformFile);

    // submitBtn
    this.$submitBtnEl = $modalBody.find('.submit-btn');

    // croppers
    this.$croppersCtn = $modalBody.find('.croppers-ctn')
      .addClass('hidden');

    // render busy state
    this.state.onUpdate(this.render);
  }

  getStoragePath(width, height) {
    // storage
    const { reelId } = this;
    // return getUserFilePath(uid, `${width}x${height}/${this.fileName}`);
    return getReelFileOfSizePath(reelId, this.fileName, { width, height });
  }

  // ###########################################################################
  // Open dropzone
  // ###########################################################################

  handleDropzoneOpen = () => {
    this.state.setState({ cropping: false });
  }

  // ###########################################################################
  // Cropping
  // ###########################################################################

  initCroppers() {
    const { $croppersCtn } = this;
    const $croppersList = $croppersCtn.find('.croppers-list');

    // clean up
    if (this.croppers) {
      this.croppers.forEach(cropper => cropper.cleanUp());
    }
    $croppersList.empty();

    // create new croppers
    const $cropperTemplate = cloneTemplateElement('.cropper-template');    // instantiate cropper template again
    const croppers = this.croppers = [];
    for (const config of this.multiConfig) {
      croppers.push(new ImageCropper($croppersList, $cropperTemplate, config.aspectRatio));
    }
  }

  validateImagesAfterCrop() {
    return this.croppers.every((cropper, i) => {
      const config = this.multiConfig[i];
      const croppedStats = cropper.getCroppedStats();

      const validationViolations = [];

      if (croppedStats.width < config.min.width) {
        validationViolations.push(`cropped area not wide enough for "${config.title}" (must be at least ${config.min.width} pixels, found: ${croppedStats.width} pixels)`);
      }
      if (croppedStats.height < config.min.height) {
        validationViolations.push(`cropped area not tall enough for "${config.title}" (must be at least ${config.min.height} pixels, found: ${croppedStats.height} pixels)`);
      }

      if (validationViolations.length) {
        alert(validationViolations.join('\n\n'));
        return false;
      }
      return true;
    });
  }

  async uploadFiles() {
    const promises = this.croppers.map(async (cropper, i) => {
      const config = this.multiConfig[i];
      const cropResultStats = cropper.getCroppedStats();

      // resize into target sizes + upload
      let previousExport;
      const files = await Promise.all(config.exports.map(async expo => {
        // disable cropper
        cropper.cropper.disable();

        // get target size
        const { width } = expo;
        const height = cropper.getHeight(width);

        if (previousExport) {
          // only export higher res if cropped size is sufficient
          const previousWidth = previousExport.width;
          const previousHeight = cropper.getHeight(previousWidth);
          const minWidth = (width + previousWidth) * 0.5;
          const minHeight = (height + previousHeight) * 0.5;
          if (cropResultStats.width < minWidth ||
            cropResultStats.height < minHeight) {
            // only export if image size exceeds median in both width + height
            console.warn(`rejected high-res upload (cropped area not large enough) - width: ${cropResultStats.width} < ${minWidth} OR height: ${cropResultStats.height} < ${minHeight}`);
            return;
          }
        }

        previousExport = expo;

        // export file
        const file = await cropper.getImageFile(width);

        // get path
        const path = this.getStoragePath(width, height);

        console.debug('uploading file -', `${width}x${height} -`, getStorageImageUrl(path));

        try {
          // upload to firebase storage
          await putFile(path, file);
          return file;
        }
        catch (err) {
          console.error('upload failed :(', err);
          alert('upload failed :(');
        }
      }));

      cropper.cleanUp();

      return files;
    });
    return (await Promise.all(promises)).flat();
  }


  transformFile = async (inputFile) => {
    // init croppers
    this.initCroppers();

    // start cropping
    this.state.setState({ cropping: true });

    try {
      // set croppers file
      for (let i = 0; i < this.multiConfig.length; ++i) {
        const config = this.multiConfig[i];
        const cropper = this.croppers[i];
        cropper.start(inputFile, config.title);
      }

      this.$submitBtnEl.text('Upload!');
      this.$submitBtnEl.removeClass('disabled');

      // wait until finished editing (and images pass validation)
      do {
        await waitForClick(this.$submitBtnEl);
      }
      while (!this.validateImagesAfterCrop());

      this.$submitBtnEl.text('uploading...');
      this.$submitBtnEl.addClass('disabled');

      try {
        // process + upload all result files
        await this.uploadFiles();

        this.handleUploadCompleted && this.handleUploadCompleted(this.fileName);

        setTimeout(() => {
          // hackfix :(
          // close modal (after minimal delay, to not f* with dropzone functionality)
          this.$modal.hide();
        });
      }
      finally {
        this.$submitBtnEl.text('Finish');
        this.$submitBtnEl.removeClass('disabled');
      }
    }
    catch (err) {
      console.error('failed to process or upload files:', err);
    }
    finally {
      this.state.setState({ cropping: false });
    }
  }


  render = () => {
    const {
      cropping
    } = this.state.get;

    decorateClasses(this.$dropzone, {
      hidden: cropping
    });

    decorateClasses(this.$croppersCtn, {
      hidden: !cropping
    });
  }
}