import Enum from 'src/util/Enum';

let CollectionAccessModeObj = {
  Private: 0,
  Protected: 1,
  Public: 2
}

/**
 * @type {CollectionAccessModeObj | Enum}
 */
const CollectionAccessMode = new Enum(CollectionAccessModeObj);
export default CollectionAccessMode;