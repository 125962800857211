
import firebase from 'firebase/app';
import FirestoreContainer from 'src/firebase/FirestoreContainer';
import EmptyArray from 'src/util/EmptyArray';

import {
  TagActionType,
  TagMaxVotes
} from './TagConfig';
import { rememberAddedTag } from './tags';

/**
 * Document structure for `tagReviews`:
 * {
 *  contentId: {
 *    tag: {
 *      tagAction: {
 *        votes,
 *        voteCount
 *      }
 *    }
 *  }
 * }
 */
class TagReviews extends FirestoreContainer {
  constructor() {
    super('tagReviews');
  }

  getTagReviews = (contentId) => {
    return this.getDocNowOrQuery(contentId);
  }

  /**
   * Get set of all tags that have been proposed but are currently still being voted on.
   */
  getPendingTags = (contentId, excludeTags) => {
    const reviews = this.getTagReviews(contentId);
    if (!reviews) { return reviews; }
    const tags = Object.keys(reviews);
    const excludeTagsSet = new Set(excludeTags);
    return !excludeTags ? tags : tags.filter(tag => !excludeTagsSet.has(tag));
  }

  getTagReview = (contentId, tag) => {
    const reviews = this.getTagReviews(contentId);
    if (!reviews) { return reviews; }
    return reviews[tag] || null;
  }

  getTagActionData = (contentId, tag, tagAction) => {
    const review = this.getTagReview(contentId, tag);
    if (!review) { return review; }
    return review[tagAction] || null;
  }

  getVoteCount = (contentId, tag, tagAction) => {
    const action = this.getTagActionData(contentId, tag, tagAction);
    if (!action) { return action; }
    return action.voteCount || 0;
  }

  /**
   * Array of uids that casted votes for this tag+tagAction combination
   */
  getVotes = (contentId, tag, tagAction) => {
    const action = this.getTagActionData(contentId, tag, tagAction);
    if (!action) { return action; }
    return action.votes || EmptyArray;
  }

  getHasVoted = (contentId, tag, tagAction, uid) => {
    const uids = this.getVotes(contentId, tag, tagAction);
    return uids && uids.includes(uid) || false;
  }

  getIsTagApproved = (contentId, tag) => {
    const voteCount = this.getVoteCount(contentId, tag, TagActionType.Yea);
    return voteCount >= TagMaxVotes.Yea;
  }

  // async toggleVote(contentId, tag, tagAction, uid) {
  //   tagAction = TagActionType.valueFromForce(tagAction);

  //   // optimistic UI: because the transaction is slow, let's show something to the user right away
  //   this.overrideTagVote(contentId, tag, tagAction, uid);

  //   // actually send out the transaction
  //   return db.runTransaction(async transaction => {
  //     const oldData = (await transaction.get(this.doc(contentId))).data();
  //     const oldTagData = oldData && oldData[tag];
  //     return await this.toggleVoteTransaction(transaction, oldTagData, contentId, tag, tagAction, uid);
  //   });
  // }

  /**
   * Used for optimistic UI: force-render the optimal result first.
   */
  overrideTagVote = (contentId, tagName, tagAction, uid) => {
    const hasVoted = this.getHasVoted(contentId, tagName, tagAction, uid);
    let votes = this.getVotes(contentId, tagName, tagAction);
    let voteCount = this.getVoteCount(contentId, tagName, tagAction);
    if (hasVoted) {
      // retract vote
      --voteCount;
      votes = votes && votes.filter(v => v !== uid) || EmptyArray;
    }
    else {
      // cast vote
      ++voteCount;
      votes = [...votes, uid];
    }

    // const updateStructureExample = {
    //   [tagName]: {
    //     [tagAction]: {
    //       voteCount: firebase.firestore.FieldValue.increment(1),
    //       votes: firebase.firestore.FieldValue.arrayUnion(uid)
    //     }
    //   }
    // };
    const orig = this.getDocNowOrQuery(contentId);
    const origTagData = orig && orig[tagName] || null;
    const tagData = Object.assign({}, origTagData, {
      [tagAction]: {
        votes,
        voteCount
      }
    });
    this.overrideDoc(contentId, {
      [tagName]: tagData
    }, true);
  }

  async toggleVoteTransaction(transaction, oldTagData, contentId, tag, tagAction, uid) {
    tagAction = TagActionType.valueFromForce(tagAction);

    const update = {
      updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
    };

    const hasCastedBefore = oldTagData?.[tagAction]?.votes?.includes(uid) || false;
    let voteCount = oldTagData?.[tagAction]?.voteCount || 0;

    if (!hasCastedBefore) {
      // casting vote
      voteCount += 1;
      addVoteUpdate(uid, tagAction, update, contentId, tag);

      // also, revoke vote to previous action
      const inverseAction = getInverseTagAction(tagAction);
      if (oldTagData && oldTagData[inverseAction] && oldTagData[inverseAction].votes && oldTagData[inverseAction].votes.includes(uid)) {
        revokeVoteUpdate(uid, inverseAction, update);
      }
    }
    else {
      // revoking vote
      voteCount -= 1;
      revokeVoteUpdate(uid, tagAction, update);
    }

    const fullUpdate = {
      [tag]: update
    };
    await transaction.set(this.doc(contentId), fullUpdate, { merge: true });

    return [!hasCastedBefore, voteCount];
  }
}


function getInverseTagAction(tagAction) {
  return tagAction === TagActionType.Yea && TagActionType.Nay || TagActionType.Yea;
}

/**
 * Cast vote in favor of given action.
 */
function addVoteUpdate(uid, tagAction, update, contentId, tag) {
  // little hack: also remember the tag being added
  rememberAddedTag(contentId, tag);

  // increment vote count, and add to votes
  update[tagAction] = update[tagAction] || {};
  update[tagAction].voteCount = firebase.firestore.FieldValue.increment(1);
  update[tagAction].votes = firebase.firestore.FieldValue.arrayUnion(uid);
}


/**
 * Revoke vote of given action.
 */
function revokeVoteUpdate(uid, tagAction, update) {
  // increment vote count, and add to votes
  update[tagAction] = update[tagAction] || {};
  update[tagAction].voteCount = firebase.firestore.FieldValue.increment(-1);
  update[tagAction].votes = firebase.firestore.FieldValue.arrayRemove(uid);
}

const tagReviews = new TagReviews();
export default tagReviews;