import NanoEvents from '../util/NanoEvents';
import { DevClass, DevHidden } from '../renderUtil/devtools';



const debugEvents = new NanoEvents();
let devMode = false;

export function isDevMode() {
  return devMode;
}

export function onDebugToggle(cb) {
  return debugEvents.on('debugToggle', cb);
}

export function startDevMode() {
  if (devMode) {
    return;
  }
  devMode = true;
  $(`.${DevHidden}`).show();
  debugEvents.emit('debugToggle', devMode);
}

export function stopDevMode() {
  if (!devMode) {
    return;
  }
  devMode = false;
  $(`.${DevHidden}`).hide();
  $(`.${DevClass}`).remove();
  debugEvents.emit('debugToggle', devMode);
}