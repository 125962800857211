
// ##################################################################################################################
// Search tags for adding to content object
// ##################################################################################################################

import { renderWorldsDefault, clearTagList, createOrRenderTagEls } from 'src/render/tags/tags';
import { TagRenderContext } from 'src/api/tags/TagConfig';
import { prepareTemplateElement, selectTextOnClick } from 'src/util/domUtil';
import { old_queryWikiSearch } from 'src/api/third-party/wikiApi';
import postUpdate from 'src/postUpdate';
import WikiSearchRenderer from 'src/render/tags/WikiSearchRenderer';

function addSearchInputEvents($input) {
  selectTextOnClick($input[0]);

  // TODO: scroll
  // const $viewport = $input.closest('.os-viewport');
  // const $cont = $input.closest('.content-container');

  // // scroll down!
  // const bottom = $viewport.height() + $viewport[0].scrollTop;
  // const h = $cont.height();
}

/**
 * Add the "world search" (or tag search) functionality to given parent.
 * Only called once during creation.
 * Call given callback every time a new tag has been rendered.
 * 
 * @param $searchCont Any ancestor of the search element.
 */
export function initRenderTagSearch(tagRenderContext, $searchCont, contentId = undefined) {
  const search = new WikiSearchRenderer($searchCont, tagRenderContext, contentId);
  search.clear();
  // inputEl.focus();
}


export function clearTagSearch(tagRenderContext, $searchCont) {
  $searchCont[0]._search.clear();
}