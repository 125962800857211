import { populateForm, serializeForm2Object } from 'src/util/formUtil';
import tagContainer from 'src/api/tags/tagContainer';
import { startWebflowAnimation } from 'src/renderUtil/animUtil';
import { authState } from 'src/auth';


export default class VideoEditorPopup {
  constructor(propName, props) {
    this.modalName = 'tag-popup-video';
    const $modal = $('.' + this.modalName);
    this.$modal = $modal;
    this.$form = $modal.find('form');
    this.$submitBtn = $modal.find('.submit-button');

    this.propName = propName;
    Object.assign(this, props);
  }

  async open() {
    // open popup
    startWebflowAnimation(this.modalName);

    // get data
    const data = await this.getData.call(this);

    const $input = this.$form.find('input');
    $input.val(data?.[this.propName] || '');
    // populateForm(this.$form, tag);

    this.$submitBtn.off('click').on('click', async evt => {
      // const newTagData = serializeForm2Object(this.$form);
      const newVal = $input.val();
      const newData = {
        [this.propName]: newVal
      };

      await this.setData.call(this, newData);

      this.$modal.hide();
    });
  }

  static async open(propName, permissionMessage, args) {
    if (authState.isModerator()) {
      const popup = new VideoEditorPopup(propName, args);
      await popup.open();
      return popup;
    }
    else {
      alert(permissionMessage);
    }
  }

  static attachToBtn($btn, propName, permissionMessage, args) {
    $btn.off('click').on('click', async evt => {
      evt.preventDefault();

      await this.open(propName, permissionMessage, args);
    });
  }
}