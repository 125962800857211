// ###########################################################################
// polyfills
// ###########################################################################

/**
 * Babel does not include a `fetch` polyfill.
 * NOTE: this fetch library only works in browser (does not work in tests).
 * @see https://github.com/babel/babel/issues/9160
 * @see https://www.npmjs.com/package/whatwg-fetch
 */
import 'whatwg-fetch';

// ###########################################################################
// import css
// ###########################################################################

import './styles.css';

import 'dropzone/dist/basic.css'; // NOTE: dropzone's css files are actually SASS. need a sass-loader for them.
import 'dropzone/dist/dropzone.css';
// import 'dropzone/dist/min/basic.min.css';
// import 'dropzone/dist/min/dropzone.min.css';

import 'cropperjs/dist/cropper.css';

// ###########################################################################
// regular imports
// ###########################################################################

import { initContent } from './api/content/contentObjects';
import { initMixPanel, trackEvent, trackOpenWeb, trackOpenApp } from 'src/api/third-party/mixpanel';
import { initStartQueue, initRenderAll } from './render/render-all';
import { initAuth, loadAuthFromCache } from './auth';
import { installBrowserHacks } from './util/browserHacks';

// import $ from 'jquery';
// window.$ = $;
// window.jQuery = $;
// console.warn('index.js', $);



// import regeneratorRuntime from 'regenerator-runtime';
import { perfLog } from './util/perf';
import { initPWAInstall, initServiceWorker, isStandaloneMode } from './web-apis/pwa';
import { initMessaging } from './api/messaging/messaging';
import startQueue from 'src/taskScheduler';
import { initLocalStorageCache } from 'src/util/localStorage';
import { initRenderPage } from 'src/render/pages';
import { routingAfterAuth } from 'src/render/pages/routing';


// /**
//  * @see https://github.com/babel/babel/issues/9044
//  */
// window.regeneratorRuntime = regeneratorRuntime;


perfLog('index.js start');

// ###########################################################################
// password reset
// ###########################################################################

// const isPasswordReset = getPageName() === 'login-help';

// Load home background Randomly
/*var cards = $(".refresh");
for(var i = 0; i < cards.length; i++){
    var target = Math.floor(Math.random() * cards.length -1) + 1;
    var target2 = Math.floor(Math.random() * cards.length -1) +1;
    cards.eq(target).before(cards.eq(target2));
}

var cardsMobile = $(".randomdiv");
for(var i = 0; i < cardsMobile.length; i++){
    var target = Math.floor(Math.random() * cardsMobile.length -1) + 1;
    var target2 = Math.floor(Math.random() * cardsMobile.length -1) +1;
    cardsMobile.eq(target).before(cardsMobile.eq(target2));
}*/

// ##################################################################################################################
// some (ill-placed (TODO!)) app-wide config
// ##################################################################################################################

const contentConfig = {
  firstPageItemCount: 3,
  ecmOrderFirstPageCount: 100, // special
  defaultPageItemCount: 20
};

const objectColumnRenderConfig = {
  firstPageItemCount: 3,
  defaultPageItemCount: 20
};

// ###########################################################################
// populateQueue
// ###########################################################################

function populateTaskQueue() {
  initStartQueue();

  startQueue.addBackgroundTasks(
    // initialize PWA installer buttons
    initPWAInstall,

    // initialize service-worker, then initialize FCM
    // NOTE: only init messaging in standalone mode (PWA)
    initServiceWorker.bind(null, initMessaging)
  );
}

// ##################################################################################################################
// go time!
// ##################################################################################################################

// TODO: https://developers.google.com/web/fundamentals/performance/prpl-pattern/

// init lscache configuration, and start a timer to flush expired items
initLocalStorageCache();

// init mixpanel + start by tracking first user action
initMixPanel();
// trackEvent('openedPage', isStandaloneMode());

// load auth from cache first -> this way, some modules can start eager-loading uid-dependnet content
loadAuthFromCache();

// implement browser-specific hacks first
installBrowserHacks();

// if (!isPasswordReset)
{

  // prepare ContentContainer objects (does not interact w/ DB)
  initContent(contentConfig);

  // start rendering all dynamic content
  initRenderAll(objectColumnRenderConfig);
  // });
}

// 0. init auth
initAuth(async () => {
  // once auth initialized -> make sure we are allowed to be here
  await routingAfterAuth();

  // track in mixpanel
  if (isStandaloneMode()) {
    trackOpenApp();
  }
  else {
    trackOpenWeb();
  }
  
  perfLog('auth complete');
});


// 1. wait for page init to finish (most important tasks first)
initRenderPage().
  then(() => {
    // 2. populate queue
    populateTaskQueue();

    // 3. process fully loaded queue
    startQueue.start()
  });