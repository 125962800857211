import { ecmCalculator, ecmContainer as defaultECMContainer } from 'src/api/ecm';
import {
  getContentObjectsByType, getContentById
} from '../../api/content/contentObjects';

import { renderModeratorTools } from '../moderation';

import { DefaultSlideDelay, selectTextOnClick, addEllipsisEl } from '../../util/domUtil';
import {
  maybeRenderContentTags, initRenderTagToggle, addToggleTagsClickHandler
} from 'src/render/tags/tags';

import { getVideoHtmlId } from 'src/renderUtil/contentObjects';
import { TagRenderContext } from '../../api/tags/TagConfig';
import { initRenderTagSearch } from '../tags/wikiSearch';
import { startWebflowAnimation } from '../../renderUtil/animUtil';
import { getShareLink } from '../../api/links';
import { startRenderContentPreview } from './contentPreview';
import { getContentColumnWidth } from '../../util/responsive';
import { renderECMExplainer } from '../ecm';
import { scrollIntoViewport } from './contentScroller';
import { authState } from 'src/auth';
import { showNotLoggedInUserActionWarning } from 'src/renderUtil/userActions';
import { initRenderContentCollectionModal, showContentCollectionModal } from 'src/render/collections/ContentCollectionModal';
import VideoEditorPopup from 'src/render/tags/VideoEditorPopup';
import { waitForMagnificPopup } from 'src/render/thirdParty';
import ContentConfig from 'src/api/content/ContentConfig';
import { isKnownStreamingPlatform } from 'src/api/third-party/networks';
import ContentType, { isContentTypeReelCategory } from 'src/api/content/ContentType';
import { getReelUrl, getReelDisplayName } from 'src/api/reel/reelInfo';
import { loadValidReelImageUrl } from 'src/api/reel/reelImages';


// ##################################################################################################################
// create + init rendering
// ##################################################################################################################

const TemplateSelectorsByContentType = {
  [ContentType.Film]: '.movie-template',
  [ContentType.Series]: '.movie-template',
  [ContentType.Book]: '.movie-template'
};


function initRenderContentEl($contentEl, contentId, content, expandTagsByDefault) {
  // coming-soon animations
  initRenderComingSoon($contentEl);

  // setup tag search functionality
  initRenderTagSearch(TagRenderContext.ContentSearch, $contentEl, contentId);

  // toggle tag lists
  initRenderTagToggle(contentId, $contentEl, expandTagsByDefault);

  // basic interaction with content
  initMiscButtons($contentEl, contentId, content);

  // decorations per ContentType
  initRenderForContentType($contentEl, contentId, content);

  // clear ecm score
  $contentEl.find('.ecm-score').text('');
}

/**
 * 2nd phase of content rendering.
 * Intentionally deferred until previous tasks have finished.
 */
export function initRenderContentEl2(contentId, content, $contentEl) {
  // ECM stuff
  initRenderECM(contentId, content, $contentEl);
}

const contentTypeDecorators = {
  [ContentType.Video]: ($contentObj, contentId, content) => {
    $contentObj.find('.video,.videobutton,.title-object-link').off('click').on('click', evt => {
      // when clicking on videos -> show in editor (with autoplay enabled)
      evt.preventDefault();
      startRenderContentPreview(contentId, { autoplay: true });
      scrollIntoViewport($contentObj); // make sure, the preview won't overlap the content object
    });
  }
};

function initRenderForContentType($contentObj, contentId, contentData) {
  // add decorations based on contentType
  const contentTypeDecorator = contentTypeDecorators[contentData.type];
  contentTypeDecorator && contentTypeDecorator($contentObj, contentId, contentData);
}

function initRenderComingSoon($contentObj) {
  const $comingSoon = $contentObj.find('.coming-soon');
  $comingSoon.on('click', () => {
    startWebflowAnimation('feature-coming-soon');
  });
}

/**
 * Buttons that allow basic interaction with given content
 */
function initMiscButtons($contentEl, contentId, content) {
  // update button text (based on content type)
  initRenderGoToButton($contentEl, content);

  // share button
  initRenderShareButton($contentEl, contentId);

  // share button
  initRenderCollectionEditorButton($contentEl, contentId);
}

function initRenderGoToButton($contentObj, contentData) {
  const $linkBtn = $contentObj.find('.videobutton');
  let btnText;

  // [i18n]
  switch (contentData.type) {
    case ContentType.Video:
      btnText = 'watch video';
      break;
    case ContentType.Article:
      btnText = 'read article';
      break;
    case ContentType.Series:
      if (isKnownStreamingPlatform(contentData.networkName)) {
        btnText = 'stream now';
      }
      else {
        btnText = 'learn more';
      }
      break;
    case ContentType.Course:
      btnText = 'explore course';
      break;
    case ContentType.Product:
      btnText = 'visit store';
      break;
    default:
      btnText = 'learn more';
      break;
  }
  $linkBtn.text(btnText);
}

function initRenderShareButton($contentObj, contentId) {
  const $shareBtn = $contentObj.find('.share-object-button');
  $shareBtn.attr('href', getShareLink(contentId));
  $shareBtn.on('click', () => {
    // pre-fill popup
    const $sharePopup = $('.share-object-popup');
    initRenderSharePopup($sharePopup, contentId);

    // show popup
    startWebflowAnimation('share-object-popup');
  });
}

function initRenderSharePopup($sharePopup, contentId) {
  const $urlText = $sharePopup.find('.object-url-text');
  const $copyBtn = $sharePopup.find('.copy-url-share-button');
  const $confirmation = $sharePopup.find('.url-copied-confirmation');
  // const $closeBtn = $sharePopup.find('.popup-top-section a');

  $urlText.text(getShareLink(contentId));
  $confirmation.hide();

  $copyBtn.off('click').on('click', () => {
    // select text
    selectTextOnClick($urlText[0]);

    // copy to clipboard
    document.execCommand('copy');

    $confirmation.show();
  });
}

function initRenderCollectionEditorButton($contentObject, contentId) {
  const $btn = $contentObject.find('.add-collection-btn');
  $btn.on('click', evt => {
    // const contentTypeName = ContentType.nameFromForce(content.type).toLowerCase();
    if (showNotLoggedInUserActionWarning()) {
      return;
    }

    // NOTE: there is only one popup/set of collections for all contentTypes
    const content = getContentById(contentId);
    initRenderContentCollectionModal(content.type, content);
    showContentCollectionModal();
  });
}

// ###########################################################################
// contentObject ECM
// ###########################################################################

function initRenderECM(contentId, contentData, $contentEl) {
  // update ECM decorations initially, and also, when ECM data changes
  decorateContentECM(contentId, contentData, $contentEl);

  defaultECMContainer.startECMSync();
  defaultECMContainer.onUpdate(() => {
    decorateContentECM(contentId, contentData, $contentEl);
  });
}

function decorateContentECM(contentId, contentData, $contentEl) {
  const $ecmScore = $contentEl.find('.ecm-score');
  const $ecmExplainer = $contentEl.find('.ecm-explainer-button');
  addToggleTagsClickHandler(contentId, $contentEl, $ecmExplainer);

  if (!ecmCalculator.isReady()) {
    // don't calculate anything
    $ecmScore.text('');
    return;
  }

  // perfLog('decorateContentECM', contentId, '00');

  const weightedScore = ecmCalculator.getECMScoreForContent(contentId, contentData);
  const unweightedScore = ecmCalculator.getUnweightedECMScoreForContent(contentId, contentData);
  const countsByFlags = defaultECMContainer.getMatchingTagCountsByECMFlags(contentData.tags);

  // make explainer clickable
  // $ecmExplainer.off('click').on('click', () => {
  //   startWebflowAnimation('ecm-explainer-popup');
  // });

  // keep track of this for now
  $ecmScore.attr('data-ecm-weighted-score', weightedScore);

  renderECMExplainer($contentEl, unweightedScore, countsByFlags);

  // perfLog('decorateContentECM', contentId, 11);
}

// ###########################################################################
// create
// ###########################################################################

export function createContentEl(contentId, contentData, expandTagsByDefault) {
  const {
    type: contentType
  } = contentData;

  const templateSelector = TemplateSelectorsByContentType[contentType] || '.video-template';

  // create new video element
  var videoSourceHtml = document.querySelector(templateSelector);
  const contentObjEl = document.createElement('div');
  const $contentEl = $(contentObjEl);

  contentObjEl.setAttribute('data-content-id', contentId);
  contentObjEl.id = getVideoHtmlId(contentId);
  contentObjEl.innerHTML = videoSourceHtml.innerHTML;
  contentObjEl.className = "individual-object-div";

  // initRender takes care of static behavior (behavior that does not change over time)
  initRenderContentEl($contentEl, contentId, contentData, expandTagsByDefault);

  // take care of dynamic behavior (behavior that does change over time)
  renderContentEl($contentEl, contentId, contentData);

  return $contentEl;
}

// ###########################################################################
// Editable video
// ###########################################################################

function setPopupHref($btn, url) {
  $btn.addClass('content-popup');
  $btn.off('click');
  $btn.attr('href', url);
}

function renderEditableVideoButton(contentId, contentObject, propName, $btn) {
  if (!$btn.length || !contentObject) {
    return;
  }

  const { type: contentType } = contentObject;
  const url = contentObject[propName];
  if (url) {
    setPopupHref($btn, url);
  }
  else {
    $btn.removeClass('content-popup');
    const contentObjectContainer = getContentObjectsByType(contentType);
    VideoEditorPopup.attachToBtn($btn, propName,
      `There isn't a ${propName} for this tag. You can submit a trailer yourself, once you have become an approved content contributor.`,
      {
        async getData() {
          return contentObjectContainer.queryDoc(contentId);
        },

        async setData(newData) {
          return contentObjectContainer.setDoc(contentId, newData, true);
        }
      }
    );
  }
}

// ###########################################################################
// render update
// ###########################################################################

export function renderContentEl($contentEl, contentId, contentObject) {
  if (isContentTypeReelCategory(contentObject.type)) {
    renderReelCategory($contentEl, contentId, contentObject)
  }
  else {
    renderContentObjectCategory($contentEl, contentId, contentObject)
  }
}

async function renderReelCategory($contentEl, reelId, reelInfo) {
  const url = getReelUrl(reelId);

  const contentObject = {
    type: reelInfo.type,
    url,
    title: getReelDisplayName(reelInfo),
    description: '',
    author: getReelDisplayName(reelInfo),
    author_url: url
  };

  renderContentObjectCategory($contentEl, reelId, contentObject)

  // url needs some extra work
  const thumbUrl = await loadValidReelImageUrl(reelId);
  if (thumbUrl) {
    const $thumbEl = $contentEl.find('.dynamic-thumbnail');
    $thumbEl.attr('src', thumbUrl);
  }
}

function renderContentObjectCategory($contentEl, contentId, contentObject) {
  const contentEl = $contentEl[0];
  // individualObject.querySelector('.dynamic-thumbnail').setAttribute('loading', 'lazy');

  // TODO: more general per-content-type rendering

  // add a little hack for videos only
  let {
    type: contentType,
    originUrl,
    url,
    thumbnail_url,
    title,
    description,
    author,
    author_url,
    author_about_video_url,
    networkUrl
  } = contentObject;
  const createdAt = contentObject.createdAt?.toDate() || new Date();

  url = originUrl || url;

  const thumbEl = contentEl.querySelector('.dynamic-thumbnail');
  thumbEl && thumbEl.setAttribute('src', thumbnail_url);

  if (contentType === ContentType.Video) {
    const $thumbEl = $(thumbEl);
    const w = getContentColumnWidth();
    const h = w / 16 * 9;
    $thumbEl.width(w);
    $thumbEl.height(h);
    $thumbEl.css('object-fit', 'cover');
  }

  contentEl.querySelector('.video')?.setAttribute('href', url);
  contentEl.querySelector('.videobutton')?.setAttribute('href', url);

  // individualObject.querySelector('.video').setAttribute('target', '_blank');
  // individualObject.querySelector('.videobutton').setAttribute('target', '_blank');

  const linkEl = contentEl.querySelector('.title-object-link');
  if (linkEl) {
    linkEl.setAttribute('href', url);
    linkEl.firstElementChild.textContent = title;
  }

  const descriptionEl = contentEl.querySelector('.subtitle-object');
  if (contentType > ContentType.Article && descriptionEl) {
    // do not show description for video + article
    const maxDescLen = ContentConfig.DescriptionLength;
    if (description?.length > maxDescLen) {
      description = description.substring(0, maxDescLen) + '...';
    }
    descriptionEl.textContent = description || '';
    // NOTE: changing ellipsis after the fact slows things abysmally!
    // setTimeout(() => {
    // hackfix: sometimes adding ellipsis does not work on an element if it is not added to DOM yet
    // addEllipsisEl(descriptionEl);

    // }, 50);
  }

  if (!createdAt) {
    // some objects don't have `createdAt` set?
    console.warn('contentObject has no `createdAt`', contentId, contentObject);
  }

  // author
  const $authorUrlEl = $contentEl.find('.author-video-url');
  $authorUrlEl.attr('target', '_blank');
  renderEditableVideoButton(contentId, contentObject, 'author_about_video_url', $authorUrlEl);

  const authorUrl = author_url || networkUrl;
  authorUrl &&
    contentEl.querySelector('.author-web-url').setAttribute('href', authorUrl);
  contentEl.querySelector('.author-web-url').setAttribute('target', '_blank');

  const authorCategoryEl = contentEl.querySelector('.author-category');

  // [i18n]
  switch (contentType) {
    case ContentType.Series:
      // series display "Network" (NOTE: we are using the same template as films here, thus the need for a dynamic change)
      authorCategoryEl.textContent = '(Network)';
      break;
    case ContentType.Book:
      authorCategoryEl.textContent = '(Author)';
      break;
  }

  contentEl.querySelector('.title-object').setAttribute('data-age-hours', ((new Date() - createdAt) / 1000 / 60 / 60).toFixed(2));
  const authorNameEl = contentEl.querySelector('.institution-title');
  authorNameEl.textContent = contentObject.networkName || author || contentObject.site || contentObject.domain;
  //individualObject.querySelector('.email-submitted').textContent = content.email;

  // price
  const $price = $contentEl.find('.title-price');
  if (contentObject.price) {
    $price.text(`(${contentObject.currency || ''}${contentObject.price})`);
  }
  else {
    $price.text('');
  }

  // trailer
  const $trailerEl = $contentEl.find('.trailer');
  switch (contentType) {
    case ContentType.Video:
      // no trailer -> just the video
      setPopupHref($trailerEl, url);
      break;
    default:
      renderEditableVideoButton(contentId, contentObject, 'trailerUrl', $trailerEl);
      break;
  }


  // add magnific popup where necessary
  waitForMagnificPopup().then(() => {
    $contentEl.find('.content-popup').magnificPopup({ type: 'iframe' });
  });

  setTimeout(() => {
    // TODO: get rid of the `setTimeout` hack
    // this timeout is a hack; necessary because some functions down the line
    //      need the $contentEl to be fully registered and in the DOM tree to work correctly.

    if (!getContentById(contentId)) {
      // sometimes, the element gets removed right away, so don't even try this in that case
      return;
    }

    // decorateContentECM(contentId, contentObject, $contentEl);

    maybeRenderContentTags(contentId, $contentEl);

    // add moderator powers to manage, update and delete videos
    renderModeratorTools(contentId);
  });
  return contentEl;
}