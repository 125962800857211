import FirestoreContainer from '../../firebase/FirestoreContainer';
import { authState } from 'src/auth';

class UserProfessionsContainer extends FirestoreContainer {
  constructor() {
    super('userProfessions');
    this.enableCache();
  }

  saveMyProfessions(reelId, professionData) {
    return this.setDoc(reelId, professionData);
  }
}

const userProfessionsContainer = new UserProfessionsContainer();
export default userProfessionsContainer;