import FirestoreContainer from '../../firebase/FirestoreContainer';
import db from '../../db';
import firebase from 'firebase/app';
import { authState } from '../../auth';


class UserStats extends FirestoreContainer {
  constructor() {
    super('userStats');
  }

  addStat(collectionName, statName, addCb, computeIncrement = null) {
    if (!authState.uid) {
      throw new Error(`Could not write to collection ${collectionName}. Login first.`);
    }

    return db.runTransaction(async t => {
      const incValue = computeIncrement ? (await computeIncrement(t) || 0) : 1;

      await addCb(t);

      if (incValue) {
        // add stat
        const statsDoc = this.doc(authState.uid);
        t.set(statsDoc, { [statName]: firebase.firestore.FieldValue.increment(incValue) }, { merge: true });
      }
    });
  }

  addStatDefault(collectionName, statName, data, computeIncrement = null) {
    function addCb(t) {
      // batched adding of docs
      // see: https://stackoverflow.com/questions/55674071/firebase-firestore-addding-new-document-inside-a-transaction-transaction-add-i
      const doc = db.collection(collectionName).doc();
      t.set(doc, data);
    }
    return this.addStat(collectionName, statName, addCb, computeIncrement);
  }

  deleteStat(collectionName, statName, deleteCb, computeIncrement = null) {
    if (!authState.uid) {
      throw new Error(`Could not write to collection ${collectionName}. Login first.`);
    }

    return db.runTransaction(async t => {
      const incValue = computeIncrement ? (await computeIncrement(t) || 0) : -1;

      await deleteCb(t);

      if (incValue) {
        // add stat
        const statsDoc = this.doc(authState.uid);
        t.set(statsDoc, { [statName]: firebase.firestore.FieldValue.increment(incValue) }, { merge: true });
      }
    });
  }

  deleteStatDefault(collectionName, statName, docId, computeIncrement) {
    function deleteCb(t) {
      t.delete(db.collection(collectionName).doc(docId));
    }
    return this.deleteStat(collectionName, statName, deleteCb, computeIncrement);
  }
}

const userStats = new UserStats();
export default userStats;