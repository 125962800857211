

export const DevClass = 'dev-actions';
export const DevHidden = 'dev-hidden';

export function getOrCreateToolCont($parent, customClasses, subSelector) {
  let $toolCont = $parent.find(`.${DevClass}`);
  const existedBefore = !!$toolCont.length;
  if (!existedBefore) {
    $toolCont = $(`<div class="${customClasses} debug-feature ${DevClass}"
  style="display: flex;flex-wrap: wrap;"></div>`);

    const $newParent = subSelector ? $parent.find(subSelector) : $parent;
    $newParent.append($toolCont);
  }

  return [existedBefore, $toolCont];
}


/**
 * We can use this function to observe any changes on any element.
 * Great for debugging.
 * 
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver#Example
 */
export function observeEl(el) {
  // select first element if it is array or jquery (TODO: apply to all instead?)
  el = el.length ? el[0] : el;

  // Options for the observer (which mutations to observe)
  const config = { attributes: true, childList: true, subtree: true };

  // Callback function to execute when mutations are observed
  const callback = function (mutationsList, observer) {
    for (let mutation of mutationsList) {
      if (mutation.type === 'childList') {
        console.debug('[DEBUG OBSERVER]', 'A child node has been added or removed.');
      }
      else if (mutation.type === 'attributes') {
        console.debug('[DEBUG OBSERVER]', 
          'Attribute modified:', 
          `${mutation.attributeName} => "${el.getAttribute(mutation.attributeName)}"`, 
          el,
          mutation
        );
      }
    }
  };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  observer.observe(el, config);

  // return disconnect function
  return observer.disconnect.bind(observer);
}