import firebase from 'firebase/app';
import db from 'src/db';
import { getContentDoc, getContentById } from '../content/contentObjects';
import tagReviews from './tagReviews';
import { TagActionType, TagMaxVotes } from './TagConfig';
import { getOrQueryWikiEntry } from '../third-party/wikiApi';
import { rememberAddedTag } from 'src/api/tags/tags';


// export function addTag(videoDoc, tagName) {
//   return setTag(videoDoc, tagName, true);
// }

// /**
//  * Toggle tag on request by user.
//  * Ask user to confirm when removing.
//  */
// export function toggleTagUser(contentId, tagName) {
//   const has = hasTag(contentId, tagName);
//   // if (has) {
//   //   // confirm with user
//   //   if (!confirm(`Are you sure you want to remove this tag? - '${tagName}'`)) {
//   //     return;
//   //   }
//   // }
//   return setTag(contentId, tagName, !has);
// }

// export async function setTag(contentId, tagName, enabled) {
// }


export async function setTagTransaction(transaction, contentId, tagName, enabled) {
  const ref = getContentDoc(contentId);
  return await transaction.update(ref, {
    tags: enabled ?
      firebase.firestore.FieldValue.arrayUnion(tagName) :
      firebase.firestore.FieldValue.arrayRemove(tagName)
  });
}

const tagActionCallbacks = {
  /**
   * Add new tag
   */
  Yea: async (transaction, contentId, tag) => {
    const allTags = [tag];
    const entry = await getOrQueryWikiEntry(tag);
    console.debug('adding tag', entry);

    if (entry && entry.redirectTo) {
      allTags.push(entry.redirectTo);
    }

    // remember tags (for highlighting purposes)
    allTags.forEach(tag => rememberAddedTag(contentId, tag));

    // store tag in contentObject
    return Promise.all(allTags.map(tag => setTagTransaction(transaction, contentId, tag, true)));
  },

  /**
   * Remove tag
   */
  Nay: (transaction, contentId, tag) => {
    const ref = tagReviews.doc(contentId);
    return Promise.all([
      setTagTransaction(transaction, contentId, tag, false), // remove tag from contentObject
      transaction.set(ref, {   // delete tag review (so it won't show up as proposed anymore)
        [tag]: firebase.firestore.FieldValue.delete()
      }, { merge: true })
    ]);
  }
};


export async function toggleVoteTag(uid, contentId, tag, tagAction, isPrivileged) {
  if (!uid) {
    throw new Error('no user logged in');
  }

  tagAction = TagActionType.valueFromForce(tagAction);
  const tagActionName = TagActionType.nameFromForce(tagAction);
  const maxVotes = TagMaxVotes[tagActionName];

  // optimistic UI: because the transaction is slow, let's show something to the user right away
  // tagReviews.overrideTagVote(contentId, tag, tagAction, uid);

  return db.runTransaction(async transaction => {
    // read all data (NOTE: must finish reading all data before writing any)
    const oldData = (await transaction.get(tagReviews.doc(contentId))).data();
    const oldTagData = oldData && oldData[tag];

    // let castedVote, voteCount;
    if (isPrivileged) {
      // TODO: this is buggy in case we want to remove
      // privileged voter -> apply voting action right away
      // voteCount = oldTagData?.[tagAction]?.voteCount || 0;
      const hasVoted = oldTagData?.[tagAction]?.votes?.includes(uid) || false;
      const isRevoke = hasVoted;
      const isAddAction = tagAction === TagActionType.Yea;
      const addingTag = isAddAction !== isRevoke;
      const cb = addingTag ? tagActionCallbacks.Yea : tagActionCallbacks.Nay;
      await cb(transaction, contentId, tag);

      if (!addingTag) {
        // removing -> don't add the vote (so it won't show up as "proposed" anymore)
        return;
      }
    }

    // cast the vote (even if already approved)
    const [castedVote, voteCount] = await tagReviews.toggleVoteTransaction(transaction, oldTagData, contentId, tag, tagAction, uid);

    if (!isPrivileged) {
      // only need to do this if not privileged
      if (castedVote) {
        // casted vote
        if (voteCount >= maxVotes) {
          // vote has reached critical amount -> apply voting action
          const cb = tagActionCallbacks[tagActionName];
          return cb(transaction, contentId, tag);
        }
      }
      else {
        // revoked vote
        if (tagAction === TagActionType.Yea && voteCount === 0) {
          // revoked the last positive vote! -> same as a vote for "Nay" succeeding
          const cb = tagActionCallbacks.Nay;
          return cb(transaction, contentId, tag);
        }
      }
    }
  });
}