import NanoEvents from 'src/util/NanoEvents';

const popupEmitter = new NanoEvents();

export function notifyPopupOpen(...args) {
  popupEmitter.emit('open', ...args);
}

export function notifyPopupClosed(...args) {
  popupEmitter.emit('closed', ...args);
}

export function onPopupOpen(cb) {
  return popupEmitter.on('open', cb);
}

export function onPopupClosed(cb) {
  return popupEmitter.on('closed', cb);
}