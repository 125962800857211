import intersection from 'lodash/intersection';
import findKey from 'lodash/findKey';
import { getCurrentPageArg } from '../../../pages';
import { ECMContainer, ecmContainer as ourEcmContainer } from '../../../api/ecm';
import { initRenderECMTabs, renderECMExplainer, initRenderECM } from '../../ecm';
import { renderTagList } from '../../tags/tags';
import { TagRenderContext } from '../../../api/tags/TagConfig';
import { perfLog } from '../../../util/perf';
import { fixPageRelativeResources } from '../../../renderUtil/pageUtil';
import { getStorageImageUrl, getReelFileOfSizePath } from '../../../api/storage/index';
import { authState, onUIDChanged } from 'src/auth';
import { decorateClasses, prepareTemplateElement, cloneTemplateElement } from 'src/util/domUtil';
import reelHubSettingsContainer from 'src/api/reel/reelHubSettingsContainer';
import userProfessionsContainer from 'src/api/users/reelProfessions';
import EmptyObject from 'src/util/EmptyObject';
import ReelPageController from 'src/render/pages/ReelPageController';
import ReelImageUploader from 'src/render/pages/ReelImageUploader';
import isFunction from 'lodash/isFunction';
import { preloadImage } from 'src/renderUtil/imageUtil';
import { renderObjectToHTML } from 'src/util/formUtil';
import reelInfoContainer, { getReelDisplayName } from 'src/api/reel/reelInfo';
import { NotLoaded } from 'src/db';
import { initRenderReelColumns } from './ReelColumnMenu';
import { getPageReelId } from './reelPageUtils';
import { isYoutubeVideoUrl } from 'src/api/content/contentUrlAnalyzer';
import ReelManager from 'src/api/reel/ReelManager';
import { isOwnBrandReelId, isPersonalReelId, isThisAPersonalReel, isReelOwner } from 'src/api/reel/reelUtil';
import { getSelectedReelId } from 'src/api/reel/reelSelection';
import { applyPageReelImages } from 'src/api/reel/reelImages';
import { showNotLoggedInUserActionWarning } from 'src/renderUtil/userActions';
import ContentType from 'src/api/content/ContentType';
import { initRenderContentCollectionModal, showContentCollectionModal } from 'src/render/collections/ContentCollectionModal';

let reelManager;
let $reelDetailsCont;


// ###########################################################################
// init
// ###########################################################################

async function _doInit() {
  const reelId = getCurrentPageArg();
  reelManager = new ReelManager(reelId);

  reelInfoContainer.onDoc(reelId, () => renderReelBasicInfo(reelId));

  try {
    // try access public profile
    // await reelManager.tryAccessReel();
  }
  catch (err) {
    console.error('could not load profile information:', err.message);
    alert('this is not a public reel');
    location.replace('/');
    return;
  }

  // init columns: will enqueue in renderQueue
  initRenderReelColumns();

  // init upload controls
  onUIDChanged(renderUploadButtons, true);

  // console.debug('initReelPage', 0);

  await Promise.all([
    applyPageReelImages(reelId),
    initHubInfo(reelId),
    initProfessions(reelId)
  ]);

  await initECM(reelId);

  // console.debug('initReelPage', 1);
}

export function initReelPage() {
  $reelDetailsCont = $('.reel-details-ctn');

  const controller = new ReelPageController();
  controller.initPromise = _doInit();
  return controller;
}



// ###########################################################################
// User details panel (bottom left)
// ###########################################################################

let commonTags;
/**
 * Called initially and every time our ECM or their ECM changes.
 */
async function renderECMSummary() {
  const ourTags = ourEcmContainer.getAllTags();
  const { reelId } = reelManager;
  if (reelId !== getSelectedReelId()) {
    const theirTags = reelManager.ecmContainer.getAllTags();
    commonTags = intersection(ourTags, theirTags);
  }
  else {
    commonTags = ourTags;
  }

  const countsByFlags = ourEcmContainer.getMatchingTagCountsByECMFlags(commonTags);
  renderECMExplainer($reelDetailsCont, commonTags.length, countsByFlags);

  if (ecmAlignmentTabsRenderer) {
    renderCommonTags();
  }

  // perfLog('reel ECM summary 1');
}

function renderReelBasicInfo(reelId) {
  // perfLog('reel.renderUserInfo');
  const reelInfo = reelInfoContainer.getDocById(reelId);

  const $addBtn = $('.reel-details-ctn .add-collection-btn');
  $addBtn.off('click');

  if (reelInfo !== NotLoaded) {
    if (!reelInfo && isOwnBrandReelId(reelId)) {
      // this is your personal brand reel, but it has not been created yet
      // alert('This is your own brand reel! ');
      return;
    }

    let displayName = getReelDisplayName(reelInfo);
    document.title = `Reel of ${displayName} - Discovereel`;

    displayName && $('.title-world-or-reel').text(displayName);
    displayName && $('.reel-name').text(displayName);

    const firstName = reelInfo?.firstName;
    firstName && $('.reel-first-name').text(firstName);

    const placeName = reelInfo?.place?.placeName;
    placeName && $('.city-name').text(placeName);

    // hackfix: webflow leftovers

    $addBtn.on('click', async evt => {
      // TODO: add/remove this reelId to/from collection
      if (showNotLoggedInUserActionWarning()) {
        return;
      }

      let contentType;
      if (isPersonalReelId(reelId)) {
        contentType = ContentType.Human;
      }
      else {
        return;
      }

      // NOTE: there is only one popup/set of collections for all contentTypes
      const content = reelInfo;

      if (!reelInfo.type) {
        // hackfix!
        await reelInfoContainer.setDoc(reelId, { type: contentType }, true);
        console.assert(reelInfo.type);
      }

      initRenderContentCollectionModal(ContentType.Human, content);
      showContentCollectionModal();
    });
  }
}

// ########################################
// userHubSettings
// ########################################

function initHubInfo(reelId) {
  reelHubSettingsContainer.onDoc(reelId, (_, hubInfo) => {
    // show social media + contact buttons
    const $hubLinks = $('.reel-details-ctn .author-icons a');
    $hubLinks.each(function (i) {
      const name = this.getAttribute('data-name');
      const url = hubInfo?.[name] || '';
      const $el = $(this);
      decorateClasses($el, {
        hidden: !url
      });

      if (url) {
        if (name === 'email') {
          this.setAttribute('href', `mailto:${url}`);
        }
        else {
          this.setAttribute('href', url);
        }
      }
    });
  });

  return reelHubSettingsContainer.waitForDoc(reelId);
}





// ###########################################################################
// reel image uploads
// ###########################################################################

let backgroundUploader, profileUploader;

function renderUploadButtons() {
  const { uid } = authState;
  const reelId = getPageReelId();
  if (!uid || !isReelOwner(reelId, uid)) {
    // TODO: [brand-reel]
    // TODO: check priv level from reelCollectionContributorContainer
    // no upload functionality available
    return;
  }


  // background
  const backgroundUploadBtnSelector = '.change-bg-image-btn';
  const backgroundModalSelector = '.background-upload-modal';
  backgroundUploader = new ReelImageUploader(
    reelId,
    'background',
    backgroundUploadBtnSelector,
    backgroundModalSelector,
    handleUploadCompleted
  );


  // profile
  const profileUploadBtnSelector = '.change-profile-image-btn';
  const profileModalSelector = '.profile-upload-modal';
  profileUploader = new ReelImageUploader(
    reelId,
    'profile',
    profileUploadBtnSelector,
    profileModalSelector,
    handleUploadCompleted
  );

  // new SingleFirebaseUploadButton($profileImageUploadBtn, 
  //   profileStoragePath, dropzoneSelector, handleUploadCompleted);
}

function handleUploadCompleted(fileName) {
  // update image
  applyPageReelImages(getPageReelId(),
    '&bustcache=' + Math.random(),
    (attr, resourceName, el) => !fileName || resourceName.includes(fileName) //filePath.endsWith(src)
  );

  // hide dropzone after upload finished
  $('.update-image-popup').hide();
}



// ###########################################################################
// ECM
// ###########################################################################

async function initECM() {
  // await waitUntilAuthStateInitialized();

  const ourStartPromise = onUIDChanged(ourUid => {
    if (ourUid) {
      // start ECM sync, if not already started
      ourEcmContainer.startECMSync(getSelectedReelId());
    }

    reelManager.initECM(() => renderECMSummary());
  }, true, true);

  ourEcmContainer.onUpdate(() => renderECMSummary());

  // init alignment ECM tabs (NOTE: does not actually render anything)
  initRenderAlignmentECMTabs();

  // wait for ECM container initialization
  await ourStartPromise;

  // wait until ECM data is all there
  // NOTE: this won't actually wait, if we read from cache
  return Promise.all([
    ourEcmContainer.waitForCurrentPage(),
    reelManager.ecmContainer.waitForCurrentPage()
  ]);
}

// ########################################
// render ECM tabs
// ########################################

let ecmAlignmentTabsRenderer;

function initRenderAlignmentECMTabs() {
  const $ecmExplainerBtn = $reelDetailsCont.find('.ecm-explainer-button');

  $ecmExplainerBtn.on('click', () => {
    ecmAlignmentTabsRenderer = initRenderECMTabs('.ecm-reel', reelManager.ecmContainer);

    renderCommonTags();
  });
}

function renderCommonTags() {
  if (!commonTags) {
    // will be called once commonTags are ready
    return;
  }

  // render common tags
  const $alignmentTab = ecmAlignmentTabsRenderer.$ecmDiv.find('.ecm-alignment-tab-panel');
  const $commonTagList = $alignmentTab.find('.container-tags');
  if (!$alignmentTab.length) {
    // this page has no alignment tab
    console.error('alignment tab not found');
    return;
  }
  renderTagList(commonTags, $commonTagList, TagRenderContext.ECM);
}




// ###########################################################################
// professions
// ###########################################################################

let $badgeCont;
let selectedProfession;

function initProfessions(reelId) {
  // prepare badge cont + template
  $badgeCont = $reelDetailsCont.find('.professions-badges-ctn');
  prepareTemplateElement($badgeCont, '.profession-badge-ctn');

  // add data event listener
  userProfessionsContainer.onDoc(reelId, () => renderProfessions(reelId));
  reelInfoContainer.onUpdate(() => renderProfessions(reelId));

  return userProfessionsContainer.waitForDoc(reelId);
}

/**
 * Render transforms
 */
const professionRenderTransforms = {
  requirementVideo($el, url, entryName) {
    if (!url) {
      return;
    }

    decorateClasses($el, {
      video: isYoutubeVideoUrl(url)
    });

    $el.off('click');

    if (isYoutubeVideoUrl(url)) {
      $el.on('click', (evt) => {
        evt.preventDefault();
        $.magnificPopup.open({
          items: {
            src: url
          },
          type: 'iframe'
        });
      });
    }

    if (url && !url.startsWith('//') && !url.startsWith('http://') && !url.startsWith('https://')) {
      // make sure this is a correct absolute URL (magnific popup needs that)
      return 'https://' + url;
    }

    return url;
  }
};

let defaultCallToAction;

function renderProfessions(reelId) {
  const professions = userProfessionsContainer.getDocById(reelId) || EmptyObject;
  const professionNames = Object.keys(professions).filter(name =>
    // NOTE: brand reels don't have toggle data
    isThisAPersonalReel() ? professions[name]?.toggle : true
  );

  const badgeNameOverride = isThisAPersonalReel() ? null : reelInfoContainer.getDocById(reelId)?.lastName;

  if (!professionNames.length) {
    // add placeholder
    professionNames.push(badgeNameOverride || (isThisAPersonalReel() ? 'Profession' : 'Organization'));
  }

  // clear badges
  $badgeCont.empty();

  // render badges
  // const isOwn = authState.uid === uid;
  let activeProfessionName = findKey(professions, prof => prof.active);

  // active profession should show first
  if (activeProfessionName) {
    professionNames.sort((a, b) => (b === activeProfessionName) - 0.5);  // move up if active, else move down
  }

  for (const professionName of professionNames) {
    activeProfessionName = activeProfessionName || professionName; // if none active, select the first

    // render badge
    const $badge = cloneTemplateElement($badgeCont, '.profession-badge-ctn').eq(0);
    decorateClasses($badge, {
      active: professionName === (selectedProfession || activeProfessionName)
    });
    $badge.find('.profession-category').text(badgeNameOverride || professionName);

    $badge.on('click', async evt => {
      if (selectedProfession === professionName) {
        // already selected
        return;
      }

      // select profession
      selectedProfession = professionName;

      // re-render
      renderProfessions(reelId);
    });
    $badgeCont.append($badge);
  }

  // render details of active profession
  let professionData = professions[selectedProfession || activeProfessionName];
  if (professionData) {
    const $professionEl = $reelDetailsCont.find('.reel-description-ctn');

    // take default callToAction from HTML
    if (!professionData.callToAction) {
      // NOTE: cannot use `lodash/defaults` here because `callToAction` can actually be an empty string and `defaults` will only override `undefined` values
      if (!defaultCallToAction) {
        defaultCallToAction = $professionEl.find('[data-name="callToAction"]').text();
      }
      professionData = {
        ...professionData,
        callToAction: defaultCallToAction
      }
    }

    // render profession
    renderObjectToHTML($professionEl, professionData, professionRenderTransforms);
  }
}