
const tagsByNetwork = {
  amazon: [
    'Amazon Prime Video',
    'Prime Video',
  ],
  netflix: [
    'Netflix'
  ]
};

const streamingPlatformNames = Object.keys(tagsByNetwork);

export function isKnownStreamingPlatform(networkName) {
  return streamingPlatformNames.includes(networkName?.toLowerCase())
}

export function getTagsOfNetwork(networkName) {
  return tagsByNetwork[networkName.toLowerCase()] || null;
}