/**
 * ACL-style management of privileges.
 * Similar in concept to StackOverflow's: https://stackoverflow.com/help/privileges
 */


import db from 'src/db';
import NotLoaded from 'src/NotLoaded';
import { userContainer } from './users/users';

/* TODO: privileges

-> (Propose change -> Review + Votes) vs. (Immediate changes)

Start w/:
1. Publishing posts require review/approval
2. Unpublish
3. Arbitration for Unpublish (if no agreement, requires involvement by higher priv contributor)


- Submit content
- Publish content

- Tag content
- Untag content

- Unpublish content
- Delete content

flavors of privileges: instant, with voting, "can be rebuttaled by user"

privileges...
-> by contentType
-> by tag (maybe later?)
*/

/**
 * These privileges require each user to individually have that privilege activated.
 */
const aclPrivileges = [
  {
    name: 'ecmSorting',
    level: 'ecmSorting',
    description: 'User can use the "sort by ECM" button at the top of each content column'
  },
  {
    name: 'premium-member',
    level: 'premium-member',
    description: 'User can use the "sort by ECM" button at the top of each content column'
  }
];

/**
 * These privileges require points
 */
const pointBasedPrivileges = [
  {
    name: "voteTag",
    description: "User can vote on adding/removing tags",
    minPoints: 0
  },
  {
    name: "submitContent",
    description: "User can submit new content",
    minPoints: 0
  },
  // {
  //   name: "voteAddTag",
  //   description: "User can vote to approve a tag on a content item",
  //   minPoints: 15
  // },
  // {
  //   name: "voteRemoveTag",
  //   description: "User can vote to approve a tag on a content item",
  //   minPoints: 40
  // },
  {
    name: "voteNewContent",
    description: "User can vote to publish or not publish new content",
    minPoints: 50
  },
  {
    name: "voteFeature",
    description: "User can vote to feature content on the homepage.",
    minPoints: 100
  },
  {
    name: "voteDelete",
    description: "User can vote to feature content on the homepage.",
    minPoints: 500
  },
  {
    name: "publishContent",
    description: "User can publish or delete new content right away, no need to vote anymore.",
    minPoints: 500
  },
  {
    name: "featureContent",
    minPoints: 750
  },
  {
    name: "unpublishContent",
    minPoints: 1000
  },
  {
    name: "updateContent",
    minPoints: 1500,
    description: "User can modify existing content."
  },
  {
    // NOTE: everyone can apply tags for now
    name: 'instantTagAction',
    // minPoints: 10000,
    minPoints: 0,
    description: 'User\'s vote is always deciding vote. Casting a vote for any content action will immediately apply it.',
  }
];

const privilegeLevelsByName = {};
pointBasedPrivileges.forEach(priv => {
  const { name, minPoints } = priv;
  if (!name || !(minPoints >= 0) ) {
    throw new Error('invalid privilegeLevel definition: ' + JSON.stringify(priv));
  }

  if (privilegeLevelsByName[name]) {
    throw new Error('[INTERNAL ERROR] multiple privileges with same name: ' + name);
  }
  privilegeLevelsByName[name] = priv;
});
aclPrivileges.forEach(priv => {
  const {name, level} = priv;
  if (!name || !level) {
    throw new Error('invalid privilegeLevel definition: ' + JSON.stringify(priv));
  }

  if (privilegeLevelsByName[name]) {
    throw new Error('[INTERNAL ERROR] multiple privileges with same name: ' + name);
  }
  privilegeLevelsByName[name] = priv;
});


export function getPrivilege(name) {
  const priv = privilegeLevelsByName[name];
  if (!priv) {
    throw new Error('invalid privilege name: ' + name);
  }
  return priv;
}


/* Get user pricilege status immediately */
export function doesUserHavePriv(uid, privName) {
  if (!uid) {
    return false;
  }
  
  const user = userContainer.getUserNow(uid);
  if (user === NotLoaded) {
    // not loaded yet
    return NotLoaded;
  }
  
  const priv = getPrivilege(privName);
  
  // moderators can do anything
  if (userContainer.isUserModerator(uid)) {
    return true;
  }

  // check for points
  if (!user) {
    return false;
  }

  // check points + special priv
  if (priv.level) {
    // user needs special permission
    return user.privileges && user.privileges.includes(priv.level);
  }
  else {
    // user needs sufficient points
    const { points = 0 } = user;
    return points >= priv.minPoints;
  }
}

export async function doesUserHavePrivAsync(uid, privName) {
  uid && await userContainer.getUserAsync(uid); // make sure, the user is loaded
  
  return doesUserHavePriv(uid, privName);
}