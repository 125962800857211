

export function traceCall(ownerName, name, args) {
  console.warn(`[TRACE] ${ownerName}.${name}(${args})`);
}

function instrument(ownerName, name, cb) {
  function instrumentedTraceWrapper(...args) {
    traceCall(ownerName, name, args);
    return cb.call(this, ...args);
  }
  return instrumentedTraceWrapper;
}

export function logInstrumentAllMethodCalls(ownerName, obj) {
  const allMethods = [
    ...Object.keys(obj),
    ...Object.getOwnPropertyNames(obj.constructor?.prototype || {}).filter(key => key !== 'constructor')
  ].filter(key => obj[key] instanceof Function);
  for (var name of allMethods) {
    obj[name] = instrument(ownerName, name, obj[name]);
  }
}