import FirestoreContainer from '../../firebase/FirestoreContainer';
import firebase from 'firebase/app';
import db from 'src/db';
import { authState } from 'src/auth';
import { getLocalStorageItem, setLocalStorageItem } from '../../util/localStorage';
import { isStandaloneMode } from '../../web-apis/pwa';
import { isMobile, isLocalhost } from '../../util/responsive';

/**
 * Manage user's FCM tokens + instance ids.
 * 
 * TODO: We don't really know a lot about the token lifecycle - https://github.com/firebase/quickstart-js/issues/101
 * @see https://stackoverflow.com/questions/45139505/the-scope-of-firebase-instance-id-and-token
 */
class TokenContainer extends FirestoreContainer {
  constructor() {
    super('tokens');
  }

  async getTokensByUid(uid) {
    return await this.queryDoc(uid);
  }

  async getDocForToken(token) {
    const snap = await this.collection.where(token + '._', '==', 1).get();
    return snap.docs && snap.docs[0] || null;
  }

  /**
   * Store token in DB
   */
  async maybeUpdateToken(newToken, force) {
    let oldToken = getLocalStorageItem('fcm-token');
    const uid = authState.uid;
    if (!uid) {
      // not logged in
      // console.error('Tried to store messaging token before authentication completed', newToken);
      return false;
    }
    const ref = this.doc(uid);

    if (!force) {
      if (newToken === oldToken) {
        // check if token is already in DB; if not, send it (again?)
        // const doc = await this.getDocForToken(newToken);
        const tokens = await this.getTokensByUid(uid);
        if (tokens && tokens[oldToken]) {
          // already in DB
          return true;
        }
        oldToken = null;  // we want to write oldToken again
      }
    }

    const batch = db.batch();
    const upd = {};

    // remove old token (if we had one)
    if (oldToken) {
      upd[oldToken] = firebase.firestore.FieldValue.delete();
    }

    // add new token (if there is one)
    if (newToken) {
      upd[newToken] = {
        _: 1, // set this for query convenience
        updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        pwa: isStandaloneMode(),
        mobile: isMobile(),
        // ua: window.navigator.userAgent
      };

      if (isLocalhost()) {
        // attach this for development purposes
        upd[newToken].local = true;
      }
    }

    batch.set(ref, upd, {merge: true});

    try {
      await batch.commit();
    }
    catch (err) {
      console.error('Failed to write token to', ref, ' - ', err);
      return;
    }

    // store in localStorage (if write was successful)
    setLocalStorageItem('fcm-token', newToken);

    console.debug('Wrote FCM token:', newToken, !!oldToken);
    return true;
  }
}

const tokenContainer = new TokenContainer();
export default tokenContainer;