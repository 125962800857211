import Cropper from 'cropperjs';
import {
  canvas2File
} from 'src/renderUtil/imageUtil';


export default class ImageCropper {
  constructor($cropperList, $cropperTemplate, aspectRatio = 1) {
    this.$cropperList = $cropperList;
    this.$cropperTemplate = $cropperTemplate;
    this.aspectRatio = aspectRatio;
  }

  _createCropperEl() {
    const $cropper = this.$cropperTemplate.clone(true);
    $cropper.removeClass('hidden');
    this.$cropperList.append($cropper);
    return $cropper;
  }

  async createImage(file) {
    return new Promise(r => {
      var imageEl = new Image();
      imageEl.src = URL.createObjectURL(file);
      imageEl.onload = () => {
        r(imageEl);
      };
    });
  }

  async start(file, title) {
    const {
      aspectRatio
    } = this;

    const $cropper = this._createCropperEl();

    // set title (if there is any)
    if (title) {
      const $title = $cropper.find('.title');
      $title.text(title);
    }

    // create image element
    const imageEl = await this.createImage(file);
    // $cropper.css({
    //   width: `${imageEl.width}px`,
    //   height: `${imageEl.height}px`
    // });

    // create new imageEl and append to $cont
    $cropper.find('.crop-body').append(imageEl);

    // create Cropper.js wrapper
    // see: https://github.com/fengyuanchen/cropperjs/blob/master/README.md#options
    const cropper = new Cropper(imageEl, {
      aspectRatio,
      autoCropArea: 1,
      viewMode: 1 // don't allow crop box to leave image
    });

    // remember relevant state
    this.cropper = cropper;
    this.$imageEl = $(imageEl);
  }

  getCroppedStats() {
    const data = this.cropper.getData();
    return data;
  }

  getHeight(width, height = 0) {
    return height || (width / this.aspectRatio);
  }

  async getImageFile(width, height = 0, imageOptions) {
    // compute height, if not given
    height = this.getHeight(width, height);

    // get canvas with image data from Cropper.js
    var canvas = this.cropper.getCroppedCanvas({
      width,
      height
    });

    // Turn the canvas into a Blob (file object without a name)
    return await canvas2File(canvas, imageOptions);
  }

  cleanUp() {
    if (this.cropper) {
      this.cropper.destroy();
      this.$imageEl.remove();
      this.cropper = null;
      this.$imageEl = null;
    }
  }

  // /**
  //  * getImage -> then clean up element
  //  */
  // async finishAndGetFile(width, height = 0, imageOptions) {
  //   const file = this.getImageFile(width, height, imageOptions);
  //   this.cleanUp();
  //   return file;
  // }
}