import ParallelTaskQueue from 'src/util/ParallelTaskQueue';
import { sleep } from 'src/util/sleep';
import { perfLog } from 'src/util/perf';
import SerialTaskQueue from 'src/util/SerialTaskQueue';
import isFunction from 'lodash/isFunction';

/**
 * Foreground tasks run in parallel.
 * Background tasks run sequentially.
 */
class TaskScheduler {
  _preQueue;

  /**
   * Parallel foreground queue.
   * 
   * @type {ParallelTaskQueue}
   */
  _foregroundQueue;

  /**
   * Serial background queue.
   * @type {SerialTaskQueue}
   */
  _backgroundQueue;

  constructor() {
    this._preQueue = new ParallelTaskQueue([sleep(20)]);
    this._foregroundQueue = new ParallelTaskQueue([sleep(20)]); // start foreground racer
    this._backgroundQueue = new SerialTaskQueue();
  }

  async start() {
    // await this._preQueue.drain();
    await this._foregroundQueue.drain();

    perfLog('[Task Scheduler]', 'Foreground work done');

    this._backgroundQueue;
    await this._backgroundQueue.waitUntilFinished();

    perfLog('[Task Scheduler]', 'Background work done');

    this._allDone();
  }

  addPreTask(promiseOrCb) {
    return this._preQueue.addTask(promiseOrCb);
  }

  addTasks(...promiseOrCbs) {
    promiseOrCbs.forEach(promiseOrCb => this.addTask(promiseOrCb));
  }

  addTask(promiseOrCb) {
    if (!this._foregroundQueue.hasFinished) {
      return this._foregroundQueue.addTask(promiseOrCb);
    }
    else {
      const promise = this._foregroundQueue._taskToPromise(promiseOrCb);
      return promise;
    }
  }

  addBackgroundTasks(...cbs) {
    this._backgroundQueue.enqueue(...cbs);
  }

  addBackgroundTask(cb) {
    this.addBackgroundTasks(cb);
  }

  _allDone() {
    perfLog('[Task Scheduler]', 'All done');
  }
}

const startQueue = new TaskScheduler();
export default startQueue;