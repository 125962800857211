
export const UserRole = {
  Guest: 0,
  User: 1,
  Moderator: 2
};

export function roleFrom(roleOrName) {
  let role;
  if (UserRole[roleOrName]) {
    role = UserRole[roleOrName];
  }
  else {
    role = roleOrName || 0;
  }
  
  return role;
}