import queryCourse from 'src/api/third-party/queryCourse.js';
import ContentType from 'src/api/content/ContentType';

import UrlInputForm from 'src/render/content/UrlInputForm';

export default class CourseInputForm extends UrlInputForm {
  sendQuery = queryCourse;
  contentType = ContentType.Course;

  async processCurrentUrl(silent = false) {
    const originalUrl = this.$inputText.val();

    const url = await this.normalizeUrl(originalUrl);

    return {
      contentType: this.contentType,
      url
    }
  }

  // validateContentType(contentType, silent) {
  //   // allow any url for now
  //   return true;
  // }

  async handleInputChange() {
    await super.handleInputChange();

    // if (!this.content) {
    // }
  }
}
