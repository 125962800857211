import { authState } from 'src/auth';
import { ModerationStatus, doesCollectionNameRequireApproval } from 'src/api/content/ContentActionConfig';
import ContentQueryInterface from 'src/api/content/ContentQueryInterface';
import { getContentCollectionQueryInterface } from 'src/api/contentCollections/contentCollections';
import EmptyArray from 'src/util/EmptyArray';


// ###########################################################################
// Collection query interface
// ###########################################################################

export default class CollectionFilterQueryInterface extends ContentQueryInterface {
  init(collectionName, reelId) {
    this.collectionName = collectionName;
    this.reelId = reelId;
    this.contentType = this.contentObjects.contentType;
  }

  getCollectionEntryCont() {
    const { reelId, collectionName, contentType, _queryArgs: queryArgs } = this;
    return getContentCollectionQueryInterface().getCollectionEntryCont({ reelId, collectionName }, contentType, queryArgs);
  }

  getLoadedPageCount() {
    return this.getCollectionEntryCont().getLoadedPageCount();
  }

  hasLoadedPage(iPage) {
    return this.getCollectionEntryCont().hasLoadedPage(iPage);
  }

  hasReachedLastPage() {
    return this.getCollectionEntryCont()?.hasReachedLastPage() || false;
  }

  /**
   * NOTE: this is primarily (exclusively?) called by `startListenContent`.
   * NOTE: might not end up giving us as many entries as we'd like, because we are filtering after querying, then re-querying with a different limit (`LimitMin`).
   */
  async query(queryArgs) {
    // NOTE: we want to make sure that we get at least `LimitMin` items
    const LimitMin = 3;

    const entries = await this._queryFirstPage(queryArgs);
    const cont = this.getCollectionEntryCont();

    while (entries.length < LimitMin && !cont.hasReachedLastPage()) {
      const more = await this.loadNextPage({ limit: LimitMin });
      if (!more) {
        break;
      }
      entries.push(...more);
    }

    // console.debug(this.getDebugTag(), 'query', entries, this.hasReachedLastPage());


    // hackfix: loading screen won't disappear if page is empty
    //    possible reason: renderer might be missing an update because.... i don't know :(((
    // this._forceUpdate();

    return entries;
  }

  async _queryFirstPage(queryArgs) {
    const {
      reelId,
      contentObjects,
      collectionName,
      contentType
    } = this;

    this._queryArgs = queryArgs;

    // NOTE: `queryContentIds` creates new query container, if it does not exist yet
    // NOTE: `queryArgs` is constructed for use in the `contentObjects` (formerly `videos`) collection,
    //      meaining: applying it as-is to the `contentCollectionEntries` collection can easily go wrong.
    const contentIds = await getContentCollectionQueryInterface().queryContentIds(
      { reelId, collectionName }, contentType, queryArgs
    );
    // if (uid !== authState.uid) {
    //   query = allContentFilters.published(query);
    // }

    return this._loadMissingDocs(contentIds);
  }

  async loadNextPage(queryArgsOverride = null) {
    const cont = this.getCollectionEntryCont();

    // load more ids
    const batch = await cont.loadNextPage(queryArgsOverride);

    const contentIds = batch && Object.values(batch).map(entry => entry.contentId);
    if (!contentIds?.length) {
      // no more ids -> (probably) reached last page
      this._forceUpdate();
      return EmptyArray;
    }

    // load missing content objects
    return this._loadMissingDocs(contentIds);
    // return this.query(queryArgsOverride);
  }

  async _forceUpdate() {
    // IMPORTANT: this call doesn't do anything but trigger events
    await this.contentObjects._forceUpdate();
  }

  async _loadMissingDocs(contentIds) {
    // query all docs that haven't been queried before
    // console.debug(this.getDebugTag(), `before _loadMissingDocs`, contentIds, entries);
    let entries = await this.contentObjects.queryMissingDocs(contentIds);
    // console.debug(this.getDebugTag(), `_loadMissingDocs`, contentIds, entries);

    // handle invalid entries
    if (entries.some(e => !e)) {
      const deletedIds = contentIds.filter((id, i) => !entries[i]);
      console.error(this.getDebugTag(), `Found ${deletedIds.length} reference(s) to deleted contentIds ${deletedIds.join(', ')}`);
      entries = entries.filter(e => !!e);
    }

    // apply filter
    entries = this.filterArray(entries);

    return entries;
  }

  filterArray(objects) {
    const {
      reelId,
      collectionName
    } = this;

    if (!doesCollectionNameRequireApproval(collectionName)) {
      // no approval necessary
      return objects;
    }

    // this collection requires approval (only show "high quality content" that was approved)

    const isOwn = reelId === authState.uid;
    // const isOwn = false;

    // can only see objects if its your own list, or the object has been published
    return objects.filter(
      obj => isOwn ||
        obj.moderationStatus === ModerationStatus.ReviewedPublished
    );
  }

  getSortable(contentObject) {
    // const contentId = contentObject._id;
    // return this.getCollectionEntryCont().getCollectionEntryOfContentId(contentId) || null;
    return contentObject;
  }

  getDebugTag() {
    return `${this.contentObjects.getDebugTag()}[${this.collectionName}]`;
  }
}
