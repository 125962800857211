import FirestoreContainer from 'src/firebase/FirestoreContainer';
import { getRealTagName } from 'src/api/third-party/wikiApi';

/**
 * Stores metadata related to tags.
 */
class TagContainer extends FirestoreContainer {
  constructor() {
    super('tags');
  }

  async getTagData(tagName) {
    const realTagName = await getRealTagName(tagName);
    return this.queryDoc(realTagName);
  }

  async setTagData(tagName, newTagData) {
    const realTagName = await getRealTagName(tagName);
    return this.setDoc(realTagName, newTagData);
  }
}

const tagContainer = new TagContainer();
export default tagContainer;