import { authState } from '../../auth';
import firebase from 'firebase/app';

const knownEvents = {
  /**
   * new user signed up
   */
  signup: 1,
  /**
   * successfully logged in
   */
  login: 1,
  /**
   * authentication of user established (logged in, or came back)
   */
  'open-web': 1,
  'open-app': 1,
  'install-app': 1,

  'user-reel-update': 1
};

// WARNING: Do not make any calls before `initMixPanel` has been called.
/*eslint-disable */
export function initMixPanel() {
  (function(c,a){if(!a.__SV){var b=window;try{var d,m,j,k=b.location,f=k.hash;d=function(a,b){return(m=a.match(RegExp(b+"=([^&]*)")))?m[1]:null};f&&d(f,"state")&&(j=JSON.parse(decodeURIComponent(d(f,"state"))),"mpeditor"===j.action&&(b.sessionStorage.setItem("_mpcehash",f),history.replaceState(j.desiredHash||"",c.title,k.pathname+k.search)))}catch(n){}var l,h;window.mixpanel=a;a._i=[];a.init=function(b,d,g){function c(b, i) {
    var a = i.split("."); 2 == a.length && (b = b[a[0]], i = a[1]); b[i] = function () {
      b.push([i].concat(Array.prototype.slice.call(arguments,
        0)))
    }
  }var e=a;"undefined"!==typeof g?e=a[g]=[]:g="mixpanel";e.people=e.people||[];e.toString=function(b){var a="mixpanel";"mixpanel"!==g&&(a+="."+g);b||(a+=" (stub)");return a};e.people.toString=function(){return e.toString(1)+".people (stub)"};l="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
for(h=0;h<l.length; h++)c(e,l[h]);var f="set set_once union unset remove delete".split(" ");e.get_group=function(){function a(c) { b[c] = function () { call2_args = arguments; call2 = [c].concat(Array.prototype.slice.call(call2_args, 0)); e.push([d, call2]) } }for(var b={},d=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<f.length; c++)a(f[c]);return b};a._i.push([b,d,g])};a.__SV=1.2;b=c.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
    MIXPANEL_CUSTOM_LIB_URL:"file:"===c.location.protocol&&"//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)?"https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js";d=c.getElementsByTagName("script")[0];d.parentNode.insertBefore(b,d)}})(document,window.mixpanel||[]);
  mixpanel.init("052156a9ab0359c2cc5c1aa15f6b9663");
}
/*eslint-enable */

function makeSureEventExists(eventName) {
  if (!knownEvents[eventName]) {
    throw new Error('invalid mixpanel event name - make sure to add to `knownEvents`: ' + eventName);
  }
}

function _userEventData() {
  const uid = authState.uid;
  mixpanel.alias(uid);
  mixpanel.identify(uid);
  const data = {
    email: uid && firebase.auth().currentUser?.email || ''
  };
  return data;
}

export function trackEvent(eventName, data) {
  console.debug('trackEvent', eventName, data);
  makeSureEventExists(eventName);
  mixpanel.track(eventName, data);
}

export function trackOpenWeb() {
  trackEvent('open-web', _userEventData());
}

export function trackOpenApp() {
  trackEvent('open-app', _userEventData());
}

export function trackUserLogin(uid, data) {
  trackEvent('login', _userEventData());
}
/**
 * @see https://community.mixpanel.com/questions/585/how-to-implement-peopleset-in-js-api.html
 */
export function trackUserDataUpdate(uid, userData) {
  mixpanel.people.set(userData);
  mixpanel.identify(uid);
}

export function trackUserSignup() {
  const data = _userEventData();
  trackEvent('signup', data);
}

export function trackAppInstall() {
  trackEvent('install-app', _userEventData());
}

export function trackUserReelUpdate(data) {
  trackEvent('user-reel-update', data);
}