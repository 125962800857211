const attrSetters = {
  backgroundImage(el, src) {
    // el.style.backgroundImage = `url(${encodeURI(src)})`;
    el.style.backgroundImage = src && `url(${src})` || '';
  },
  src(el, src) {
    el.src = src;
  }
};

/**
 * Use script to replace resource URLs with a URL that is relative to the current path.
 */
export async function fixPageRelativeResources(settings, predicate) {
  const resources = document.querySelectorAll('[data-res="page"]');
  const promises = Array.from(resources).map(async el => {
    const attr = el.getAttribute('data-attr') || 'src';
    const resourceName = el.getAttribute('data-src');
    if (predicate && !predicate(attr, resourceName, el)) {
      return;
    }

    loadElImage($(el), attr, resourceName, settings);
  });

  return await Promise.all(promises);
}

export async function loadElImage($el, attrName, resourceName, { makeUrl, decorate }) {
  const el = $el[0];
  const attrSetter = attrSetters[attrName];
  if (!attrSetter) {
    console.error('invalid data-attr on custom resource object:', attrName);
    return;
  }
  // const realSrc = `/pages${location.pathname}/${src}`;
  // const realSrc = `${urlPrefix}/${src}`;
  const realSrc = await makeUrl?.(resourceName, $el);
  attrSetter(el, realSrc);
  await decorate?.($el, realSrc);
}