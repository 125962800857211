import Bowser from 'bowser';
let browserParser, browserInfo;

function initBrowserInfo() {
  if (!browserInfo) {
    browserParser = Bowser.getParser(window.navigator.userAgent);
    browserInfo = browserParser.getResult();
  }
}

export function getBrowserInfo() {
  initBrowserInfo();
  return browserInfo;
}

export function getBrowserParser() {
  initBrowserInfo();
  return browserParser;
}