import SearchRenderer from 'src/renderUtil/SearchRenderer';
import { TagRenderContext } from 'src/api/tags/TagConfig';
import { renderWorldsDefault, createOrRenderTagEl, createOrRenderTagEls } from 'src/render/tags/tags';
import { queryWikiSearch } from 'src/api/third-party/wikiApi';

export default class WikiSearchRenderer extends SearchRenderer {
  constructor($cont, tagRenderContext, contentId = undefined) {
    super($cont,
      '.div-search-tags input', '.wikipedia-output', '.wiki-title-object',);

    this.tagRenderContext = tagRenderContext;
    this.contentId = contentId;
    this.$resultList.removeClass('hidden');

    $cont[0]._search = this;
  }

  startSearch = (searchTerm) => {
    return queryWikiSearch(searchTerm);
  }

  // transformResult = () => {

  // }

  renderResults(entries) {
    const names = entries.map(entry => entry.name);
    const { tagRenderContext } = this;
    return createOrRenderTagEls(tagRenderContext, this.$resultList, names, this.contentId);
  }

  // createEl(entry) {
  //   return createOrRenderTagEl(this.tagRenderContext, this.$resultList, entry.name, this.contentId);
  // }

  // renderResult = ($el, entry, i) => {
  //   return createOrRenderTagEl(this.tagRenderContext, this.$resultList, entry.name, this.contentId);    
  // }

  cleared = (beforeResults) => {
    if (!beforeResults && this.tagRenderContext === TagRenderContext.WorldSearch) {
      renderWorldsDefault(this.$resultList);
    }
  }
}