import { getPageName, getCurrentPageTag, getCurrentPageArg, getQueryStringArg } from '../pages';
import { startRenderContentPreview } from './content/contentPreview';
import { initReelPage } from './pages/reel/reel';
import DefaultPageController from 'src/render/pages/DefaultPageController';
import { setCurrentPageController as setPageController, getCurrentPageController } from 'src/render/pages/pageController';
import { getContentById } from 'src/api/content/contentObjects';
// import { initLoginHelp } from 'src/render/pages/LoginHelp';


const initRenderPerPage = {
  tags: initRenderTagsPage,
  share: initRenderSharePage,
  reel: initReelPage,
  // 'login-help': initLoginHelp
};



// ##################################################################################################################
// tags
// ##################################################################################################################

function initRenderTagsPage() {
  // show tag in vertical title bar
  const $pageTagNames = $('.page-tag-name');

  const tagName = getCurrentPageTag();
  $pageTagNames.text(tagName);

  // update title
  document.title = `${tagName} (Curated Content) - DiscoveReel`;
}


// ##################################################################################################################
// share
// ##################################################################################################################

async function initRenderSharePage() {
  // open content editor/preview right away
  const contentId = getCurrentPageArg();
  // const 

  const editorConfig = {
    hasLoader: true
  };
  const res = await startRenderContentPreview(contentId, editorConfig);

  let content = getContentById(contentId);
  document.title = `${content.title} - DiscoveReel`;

  if (!res) {
    alert('could not show shared item :(');
  }
}

// ##################################################################################################################
// exports
// ##################################################################################################################

export async function waitForPageInit() {
  return getCurrentPageController()?.initPromise;
}

export async function initRenderPage() {
  const cb = initRenderPerPage[getPageName()];
  const controller = cb && (await cb()) || new DefaultPageController();
  setPageController(controller);
  // debugger;
  return waitForPageInit();
}