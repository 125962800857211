
// ##################################################################################################################
// Misc utilities
// ##################################################################################################################

/**
 * @see https://stackoverflow.com/a/273810
 */
export function regexIndexOf(text, re, i = 0) {
    var indexInSuffix = text.slice(i).search(re);
    return indexInSuffix < 0 ? indexInSuffix : indexInSuffix + i;
}

export function isEmptyObject(obj) {
    for(var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            return false;
        }
    }
    return true;
}

export function genRandomString(len, chrs) {
  chrs = chrs || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(len);
  crypto.getRandomValues(array);
  const result = Array.from(array).map(x => chrs[x % chrs.length]);
  return result.join('');
}

/**
 * @see https://stackoverflow.com/a/9436948
 */
export function isString(s) {
    return typeof s === 'string' || s instanceof String;
}

/**
 * @see https://stackoverflow.com/a/13419367
 */
export function parseQueryString(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        if (!pairs[i].length) {
            continue;
        }
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}