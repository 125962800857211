import get from 'lodash/get';
import set from 'lodash/set';
import { decorateClasses } from 'src/util/domUtil';
import { setSelectedCheckboxEl, isSelectedCheckboxEl, isWebflowInputEl } from 'src/webflow/webflowFormHelpers';


/**
 * @see https://stackoverflow.com/a/22420377
 */
export function serializeForm2Object($form, parentAttrName = '') {
  var o = {};
  var $inputs = $form.find('input');
  $inputs.each(function () {
    let { name, value, checked, type } = this;

    const $el = $(this);

    // nest objects based on this attribute
    let basename;
    if (parentAttrName) {
      const $parent = $el.closest(`[${parentAttrName}]`);
      basename = $parent.attr(parentAttrName);
      name = `${basename ? `${basename}.` : ''}${name}`;
    }

    // extract value
    if (type === 'checkbox' || type === 'radio') {
      if (isWebflowInputEl($el)) {
        value = isSelectedCheckboxEl($el);
      }
      else {
        value = checked;
      }
    }

    // store value
    let existing = get(o, name);
    if (existing !== undefined) {
        // multiple values mapping to same key -> must be an array
      if (!existing.push) {
        existing = [existing];
        set(o, name, existing);
      }
      existing.push(value);
    } else {
      set(o, name, value);
    }
  });
  return o;
}

/**
 * @see https://github.com/dannyvankooten/populate.js/blob/master/populate.js
 */
export function populateForm($form, data, parentAttrName = '', parentName = '') {
  const form = $form[0];
  for (var key in data) {
    let name = key;
    var value = data[key];

    if (value === undefined || value === null) {
      value = '';
    }

    // handle array name attributes
    // if (basename) {
    //   name = basename + "." + key;
    // }

    if (value.constructor === Array) {
      name += '[]';
    } else if (typeof value === "object") {
      populateForm($form, value, parentAttrName, name);
      continue;
    }

    // reflect object hierarchy in form
    const $cont = parentName ? $form.find(`[${parentAttrName}="${parentName}"]`) : $form;

    // only proceed if element is set
    const $el = $cont.find(`[name="${name}"]`);
    // var element = form.elements.namedItem(name);
    if (!$el.length) {
      console.warn(`tried to poplate form but found value with no corresponding input in form: "${name}" - data:`, data);
      continue;
    }

    var type = $el[0].type;

    switch (type) {
      default:
        $el[0].value = value;
        break;

      case 'radio':
      case 'checkbox':
        if (isWebflowInputEl($el)) {
          // webflow checkbox
          // TODO: handle mutliple matches
          setSelectedCheckboxEl($el, value);
        }
        else if ($el.length > 1) {
          // domi edit: this was initially buggy; assuming `element` to always be an array
          for (var j = 0; j < $el.length; j++) {
            $el[j].checked = (value.indexOf($el[j].value) > -1);
          }
        }
        else {
          $el[0].checked = value;
        }
        break;

      case 'select-multiple':
        var values = value.constructor == Array ? value : [value];

        for (var k = 0; k < $el.options.length; k++) {
          $el[0].options[k].selected |= (values.indexOf($el[0].options[k].value) > -1);
        }
        break;

      case 'select':
      case 'select-one':
        $el[0].value = value.toString() || value;
        break;

      case 'date':
        $el[0].value = new Date(value).toISOString().split('T')[0];
        break;
    }
  }
}


export function renderObjectToHTML($cont, data, transforms) {
  const $allEls = Array.from($cont.find('[data-name]'));
  for (const el of $allEls) {
    const $el = $(el);
    const entryName = $el.attr('data-name');
    let entry = data[entryName] || '';
    const attrName = $el.attr('data-attr');

    const entryOverride = transforms?.[entryName]?.($el, entry, entryName);
    if (entryOverride) {
      entry = entryOverride;
    }

    if (attrName) {
      if (attrName === 'href') {
        // hide if there is no url
        decorateClasses($el, {
          hidden: !entry
        });
      }
      if (entry) {
        // render data to attribute
        $el[0].setAttribute(attrName, entry);
      }
    }
    else {
      // render data as text
      $el.text(entry);
    }
  }
}