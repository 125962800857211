import pick from 'lodash/pick';
import without from 'lodash/without';
import mapValues from 'lodash/mapValues';

import {
  getContentObjectsByType,
  ContentObjectContainer,
  getContentById
} from '../../api/content/contentObjects';

import postUpdate from 'src/postUpdate';


// import OverlayScrollbars from 'overlayscrollbars';

import { onDebugToggle } from 'src/api/devtools';

import { getContentEl, getVideoHtmlId } from 'src/renderUtil/contentObjects';
import { doesPageRequireAuth, isReelPage } from '../../pages';
import { onAuthStateChanged, authState, waitUntilAuthStateInitialized } from '../../auth';
import { startWebflowAnimation } from '../../renderUtil/animUtil';
import { showNotLoggedInUserActionWarning } from '../../renderUtil/userActions';
import { createContentEl, renderContentEl, initRenderContentEl2 } from './contentObjects';
import { sortChildren, decorateClasses } from '../../util/domUtil';
import { getCurrentPageController } from 'src/render/pages/pageController';
import { sleep, waitUntilNextFrame } from 'src/util/sleep';
import { isMobile } from 'src/util/responsive';

import { getRenderQueue } from '../renderQueue';
import ContentType from 'src/api/content/ContentType';
import { EnabledContentTypes } from 'src/api/content/ContentType';

// ##################################################################################################################
// ContentColumnManager
// ##################################################################################################################

class ContentColumnManager {
  /**
   * TODO: better config management
   * 
   * @param {*} columnConfig global config object, passed in from `index.js`
   */
  init(columnConfig) {
    const allSelectorsByType = {
      [ContentType.Video]: '.videos-column',
      [ContentType.Article]: '.articles-column',
      [ContentType.Film]: '.films-column',
      [ContentType.Series]: '.series-column',
      [ContentType.Course]: '.courses-column',
      [ContentType.Book]: '.books-column',
      [ContentType.App]: '.apps-column',
      [ContentType.Product]: '.products-column',
      [ContentType.Human]: '.humans-column'
    };

    // only pick enabled contents
    const selectorsByType = pick(allSelectorsByType, EnabledContentTypes);

    // remove disabled columns
    const disabledContentTypes = without(ContentType.values, ...EnabledContentTypes);
    if (disabledContentTypes.length) {
      const disabledSelectors = Object.values(pick(allSelectorsByType, disabledContentTypes));
      disabledSelectors.forEach(sel => $(sel).remove());
      console.error('\n'.repeat(7), '#'.repeat(50), 'Disabled content columns:', disabledSelectors, '#'.repeat(50), '\n'.repeat(7));
    }

    // build columns
    this.columnsByType = mapValues(selectorsByType,
      (selector, type) => new ContentColumn(parseInt(type), selector, columnConfig)
    );

    // console.warn('######\n\n\n#######\n\n\n######\n', this.columnsByType);

    this.columns = Object.values(this.columnsByType);

    //const allColSelector = this.getAllColumnsArray().reduce((acc, next) => acc + ' ' + next.columnSelector, '');
    this.allColSelector = '.content-column';
    this.$allColumnsEl = $(this.allColSelector);
  }

  getColumnByType = (contentData) => {
    const type = contentData.type || ContentType.Video;  // for now, assume video as default type
    return this.getColumnByContentType(type);
  }

  getColumnByContentType = (contentType) => {
    return this.columnsByType[contentType];
  }

  getAllColumnsArray = () => {
    return this.columns;
  }

  clear = () => {
    this.columns.forEach(col => col.clear());
  }

  addContentEl = ($contentEl, contentId, contentData) => {
    // console.log('addContentEl', $contentEl, contentId, contentData);
    const col = this.getColumnByType(contentData);
    col.$contentList.append($contentEl);
  }

  // sortAll = (cmp) => {
  //   this.columns.forEach(c => c.sort(cmp));
  // }


  /**
   * NOTE: does nothing, if column is not loaded.
   * Need to call `startListenContent` first.
   */
  updateContentRender = () => {
    this.columns.forEach(column => column.updateContentRender());
  }

  // TODO: move this to some global `contentManager`?
  // getEmptyColumnCount = () => {
  //   return this.columns.reduce((sum, col) => sum + col.contentObjects., 0);
  // }

  // ###########################################################################
  // startLoadVisibleColumns
  // ###########################################################################

  /**
   * Identify all columns that are now visible but have not yet loaded,
   * then enqueue their loading (once; will not accidentally load multiple times).
   */
  checkLoadVisibleContentColumns = (advanceX) => {
    this.columns.forEach(column => column.checkLoadColumn(advanceX));
  }
}

// ##################################################################################################################
// View + render content columns
// ##################################################################################################################

class ContentColumn {
  /**
   * @type {ContentObjectContainer}
   */
  contentObjects;
  _rendered = false;
  busy = true;

  constructor(contentType, columnSelector, columnConfig) {
    this.contentType = contentType;
    this.columnSelector = columnSelector;

    Object.assign(this, columnConfig);
    this.config = columnConfig;

    this._shownCount = this.config.firstPageItemCount;

    const contentObjects = this.contentObjects = getContentObjectsByType(contentType);

    const $column = this.$column = $(columnSelector);
    if (!$column.length) {
      // column is not appearing on this page
      return;
    }

    // make an impact
    $column[0]._column = this;

    this.$contentList = $column.find('.content-list');
    // hackfix: OverlayScrollbars needs some content to work at all (so we make it the "loading element")
    // NOTE: Is this still in play?
    // this.$loading = $('<div>loading...</div>');
    // this.$contentList.append(this.$loading);
    this.$loading = $column.find('.loader-main-ctn');
    this.$placeholder = $column.find('.placeholder');

    // start fetching data
    contentObjects.emitter.on('clear', this.clear);
    contentObjects.onUpdate(this.updateContentRender);

    // render all the fancy stuff
    this.initRenderFilterMenu();
    this.initRenderEcmOrderButtons();
    this.initRenderColumnControls();
  }

  hasRendered() {
    return this._rendered;
  }

  get columnName() {
    return ContentType.nameFrom(this.contentType);
  }

  getContentEl(contentId) {
    return this.$column.find(`#${getVideoHtmlId(contentId)}`);
  }

  // ##########################################################################
  // getters
  // ##########################################################################

  getOrCreateContentEl = (contentId, contentData) => {
    let $contentEl = this.getContentEl(contentId);
    if (!$contentEl.length) {
      $contentEl = createContentEl(contentId, contentData);
    }
    return $contentEl;
  }

  // /**
  //  * Returned number is 0 if renderer and loader are in sync.
  //  * Returned number is positive in case we load objects in advance but do not show them.
  //  * Returned number is negative while objects are still being loaded.
  //  * @returns The amonut of objects that are loaded but not shown.
  //  */
  // getLoadedNotShownCount() {
  //   return this.contentObjects.count() - this.getShownCount();
  // }

  /**
   * Amount of objects we need to load to show given target amount.
   */
  getMissingLoadedCount(targetShownCount) {
    return targetShownCount - this.contentObjects.getRenderCount();
  }

  getShownCount() {
    return this._shownCount;
  }

  /**
   * First getRenderDocs, then reduce it to the desired amount (e.g. initially, we only want to show 3)
   */
  getDocsToRender() {
    const { contentObjects } = this;
    let docs = contentObjects.getRenderDocs();
    if (docs.length > this._shownCount) {
      docs = docs.slice(0, this._shownCount);
    }
    return docs;
  }

  // ##########################################################################
  // init
  // ##########################################################################

  initRenderFilterMenu() {
    // TODO: improve this to work for all the use cases
    // NOTE: `render/pages/reel.js` manages it's own filter menu
    // NOTE: the filter names are mostly the same for submitted/ and approval/ page
    const filterButtonConfig = {
      // filters for approval/ page
      '.filter-not-reviewed': 'notReviewed',
      '.filter-featured': 'featured',  // featured on homepage
      '.filter-published': 'published',
      '.filter-unpublished': 'unpublished',

      // filters for submitted/ page
      '.filter-submitted': 'submitted'
    };

    Object.entries(filterButtonConfig).forEach(([selector, filterName]) => {
      this.$column.find(selector).click(evt => {
        this.contentObjects.filters.setController(filterName);
      });
    });
  }

  /**
   * ECM order buttons are at the top of each column. They toggle the sorting of items.
   */
  initRenderEcmOrderButtons() {
    // toggle between normal and ECM search order in content columns
    this.$column.find('.alignment-activate-button').off('click').on('click', async (evt) => {
      //if (await showECMOrderNeedsPrivWarningAsync()) { // custom privilege level required
      if (await showNotLoggedInUserActionWarning()) { // login required
        // show warning if user cannot do that
        evt.preventDefault();
        return;
      }

      const { contentType } = this;

      const normalizedTypeName = ContentType.nameFrom(contentType).toLowerCase();

      // clear
      this.contentObjects.clear();

      // play button click animation
      if (this.contentObjects.getActiveContentOrder() !== 'ecmScore') {
        startWebflowAnimation('toggle-ecm-on-' + normalizedTypeName);
      }
      else {
        startWebflowAnimation('toggle-ecm-off-' + normalizedTypeName);
      }

      // show artificial loading screen -> then toggle order
      // TODO: when clicking it too fast, it won't work
      this.showLoading();
      await sleep(500);
      this.hideLoading();

      // ECM order functionality depends on page -> deligate to PageController
      getCurrentPageController().toggleEcmOrder(contentType);
    });
  }

  /**
   * Render the buttons inside (at the bottom of) the column
   */
  initRenderColumnControls() {
    this.addControlsLoadMoreButton();
    this.addControlsLoadMoreOnFirstScroll();
  }

  // ##########################################################################
  // clear + update
  // ##########################################################################

  /**
   * Will be called when data is starting to load
   */
  clear = () => {
    // reset everything
    this._rendered = false;
    this._shownCount = this.config.firstPageItemCount;

    // remove all videos
    const $videos = this.$contentList.find('.individual-object-div');
    $videos.remove();
    // this.$contentList.append(this.$loading);

    this._renderDecorations();
  }

  /**
   * 
   */
  updateContentRender = async () => {
    const { contentObjects } = this;

    // get and render all docs
    if (!contentObjects.hasLoaded()) {
      // *NOTE: does nothing, if column is not loaded. Needs `startListenContent` first.*
      // console.debug(`[Column ${this.contentType}]`, 'no render'); //'where', contentObjects._queryInterface.queryArgs // (NOTE: _queryInterface does not currently expose its `queryArgs`));
      return;
    }

    this.busy = true;
    // this.renderLoading();

    try {
      // prepare entries
      const entries = this.getDocsToRender();

      // console.debug(`[Column ${this.contentType}]`, 'render', entries) ; //'where', contentObjects._queryInterface.queryArgs // (NOTE: _queryInterface does not currently expose its `queryArgs`));

      // delete missing elements
      const docIds = new Set(entries.map(entry => entry._id));
      const existingEls = Array.from(this.$contentList.children());
      for (let i = existingEls.length - 1; i >= 0; --i) {
        const el = existingEls[i];
        const id = el.getAttribute('data-content-id');
        if (!docIds.has(id)) {
          $(el).remove();
        }
      }

      if (!entries.length) {
        this.busy = false;
        return;
      }

      // render decorations
      this._renderDecorations();


      // console.log('updateContentRender: ', docs.length, ' items, range:', 0, this._shownCount);

      // render objects (first pass)
      const newIds = [];
      await renderQueue.enqueueRenderTasks(entries.map((entry) =>
        () => {
          const isNew = this.renderContent(entry._id, entry);
          isNew && newIds.push(entry._id);
        }
      ));

      // render objects (second round of initialization; for those not fully initialized yet)
      const cbs2 = renderQueue.enqueueRenderTasks(newIds.map((newId) =>
        this.initContent2.bind(this, newId)
      ));
      await renderQueue.enqueueMediumPriority(...cbs2);

      // TODO: 2nd init render phase (only for elements that are not inited yet)
      // let $contentEl = this.getContentEl(contentId);
      // initRenderContentEl2(contentId, content, $contentEl);

      // sort columns (if necessary)
      // TODO: do not sort, if content has already changed again
      this._sortColumn();

      this._rendered = true;

      contentObjects._perfLog('updateContentRender', 'postUpdate');
    }
    finally {
      this.busy = false;

      // render decorations
      this._renderDecorations();
    }

    postUpdate(this.$column); // update moderator tools and other things
  }

  /**
   * Sort all objects in column by currently selected content order
   */
  _sortColumn() {
    const { contentObjects } = this;
    const orderFn = contentObjects.getActiveContentOrderFn();
    const cmp = (a, b) => {
      const idA = $(a).attr('data-content-id');
      const docA = contentObjects.getDocById(idA);
      const idB = $(b).attr('data-content-id');
      const docB = contentObjects.getDocById(idB);
      if (!docA || !docB) {
        console.error(`[${this.columnName}]`, 'cannot sort column - missing elements or data-content-id in content list', idA, idB, docA, docB);
        return docB - docA;
      }
      return orderFn(docA, docB);
    };
    sortChildren(this.$contentList, cmp);
  }


  // ##########################################################################
  // load more
  // ##########################################################################

  loadMore = async () => {
    const { $column } = this;
    const $controlCont = $column.find('.message-bottom-feed');
    const $loadMoreBtn = $controlCont.find('.load-more-button');

    if (!$loadMoreBtn.hasClass('disabled')) {
      $loadMoreBtn.addClass('disabled');    // disable load button until first scroll has fully loaded next batch of items
    }

    const targetCount = this._shownCount + this.defaultPageItemCount;
    const missingCount = this.getMissingLoadedCount(targetCount);
    this._shownCount = targetCount;
    if (missingCount > 0) {
      await this.contentObjects.loadNextPage({ limit: missingCount });
    }
    // else {
    this.updateContentRender();   // button should not actually show, but we somehow missed to render it correctly
    // await this.contentObjects.loadNextPage({ limit: this.defaultPageItemCount });
    // }
  }

  addControlsLoadMoreOnFirstScroll() {
    // load next page on first scroll
    const scrollHandler = (evt) => {
      // only load one page, then use "load more button".
      // also, unregister handler, because it should only load on first scroll, not on any subsequent scrolls
      const $target = $(evt.target);
      // console.log('scroll', evt, evt.target);
      if ($target.hasClass('os-size-auto-observer') ||
        $target.hasClass('os-resize-observer-host') ||
        $target.hasClass('os-resize-observer-item')
      ) {
        // these "overlay-scrollbars" element triggers scroll events -> ignore them!
        return;
      }

      //this.$column[0].removeEventListener('scroll', scrollHandler, true);
      if (this._shownCount === this.config.firstPageItemCount) {
        // load on any scroll, if only loaded first page
        this.loadMore();
      }
    };

    // jquery events don't bubble up.
    // see: https://stackoverflow.com/a/19375645
    this.$column[0].addEventListener('scroll', scrollHandler, true);
  }

  addControlsLoadMoreButton() {
    const { $column } = this;
    const $controlCont = $column.find('.message-bottom-feed');
    const $loadMoreBtn = $controlCont.find('.load-more-button');

    $loadMoreBtn.addClass('disabled');    // disable load button until first scroll has fully loaded next batch of items

    // add "load more button" logic
    $loadMoreBtn.on('click', async () => {
      if (showNotLoggedInUserActionWarning()) {
        return;
      }

      this.loadMore();
    });
  }


  // ##########################################################################
  // render content
  // ##########################################################################

  /**
   * Get or create content element, then (re-)render.
   * @return {boolean} Whether the content object has been rendered for the first time.
   */
  renderContent = (contentId, contentData) => {
    if (contentData.type !== this.contentType) {
      console.error('Trying to render content object of incorrect type to column:', ContentType.nameFrom(this.contentType), contentId, contentData);
      return false;
    }
    // const $contentEl = this.getOrCreateContentEl(contentId, contentData);

    let $contentEl = this.getContentEl(contentId);
    const isNew = !$contentEl.length;
    if (isNew) {
      $contentEl = createContentEl(contentId, contentData);

      // TODO: why is this here?
      contentRenderManager.addContentEl($contentEl, contentId, contentData);
      // if (!$contentEl.parent().length) {
      //   // does not exist yet -> create new contentEl
      // }
    }

    renderContentEl($contentEl, contentId, contentData);

    return isNew;
  }

  initContent2 = (contentId) => {
    const contentData = getContentById(contentId);
    if (!contentData) {
      // already gone
      return;
    }
    let $contentEl = this.getContentEl(contentId);
    initRenderContentEl2(contentId, contentData, $contentEl);
  }

  // ###########################################################################
  // render decorations
  // ###########################################################################

  _renderDecorations() {
    this.renderEmpty();
    this.renderShowMore();
    this.renderLoading();
  }

  /**
   * Show placeholder if we finished loading, and there is nothing there.
   */
  renderEmpty() {
    const { contentObjects } = this;

    // console.debug('placeholder', contentObjects.getDebugTag(),
    //   contentObjects.hasLoaded(),
    //   !contentObjects.getRenderCount(),
    //   contentObjects.hasReachedLastPage()
    // );

    const isEmpty = this._emptyOverride ||
      (!contentObjects._busy &&  // TODO: defer busy check to queryInterface
        contentObjects.hasLoaded() &&
        !contentObjects.getRenderCount() && contentObjects.hasReachedLastPage());

    const wasEmpty = this.$column.hasClass('hidden');

    // if (wasEmpty !== isEmpty) {
    //   console.debug(this.contentObjects.getDebugTag(), 'column empty', isEmpty);
    // }

    if (this.contentType !== ContentType.Video) {
      // hide entire column
      decorateClasses(this.$column, {
        hidden: isEmpty
      });
    }
    else {
      // show placeholder on Video page
      if (!isEmpty) {
        // column is not empty -> hide placeholder
        this.$placeholder.hide();
      }
      else {
        this.$placeholder.show();
      }
    }
  }

  renderShowMore() {
    const { contentObjects } = this;
    const { $column } = this;

    const $controlCont = $column.find('.message-bottom-feed');
    const $multiPurposeCont = $column.find('.temporal');
    const $loadMoreBtn = $controlCont.find('.load-more-button');

    // console.log('renderShowMore', !contentObjects.hasLoaded(), 
    //   contentObjects.isLoadingPage(), 
    //   contentObjects.hasReachedLastPage());

    if (!contentObjects.hasLoaded() ||
      contentObjects.isLoadingPage() ||
      contentObjects.hasReachedLastPage()
    ) {
      // still loading, or: no more pages to load
      $controlCont.css('display', 'none');
      $multiPurposeCont.css('display', 'flex');   // stick with default flex layout
    }
    else {
      // more pages to load
      $loadMoreBtn.removeClass('disabled');
      $controlCont.css('display', 'flex');
      $multiPurposeCont.css('display', 'block');    // need to change to block to display on Safari
    }
  }

  showLoading() {
    this.$loading.css('display', 'flex');
  }

  hideLoading() {
    this.$loading.hide();
  }

  renderLoading() {
    // console.debug(this.contentObjects.getDebugTag(), 'renderLoading',
    //   this._emptyOverride,
    //   !this.contentObjects.hasLoaded(), this.busy);

    if (!this._emptyOverride && (!this.contentObjects.hasLoaded() || this.busy)) {
      this.showLoading();
    }
    else {
      this.hideLoading();
    }
  }

  // ###########################################################################
  // determine visibility
  // ###########################################################################

  /**
   * Use some heuristics to determine if this should be loaded.
   * 
   * @param {Number} advanceX Amount of pixels we should check in advance. Usually stems from animation not having played out yet, and we anticipate the animation to advance by that much within a very short period of time.
   */
  isVisibleOrSoonVisible(advanceX = 0) {
    if (!this.$column.length) {
      // this is an empty column container
      return false;
    }
    const deltaX = window.innerWidth - this.$column[0].getBoundingClientRect().x;

    let pixelThreshold;

    if (isMobile()) {
      // mobile
      pixelThreshold = 0;
    }
    else {
      // desktop
      pixelThreshold = 0;
    }

    // console.debug('checkLoadColumn', 
    // window.innerWidth, 
    // this.$column[0].getBoundingClientRect().x,
    // deltaX,
    // advanceX);

    if (deltaX + advanceX + pixelThreshold > 0) {
      // is in view
      return true;
    }
    return false;
  }

  checkLoadColumn(advanceX) {
    if (!this.contentObjects.started && this.isVisibleOrSoonVisible(advanceX) && !this.__startedLoading) {
      this.__startedLoading = true;

      // low priority task: add new column to queue
      renderQueue.enqueueLowPriority(this.contentObjects.startListenContent);
    }
  }

  // ###########################################################################
  // inactive
  // ###########################################################################

  /**
   * Called from `reel` page menu controller if it turns out that there is no available collection to display.
   */
  setEmpty() {
    this._emptyOverride = true;
    this._renderDecorations();
  }
}


//OverlayScrollbars(document.querySelectorAll(".overlay"), { }); 


// ##################################################################################################################
// init
// ##################################################################################################################

export const contentRenderManager = new ContentColumnManager();

// add contentRenderManager to window, so we can use this in the console for debugging sometimes
// [debug-global]
window.contentRenderManager = contentRenderManager;

/**
 * @returns {ContentColumn}
 */
export function getClosestContentColumn($el) {
  const $colEl = $el.closest('.content-column');
  return $colEl[0]?._column;
}

async function _authWaitTask() {
  await waitUntilAuthStateInitialized();
  if (!authState.uid || authState.isFromCache()) {
    return;
  }
}


let renderQueue;

export function initRenderContentColumns(columnConfig) {
  renderQueue = getRenderQueue();

  // re-render all columns when debug mode is toggled
  onDebugToggle(contentRenderManager.updateContentRender);

  // init content rendering
  contentRenderManager.init(columnConfig);

  // start rendering content
  if (doesPageRequireAuth() && !authState.isLoggedIn()) {
    // TODO: re-render every time auth changes?
    // onAuthStateChanged((authState) => {
    //   if (!authState.uid || authState.isFromCache()) {
    //     return;
    //   }
    //   contentRenderManager.startLoadVisibleColumns();
    // });
    renderQueue.enqueueHighPriority(_authWaitTask);
  }
}

let initialized = false;

export function initRenderVisibleContentColumns() {
  initialized = true;
  updateVisibleContentColumns();
}

export function updateVisibleContentColumns(advanceX = 0) {
  if (!initialized) {
    return;
  }
  contentRenderManager.checkLoadVisibleContentColumns(advanceX);
}