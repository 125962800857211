import { getContentEl } from './contentObjects';

export function getTagsContainer(contentId) {
  const $contentEl = getContentEl(contentId);
  return $contentEl.find('.existing-tags');
}

function getTagEl(contentId, tagName) {
  const $contentEl = getContentEl(contentId);
  return getTagElInList($contentEl, tagName);
}

export function getTagElInList($contentEl, tagName) {
  return $contentEl.find(`[data-tag="${encodeURIComponent(tagName)}"]`);
}