import { setLocalStorageItem, getLocalStorageItem } from 'src/util/localStorage';
import { authState } from 'src/auth';
import NanoEvents from 'src/util/NanoEvents';

let selectedReelId;
let reelSelectionEmitter = new NanoEvents();

export function selectReel(reelId) {
  setLocalStorageItem('reelId', reelId);
  _updateSelectedReel(reelId);
}

export function _updateSelectedReel(reelId) {
  if (selectedReelId === reelId) {
    return;
  }

  const oldReelId = selectedReelId;
  selectedReelId = reelId;
  reelSelectionEmitter.emit('reelSelected', reelId, oldReelId);
}


export function getSelectedReelId() {
  const reelId = getSelectedReelIdOrNull();
  if (reelId === null) {
    // NOTE: reelId is uid, if logged in
    throw new Error(`Tried to getSelectedReelId() before login.`);
  }

  return reelId;
}

export function getSelectedReelIdOrNull() {
  if (!selectedReelId) {
    if (selectedReelId === undefined) {
      // did not load before
      selectedReelId = getLocalStorageItem('reelId') || null;
    }

    // NOTE: maybe we tried to load before, but was not logged in
    selectedReelId = selectedReelId || authState.uid || null;
    if (selectedReelId) {
      _updateSelectedReel(selectedReelId);
    }
  }
  
  return selectedReelId;
}

export function onReelSelected(cb) {
  return reelSelectionEmitter.on('reelSelected', cb);
}