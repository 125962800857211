import FirestoreContainer from './FirestoreContainer';

function serializeKey(keys) {
  return JSON.stringify(keys);  
}

/**
 * Handles multiple firestore containers, each responsible for some subset of data
 * represented by some key.
 * NOTE: Not the best solution ever but it (kinda) gets the job (somewhat) done.
 */
class FirestoreQueryInterface {
  containers = {};
  
  /**
   * 
   * @param {string} key 
   * @param {FirestoreContainer} Container
   * @param  {...any} allArgs 
   * @returns {FirestoreContainer}
   */
  async addContainerAndQuery(Container, queryArgs, ...keys) {
    const key = serializeKey(keys);
    const cont = this.containers[key] = new Container(key);
    const result = await cont.query(queryArgs);
    // console.debug('addContainer -> after query', cont.hasLoaded(), cont.getDebugTag(), queryArgs, result);
    return cont;
  }

  addContainer(Container, ...keys) {
    const key = serializeKey(keys);
    const cont = this.containers[key] = new Container(key);
    return cont;
  }

  /**
   * @returns {FirestoreContainer}
   */
  getContainer(...keys) {
    const key = serializeKey(keys);
    return this.containers[key];
  }
}

export default FirestoreQueryInterface;