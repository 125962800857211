
export function extractURLDomain(url) {
  try {
    let { hostname: domain } = new URL(url);
    if (domain.startsWith('www.')) {
      domain = domain.substring(4);
    }
    return domain;
  }
  catch (err) {
    console.error('could not parse url', url, err);
    // probably invalid url
    return '';
  }
  // const re = new RegExp([
  //   /^(?:https?:\/\/|\/\/)?/, // Optional URL scheme. Either http, or https, or protocol-relative.
  //   /(?:www\.|m\.)?/, //  Optional www or m subdomain.
  //   /([^/]+)[/$]/,     // domain (add any amount, separated by "|")
  // ].map(exp => exp.source).join(''), 'm');
  // const match = re.exec(url);
  // return match[1];
}