import firebase from 'firebase/app';
import pull from 'lodash/pull';
import { onAuthStateChanged, onUIDChanged, waitUntilAuthStateInitialized } from 'src/auth';
import userStats from './users/userStats';
import FirestoreContainer from '../firebase/FirestoreContainer';
import ECMFlags from './ECMFlags';
import { perfLog } from 'src/util/perf';
import { sleep } from 'src/util/sleep';
import startQueue from 'src/taskScheduler';
import EmptyArray from 'src/util/EmptyArray';
import { getOrQueryWikiEntry } from 'src/api/third-party/wikiApi';
import { onReelSelected, getSelectedReelId } from 'src/api/reel/reelSelection';

const Verbose = true;

const collectionName = 'ecm';

// ##################################################################################################################
// ECM API + state management
// ##################################################################################################################


export class ECMContainer extends FirestoreContainer {
  _orderedTags = null;
  _entriesByTagName = null;

  _listenersByTag = {};
  _busyUpdateCount = 0;

  constructor() {
    super(collectionName);
    // this.enableCache();

    // this.onUpdate(this._handleUpdate);
  }

  _ensureEntriesByTagName() {
    if (this._entriesByTagName) return;

    this._entriesByTagName = {};
    this._orderedTags = [];

    // re-build cached array of docs
    // let docs = this._pages.flat();
    const docs = this.getAllNotNull();

    if (!docs) {
      return EmptyArray;
    }

    for (let doc of docs) {
      const entry = this.getDocById(doc._id);
      const { tag: tagName } = entry;

      if (this._entriesByTagName[tagName]) {
        console.error(`[api/ECM] tag ${tagName} was added twice:`, entry, this._entriesByTagName[tagName]);
      }

      this._entriesByTagName[tagName] = entry;
      this._orderedTags.push(tagName);
    }

    Verbose && console.debug('[api/ECM] _ensureEntriesByTagName');
  }

  onBeforePageUpdateCb = () => {
    Verbose && console.debug('[api/ECM]', 'data loaded', this.getAllNotNull());
    this._orderedTags = null;
    this._entriesByTagName = null;
  }

  // ###########################################################################
  // general getters
  // ###########################################################################

  /**
   * Array of all tags
   */
  getAllTags() {
    this._ensureEntriesByTagName();
    return this._orderedTags;
  }

  getEntryByTag(tagName) {
    this._ensureEntriesByTagName();
    return this._entriesByTagName[tagName] || null;
  }

  hasTag(tagName) {
    return !!this.getEntryByTag(tagName);
  }

  hasECM(tagName, ecmFlag) {
    const entry = this.getEntryByTag(tagName);
    return !!(entry?.ecm & ecmFlag);
  }

  getLastSnapshot() {
    return this._pageSnapshots[0];
  }

  getTagsByECMFlag(ecmFlag) {
    return this.getAllNotNull()?.filter(entry => entry.ecm & ecmFlag).map(entry => entry.tag) || [];
  }

  *getECMFlagsForTag(tagName) {
    const entry = this.getEntryByTag(tagName);
    if (entry) {
      yield* this.getECMFlagsOfEntry(entry);
    }
  }

  *getECMFlagsOfEntry(entry) {
    if (entry.ecm & ECMFlags.Experience) {
      yield ECMFlags.Experience;
    }
    if (entry.ecm & ECMFlags.Curiosity) {
      yield ECMFlags.Curiosity;
    }
    if (entry.ecm & ECMFlags.Mindset) {
      yield ECMFlags.Mindset;
    }
  }

  /**
   * Compute how many ECM matches we have for each tag
   */
  getMatchingTagCountsByECMFlags(tagNames) {
    const counts = {};
    if (tagNames) {
      for (const tag of tagNames) {
        for (const ecmFlag of this.getECMFlagsForTag(tag)) {
          counts[ecmFlag] = (counts[ecmFlag] || 0) + 1;
        }
      }
    }
    return counts;
  }

  // ###########################################################################
  // initialization
  // ###########################################################################

  _syncPromise = null;

  _syncWithCurrentReel = async () => {
    this._syncWithReel(getSelectedReelId());
  }

  async _syncWithReel(reelId) {
    if (!reelId || reelId === this.reelId) {
      // already done
      return;
    }
    // if (this.reelId && this.reelId !== reelId) {
    //   console.error('Tried to ecm._syncWithReel with different `reelId` on same container', reelId);
    //   return;
    // }

    await this._syncPromise;

    this.reelId = reelId;

    // clear
    this.clear();

    // send out query in background
    this._syncPromise = startQueue.addTask(() => {
      perfLog('ecm._syncWithReel', reelId);

      // clear (we might be doing this more than once...?)
      this.clear();

      // get new data
      return this.query({
        where: ['uid', '==', reelId],
        orderBy: ['createdAt', 'desc']
      }).finally(() => {
        // done synchronizing
        this._syncPromise = null;
      });
    });

    return this._syncPromise;
  }

  _startedSync = false;

  async startECMSync(reelId = null) {
    if (this._startedSync) {
      // don't start more than once
      return;
    }
    this._startedSync = true;

    if (!reelId) {
      // NOTE: !reelId means we want the default ECMContainer (based on selectedReelId)
      await waitUntilAuthStateInitialized();
      
      onReelSelected(this._syncWithCurrentReel);
      this._syncWithCurrentReel();
    }
    else {
      this._syncWithReel(reelId);
    }

    return this._syncPromise;
  }

  clear() {
    this._orderedTags = null;
    this._entriesByTagName = null; // invalidate
    super.clear();
  }

  async waitForSync() {
    while (!this.hasLoaded() && !this._syncPromise) {
      await sleep(100);
    }

    if (this._syncPromise) {
      await (this._syncPromise = this._syncPromise.then(() => { }));
    }
  }

  // ###########################################################################
  // manage busy state
  // ###########################################################################

  isBusyUpdating() {
    return !!this._busyUpdateCount;
  }

  decBusyUpdating() {
    // TODO: need to have this logic per entry
    --this._busyUpdateCount;
    if (!this._busyUpdateCount) {
      // finished updating -> notify `onUpdate` event handlers
      // this.state.forceUpdate();
    }
  }

  // ###########################################################################
  // _getMatchingDoc
  // ###########################################################################

  async _getMatchingDoc(t, tag) {
    if (!this.hasLoaded()) {
      throw new Error('ecm not loaded when adding/removing: ' + tag);
    }

    // ugly but necessary (since Firestore does not support where or other queries in transactions)
    // NOTE: optimistic UI actually adds a `previousEntry` with a newly generated id, but is not in DB.
    const previousEntry = this.getEntryByTag(tag);
    const docRef = previousEntry?._id && this.doc(previousEntry._id);
    const doc = docRef && await t.get(docRef);
    // if (!!match !== !!tagDoc) {
    //   throw new Error('things changed during transaction: ' + match + ' - ' + previousEntry);
    // }
    Verbose && console.debug('getMatching', tag, '- previous:', previousEntry, '- matching:', doc?.id, doc?.data(), this.getDebugTag());
    return doc;
  }


  // ###########################################################################
  // add ECM
  // ###########################################################################

  _addOptimistically(tag, ecmFlag) {
    const { reelId: uid } = this;

    let entry = this.getEntryByTag(tag);
    if (!entry) {
      // new
      const newId = this.collection.doc().id; // generate new id
      entry = {
        _id: newId,
        tag,
        uid,
        ecm: ecmFlag
      };

      if (this._pages?.length) {
        // hackfix: also add to first page (since that's where most features get their data from)
        this._pages[0].push({
          id: newId,
          data: entry
        });
      }
    }
    else {
      // update
      entry.ecm = entry.ecm | ecmFlag;
    }

    this.overrideDoc(entry._id, entry, false);

    Verbose && console.debug('addOptimstically', tag, ecmFlag, this.hasECM(tag, ecmFlag), this.getDebugTag());
  }

  async _addECMEntry(tagName, ecmFlag) {
    const { reelId: uid } = this;
    if (!uid) {
      console.error('not logged in');
      return;
    }

    // send update to DB
    const computeIncrement = async (t) => {
      let doc = await this._getMatchingDoc(t, tagName);
      if (doc?.data()) {
        // already have an ecm of that tag -> don't count twice
        return 0;
      }
      return 1;
    }

    const addECM = async (t) => {
      let doc = await this._getMatchingDoc(t, tagName);
      const { reelId: uid } = this;
      if (!doc?.data()) {
        // new doc
        const newEntry = {
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
          tag: tagName,
          uid,
          ecm: ecmFlag
        };
        const ref = doc?.ref ||
          this.collection.doc(); // creates new unique doc id
        t.set(ref, newEntry);

        Verbose && console.debug('addECM (new)', tagName, ecmFlag, this.getDebugTag());
      }
      else {
        // add to existing doc
        t.update(this.doc(doc.id), {
          uid,  // need uid for permissions
          ecm: doc.data().ecm | ecmFlag
        });

        Verbose && console.debug('addECM (existed)', tagName, ecmFlag, this.getDebugTag());
      }
    }

    ++this._busyUpdateCount;
    try {
      await userStats.addStat(collectionName, 'ecm', addECM, computeIncrement);
    }
    finally {
      this.decBusyUpdating();
    }
  }

  // ###########################################################################
  // remove ECM
  // ###########################################################################

  _removeOptimistically(tag, ecmFlag) {
    let entry = this.getEntryByTag(tag);

    if (!entry) {
      // nothing to do
      return;
    }

    // update
    entry.ecm = entry.ecm & ~ecmFlag;
    this.overrideDoc(entry._id, entry, false);

    Verbose && console.debug('removeOptimistically', tag, ecmFlag, this.hasECM(tag, ecmFlag), this.getDebugTag());
  }

  async _removeECMEntry(tagName, ecmFlag) {
    const { reelId: uid } = this;
    if (!uid) {
      console.error('not logged in');
      return;
    }

    // send update to DB
    const computeIncrement = async (t) => {
      let doc = await this._getMatchingDoc(t, tagName);
      if (!doc) {
        return 0;
      }
      if (doc.data().ecm & ~ecmFlag) {
        // still has remaining flags on tag
        return 0;
      }
      else {
        // last flag on tag
        return -1;
      }
    }

    const deleteECM = async (t) => {
      let doc = await this._getMatchingDoc(t, tagName);
      if (!doc) {
        return;
      }
      const docRef = this.doc(doc.id);
      const ecmFlags = doc.data().ecm & ~ecmFlag;
      if (ecmFlags) {
        // still has remaining flags on tag
        t.update(docRef, {
          uid,  // need uid for permissions
          ecm: ecmFlags
        });

        Verbose && console.debug('deleteECM (has more remaining)', tagName, ecmFlag, ecmFlags, this.getDebugTag());
      }
      else {
        // last flag on tag
        t.delete(this.doc(doc.id));

        Verbose && console.debug('deleteECM (last)', tagName, ecmFlag, this.getDebugTag());
      }
    }

    ++this._busyUpdateCount;
    try {
      await userStats.deleteStat(collectionName, 'ecm', deleteECM, computeIncrement);
    }
    finally {
      this.decBusyUpdating();
    }
  }

  // ###########################################################################
  // toggleECM
  // ###########################################################################

  _tagUpdatePromises = new Map();

  async toggleECM(tagName, ecmFlag) {
    if (this.hasECM(tagName, ecmFlag)) {
      // optimistic UI
      this._removeOptimistically(tagName, ecmFlag);

      // get redirect
      const tagName2 = await getOrQueryWikiEntry(tagName)?.redirectTo;

      // send it out
      await Promise.all([
        this._removeECMEntry(tagName, ecmFlag),
        tagName2 && this._removeECMEntry(tagName2, ecmFlag)
      ]);
    }
    else {
      // optimistic UI
      this._addOptimistically(tagName, ecmFlag);

      // get redirect
      const tagName2 = await getOrQueryWikiEntry(tagName)?.redirectTo;

      // send it out
      await Promise.all([
        this._addECMEntry(tagName, ecmFlag),
        tagName2 && this._addECMEntry(tagName2, ecmFlag)
      ]);
    }
  }

  // function stopECMSync() {
  //   if (this.ecmUnsubscribe) {
  //     this.ecmUnsubscribe();
  //     this.ecmUnsubscribe = null;
  //   }

  //   offAuthStateChanged(this.onAuthStateChangedUpdateECM);    
  // }


  // ###########################################################################
  // events
  // ###########################################################################

  onTagUpdate(tagName, cb) {
    // TODO: changed is currently not an actual delta, but the entire snapshot?
    this.onUpdate(changed => {
      // if (changed.find(e => e.tag === tagName) < 0) {
      //   return;
      // }

      // console.debug('onTagUpdate', tagName);

      // defer updates while busy updating (this is to not override optimistic UI with intermediate database results)
      if (this.isBusyUpdating()) {
        // return;
      }
      cb();
    });
    // TODO
    // this._listenersByTag[tagName] = (this._listenersByTag[tagName] || []);
    // this._listenersByTag[tagName].push(cb);
  }


  // ###########################################################################
  // complex queries
  // ###########################################################################

  /**
   * @return {*} An object containing the count for each E, C and M for this user.
   */
  async getCountsByFlags() {
    await this.waitForSync();

    const counts = {};
    for (const entry of this.getAllNotNull()) {
      const flags = this.getECMFlagsOfEntry(entry)
      for (const flag of flags) {
        counts[flag] = (counts[flag] || 0) + 1;
      }
    }
    return counts;
  }

  async hasAtLeastNOfEachFlag(n) {
    const counts = await this.getCountsByFlags();
    const values = Object.values(counts);
    const hasAllFlags = values.length === 3;
    return hasAllFlags && 
      // for each count we have at least n
      values.every(count => count >= n);
  }
}

// ##################################################################################################################
// ECM score computation
// ##################################################################################################################

/**
 * Amount of value per ECM type (conceptually different from const ECM declared above).
 */
const ECMValues = {
  Experience: 1,
  Curiosity: 2,
  Mindset: 3
};

const ECMValuesByECMFlag = {};
for (let ecmName in ECMValues) {
  const ecmFlag = ECMFlags.valueFromForce(ecmName);
  ECMValuesByECMFlag[ecmFlag] = ECMValues[ecmName];
}


// TODO: need a more flexible render system to update everything when values change


class ECMCalculator {
  _version = 0;
  _cache = {};

  isReady() {
    return ecmContainer.hasLoaded();
  }

  getECMValue(ecmFlag) {
    return ECMValuesByECMFlag[ecmFlag] || 0;
  }

  /**
   * reduce score based on age:
   * 30d -> 0
   * 0 minutes -> 1
   * 
   * Current Formula: (max(30days - age, 0)/30days)^3
   */
  getECMWeight(seconds) {
    const maxPeriod = 200 * 24 * 60 * 60; // 30 days
    if (seconds >= maxPeriod) {
      return 0;
    }
    const normalizedAge = (seconds / maxPeriod);
    const linearWeight = (1 - normalizedAge);
    return Math.pow(linearWeight, 3);
  }

  lookupFromCache(contentId, tagString) {
    // if (this._version !== ecmContainer._version) {
    // cached data has changed... invalidate entire cache (TODO: optimize)
    this._version = ecmContainer._version;
    this._cache = {};
    return null;
    // }
    // else {
    //   let entry = this._cache[contentId];
    //   if (entry) {
    //     // check if input arguments are still the same
    //     if (entry.tagString !== tagString) {
    //       // things changed - invalidate entry!
    //       entry = this._cache[contentId] = null;
    //     }
    //   }
    //   return entry;
    // }
  }

  /**
   * Compute the weighted ECM score.
   */
  getUnweightedECMScoreForContent(contentId, content) {
    const tagNames = content.tags || [];
    const tagString = tagNames.join('__#_#__');

    const cacheEntry = this.lookupFromCache(contentId, tagString);
    if (cacheEntry) {
      return cacheEntry.score;
    }

    let sum = 0;
    if (tagNames) {
      for (const tag of tagNames) {
        for (const ecmFlag of ecmContainer.getECMFlagsForTag(tag)) {
          sum += this.getECMValue(ecmFlag);
        }
      }
    }

    this._cache[contentId] = {
      score: sum,
      tagString
    };
    return sum;
  }

  /**
   * Compute the weighted ECM score.
   */
  getECMScoreForContent(contentId, content) {
    const score = this.getUnweightedECMScoreForContent(contentId, content);
    const ageSeconds = Date.now() / 1000 - (content.createdAt?.seconds || 0);
    return score * this.getECMWeight(ageSeconds);
  }
}



/*
// some test cases:

var scores = [
  { ecmScore: 2, age: 1 },
  { ecmScore: 100, age: 180 },
  { ecmScore: 10, age: 60 },
  { ecmScore: 10, age: 180 },
  { ecmScore: 20, age: 300 }
];

// reduce score based on age:
// 1d (1440 minutes) -> 0
// 0 minutes -> 1
function weight(age) {
  const minutesPerDay = 60 * 24;
  const normalizedAge = (age/minutesPerDay);
  const linearWeight = (1 - normalizedAge);
  return Math.pow(linearWeight, 3);
}

function computeRealEcmScore({ecmScore, age}) {
  return ecmScore * weight(age);
}

console.log(scores.map(computeRealEcmScore));
/*
Linear result:
0: 1.9986111111111111
1: 87.5
2: 9.583333333333334
3: 8.75
4: 15.833333333333332
*/

/*
Cubic result:
0: 1.995836226182056
1: 66.9921875
2: 8.801359953703706
3: 6.69921875
4: 9.923321759259258
*/


/**
 * Default ECM container for `currentUser`
 */
export const ecmContainer = new ECMContainer();
export const ecmCalculator = new ECMCalculator();

// [debug-global]
window.ecmContainer = ecmContainer;


export function userHasECM(tagName) {
  return ecmContainer.hasTag(tagName);
}