import Enum from 'src/util/Enum';

const ContributorPrivilege = new Enum(/** @lends {ContributorPrivilege} */ {
  /**
   * Anyone can view public collections.
   */
  None: 0,
  /**
   * Can view "protected" collections.
   */
  View: 1,
  /**
   * Can add items and remove (but only items that they added).
   */
  Edit: 2,
  /**
   * Can view/edit private/protected/public collections
   */
  Admin: 3,

  /**
   * Same as admin, but higher.
   */
  Owner: 4
});

// /**
//  * @type {ContributorPrivilegeObj | Enum}
//  */
// ContributorPrivilegeObj);

export default ContributorPrivilege;