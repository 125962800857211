import { decorateClasses, addInputTextChangedEvent, decorateAttr, prepareTemplateElement, cloneTemplateElement } from 'src/util/domUtil';
import { initRenderModal } from 'src/renderUtil/ModalBase';
import { sleep } from 'src/util/sleep';
import reelInfoContainer, { getReelDisplayName } from 'src/api/reel/reelInfo';
import db, { NotLoaded } from 'src/db';
import reelCollectionContributorContainer, { getDefaultContributorPrivilege } from 'src/api/reel/reelCollectionContributorContainer';
import ContributorPrivilege from 'src/api/reel/ContributorPrivilege';
import reelCollectionsContainer, { getCollectionLabel } from 'src/api/reel/reelCollectionContainer';
import { authState } from 'src/auth';
import { setSelectedCheckboxEl, setDisabledCheckboxEl, isSelectedCheckboxEl } from 'src/webflow/webflowFormHelpers';
import EventHandlerList from 'src/util/EventHandlerList';
import State from 'src/util/State';


function getUidFromContributorEl($el) {
  return $el.closest('.contributor').attr('data-uid');
}

// NOTE: this is global because we should only have one such modal active at a time
/**
 * @type {EventHandlerList}
 */
let dataHandlers = new EventHandlerList();

class CollectionContributorEditor {
  _version = 1;
  state = new State();

  // ###########################################################################
  // init
  // ###########################################################################

  init($cont, collectionIdRef) {
    this.$cont = $cont;
    this.collectionIdRef = collectionIdRef;
    this.$title = $cont.find('.collection-title');
    this.$contributorList = $cont.find('.contributors-list');
    this.$emailInput = $cont.find('.creating-new-collection input');
    this.$addBtn = $cont.find('.creating-new-collection a');
    this.$saveChangesBtn = $cont.find('.submit-button');

    prepareTemplateElement(this.$contributorList, '.contributor');

    // input event: email changed
    addInputTextChangedEvent(this.$emailInput, this.handleEmailChanged);

    // button events
    this.$addBtn.off('click').on('click', this.tryAdd);
    this.$saveChangesBtn.off('click').on('click', this.saveContributorChanges);

    // re-render on state change
    this.state.onUpdate(this.render);
    this.clear();
  }

  // ###########################################################################
  // input change event handlers
  // ###########################################################################

  handleEmailChanged = async () => {
    const searchTerm = this.$emailInput.val();

    if (!searchTerm) {
      this.clear();
      return;
    }

    const version = ++this._version;
    await sleep(50);

    if (this._version !== version) {
      // something else is already going on
      return;
    }

    if (this.state.get.lastSearchTerm === searchTerm) {
      return; // after typing a bit, we landed back on our last input
    }

    // go time!
    this.state.setState({ lastSearchTerm: searchTerm });
    // this.$resultList.addClass('searching');

    const user = await this.findUser(searchTerm);

    if (this._version !== version) {
      // something else is already going on
      return;
    }

    // set state
    this.state.setState({ user });
  };

  setContributorPriv = (uid, mayEdit) => {
    const contributorUpdate = this.state.get.contributorUpdate || {};
    contributorUpdate[uid] = {
      priv: mayEdit ? ContributorPrivilege.Edit : ContributorPrivilege.View
    };

    this.state.setState({
      contributorUpdate
    });
  }


  // ###########################################################################
  // tryAdd
  // ###########################################################################

  tryAdd = async () => {
    const { _version: version, collectionIdRef: { reelId } } = this;
    const { user, lastSearchTerm: email } = this.state.get;

    const collectionEntry = reelCollectionsContainer.getDocById(reelId);
    if (!collectionEntry) {
      return;
    }
    const collectionAccessMode = reelCollectionsContainer.getAccessMode(this.collectionIdRef);
    if (collectionAccessMode === null) {
      console.error('[INTERNAL ERROR] could not add contributor - accessMode not set.');
      return;
    }

    // render result
    if (!user) {
      alert(`Could not lookup user email: ${email}`);
    }
    else if (user.uid === authState.uid) {
      alert(`Cannot add yourself as a contributor`);
    }
    else if (!user.firstName && !user.lastName) {
      alert(`This user did not enter a name yet. Please ask them to enter a name and then try again.`);
    }
    else {
      const canAdd = confirm(`Found user: ${getReelDisplayName(user)} (${email}). Do you want to add them?`);
      if (canAdd) {
        const priv = getDefaultContributorPrivilege(collectionAccessMode);

        // go at it
        this.state.inc('busy');
        try {
          await this.addContributor(user, priv);
        }
        finally {
          this.state.dec('busy');
        }

        if (this._version !== version) {
          // something else is already going on
          return;
        }

        // done!
        this.clear();
      }
    }
  }

  /**
   * Update contributor privileges from checkbox states.
   */
  saveContributorChanges = async () => {
    this.state.inc('busy');
    try {
      const { collectionIdRef } = this;
      const { contributorUpdate } = this.state.get;
      await reelCollectionContributorContainer.updateContributorPrivileges(collectionIdRef, contributorUpdate);

      this.state.setState({ contributorUpdate: null });
    }
    finally {
      this.state.dec('busy');
    }
  }

  // ###########################################################################
  // state management + API
  // ###########################################################################

  /**
   * TODO: move this to UsersPrivateContainer
   */
  async findUser(email) {
    // see: https://stackoverflow.com/questions/52043691/firestore-orderby-security-rule
    const snap = await db.collection('usersPrivate').where('email', '==', email).limit(1).orderBy('createdAt').get();
    if (!snap.docs.length) {
      return null;
    }

    const d = snap.docs[0];
    const { id: uid } = d;
    const reelInfo = await reelInfoContainer.queryDoc(uid);

    if (!reelInfo) {
      // user exists, but no info on them
      return {};
    }

    // return the user
    // NOTE: make sure, it's shape still resembles that of `reelInfo` for duck-typing purposes
    //        (e.g. be able to use `getReelDisplayName` etc.)
    return {
      ...reelInfo,
      _id: uid,
      uid,
      email
    }
  }

  async addContributor(user, priv) {
    const { collectionIdRef } = this;
    const { uid } = user;
    return reelCollectionContributorContainer.addContributor(collectionIdRef, uid, priv);
  }

  async load() {
    // add data listener
    const { reelId } = this.collectionIdRef;

    this.state.inc('busy');

    try {
      dataHandlers.subscribeNew(
        reelCollectionsContainer.onDoc(reelId, this.render),
        reelCollectionContributorContainer.onDoc(reelId, this.initRenderContributorList)
      );

      await Promise.all([
        reelCollectionsContainer.waitForDoc(reelId),
        reelCollectionContributorContainer.waitForDoc(reelId),

        // NOTE: `queryCompleteContributorInfos` will also get user data from `reelInfoContainer`.
        //    When adding users, this query is also sent out separately, assuring that we have that user data for rendering.
        //    However, if users are added from somewhere else, this will crap out (because onDoc will call render, but the user info is not there).
        reelCollectionContributorContainer.queryCompleteContributorInfos(this.collectionIdRef)
      ]);
    }
    finally {
      this.state.dec('busy');
      this.initRenderContributorList();
    }
  }

  // ###########################################################################
  // rendering
  // ###########################################################################

  render = () => {
    const {
      busy,
      user,
      contributorUpdate,
      reelId
    } = this.state.get;

    this.$title.text(getCollectionLabel(this.collectionIdRef.collectionName, reelId));

    const $inputs = this.$cont.find('input');
    setDisabledCheckboxEl($inputs, busy);
    decorateClasses(this.$addBtn, { disabled: busy || !user });
    decorateClasses(this.$saveChangesBtn, { disabled: busy || !contributorUpdate });
  }

  initRenderContributorList = () => {
    const contributorEntries = reelCollectionContributorContainer.getCompleteContributorInfos(this.collectionIdRef);

    this.$contributorList.empty();

    if (contributorEntries === NotLoaded) {
      this.$contributorList.text('loading...');
      return;
    }

    if (!contributorEntries?.length) {
      this.$contributorList.text('(0 contributors)');
      return;
    }

    for (const { uid, priv, info } of contributorEntries) {
      const $el = cloneTemplateElement(this.$contributorList, '.contributor');
      $el.attr('data-uid', uid);

      // set text
      const $text = $el.find('span');
      $text.text(info && getReelDisplayName(info) || '(unnamed user)');

      // checkbox state
      const $checkbox = $el.find('input');
      $checkbox.attr('data-role', 'priv');
      setSelectedCheckboxEl($checkbox, priv >= ContributorPrivilege.Edit);
      $checkbox.on('change', (evt) => {
        // if (evt.target.parentNode === $deleteBtn[0]) {
        //   evt.preventDefault();
        //   evt.stopPropagation();
        //   return false;
        // }
        // else if (evt.target !== $checkbox[0]) 
        {
          // event triggers multiple times; only react once, and only if we click the text or the checkbox directly
          this.setContributorPriv(uid, !isSelectedCheckboxEl($checkbox))
        }
      });

      // delete button
      const $deleteBtn = $el.find('.delete');
      $deleteBtn.on('click', async () => {
        if (confirm('Do you really want to delete this user from the collection?')) {
          await reelCollectionContributorContainer.removeContributor(this.collectionIdRef, uid);
        }
      });

      // append
      this.$contributorList.append($el);
    }

    this.render();
  }

  clear() {
    this.$emailInput.val('');
    this.state.setState({
      user: null,
      lastSearchTerm: null
    });
  }
}

export async function openCollectionContributorModal(collectionIdRef) {
  const $cont = $('.collection-user-permissions');
  const cbs = {
    createRenderer() {
      const editor = new CollectionContributorEditor();
      return editor;
    },

    load({renderer: editor}) {
      editor.load();
    }
  }
  return initRenderModal($cont, cbs, collectionIdRef);
}