/**
 * Test iframely results on http://debug.iframely.com/
 * 
 * @file
 */

import { fetchGET } from '../../util/fetch';
import { htmlDecode } from '../../util/htmlUtil';

// Submitting links with Iframely
var iframelyLink = 'https://iframe.ly/api/iframely?url=';
var iframelyApiKey = '&api_key=4333827ef32a96a7d95447';

export function getTumbnailUrl(thumbs) {
  if (!thumbs || !thumbs.length) {
    return null;
  }

  // get thumbnail of highest resolution
  const biggestThumb = thumbs.reduce((a, x) => a.media.width > x.media.width ? a : x);
  return biggestThumb.href;

  // // [old version] get the one that is closest to width 480 (first look for the next bigger one, then for the next smaller one)
  // const sorted = thumbs.sort((a, b) => {
  //   const targetW = 480;
  //   a = a.media.width;
  //   b = b.media.width;
  //   let x = Math.abs(b - targetW) - Math.abs(a - targetW);
  //   x *= !(b >= targetW && (a < targetW)) * -1e6
  //   return x;
  // });
  // return sorted[0].href;
}

function getVideoUrl(playerArr) {
  if (!playerArr?.length) {
    return null;
  }

  // return first video
  // NOTE: data also has preferred aspect-ratio + embed `html` code
  return playerArr[0].href;
}

export async function queryIframely(originalUrl, ignoreErr = false) {
  const encodedUrl = encodeURIComponent(originalUrl);
  const iframelyUrl = iframelyLink + encodedUrl + iframelyApiKey;
  let data;
  try {
    data = await fetchGET(iframelyUrl);

    if (data.error) {
      // error handling
      const msg = data.error;
      throw new Error(msg);
    }

    // // check for author or site metadata
    // const {
    //   meta
    // } = iframelyData;
    // const whitelistedDomain = contentUrlAnalyzer.getWhitelistedDomain(canonicalUrl);
    // if (!whitelistedDomain) {
    //   // if not whitelisted -> check metadata
    //   const validator = contentUrlAnalyzer.contentMetadataValidators[contentType];
    //   if (!meta || (validator && !validator(meta))) {
    //     console.error('invalid metadata: ', iframelyData);
    //     return alert('Could not verify content integrity - metadata missing :(');
    //   }
    // }

    return normalizeResult(data, originalUrl);
  }
  catch (e) {
    if (!ignoreErr) {
      console.error('[IFRAMELY ERROR]', e, '\n  url =', iframelyUrl, '\n  data =', data);
      
      // TODO: don't alert here...
      alert('Could not fetch content: ' + e.message);
      throw e;
    }
    
    return null;
  }
}

function normalizeResult(data, originalUrl) {
  let {
    links: {
      player,
      thumbnail
    },
    meta: {
      canonical: canonicalUrl,
      title,
      description,
      author,
      author_url,
      site,
      promo,
      price,
      currency,
      date: contentDate = '' // the date at which the content was created or published
    },
  } = data;

  // clean the data: some websites send HTML-encoded titles
  title = htmlDecode(title);

  const thumbnailUrl = getTumbnailUrl(thumbnail);
  const trailerUrl = getVideoUrl(player) || promo;

  return {
    contentDate,
    title,
    description,
    author: author || site || '',
    author_url: author_url || '',
    site: site || '',   // site as provided by iframely
    thumbnail_url: thumbnailUrl || '',
    url: canonicalUrl || originalUrl,
    trailerUrl,
    promo,
    price,
    currency
  }
}