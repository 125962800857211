import tagReviews from 'src/api/tags/tagReviews';

import {
  TagActionType,
  AvailableTagActions,
  TagMaxVotes,
  TagActionPrivileges,
  TagRenderContext,
  TagActionVerb
} from 'src/api/tags/TagConfig';
import { toggleVoteTag } from 'src/api/tags/tagVoting';
import { getOrCreateToolCont } from 'src/renderUtil/devtools';

import {
  isDevMode
} from 'src/api/devtools';
import { authState } from '../../auth';
import { genRandomString } from '../../util/misc';


function addDebugTagActionButton($toolCont, contentId, tagName, tagRenderContext, tagActionType) {
  // get configuration
  const actionName = TagActionType.nameFromForce(tagActionType);

  // render button
  const label = `Vote ${TagActionVerb[actionName]}`;
  const btnSel = `debug-action-${tagActionType}-btn`;
  const $btn = $(`<button class="debug-feature ${btnSel}">${label}</button>`);

  // add click handler
  $btn.on('click', async () => {
    // just apply the vote without any extra sanity checks; use a random uid
    const { uid } = authState;
    const randomUid = `${uid}!${genRandomString(8)}`;    
    await toggleVoteTag(randomUid, contentId, tagName, tagActionType);
  });
  $toolCont.append($btn);
}


export function addTagDevTools($tagEl, contentId, tagName, tagRenderContext) {
  if (!isDevMode()) {
    return;
  }
  const [existedBefore, $toolCont] = getOrCreateToolCont($tagEl, '', '.voting-buttons-ctn');

  if (!existedBefore) {
    // add force vote buttons to tags
    for (const tagAction of TagActionType.values) {
      addDebugTagActionButton($toolCont, contentId, tagName, tagRenderContext, tagAction);
    }
  }
}