import identity from 'lodash/identity';
import reelInfoContainer from 'src/api/reel/reelInfo';
import userProfessionsContainer from 'src/api/users/reelProfessions';
import { ecmContainer as ourEcmContainer, ECMContainer } from 'src/api/ecm';
import { authState } from 'src/auth';
import GlobalConfig from 'src/GlobalConfig';
import Enum from 'src/util/Enum';
import { doesUserHaveImage } from 'src/api/reel/reelImages';
import reelCollectionsContainer from 'src/api/reel/reelCollectionContainer';
import reelHubSettingsContainer from 'src/api/reel/reelHubSettingsContainer';
import { isThisAPersonalReel, isPersonalReelId } from 'src/api/reel/reelUtil';
import { getSelectedReelId } from 'src/api/reel/reelSelection';

// ###########################################################################
// Reel public conditions
// ###########################################################################

const ReelPublicConditionType = new Enum({
  /**
   * have at least 5 tags in each E, C and M
   */
  ECMCount: 1,
  /**
   * provided profile + background picture
   */
  HasProfilePictures: 2,
  /**
   * filled out firstName, lastName, city, profession(position)
   */
  FilledOutBasicInfo: 3,
  /**
   * At least one entry in `userHubSettings`
   */
  AtLeastOneContactInfo: 4,
  /**
   * At least one column that has at least one public collection with at least 3 objects
   */
  PublicCollection: 5
});

class ReelPublicCondition {
  cb;
  /**
   * @type {ReelManager}
   */
  manager;

  constructor(reelManager, conditionType, cb) {
    this.manager = reelManager;
    this.conditionType = conditionType;
    this.cb = cb.bind(this);
  }

  get name() {
    return ReelPublicConditionType.nameFromForce(this.conditionType);
  }

  async query() {
    return this.cb();
  }
}

class ReelPublicResults {
  async queryResults(conditions) {
    this.resultsByName = Object.fromEntries(
      await Promise.all(
        conditions.map(async cond => {
          let result;
          try {
            result = await cond.query();
            console.debug(`Condition query "${cond.name}" result -`, result);
          }
          catch (err) {
            console.error(`Condition query "${cond.name}" failed -`, err);
            result = false;
          }
          return [cond.name, result];
        })
      )
    );

    return this.ok = Object.values(this.resultsByName).every(identity);
  }
}

// ###########################################################################
// ConditionCallbacks
// ###########################################################################

const ConditionCallbacks = {
  ECMCount() {
    const {
      manager: { ecmContainer }
    } = this;
    return ecmContainer.hasAtLeastNOfEachFlag(GlobalConfig.user.publicReelConditions.ecmCountPerFlag);
  },

  async HasProfilePictures() {
    const results = await Promise.all([
      await doesUserHaveImage(authState.uid, 'background'),
      await doesUserHaveImage(authState.uid, 'profile')
    ])

    return results.reduce((a, x) => a && x);
  },

  /**
   * firstName, lastName, city, profession(position)
   */
  async FilledOutBasicInfo() {
    const [userInfo, professions] = await Promise.all([
      await reelInfoContainer.queryDoc(authState.uid),
      await userProfessionsContainer.queryDoc(authState.uid)
    ]);
    return userInfo && professions &&
      // basic info filled out
      reelInfoContainer.isBasicReelInfoComplete(userInfo) &&
      // at least one profession with "position" filled out
      Object.values(professions).some(prof => prof.position) &&
      true || false;
  },

  async PublicCollection() {
    const n = 3;
    const { uid } = authState;
    return await reelCollectionsContainer.queryHasPublicCollectionWithAtLeastNEntries(uid, n);
  },

  async AtLeastOneContactInfo() {
    const { uid } = authState;
    const hubInfo = await reelHubSettingsContainer.queryDoc(uid);
    return hubInfo && Object.values(hubInfo).filter(val => !!val).length > 0;
  }
};


// ###########################################################################
// ReelManager
// ###########################################################################

class ReelManager {
  /**
   * "Their" ECM container.
   * @type {ECMContainer}
   */
  ecmContainer;

  constructor(reelId) {
    this.reelId = reelId;
  }

  // ###########################################################################
  // init
  // ###########################################################################

  initECM() {
    const ourOwnReelId = getSelectedReelId();
    const { reelId } = this;

    if (ourOwnReelId === reelId) {
      // we are already done
      this.ecmContainer = ourEcmContainer;
    }
    else {
      // start a separate container
      this.ecmContainer = new ECMContainer();
      this.ecmContainer.startECMSync(reelId);
    }
  }

  // ###########################################################################
  // basic reel management
  // ###########################################################################

  async tryAccessReel() {
    // NOTE: public reel status is determined by firestore rules.
    //  This call will throw a permission error, if accessing any user's info
    //  other than your own, that does not exist or is not `public == true`.
    return await reelInfoContainer.waitForDoc(this.reelId);
  }


  // ###########################################################################
  // reel public status checks
  // ###########################################################################

  async checkReelPublicConditions() {
    const conditions = this.conditions = Object.entries(ConditionCallbacks).map(
      ([key, cb]) => new ReelPublicCondition(
        this,
        ReelPublicConditionType.valueFromForce(key),
        cb
      )
    );
    const results = new ReelPublicResults();
    await results.queryResults(conditions);
    return results;
  }
}

export default ReelManager