import { queryIframely } from 'src/api/third-party/iframely';
import EmptyObject from 'src/util/EmptyObject';
import { fetchPOST } from 'src/util/fetch';
import pickBy from 'lodash/pickBy';

/**
 * User Context: Franco Vargas Golac
 */
const accessToken = 'yEXFVeGp4PXjtGFBJKVgtG7_OzJtNvaJZfiyXuzdmas';

/**
 * @see http://api-v2-docs.producthunt.com.s3-website-us-east-1.amazonaws.com/operation/query/ (API docs)
 * @see https://ph-graph-api-explorer.herokuapp.com/ (API explorer)
 * @see https://medium.com/@joie.software/how-to-use-the-new-product-hunt-api-in-node-js-e6e86b976609
 * 
 * Examples: sablecard.com
 */
// class ProductHuntApi {
//   // TODO: get author 
// }

export async function queryProductHunt(query) {
  const opts = {
    headers: {
      Authorization: 'Bearer ' + accessToken
    },
    mode: "cors",
  };

  const body = {
    query
  };

  // TODO: currently this generates a 400
  //    compare to: https://medium.com/@joie.software/how-to-use-the-new-product-hunt-api-in-node-js-e6e86b976609
  //                https://gist.githubusercontent.com/Jo-IE/a35448c9f87427c3c04f346158645528/raw/39ae594ff7006ad7476c9dd72ede9de798cd7186/apiRequest.js
  const res = await fetchPOST(`https://api.producthunt.com/v2/api/graphql`, body, null, opts);
  if (res.errors) {
    const n = res.errors.length;
    const msg = res.errors.
      map((err, i) => {
        const { message, ...errorDetails } = err;
        const prefix = n > 1 ? ((i + 1) + '. ') : '';
        return `${prefix}${err.message} (${JSON.stringify(errorDetails)})`;
      }).
      join('\n');
    throw new Error(`Product hunt query failed: ${msg} - \n${query}`);
  }
  return res;
}

async function queryProductPostId(url) {
  const query = `
  query {
    posts(url: "${url}", order: NEWEST) {
      edges {
        cursor
        node {
          id
          slug
        }
      }
    }
  }`;

  const result = await queryProductHunt(query);

  // extract id from data
  const products = result?.data?.posts?.edges;
  if (!products) {
    return null;
  }

  // return id of first result
  const node = products[0]?.node;
  if (!node) {
    return null;
  }

  const {
    id,
    slug
  } = node;
  return { id, slug };
}

// /**
//  * @param {*} url Example: 'https://www.producthunt.com/posts/getscreen-me'
//  */
// function getProductPostSlug(url) {
//   return url.match(/producthunt.com\/posts\/(.*)/)?.[1] || null;
// }

export async function queryProductHuntPost(url) {
  const postInfo = await queryProductPostId(url);
  // const slug = await getProductPostSlug(url);
  if (!postInfo?.slug) {
    // console.warn('could not find ProductHunt post id for url:', url);
    console.warn('invalid ProductHunt post:', url);
    return null;
  }

  const { id, slug } = postInfo;

  // const search = `id: ${JSON.stringify(id)}`;
  const search = `slug: ${JSON.stringify(slug)}`;

  // id for example: 282222 (Sablecard), 36161 (Webflow CMS)
  const query = `
query {
  post(${search}) {
    name
    tagline
    description
    url
    makers {
      id
      name
      username
      twitterUsername
    }
    media {
      videoUrl
      url
    }
    votesCount
    thumbnail {
      type
      url
    }
    website
    reviewsRating
  }
}
`;

  return await queryProductHunt(query);
}

function normalizeProductPost(post) {
  const {
    name: title,
    // tagline,
    description,
    url: postUrl,
    makers, // : { id, name, username, twitterUsername },
    media, // : { videoUrl, url },
    // votesCount,
    // thumbnail, // : { videoUrl, url }, // very small
    // website, // producthunt redirect to actual url
    // reviewsRating
  } = post;

  const thumbnail_url = media?.find(m => m.url)?.url || null;
  const trailerUrl = media?.find(m => m.videoUrl)?.videoUrl || null;

  /*

    contentDate,
    title,
    description,
    author: author || site || '',
    author_url: author_url || '',
    site: site || '',   // site as provided by iframely
    thumbnail_url: thumbnailUrl || '',
    url: canonicalUrl || originalUrl,
    trailerUrl,
    promo,
    price,
    currency
    */

  return {
    title, 
    description,
    postUrl,
    makers,
    media,
    thumbnail_url,
    trailerUrl,
    promo: trailerUrl
  }
}

// trailer, author, productHuntUrl, iosStoreUrl, googlePlayUrl, all authors' (makers) id/url/twitter etc 

/**
 * E.g. (NOTE: actual query url must be full url with "https://"): 
 * ```js
 * await queryProduct('sablecard.com')
 * ```
 */
export default async function queryProduct(url) {
  // first get it from product hunt
  url = url.trim();
  if (!url.startsWith('http')) {
    url = 'https://' + url;
  }
  let resultIframely, resultPh;
  [resultIframely, resultPh] = await Promise.all([
    queryIframely(url),
    queryProductHuntPost(url)
  ]);

  let finalResult;
  let phPost = resultPh?.data?.post;

  console.debug('Product results', resultIframely, phPost);

  if (phPost) {
    phPost = normalizeProductPost(phPost);
    phPost = pickBy(phPost, val => !!val);
    resultIframely = pickBy(resultIframely, val => !!val);
    finalResult = {
      ...phPost,
      ...resultIframely
    }

    // always prefer productHunt thumbnails
    if (phPost.thumbnail_url) {
      finalResult.thumbnail_url = phPost.thumbnail_url;
    }
  }
  else {
    finalResult = resultIframely;
  }



  // url = results.url || url;

  // 

  // get additional product hunt info
  return finalResult;
}

// [debug-global]
window.queryProduct = queryProduct;