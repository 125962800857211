// This import loads the firebase namespace.
import firebase from 'firebase/app';

// These imports load individual services into the firebase namespace.
import 'firebase/auth';
import 'firebase/firestore';
import NotLoaded from './NotLoaded';

// Initialize Firebase
var config = {
  apiKey: "AIzaSyDbysVdVaZbPRIiS55XEKfrkhcoEhVcqHc",
  authDomain: "discovereel-994a6.firebaseapp.com",
  databaseURL: "https://discovereel-994a6.firebaseio.com",
  projectId: "discovereel-994a6",
  storageBucket: "discovereel-994a6.appspot.com",
  messagingSenderId: "801544956728",
  appId: "1:801544956728:web:62238e18169cea83df2fcd"
};
firebase.initializeApp(config);

// Initialize Cloud Firestore through Firebase
const db = firebase.firestore();


// see: https://console.firebase.google.com/u/0/project/discovereel-994a6/performance/app/web:YWFiYzdkOTMtNmQ1Yy00OGMwLTlhNWMtYzYyM2JhMjdmZjcw/trends
// firebase.performance();

(async () => {
  try {
    // await db.enablePersistence();
    // db.disableNetwork();
    // console.log('db is now offline');
  }
  catch (err) {
    console.error('Firestore OFFLINE MODE not supported:', err.code, err);
    if (err.code == 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  }
})();

// db.settings();

// for debugging purposes, add as global
// [debug-global]
window.db = db;
window.firebase = firebase;


export { NotLoaded };
export default db;