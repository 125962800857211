import firebase from 'firebase/app';
import 'firebase/messaging';

let isInitialized = false;
const APIPublicKey = 'BOMledzhtvzOqQwpDZwoxuJtPadH1KXL43q303PATll5V9VVSLU-rGDkxiFPsaFhtbQiLiwoBiBybcmnzFeRtUc';

/**
 * @see https://firebase.google.com/docs/cloud-messaging/js/client
 * @see https://github.com/firebase/quickstart-js/tree/master/messaging
 */

/**
 * Implementation notes:
 * 
 * VAPID Key
 * @see https://stackoverflow.com/questions/54996206/firebase-cloud-messaging-where-to-find-public-vapid-key
 */

export function initFCM(swRegistration) {
  try {
    // TODO: somehow pass a single callback and let event ownership rest with messagingContainer
    const messaging = firebase.messaging();

    // [debug-global]
    window.messaging = messaging;   // for debugging purposes

    if (swRegistration) {
      // initialize service worker for FCM, before actually initializing it
      // see: https://config9.com/apps/firebase/firebase-change-the-location-of-the-service-worker/
      // see: https://www.fullstackfirebase.com/cloud-messaging/challenge#notes-on-sw-js-vs-firebase-messaging-sw-js
      messaging.useServiceWorker(swRegistration);
      // TODO
      //subscribePushNotifications(swRegistration);
    }
    messaging.usePublicVapidKey(APIPublicKey);

    setTimeout(async () => {
      // TODO: in v7.5.x, trying to send out a `DELETE` request which will be replied with a `500` server error - https://github.com/firebase/firebase-js-sdk/issues/2364
      // TODO: v7.6.0 and v7.6.1 are bugging out - https://github.com/firebase/firebase-js-sdk/issues/2467
      try {
        await messaging.getToken();
      }
      catch (err) {
        console.error('unable to fetch FCM token', err);
      }
    });

    // initialization worked out!
    isInitialized = true;
  }
  catch (err) {
    // not all browsers support messaging
    console.error('Could not initialize FCM:', err.message);
  }
}

export async function addFCMEventListeners(onToken, onMessage) {
  if (!isInitialized) return; // don't do anything
  const messaging = firebase.messaging();

  messaging.onTokenRefresh(async () => {
    const refreshedToken = await messaging.getToken();

    onToken(refreshedToken);
  });

  messaging.onMessage((payload) => {
    onMessage(payload);
  });

  const token = await messaging.getToken();
  onToken(token);
}

export async function unregisterFromFCM() {
  if (!isInitialized) return; // don't do anything
  const messaging = firebase.messaging();
  const currentToken = await messaging.getToken();
  return await messaging.deleteToken(currentToken);
}

// /**
//  * TODO: subscriptions
//  * https://stackoverflow.com/questions/40389335/how-to-subscribe-to-topics-with-web-browser-using-firebase-cloud-messaging
//  */
// export async function subscribe(topic) {
//   if (!isInitialized) return; // don't do anything
//   const messaging = firebase.messaging();
//   // TODO
// }