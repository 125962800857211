import { fetchGET } from '../../util/fetch';
import State from '../../util/State';
import ContentConfig from 'src/api/content/ContentConfig';
import ParallelTaskQueue from 'src/util/ParallelTaskQueue';

const Verbose = false;

// ##################################################################################################################
// Wiki API + caching
// ##################################################################################################################

// get tag data from wiki and put it in this cache for repeated use
const wikiApiCache = new State();

/**
 * Straight up get entry from cache
 */
export function getWikiEntry(tagName) {
  return wikiApiCache.get[tagName];
}

// remember all the pending requests that have been sent out, so we won't repeat any
const activeDetailRequests = {};

function startTagLoad(tagName, promise) {
  activeDetailRequests[tagName] = promise;
}

function resetTagLoadStatus(tagName) {
  activeDetailRequests[tagName] = null;
}

export function isQuerying(tagName) {
  return !!activeDetailRequests[tagName];
}

/**
 */
export async function queryDetails(tagName) {
  // console.warn('queryDetails', tagName);
  if (wikiApiCache.get[tagName]?.description) {
    return wikiApiCache.get[tagName];
  }
  let promise = activeDetailRequests[tagName];
  if (!promise) {
    promise = _queryDetails(tagName);
  }
  try {
    startTagLoad(tagName, promise);
    const result = await promise;
    // console.warn('queryDetails', tagName, result);
    return result;
  }
  finally {
    resetTagLoadStatus(tagName);
  }
}

/**
 * Uses Wiki API's TextExtract addon
 * @see https://en.wikipedia.org/w/api.php?action=help&modules=query%2Bextracts
 * @see https://stackoverflow.com/questions/8555320/is-there-a-clean-wikipedia-api-just-for-retrieve-content-summary
 * @example https://en.wikipedia.org/w/api.php?action=query&redirects=1&format=json&origin=*&prop=extracts&exintro=1&explaintext=1&titles=Dinosaur%20size&exchars=200
 */
async function _queryDetails(tagName) {
  // console.info(`Querying extract: ${tagName}...`);

  const queryString = {
    action: 'query',
    redirects: 1,
    format: 'json',
    origin: '*',
    prop: 'extracts',
    exintro: 1,
    explaintext: 1,
    exchars: ContentConfig.DescriptionLength,
    titles: tagName
  };
  const results = await fetchGET('https://en.wikipedia.org/w/api.php', queryString);

  if (results.error) {
    // wiki API layer error
    throw new Error(`${results.error}\n\n${JSON.stringify(results)}`);
  }

  const pageInfo = getFirstValueFromObject(results.query.pages);
  const {
    title,
    extract
  } = pageInfo;

  const updatedEntries = {};
  updatedEntries[tagName] = {
    // ...wikiApiCache.get[tagName],
    name: tagName,
    description: extract
  };

  if (title !== tagName) {
    Verbose && console.info(`Found redirect: ${tagName} -> ${title}`);
    updatedEntries[tagName].redirectTo = title;
    updatedEntries[title] = {
      // ...wikiApiCache.get[title],
      name: title,
      description: extract
    };
  }

  // update cache entries
  // console.debug('[_queryDetails] wikiApiCache.setState', updatedEntries);
  wikiApiCache.setState(updatedEntries);

  // return title
  return updatedEntries[tagName];
}

function normalizeResults(searchInput, results) {
  const [searchTerm, names, descriptions, links] = results;

  const normalizedResults = [];
  const updatedEntries = {};
  for (let i = 0; i < names.length; ++i) {
    const tagName = names[i];
    const description = descriptions[i];

    if (wikiApiCache.get[tagName]) {
      // don't override existing entry (might override good stuff with worse)
      normalizedResults.push(wikiApiCache.get[tagName]);
      continue;
    }

    // update cache entry
    const entry = {
      name: tagName,
      description
    };
    updatedEntries[tagName] = entry;
    normalizedResults.push(entry);
  }

  Verbose && console.debug('[cacheResults] wikiApiCache.setState', updatedEntries);
  wikiApiCache.setState(updatedEntries);

  return normalizedResults;
}


async function old_queryAllResults(searchInput, results) {
  const normalizedResults = normalizeResults(searchInput, results);
  // console.debug('added wiki entries', upd);

  // wait for all pending actions to complete
  await Promise.all(normalizedResults
    .filter(res => !res.description)
    .map(res => queryDetails(res.name))  // follow redirect (by getting extract)
  );

  return normalizedResults;
}


async function* queryAllResults(searchInput, results) {
  const normalizedResults = normalizeResults(searchInput, results);
  // console.debug('added wiki entries', upd);

  // yield initial results
  yield normalizedResults;

  // yield every time we get more results back
  yield* new ParallelTaskQueue(normalizedResults
    .filter(res => !res.description)
    .map(async res => {
      await queryDetails(res.name)  // follow redirect and get details
      // console.debug(res.name, normalizedResults);
      return normalizedResults;     // return modified results array
    })
  );

  yield normalizedResults;
}

export async function getRealTagName(tagName) {
  const tagData = await getOrQueryWikiEntry(tagName);
  return tagData?.redirectTo || tagName;
}

/**
 * Try to get from cache first, and only if it doesn't have it, send out query.
 */
export async function getOrQueryWikiEntry(tagName) {
  if (activeDetailRequests[tagName]) {
    // currently has a request pending -> wait for existing request
    await activeDetailRequests[tagName];
  }

  return _getOrQueryWikiEntry(tagName);
}

/**
 * Used internally to prevent deadlocks.
 */
async function _getOrQueryWikiEntry(tagName) {
  if (!wikiApiCache.get[tagName]) {
    // not cached -> send out new query
    // HACK: query 3 because for some single-word queries, the first match for search term is not the term itself
    //    (e.g.: "Design", "Running")

    // const limit = !tagName.includes(' ') ? 3 : 1; // get 2 results for single-word tag names
    // await queryWikiEntry(tagName, limit);
    await queryDetails(tagName);
  }

  if (!wikiApiCache.get[tagName]) {
    debugger;
  }

  // always return from cache
  return wikiApiCache.get[tagName];
}


export function addTagListener(tagName, cb) {
  return wikiApiCache.addListener(tagName, cb);
}

export function onTagUpdate(cb) {
  return wikiApiCache.onUpdate(cb);
}

/**
 * Query the wiki API using opensearch feature.
 * @see https://www.mediawiki.org/wiki/API:Opensearch
 * 
 * @see https://stackoverflow.com/questions/8555320/is-there-a-clean-wikipedia-api-just-for-retrieve-content-summary
 * @see https://www.mediawiki.org/wiki/Extension:TextExtracts#API
 * @see https://en.wikipedia.org/w/api.php?action=help&modules=query%2Bextracts
 */
export async function old_queryWikiSearch(searchInput, limit = 10, namesOnly = false) {
  Verbose && console.debug(`querying wiki: ${searchInput} (limit = ${limit}${namesOnly && ', namesOnly' || ''})`);

  const queryString = {
    action: 'opensearch',
    limit,
    format: 'json',
    origin: '*',
    search: searchInput
    // redirects: 'resolve'
  };

  try {
    const results = await fetchGET('https://en.wikipedia.org/w/api.php', queryString);

    if (results.error) {
      // wiki API layer error
      throw new Error(`${results.error}\n\n${JSON.stringify(results)}`);
    }

    if (namesOnly) {
      const [searchTerm, names, descriptions, links] = results;
      return names;
    }

    // cache result
    const promise = old_queryAllResults(searchInput, results);
    return await promise;
  }
  catch (err) {
    console.error('wiki search did not succeed :(');
    throw err;
  }
}

/**
 * Query the wiki API using opensearch feature.
 * @see https://www.mediawiki.org/wiki/API:Opensearch
 * 
 * @see https://stackoverflow.com/questions/8555320/is-there-a-clean-wikipedia-api-just-for-retrieve-content-summary
 * @see https://www.mediawiki.org/wiki/Extension:TextExtracts#API
 * @see https://en.wikipedia.org/w/api.php?action=help&modules=query%2Bextracts
 */
export async function* queryWikiSearch(searchInput, limit = 10) {
  Verbose && console.debug(`querying wiki: ${searchInput} (limit = ${limit})`);

  const queryString = {
    action: 'opensearch',
    limit,
    format: 'json',
    origin: '*',
    search: searchInput
    // redirects: 'resolve'
  };

  try {
    const results = await fetchGET('https://en.wikipedia.org/w/api.php', queryString);

    if (results.error) {
      // wiki API layer error
      throw new Error(`${results.error}\n\n${JSON.stringify(results)}`);
    }

    // normalize + query details
    yield* queryAllResults(searchInput, results);
  }
  catch (err) {
    console.error('wiki search did not succeed :(');
    throw err;
  }
}


// utility
function getFirstValueFromObject(obj) {
  for (const key in obj) {
    return obj[key];
  }
  return null;
}