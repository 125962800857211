import ReelType from 'src/api/reel/ReelType';
import { fetchGET } from 'src/util/fetch';
import { getReelType } from 'src/api/reel/reelUtil';
import { deserializeCSV } from 'src/util/csv';
import { plural as plural } from 'pluralize';
import ContentType from 'src/api/content/ContentType';

const fileNameByReelType = {
  [ReelType.Personal]: '/collection-labels-personal.csv',
  [ReelType.Brand]: '/collection-labels-brand.csv'
};

const labelCache = {};

export async function querySystemCollectionLabels() {
  await Promise.all([
    querySystemCollectionLabelsOfReelType(ReelType.Personal),
    querySystemCollectionLabelsOfReelType(ReelType.Brand)
  ]);
}

export async function querySystemCollectionLabelsOfReelType(reelType) {
  const url = fileNameByReelType[reelType];
  if (!labelCache[reelType]) {
    // fetch
    const csv = await fetchGET(url, '', null, true, {
      raw: true
    });
    
    // deserialize
    const rows = deserializeCSV(csv, (key, iRow, iCol) => iCol !== 0 ? key : 'name');

    // store label object by system collection name (first column)
    const labels = Object.fromEntries(rows.map(row => [row.name, row]));

    labelCache[reelType] = labels;
  }
  return getSystemCollectionLabels(reelType);
}

function getSystemCollectionLabels(reelType) {
  return labelCache[reelType];
}

export function getSystemCollectionLabel(collectionName, reelId, contentType) {
  const reelType = getReelType(reelId);
  const labels = getSystemCollectionLabels(reelType);
  if (!labels) {
    return collectionName;
  }
  const labelRow = labels[collectionName];
  const contentTypeName = contentType && ContentType.nameFrom(contentType) || 'Content';
  const template = labelRow?.[contentTypeName] || labelRow?.default;

  if (!template) {
    // could not look up system collection
    console.error('Unable to getSystemCollectionLabel (template not found and no default given?):', collectionName, reelId, contentType, labelRow);
    return collectionName;
  }

  // we are using this to prevent webpack name mangling (which is usually not happening for locals, but definitely for imports)
  // also prevents issues arising from tree shaking et al
  // TODO: still not sure how to make sure, `templateVariables` will never get mangled.
  const templateVariables = {
    plural
  };
  const unpackedVariables = Object.keys(templateVariables).map(v => `${v} = templateVariables.${v}`).join(', ');
  return eval('var ' + unpackedVariables + '; `' + template + '`');
}