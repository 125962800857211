/**
 * NOTE: Chrome PWAs are managed under chrome://apps
 */

import { startWebflowAnimNotResponsive } from '../renderUtil/animUtil';
import { getBrowserInfo, getBrowserParser } from '../util/browserInfo';
import { fcmUseServiceWorker } from '../api/messaging/FCM';
import { isMobile } from '../util/responsive';
import { initServiceWorkerUtilities } from '../util/serviceWorkerUtil';
import { trackAppInstall } from '../api/third-party/mixpanel';


/**
 * Bowser versioning
 * @see https://github.com/lancedikson/bowser#filtering-browsers
 * @see https://github.com/lancedikson/bowser/blob/master/src/constants.js
 * 
 * PWA browser support (install)
 * @see https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent
 */
const featureMap = (function genVersionMap() {
  const pwaWithoutCustomInstallMobile = {
    firefox: '>=44',
    safari: '>=11.3'
  };
  const pwaWithoutCustomInstallDesktop = {
    safari: '>=11.1'
  };
  return {
    // PWA support and custom installer support
    fullSupport: {
      chrome: '>=45',
      samsung_internet: '>=45',
      android: '>=45'
    },

    // `beforeInstallPrompt` is not supported
    pwaWithoutCustomInstall: {
      mobile: pwaWithoutCustomInstallMobile,
      tablet: pwaWithoutCustomInstallMobile,
      tv: pwaWithoutCustomInstallDesktop,
      desktop: pwaWithoutCustomInstallDesktop
    }
  };
})();

function getSupportedFeatures(browserParser) {
  const supportsCustomInstall = browserParser.satisfies(featureMap.fullSupport);
  const supportsPwa = supportsCustomInstall || browserParser.satisfies(featureMap.pwaWithoutCustomInstall);

  return {
    supportsCustomInstall,
    supportsPwa
  };
}

/**
 * Install using custom buttons.
 */
function addCustomInstallFeatures(installBtnsSelector) {
  let deferredPrompt; // Allows to show the install prompt

  function addClickHandler($installBtns) {
    // hook up install button logic
    $installBtns.on('click', function onInstallClicked() {
      // Show the prompt
      deferredPrompt.prompt();
      $installBtns.prop('disabled', true);
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice
        .then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('PWA setup accepted');
            // hide our user interface that shows our A2HS button
            $installBtns.addClass('hidden');
          } else {
            console.log('PWA setup rejected');
          }
          deferredPrompt = null;
        });
    });
  }

  // NOTE: this event will be triggered automatically, if all conditions are met
  //    see: https://stackoverflow.com/a/51248010
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;

    // Show the setup button
    const $installBtns = $(installBtnsSelector);
    $installBtns.removeClass('hidden');
    $installBtns.prop('disabled', false);

    addClickHandler($installBtns);
  });


  window.addEventListener('appinstalled', (evt) => {
    trackAppInstall();
  });
}

function addPwaInstallInfo(installBtnsSelector, browserInfo) {
  const $installBtns = $(installBtnsSelector);
  $installBtns.removeClass('hidden');
  $installBtns.on('click', () => {
    const browserAliases = {
      Safari: 'safari',
      Chrome: 'chrome',
      Firefox: 'firefox'
    };

    // show different info, based on browser info
    const { browser: { name }, platform } = browserInfo;
    const alias = browserAliases[name];
    const isMobile = platform.type === 'mobile' || platform.type === 'tablet';
    const platformName = isMobile ? 'mobile' : 'desktop';
    const animName = `pwa-install-howto-${alias}-${platformName}`;
    // alert(JSON.stringify(browserInfo, null, 2));
    startWebflowAnimNotResponsive(animName);
  });
}

/**
 * Is this running as PWA in stand-alone mode?
 * @see https://stackoverflow.com/questions/41742390/javascript-to-check-if-pwa-or-mobile-web
 */
export function isStandaloneMode() {
  if ((window.navigator?.standalone) || document.referrer.includes('android-app://')) {
    return true;
  }

  // check displayModes
  return ["fullscreen", "standalone", "minimal-ui"].some(
    mode => window.matchMedia('(display-mode: ' + mode + ')').matches
  );
}

/**
 * ###########################################################################
 * custom PWA install button
 * ###########################################################################
 * @see https://pwa-workshop.js.org/6-pwa-setup/
 */
function initPWAInstallButtons() {
  const browserInfo = getBrowserInfo();
  const browserParser = getBrowserParser();
  // console.log('Browser', browserInfo);
  window.__browser__ = {
    browserInfo,
    browserParser
  };

  const {
    supportsCustomInstall,
    supportsPwa
  } = getSupportedFeatures(browserParser);

  if (supportsPwa && !isStandaloneMode()) {
    // PWA is supported and not active yet
    if (supportsCustomInstall) {
      const installBtnsSelector = '.pwa-install-button,.pwa-extra-install-button';
      addCustomInstallFeatures(installBtnsSelector);
    }
    else {
      // beforeinstallprompt not supported:
      //    -> only show left-most button, but trigger anim instead of app install
      let installBtnsSelector = '.pwa-install-button';
      if (isMobile()) {
        // on mobile, we don't have a default navbar, so we want to add the column buttons anyway
        installBtnsSelector += ',.pwa-extra-install-button';
      }
      addPwaInstallInfo(installBtnsSelector, browserInfo);
    }
  }
}

export function initPWAInstall() {
  initPWAInstallButtons();
}


/**
 * ###########################################################################
 * Load service-worker
 * ###########################################################################
 */

export function initServiceWorker(onInit) {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", async () => {
      // this is not currently possible:
      // const swUrl = functionToUrl(serviceWorkerSource);
      // navigator.serviceWorker.register(swUrl);

      const swRegistration = await navigator.serviceWorker.register("/sw.js");
      onInit && onInit(swRegistration);
      initServiceWorkerUtilities(swRegistration);
    });
  }
  else {
    console.error('[ServiceWorker] browser does not have ServiceWorker support');
  }
}

// export function functionToUrl (fn){
//   const blob = new Blob([`(${fn})()`], { type: 'application/javascript' });
//   return URL.createObjectURL(blob);
// }