import firebase from 'firebase/app';
import { startWebflowAnimation } from '../renderUtil/animUtil';
import { trackUserLogin, trackUserSignup } from '../api/third-party/mixpanel';
import { formOnEnter } from '../util/domUtil';
import { authState, onAuthStateChanged, logout, onUIDChanged, forgotPassword } from '../auth';
import { getMyReelHref } from 'src/api/links';
import { redirectButKeepHistory } from 'src/util/historyUtil';


// ###########################################################################
// Login
// ###########################################################################

// user login/logout DOM elements
const loggedOutLinks = document.querySelectorAll('.logged-out');
const loggedInLinks = document.querySelectorAll('.logged-in');
const loggedInAndOutLinks = document.querySelectorAll('.logged-in-n-out');


async function doSignUp() {
  // get user info
  const $signUpCont = $('.sign-up-wrapper');
  const email = $signUpCont.find('.signup-login-field').val();
  const password = $signUpCont.find('.password-field').val();

  $signUpCont.find('.error-message').text('signing in...');

  // sign up the user & add firestore data
  try {
    const result = await firebase.auth().createUserWithEmailAndPassword(email, password);

    trackUserSignup(result.user.uid, { method: 'email' });

    // setTimeout(() => {
    //   // HACKFIX: refresh page
    //   location.reload();
    // }, 200);

    // startWebflowAnimation('close-panels');
    $signUpCont.find('.error-message').text('Login successful!');
    onUIDChanged(() => {
      const myReelPath = getMyReelHref();
      redirectButKeepHistory(myReelPath);
    }, 100);
    // const createdAt = firebase.firestore.Timestamp.fromDate(new Date());
    // await Promise.all([
    //   db.collection('users').doc(cred.user.uid).set({
    //     createdAt,
    //     role: UserRole.User,
    //     displayRole: UserRole.User,
    //     points: 0
    //   }),
    //   db.collection('usersPrivate').doc(cred.user.uid).set({
    //     createdAt,
    //     email
    //   })
    // ]);

    // // close the signup modal & reset form
    // const signUpWrapper = document.querySelector('.sign-up-wrapper');
    // signUpWrapper.style.display = 'none';
  }
  catch (err) {
    console.error(err);
    $signUpCont.find('.error-message').text(err.message);
  }
}

function getEmailText() {
  const $loginCont = $('.log-in-wrapper');
  return $loginCont.find('.signup-login-field').val();
}

async function doLogin() {
  const $loginCont = $('.log-in-wrapper');
  const email = getEmailText();
  const password = $loginCont.find('.password-field').val();

  $loginCont.find('.error-message').text('logging in...');

  try {
    const result = await firebase.auth().signInWithEmailAndPassword(email, password);

    trackUserLogin(result.user.uid, { method: 'email' });

    // setTimeout(() => {
    //   // HACKFIX: refresh page
    //   location.reload();
    // }, 200);

    $loginCont.find('.error-message').text('Login successful!');
    startWebflowAnimation('close-panels');

    // // close the signup modal & reset form
    // const signUpWrapper = document.querySelector('.sign-up-wrapper');
    // signUpWrapper.style.display = 'none';
  }
  catch (err) {
    console.error(err);
    $loginCont.find('.error-message').text(err.message);
  }
}

async function renderLoginForm() {
  // [debug-global]
  window.currentUser = authState.currentUser;
  
  if (authState.isLoggedIn()) {
    // toggle user UI elements
    loggedInLinks.forEach(item => item.style.display = 'block');
    loggedOutLinks.forEach(item => item.style.display = 'none');
    loggedInAndOutLinks.forEach(item => item.style.display = 'block');
  } else {
    // toggle user elements
    loggedInLinks.forEach(item => item.style.display = 'none');
    loggedOutLinks.forEach(item => item.style.display = 'block');
    loggedInAndOutLinks.forEach(item => item.style.display = 'block');
  }

  // show/hide moderator tools
  const { moderatorTools } = await import('src/render/moderation');
  moderatorTools.invalidateView();

  // Google Login Button
  $('.google-login').off("click").click(async () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    const result = await firebase.auth().signInWithPopup(provider);

    trackUserLogin(result.user.uid, { method: 'google' });

    // setTimeout(() => {
    //   // HACKFIX: refresh page
    //   location.reload();
    // }, 200);
    
    startWebflowAnimation('close-panels');
    moderatorTools.invalidateView();
  });

  // Email/Password Login button
  // $('.loginButton,#loginButton').off("click").click(() => {
  //   const { email, password } = getEmailAndPasswordFromForm();

  //   firebase.auth().signInWithEmailAndPassword(email, password)
  //     .then(function() {
  //       //window.location.reload();
  //     })
  //     .catch(function(error) {
  //       var errorCode = error.code;
  //       var errorMessage = error.message;
  //       console.log('Error code: ' + errorCode);
  //       console.log('Error message: ' + errorMessage);
  //       signupButton.style.display = 'block';
  //       signupError.innerText = errorMessage;
  //       signupError.style.display = 'block';
  //     });
  // });



  // Log out
  $('.btn-log-out').off("click").on('click', logout);


  // clear error messages
  $('.error-message').text('');

  // Sign up w/ email + password
  const $signUpBtn = $('.signup-btn');
  const $signUpForm = $signUpBtn.closest('form');
  formOnEnter($signUpForm.off('keydown'), doSignUp);
  $signUpBtn.off("click").on('click', doSignUp);


  // Login w/ email + password
  const $loginBtn = $('.login-btn');
  const $loginForm = $loginBtn.closest('form');
  formOnEnter($loginForm.off('keydown'), doLogin);
  $loginBtn.off('click').on('click', doLogin);


  // forgot password
  const $forgotPasswordBtn = $('.log-in-wrapper .forgot-password');
  $forgotPasswordBtn.off('click').on('click', async () => {
    let email = getEmailText();
    email = prompt('Please enter your email', email);
    if (!email) {
      return;
    }

    try {
      await forgotPassword(email);
      alert('Reset email sent. Please check your inbox.');
    }
    catch (err) {
      alert('Failed to send email - ' + err.message);
    }
  });
}


// function getEmailAndPasswordFromForm() {
//   const mobile = isMobile();
//   const emailId = mobile ? '#loginEmail2' : '#loginEmail';
//   const passwordId = mobile ? '#loginPassword2' : '#loginPassword';

//   return {
//     email: $(emailId).val(),
//     password: $(passwordId).val()
//   };
// }

export function initLoginControls() {
  // NOTE: cannot add to task queue since `onUIDChanged` will not resolve unless other tasks finish first
  renderLoginForm();
  onUIDChanged(renderLoginForm, true);

  // let promise = new Promise(resolve => {
  //   onUIDChanged(async () => {
  //     await renderLoginForm();
  //     if (promise) {
  //       promise = null;
  //       resolve();  // resolve once after first render completed
  //     }
  //   });
  // });
  // return promise;
}
