import { convertYoutubeToEmbedUrl } from 'src/api/content/contentUrlAnalyzer';

export function buildYoutubeEmbedIframe(youtubeUrl, { w, h, autoPlay, showRelatedVideos, allow, enableScripts = true }) {
  const embedUrl = convertYoutubeToEmbedUrl(youtubeUrl);
  if (!embedUrl) {
    console.error('invalid youtube url:', this.content.url);
    return null;
  }
  const scriptArgs = enableScripts ? '&enablejsapi=1&version=3&playerapiid=ytplayer' : '';
  allow = allow || 'accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture';

  return $(`<iframe style="z-index: 1000" width="${w}" height="${h}"\
frameborder="0" allow="${allow}" allowfullscreen
src="${embedUrl}?autoplay=${autoPlay}&rel=${showRelatedVideos}${scriptArgs}"></iframe>`);
}