import FirestoreContainer from '../../firebase/FirestoreContainer';

class ReelHubSettingsContainer extends FirestoreContainer {
  constructor() {
    super('userHubSettings');
    this.enableCache();
  }
}

const reelHubSettingsContainer = new ReelHubSettingsContainer();
export default reelHubSettingsContainer;