let _isCallingPostUpdate = false;

// we call this function after we have appended the given child to it's current parent
export default async function postUpdate($outerMostEl) {
  const { moderatorTools } = await import('src/render/moderation');
  if (_isCallingPostUpdate) {
    // possible recursion!
    debugger;
    console.warn("prevented recursive call to postUpdate. Make sure to not append elements as a direct result of appending elements!");
    return;
  }
  _isCallingPostUpdate = true;
  
  // TODO: only apply changes to elements inside of $outerMostEl
  // invalidate moderator tools view
  moderatorTools.invalidateView();
  
  _isCallingPostUpdate = false;
}