import EmptyObject from 'src/util/EmptyObject';

const DefaultImageMimeType = 'image/jpeg';
const DefaultImageQuality = 0.85;

// ###########################################################################
// canvas utility methods
// ###########################################################################

let canvasPool = [];
function _allocateCanvas() {
  let tempCanvas;
  if (!canvasPool.length) {
    // allocate new canvas
    console.debug('[PERF]', 'allocating new canvas for image operations');
    tempCanvas = document.createElement('canvas');
  }
  else {
    // use existing canvas
    tempCanvas = canvasPool.pop();
  }
  return tempCanvas;
}

function _releaseCanvas(tempCanvas) {
  canvasPool.push(tempCanvas);
}

/**
 * Save image on canvas to given imageEl.
 * Will override image size.
 */
function canvas2Image(canvas, imageEl, options) {
  const {
    quality = DefaultImageQuality,
    mimeType = DefaultImageMimeType
  } = options || EmptyObject;

  const url = canvas.toDataURL(mimeType, quality);

  imageEl.src = url;
  imageEl.width = canvas.width;
  imageEl.height = canvas.height;
}

function _image2Canvas(imageEl) {
  const tempCanvas = _allocateCanvas();
  tempCanvas.width = imageEl.width;
  tempCanvas.height = imageEl.height;

  const context = tempCanvas.getContext('2d');
  context.drawImage(imageEl, 0, 0);

  return tempCanvas;
}

// ###########################################################################
// core
// ###########################################################################

export async function canvas2File(canvas, options) {
  const {
    quality = DefaultImageQuality,
    mimeType = DefaultImageMimeType
  } = options || EmptyObject;

  return new Promise(r => canvas.toBlob(blob => {
    const file = new File([blob], "_canvas2file_.jpg", { type: "application/octet-stream" });

    r(file);

  }, mimeType, quality));
}

export async function getImageSizeFromCanvas(canvas, options = null) {
  const file = await canvas2File(canvas, options);
  return file.size;
}

export async function getImageFileSize(image, options = null) {
  const tempCanvas = _image2Canvas(image);
  try {
    return getImageSizeFromCanvas(tempCanvas, options);
  }
  finally {
    _releaseCanvas(tempCanvas);
  }
}

/**
 * @see https://stackoverflow.com/questions/8647305/preload-background-image
 */
export async function preloadImage(url) {
  var $img = $('<img src="' + url + '">');
  return new Promise((r, j) => {
    $img.on('load', () => {
      r(true);
    });
    $img.on('error', (evt) => {
      console.error(`[${evt.type}]`, 'failed to load image at', url);
      r(false);
    });
    if ($img[0].width) { $img.trigger('load'); }
  });
}