import shortcuts, { initShortcuts } from '../api/shortcuts';
import { showNotLoggedInUserActionWarning } from '../renderUtil/userActions';
import { cloneTemplateElement, decorateClasses, prepareTemplateElement } from '../util/domUtil';
import { startWebflowAnimation } from '../renderUtil/animUtil';
import { getWorldLink } from '../api/worlds';
import EmptyArray from '../util/EmptyArray';
import { getCurrentPageTag } from '../pages';

let $shortcutsList;

class ShortcutRenderer {
  initRender() {
    // prepare list
    $shortcutsList = $('.shortcuts-ctn');

    if ($shortcutsList.length) {
      prepareTemplateElement($shortcutsList, '.shortcut-button');
    }

    // go!
    this.populateShortcuts();
    this.renderAddShortcutBtn();
  }

  populateShortcuts() {
    if (!$shortcutsList.length) {
      // this page has no shortcuts
      return;
    }

    shortcuts.onUpdate(() => {
      // TODO: not efficient
      $shortcutsList.empty();

      const shortcutDocs = (shortcuts.getAllNotNull() || EmptyArray);
      shortcutDocs.sort((a, b) => a.num - b.num);
      for (const doc of shortcutDocs) {
        // prepare elements
        const $shortcutEl = cloneTemplateElement($shortcutsList, '.shortcut-button');
        $shortcutEl.removeClass('display-none');
        const $colorEl = $shortcutEl.find('.shortcut-color');
        const $textEl = $shortcutEl.find('.shortcut-text');

        // populate data
        const targetLink = doc.link || doc.tag && getWorldLink(doc.tag) || '';
        if (!targetLink) {
          console.error('invalid shortcut doc', doc);
        }
        $shortcutEl.attr('href', targetLink);
        $shortcutEl.on('click', () => {
          startWebflowAnimation('loader-shortcut');
        });
        $colorEl.attr('style', doc.style);
        $textEl.text(doc.shortName);

        $shortcutsList.append($shortcutEl);
      }
    });
  }

  renderAddShortcutBtn() {
    const $addBtn = this.$addBtn = $('.world-description-container .add-shortcut-button');

    if (!$addBtn.length) {
      // not all pages have this button
      return;
    }

    shortcuts.onUpdate(this.decorateAddButton);

    $addBtn.on('click', () => {
      if (showNotLoggedInUserActionWarning()) {
        return;
      }

      const tag = getCurrentPageTag();
      const style = $addBtn.attr('style');
      const link = window.location.href;

      if (!shortcuts.hasLoaded()) {
        // don't do anything
        return;
      }

      if (!shortcuts.getShortcutIdOfTag(tag) && shortcuts.hasMaxShortcuts()) {
        // if (!window.confirm("Adding more than 3 shortcuts is for premium subscribers only. Do you want to delete the oldest?")) {
        if (!window.confirm(`Cannot add more than ${shortcuts.getMaxShortcutCount()} shortcuts. Do you want to replace the oldest?`)) {
          return;
        }
      }

      shortcuts.toggleShortcut(tag, style, link);
    });
  }

  decorateAddButton = () => {
    const tag = getCurrentPageTag();

    const $addBtn = this.$addBtn;
    const $img = $addBtn.find('img');

    decorateClasses($img, {
      'close-image': shortcuts.hasTag(tag)
    });
  }
}

const shortcutRenderer = new ShortcutRenderer();


export function initRenderShortcuts() {
  // init ShortcutsContainer
  initShortcuts();

  shortcutRenderer.initRender();

  return shortcuts.waitForCurrentPage();
}

export default shortcutRenderer;