import firebase from 'firebase/app';
import FirestoreContainer from '../../firebase/FirestoreContainer';
import { NotLoaded } from 'src/db';
import { isPersonalReelId } from 'src/api/reel/reelUtil';
import { authState } from 'src/auth';

/**
 * NOTE: for brand reel, `lastName` is actually "organizationType"
 * @typedef {{
 *  firstName: string,
 *  lastName: string,
 * }} ReelInfo
 */

class ReelInfoContainer extends FirestoreContainer {
  constructor() {
    // TODO: fix this name (migration)
    // TODO: When changed here, also need to update things in collectionNameByContentType
    super('userInfo');
    this.enableCache();
  }

  // ###########################################################################
  // queries
  // ###########################################################################

  async queryReel(reelId) {
    return this.queryDoc(reelId);
  }

  async queryReelName(reelId) {
    await this.queryDoc(reelId);
    return this.getReelName(reelId);
  }

  async queryReelShortName(reelId) {
    await this.queryDoc(reelId);
    return this.getReelShortName(reelId);
  }

  async queryReelsOfUser(uid) {
    // NOTE: we cannot currently use one container to maintain multiple queries
    // hackfix: rought heuristics to determine whether we have queried given user before and clear/query correspondingly
    const queryArgs = this.getQueryArgs();
    const hasUserInfo = queryArgs?.where?.includes(uid);
    if (!hasUserInfo) {
      if (this._started) {
        // need to clear
        this.clear();
      }
      await this.where('uid', '==', uid);
    }
    else {
      await this.waitForCurrentPage();
    }

    return this.getReelsOfUser(uid);
  }

  // ###########################################################################
  // getters
  // ###########################################################################


  getReelName(reelId) {
    const reelInfo = this.getDocById(reelId);

    if (reelInfo === NotLoaded) {
      return NotLoaded;
    }
    return getReelDisplayName(reelInfo);
  }

  getReelShortName(reelId) {
    const reelInfo = this.getDocById(reelId);

    if (reelInfo === NotLoaded) {
      return NotLoaded;
    }
    return getReelShortDisplayName(reelInfo);
  }

  isBasicReelInfoComplete({ firstName, lastName, place }) {
    return !!firstName && !!lastName && !!place;
  }

  getReelsOfUser(uid) {
    const all = this.getAllNotNull();
    return all.filter(reelInfo => reelInfo.uid === uid);
  }

  // ###########################################################################
  // actions
  // ###########################################################################

  async saveReelInfo(reelId, reelInfo) {
    reelInfo.updatedAt = firebase.firestore.Timestamp.fromDate(new Date());
    reelInfo.createdAt = reelInfo.createdAt || reelInfo.updatedAt;
    return this.setDoc(reelId, reelInfo);
  }
}

const reelInfoContainer = new ReelInfoContainer();
export default reelInfoContainer;

export function getReelDisplayName(reelInfo) {
  if (!reelInfo) {
    return `(unnamed reel)`;
  }

  const reelId = reelInfo._id;

  if (isPersonalReelId(reelId)) {
    // personal reel
    return [reelInfo.firstName, reelInfo.lastName].filter(n => !!n).join(' ');
  }

  // brand reel
  return reelInfo.firstName;
}

export function getReelShortDisplayName(reelInfo) {
  if (!reelInfo) {
    return `(unnamed reel)`;
  }

  const reelId = reelInfo._id;

  if (isPersonalReelId(reelId)) {
    // personal reel
    return [reelInfo.firstName, reelInfo.lastName && (reelInfo.lastName[0] + '.')].filter(n => !!n).join(' ');
  }

  // brand reel
  return reelInfo.firstName;
}

export function getVeryShortReelName(reelInfo) {
  const displayName = getReelDisplayName(reelInfo);
  return displayName.substring(0, 3);
}

export function getReelUrl(reelId) {
  return `/reel/${reelId}`;
}