import defaultsDeep from 'lodash/defaultsDeep';
import firebase from 'firebase/app';

import db, { NotLoaded } from 'src/db';
import { authState } from 'src/auth';
import { getPageName } from 'src/pages';
import ContentFilters from 'src/api/content/ContentFilters';
import { DefaultContentQueryInterface } from 'src/api/content/ContentQueryInterface';

import FirestoreContainer from '../../firebase/FirestoreContainer';
import FirestoreQueryInterface from '../../firebase/FirestoreContainerCollection';
import { docToSimpleObject } from '../../firebase/firebaseUtil';
import { extractURLDomain } from '../../util/urlUtil';

import { ModerationStatus } from './ContentActionConfig';
import ContentType, { getCollectionNameByContentType, EnabledContentTypes } from './ContentType';
import { allContentOrders, defaultContentOrdersByPage, pageWhereArgs } from './contentObjectsConfig';
import EmptyArray from 'src/util/EmptyArray';

// data-content-type="Video"

const ContentTypeNamesById = {};
for (const name in ContentType) {
  ContentTypeNamesById[ContentType[name]] = name;
}

export function getContentTypeName(contentType) {
  return ContentTypeNamesById[contentType];
}



// ##################################################################################################################
// Content manager (exists only once for the entire website)
// ##################################################################################################################

export const contentCollection = db.collection('videos');
export function getContentDoc(videoId) {
  if (!videoId) {
    throw new Error('cannot `getContentDoc`, missing videoId');
  }
  return contentCollection.doc(videoId);
}

/**
 * @type {ContentObjectContainer[]}
 */
const allContentObjectContainers = [];

// HACK: an easy way to store content data that we have no listener on.
//  This is used for content items that we need but are not rendered to the content column.
const contentUnlistedCache = {};

export function getContentById(contentId) {
  // just go through all caches...
  // TODO: improve this process
  if (contentUnlistedCache[contentId]) {
    return contentUnlistedCache[contentId];
  }

  for (const cache of allContentObjectContainers) {
    const content = cache.getDocById(contentId);
    if (content !== NotLoaded) {
      return content;
    }
  }
  return NotLoaded;
}

/**
 * NOTE: does not put object into shared cache 
 *  (as that would render it in the content column)
 */
export async function getOrQueryContentByUrl(contentType, url) {
  // TODO: fix this total mess (possibly consider using ContainerCollection)
  let doc = Object.values(contentUnlistedCache).find(
    entry => entry.url === url && entry.contentType === contentType
  );
  if (doc) {
    // TODO: we are currently unable to cache misses - definitely want to fix up this mess!!
    return doc;
  }

  const snap = await contentCollection.where('url', '==', url).where('type', '==', contentType).limit(1).get();
  doc = snap.docs && snap.docs[0] || null;

  // hackfix: store doc in unlisted cache
  return doc && setContentUnlistedCache(doc.id, doc.data());
}

export function setContentUnlistedCache(contentId, docData) {
  return contentUnlistedCache[contentId] = docToSimpleObject(contentId, docData);
}

export async function setDocUnlisted(contentId, docData, merge = false) {
  // NOTE: we don't use `setDoc` here as that might add this item to a currently rendering content column, even though we don't want that
  // TODO: we definitely want to improve this extremely messy mess of a mess that is messy :*(

  await contentCollection.doc(contentId).set(docData, { merge });

  if (!merge) {
    setContentUnlistedCache(contentId, docData);
  }
  else if (contentUnlistedCache[contentId]) {
    // already cached -> merge into it
    Object.assign(contentUnlistedCache[contentId], docData);
  }

  return contentUnlistedCache[contentId] || docToSimpleObject(contentId, docData);
}

// ##################################################################################################################
// Content cache (exists once per column)
// ##################################################################################################################

/**
 * 
 */
export class ContentObjectContainer extends FirestoreContainer {
  _listenerUnsubscribeCallbacks = [];
  _trackedDocs = {};
  _ignoredIds = {};

  constructor(contentType, config) {
    super(getCollectionNameByContentType(contentType));

    this.enableCache();

    this.contentType = contentType;

    Object.assign(this, config);
    this.config = config;

    this.firstPageItemCount = this.firstPageItemCount || this.defaultPageItemCount;

    if (!this.defaultPageItemCount) {
      throw new Error('defaultPageItemCount not set (usually provided via config)');
    }
    if (!this.firstPageItemCount) {
      throw new Error('firstPageItemCount not set (usually provided via config)');
    }

    allContentObjectContainers.push(this);

    this.setDefaultContentOrder();

    this.filters = new ContentFilters(this);
    this.filters.onFilterChanged(this._handleFilterChanged);
    this.filters.setDefaultController();
  }

  // ##########################################
  // getters
  // ##########################################

  getDebugTag() {
    return `[Content ${ContentType.nameFrom(this.contentType)}]`;
  }


  isReadyToGo() {
    return this.started || this._waitingToStart;
  }

  _getFilteredDocs() {
    // TODO: we shouldn't need this anymore. We take care of filtering in `query` phase already.
    const objects = this.getAllNotNull();
    if (this._queryInterface.filterArray) {
      return this._queryInterface.filterArray(objects);
    }
    return objects;
  }

  getRenderCount() {
    return this.getRenderDocs()?.length;
  }

  // contentCache.getCurrentDocs().map(d => d.data().type)
  /**
   * This is an array of all docs to be rendered in column of corresponding `contentType`:
   * (i) all items of all currently loaded pages with
   * (ii) ignored items removed and 
   * (iii) explicitely tracked items added
   */
  getRenderDocs() {
    if (!this.hasLoaded()) {
      return NotLoaded;
    }
    if (!this._columnDocsArray) {
      // re-build cached array of docs
      let objects = this._getFilteredDocs();

      // remove ignored docs
      objects = objects.filter(doc => !this._ignoredIds[doc._id]);

      // add speficially tracked docs
      const trackedObjects = Object.values(this._trackedDocs).map(d => docToSimpleObject(d));
      objects = objects.concat(trackedObjects);

      // cache, so we don't have to do all this work on every render call
      this._columnDocsArray = objects;

      // sort
      this._onOrderUpdate();
    }
    return this._columnDocsArray;
  }

  shouldTrackDocsAddedByMe() {
    return this.getActiveFilterName() === 'notReviewed';
    // return true;
    // return !this.getActiveFilterName() || this.getActiveFilterName() === 'notReviewed';
  }

  getActiveFilterName() {
    return this.filters.name;
  }

  // ###########################################################################
  // create, update, delete
  // ###########################################################################

  // Saving new content item to Firestore
  addContentObject = async (data) => {
    //var emailSubmitted = document.getElementById('emailSubmitted').value;
    const uid = authState.uid;
    const { contentType } = this;

    if (!uid) {
      alert('you must be logged in to save anything :(');
      return;
    }

    // we can display domain, if author or site metadata are not provided by query library

    const tags = [];
    // const tag = getCurrentWorldTag();
    // if (tag) {
    //   tags.push(tag);
    // }

    // prepare data to be sent to database
    const domain = extractURLDomain(data.url);
    const moderationStatus = data.moderationStatus || ModerationStatus.NotReviewed;
    const newDocData = {
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      statusChangedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      uid,
      type: contentType,
      //email: emailSubmitted,
      tags,
      moderationStatus,

      // NOTE: we need to add a `private` prop for queries that should ignore private objects
      private: moderationStatus === ModerationStatus.Private,
      domain,

      ...data
    };

    // Firestore errors out on `undefined` values, so fix them up
    for (const key in newDocData) {
      if (newDocData[key] === undefined) {
        newDocData[key] = null;
      }
    }


    // add to content collection
    try {
      console.log('contentObjects', 'adding', contentType, newDocData);

      // track new doc
      const newRef = this.collection.doc(); // create new Doc
      const contentId = newRef.id;
      if (this.shouldTrackDocsAddedByMe()) {
        // start tracking explicitely added doc
        this.startTrackingDoc(contentId);
      }

      // save to DB
      const newDoc = await setDocUnlisted(contentId, newDocData);

      console.log('contentObjects', 'added', newDoc);

      return newDoc;
    }
    catch (err) {
      alert("could not store video :( --\n" + err.message)
      console.error("Error writing content object: ", err);
    }
  }


  // just clear everything
  clear() {
    this._trackedDocs = {};
    this._ignoredIds = {};

    this._columnDocsArray = null; // invalidate

    super.clear();
  }

  // ###########################################################################
  // queries
  // ###########################################################################

  onBeforePageUpdateCb(_this, iPage, snapshot, isOverride, isFirstTimeForThisPage) {
    this._perfLog('onBeforePageUpdateCb (onSnapshot)', iPage, isFirstTimeForThisPage);

    // const previousEntries = this._columnDocsArray;
    this._columnDocsArray = null; // invalidate 

    if (snapshot) {
      // snapshot is not given if this is triggered from an override or cache load
      let changes = snapshot.docChanges().map(({ type, doc }) => {
        if (!doc.createdAt) {
          //doc.createdAt = { seconds: 0 };
        }
        return { type, doc };
      });

      // if (isFirstTimeForThisPage && previousEntries) {
      //   // loaded from database after having already loaded from cache
      //   //    -> handle the case where documents were deleted since last cache (`removed` change would not exist in that case)
      //   const removed = previousEntries.filter(entry => !snapshot.docs?.find(entry._id));
      //   changes.push();
      // }

      // console.log(`onBeforePageUpdateCb: ${changes.length} items changed (type = ${this.contentType})`);

      //changes.sort(({ type: ta, doc: a }, { type: tb, doc: b }) => activeContentOrderFn(a, b))
      changes.forEach(({ type, doc }) => {
        if (type == 'added') {
          // when added back to main snapshot:
          // (1) don't show newly added items dynamically
          // (2) stop tacking individually tracked docs
          !isFirstTimeForThisPage && this._ignoreNewDoc(doc.id);
        }
        else if (type == 'removed') {
          //getContentEl(doc.id).remove();    // remove element

          // NOTE: "removed" does not mean "deleted from database"
          // When an item is removed it usually means that it does not fit 
          // the currently selected filter anymore, so the data is now stale 
          // (`onSnapshot` listeners will not provide this data anymore).
          // However, at the same time, we also want to keep some of the videos visible 
          // (e.g. when publishing a video, we still want to see it, even though it does not match the filter anymore).
          if (!this._ignoredIds[doc.id]) {
            // keep tracking it (if not already ignored)
            this.startTrackingDoc(doc.id);
          }
        }
        //else { // modified
        //console.warn(type, doc.data());
        //}
      });
    }

    // call callback
    this.notifyContentChanged(snapshot);
  }

  notifyContentChanged = () => {
    this.emitter.emit('update');
  }

  _queryWhereThisContentType = query => {
    return query.where();
  }

  _buildQueryArgs() {
    const filterController = this.filters.controller;
    const args = {
      where: [
        'type', '==', this.contentType,
        ...this.filters.where,
        
        // filters by page
        ...(pageWhereArgs[getPageName()](this) || EmptyArray),

        // filters by in-page filter
        ...(this.filters.getWhereArgs()(this) || EmptyArray)
      ],
      orderBy: [
        ...this.activeContentOrder.queryDecorator(this)
      ],
      limit: filterController?.limit?.() || this.firstPageItemCount
    };
    return args;
  }

  startListenContent = async function startListenContent() {
    this._waitingToStart = true;
    if (!this.filters.isAnyFilterSelected()) {
      // wait until filter is selected
      // defers loading of column to page controller
      // (currently only used on `reel` page)
      return;
    }

    // stop listening if we were listening before
    for (const listener of this._listenerUnsubscribeCallbacks) {
      listener();
    }
    this.activeContentListener = [];

    // clear cache + clear content columns
    this.clear();

    // re-subscribe to ECM changes
    // if (this.ecmUnsubscribe) {
    //   this.ecmUnsubscribe();  // unsubscribe from ECM changes
    // }
    // this.ecmUnsubscribe = ecmContainer.onUpdate(() => {
    //   // re-render when ECM data changed -> affects renderers
    //   this.notifyContentChanged();
    // });

    if (!pageWhereArgs[getPageName()]) {
      console.error('WARNING: unknown page - rendering might fail', getPageName());
      //alert('404 - unknown page - cannot render content');
    }

    // send out query
    const queryArgs = this._buildQueryArgs();


    // console.debug('started loading', this.getDebugTag(), queryArgs);

    // start loading
    const result = await this._queryInterface.query(queryArgs);

    // console.debug('1st page', this.getDebugTag(), queryArgs, result);

    // finished loading

    return result;
  }.bind(this);

  // ###########################################################################
  // custom query interface: Allow for indirect queries + paging
  // ###########################################################################

  _buildQueryInterface() {
    const iface = this.filters.controller.buildQueryInterface?.(this);
    if (iface) {
      // filter provides custom query interface
      this._queryInterface = iface;
    }
    else {
      // default -> directly work against `videos` collection
      this._queryInterface = new DefaultContentQueryInterface(this, {
        query: super.query.bind(this),
        loadNextPage: super.loadNextPage.bind(this),

        getLoadedPageCount: super.getLoadedPageCount.bind(this),
        hasLoadedPage: super.hasLoadedPage.bind(this),
        hasReachedLastPage: super.hasReachedLastPage.bind(this),
        filterArray: x => x,
        getSortable(contentObject) {
          // return object to use for sort (activeOrder) function
          return contentObject;
        }
      });
    }
  }

  getLoadedPageCount() {
    return this._queryInterface.getLoadedPageCount();
  }

  hasLoadedPage(iPage) {
    return this._queryInterface.hasLoadedPage(iPage);
  }

  /**
   * Also account for possile indirect queries coming from highly customized filters.
   * 
   * @override
   */
  hasReachedLastPage() {
    return this._queryInterface.hasReachedLastPage();
  }

  /**
   * 
   * @override
   */
  async loadNextPage(queryArgsOverride) {
    // apply default parameters
    defaultsDeep(queryArgsOverride, {
      limit: this.defaultPageItemCount
    });

    return this._queryInterface.loadNextPage(queryArgsOverride);
  }

  // ###########################################################################
  // tracking + ignoring of individual docs
  // NOTE: sometimes, videos are not matching our current filter, so we have to track it explicitely since firestore will not keep us up-to-date anymore
  // ###########################################################################

  /**
   * Called on docs of given id when added after the fact to fix it's bookkeeping.
   */
  _ignoreNewDoc(id) {
    // stop tracking (because it is already tracked with the current filter)
    if (!this.stopTrackingDoc(id)) {
      // if was not tracked -> ignore! (we don't want to show any new docs)
      this._ignoredIds[id] = true;
    }
  }

  startTrackingDoc(docId) {
    // sync with DB
    contentCollection.doc(docId).onSnapshot(doc => {
      // if (!doc.exists) {
      //   // doc got removed
      //   this.stopTrackingDoc(id);
      // }
      // else {
      if (doc.exists) {
        // updated doc
        this._trackedDocs[docId] = doc;
        delete this._ignoredIds[docId];
      }
      else {
        // deleted doc
        this._trackedDocs[docId] = null;
      }

      this._columnDocsArray = null;
      this.docsById[docId] = doc;

      this.notifyContentChanged(doc);
    });
  }

  /**
   * @returns {Boolean} Whether the document of given id was tracked.
   */
  stopTrackingDoc(id) {
    if (this._trackedDocs[id]) {
      delete this._trackedDocs[id];

      this._columnDocsArray = null; // invalidate
      this.docsById = null;
      return true;
    }
    return false;
  }

  // ###########################################################################
  // filtering
  // ###########################################################################

  /**
   * Query all data again.
   * Probably triggered because filter settings have changed.
   */
  refreshFilter() {
    this._handleFilterChanged();
  }

  _handleFilterChanged = () => {
    // instantiate query interface
    this._buildQueryInterface();

    // after changing filter => load data again
    if (this.isReadyToGo()) {
      // change comparer + re-render (if any load has been requested before)
      this.startListenContent(this.contentType);
    }
  }


  // ###########################################################################
  // ordering
  // ###########################################################################

  setActiveContentOrder(newName) {
    if (!allContentOrders[newName]) {
      throw new Error('invalid compare function name: ' + newName);
    }

    if (this.activeContentOrderName === newName) {
      // don't try to do this if already set
      return;
    }

    // change to new content order
    this.activeContentOrderName = newName;
    this.activeContentOrderCmp = (contentObjectA, contentObjectB) => {
      const a = this._queryInterface.getSortable(contentObjectA);
      const b = this._queryInterface.getSortable(contentObjectB);
      if (!a || !b) {
        console.error('could not get sortable object for contentObject when comparing',
          { a: contentObjectA, b: contentObjectB });
        return 0;
      }
      return this.activeContentOrder.cmp(a, b);
    };
    this.activeContentOrder = allContentOrders[newName];

    // sort docs again
    this._onOrderUpdate();

    // change comparer + re-render!
    // TODO: don't query again right away, but schedule it for later, so we can have multiple updates with only a single query
    this.notifyContentChanged();
  }

  setDefaultContentOrder() {
    const defaultName = defaultContentOrdersByPage[getPageName()] || 'mostRecentFirst';
    this.setActiveContentOrder(defaultName);
  }

  getActiveContentOrder() {
    return this.activeContentOrderName;
  }

  /**
   * Return the function we are currently using for sorting content.
   */
  getActiveContentOrderFn() {
    return this.activeContentOrderCmp;
  }

  _onOrderUpdate() {
    // sort
    this._columnDocsArray && this._columnDocsArray.sort(this.getActiveContentOrderFn());
  }


  // ###########################################################################
  // moderator tools
  // ###########################################################################

  setModerationStatus(contentId, moderationStatus) {
    return setDocUnlisted(contentId, {
      moderationStatus,
      private: moderationStatus === ModerationStatus.Private
    }, true);
  }

  // ###########################################################################
  // utilities
  // ###########################################################################

  /**
   * Performance logging
   */
  _perfLog(...args) {
    // noop for now
    //if (this.contentType === ContentType.Video) { //  for video column only
    // perfLog(ContentType.nameFrom(this.contentType), ...args);
    //}
  }
}


// ##################################################################################################################
// Content by tag
// ##################################################################################################################

class ContentContainerSimple extends FirestoreContainer {
  constructor() {
    super('videos');
  }
}

let _contentByTag;

/**
 * @returns {FirestoreQueryInterface}
 */
function contentByTag() {
  if (!_contentByTag) {
    _contentByTag = new FirestoreQueryInterface();
  }
  return _contentByTag;
}

/**
 * Check if there is at least one content item that is published and has the given tag.
 */
export async function queryHasContentWithTag(tag) {
  const cont = contentByTag().getContainer(tag);
  if (!cont) {
    const queryArgs = {
      limit: 1,
      where: [
        'tags', 'array-contains', tag,
        'moderationStatus', '==', ModerationStatus.ReviewedPublished
      ]
    };
    await contentByTag().addContainerAndQuery(ContentContainerSimple, queryArgs, tag);
  }
  else {
    // already sent out query -> wait for result
    await cont.waitForCurrentPage();
  }

  // return whether we have at least one content item matching the tag
  return hasContentWithTag(tag);
}

/**
 * Return tag situation immediately.
 * @throws {Error} if `queryHasContentWithTag` has not called before on given tag.
 */
export function hasContentWithTag(tag) {
  const cont = contentByTag().getContainer(tag);
  if (!cont?.hasLoaded()) {
    return NotLoaded;
  }

  // check if at least one content item is in the container
  return !!cont.count();
}

// ##################################################################################################################
// Globals + exports
// ##################################################################################################################

/**
 * @type {ContentObjectContainer[]}
 */
const contentObjectsByType = {};

export function getContentObjectsByType(contentType) {
  if (!contentObjectsByType[contentType]) {
    throw new Error('contentType not enabled: ' + contentType);
  }
  return contentObjectsByType[contentType];
}


export function initContent(config) {
  EnabledContentTypes.forEach(contentType => {
    contentObjectsByType[contentType] = new ContentObjectContainer(contentType, config);
  });
}

// [debug-global]
window.addContent = async function (contentType, content) {
  const contentObjectContainer = getContentObjectsByType(contentType);
  content.uid = authState.uid;
  return await contentObjectContainer.addContentObject(content);
};
window.deleteContent = async function (id) {
  return db.collection('videos').doc(id).delete();
}