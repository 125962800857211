// see: https://github.com/dimsemenov/Magnific-Popup/tree/master/dist
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup/dist/jquery.magnific-popup';

// see: https://github.com/KingSora/OverlayScrollbars/blob/master/package.json#L13
import 'overlayscrollbars/css/OverlayScrollbars.css';
import OverlayScrollbars from 'overlayscrollbars';
import { notifyPopupOpen, notifyPopupClosed } from 'src/renderUtil/popups';


let magnificPopupPromise;

export function initRenderThirdParty() {
  magnificPopupPromise = initMagnificPopup();
  initOverlayScrollbars();
}

export async function waitForMagnificPopup() {
  await magnificPopupPromise;
}


/**
 * Add some defaults
 * 
 * @see https://github.com/dimsemenov/Magnific-Popup/blob/c8f6b8549ebff2306c5f1179c9d112308185fe2c/dist/jquery.magnific-popup.js#L847
 */
async function initMagnificPopup() {
  // NOTE: magnific popup depends on jquery, and jquery might not be loaded initially; so we load it later.
  // await import('magnific-popup/dist/jquery.magnific-popup');

  // add support for "youtu.be" urls
  $.magnificPopup.defaults.iframe.patterns.youtube2 = {
    index: 'youtu.be',
    id: '/',
    src: '//www.youtube.com/embed/%id%?autoplay=1'
  };

  // add magnific to all `.video` elements
  $('.video').magnificPopup({
    type: 'iframe'
  });

  // add open + close hooks
  const open = $.magnificPopup.proto.open;
  $.magnificPopup.proto.open = function _open(...args) {
    notifyPopupOpen(this);
    return open.apply(this, args);
  };

  const close = $.magnificPopup.proto.close;
  $.magnificPopup.proto.close = function _close(...args) {
    const res = close.apply(this, args);
    notifyPopupClosed(this);
    return res;
  };
}

function initOverlayScrollbars() {
  /*
  localStorage.setItem('__no_OverlayScrollbars', 1)
  */
  if (!localStorage.getItem('__no_OverlayScrollbars')) {
    OverlayScrollbars(document.querySelectorAll(".new-overlay"), {});
  }
}