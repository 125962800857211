import ContentCollectionEditorTabView from 'src/render/collections/ContentCollectionEditor';
import { startWebflowAnimation } from 'src/renderUtil/animUtil';

let singleton;

/**
 * Popup to add contentObject to your own content collections.
 */
class ContentCollectionModal {
  constructor($modal, content) {
    singleton = this;
    this.$modal = $modal;
    this.$submitBtn = $modal.find('.submit-button');

    this.content = content;
    this.contentCollectionEditors = new ContentCollectionEditorTabView($modal);
    this.submitted = false;

    this.$submitBtn.off('click').on('click', async evt => {
      await this.contentCollectionEditors.editor.saveCollections(content);
      this.submitted = true;
      $modal.hide();
    });
  }
}

const collectionModalName = 'collection-popup-video'; // + contentTypeName;

export function initRenderContentCollectionModal(contentType, content) {
  const $modal = $('.' + collectionModalName);
  const modal = new ContentCollectionModal($modal, content);
  modal.contentCollectionEditors.initRender(contentType, content);  // load collection entries
  return modal;
}

export function showContentCollectionModal() {
  startWebflowAnimation(collectionModalName);
}

/**
 * @returns {bool} Whether modal was submitted [true], or closed/cancelled [false]
 */
export async function showAndWaitForContentCollectionModal() {
  showContentCollectionModal();

  const modal = singleton;

  // hackfix: make sure, we always know when the modal closes
  // TODO: this can f up if someone manages to close + open the modal between two intervals
  return new Promise(resolve => {
    let timer = setInterval(() => {
      const { $modal } = modal;
      if (!$modal.is(':visible')) {
        clearInterval(timer);
        resolve(modal.submitted);
      }
    }, 50);
  });
}