import Enum from 'src/util/Enum';

const ReelTypeObj = {
  Personal: 1,
  Brand: 2
};

/**
 * @type {ReelTypeObj | Enum}
 */
const ReelType = new Enum(ReelTypeObj);

export default ReelType;