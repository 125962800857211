import { selectTextOnClick, prepareTemplateElement, cloneTemplateElement } from '../util/domUtil';
import postUpdate from '../postUpdate';
import EmptyObject from 'src/util/EmptyObject';
import { sleep } from 'src/util/sleep';
import { clearTagList } from 'src/render/tags/tags';


export default class SearchRenderer {
  constructor(
    $searchCont,
    inputSelector,
    resultListSelector,
    templateSelector,
    callbacks
  ) {
    this.$cont = $searchCont;

    for (const key in (callbacks || EmptyObject)) {
      this[key] = callbacks[key];
    }

    // get DOM elements
    const $inputEl = this.$inputEl = $searchCont.find(inputSelector);
    const $resultList = this.$resultList = $searchCont.find(resultListSelector);
    const $resetBtn = this.$resetBtn = $searchCont.find('.reset-results-button');
    this.templateSelector = templateSelector;

    prepareTemplateElement($resultList, templateSelector);

    // select text on click
    selectTextOnClick($inputEl[0]);

    // reset button
    $resetBtn.off('click').on('click', (evt) => this.clear());

    // clear everything
    this.clear();

    // input key events
    $inputEl.off('keyup').on('keyup', this.handleKeyInput.bind(this));
    $inputEl.off('paste').on('paste', async evt => {
      await sleep(); // wait for event to finish
      this.updateAfterInput();
    });
  }

  _version = 1;

  async handleKeyInput(evt) {
    // NOTE: odd browser behavior; `keyCode` can be null when calling `selectTextOnClick` for some reason
    // do nothing
    if (evt.keyCode && !evt.keyCode.toString().match(/^(37|38|39|40|13|16|17|18|224)$/)) {
      this.updateAfterInput();
    }
  }

  async updateAfterInput() {
    // start looking right away
    var searchTerm = this.$inputEl.val();
    if (!searchTerm) {
      // nothing to do
      this.clear();
      return;
    }
    this.$resetBtn.show();

    //tag = document.createElement('script');
    //tag.src = 'https://en.wikipedia.org/w/api.php?action=opensearch&limit=10&format=json&callback=ybComplete&search='+term;
    //document.body.appendChild(tag);
    const version = ++this._version;
    await sleep(50);

    if (this._version !== version) {
      // something else is already going on
      return;
    }

    if (this.lastSearchTerm === searchTerm) {
      return; // after typing a bit, we landed back on our last input
    }

    // go time!
    this.lastSearchTerm = searchTerm;
    const resultIterator = this.startSearch(searchTerm);
    this.$resultList.addClass('searching');

    // NOTE: results can come in over time. 
    //    Here we re-iterate all currently existing results, and keep rendering them out.
    let isFirstTime = true;
    for await (const results of resultIterator) {
      if (searchTerm !== this.$inputEl.val()) {
        // already searching for something else
        // debugger;
        return;
      }

      if (isFirstTime) {
        // clear previous results
        this.clear(true);
        // this.$resultList.empty();
        // this.lastSearchTerm = null;
      }

      // render result list
      this.renderResults(results, isFirstTime);

      isFirstTime = false;

      postUpdate();
    }
  }

  renderResults(results, isFirstTime) {
    for (let i = 0; i < results.length; ++i) {
      const entry = results[i];
      let $el;
      // console.debug('search result entry', isFirstTime, entry, i);
      if (isFirstTime) {
        this.transformResult?.(entry);
        $el = this.createEl(entry, i, this);
        this.$resultList.append($el);
        this.initRenderResult($el, entry, i, this);
      }
      else {
        $el = $(this.$resultList.children()[i]);
        if (!$el.length) {
          // should exist, but who knows...
          continue;
        }
        this.renderResult($el, entry, i, this);
      }
    }
  } 

  createEl() {
    return cloneTemplateElement(this.$resultList, this.templateSelector);
  }

  initRenderResult() {
  }

  clear(beforeResults = false) {
    if (!beforeResults) { // also clear input
      // reset search term
      this.lastSearchTerm = null;

      // clear input
      this.$inputEl.val('');

      // hide button
      this.$resetBtn.hide();

      // set min-height on output
      this.$resultList.removeClass('searching');
    }

    // clear previous result list
    // this.$resultList.empty();
    clearTagList(this.$resultList);

    this.cleared(beforeResults);
  }

  cleared() { }
}