import Enum from 'src/util/Enum';
import EmptyObject from 'src/util/EmptyObject';
import EmptyArray from 'src/util/EmptyArray';
import CollectionAccessMode from 'src/api/reel/CollectionAccessMode';

const SystemCollectionsConfig = {
  MySubmissions: {
    accessMode: CollectionAccessMode.Public
  },
  MyRecommendations: {
    accessMode: CollectionAccessMode.Public
  },
  MyOwn: {
    accessMode: CollectionAccessMode.Public
  },
  MyMindset: {
    accessMode: CollectionAccessMode.Public
  },
  MyCurriculum: {
    accessMode: CollectionAccessMode.Public
  },

  MyPrivateStuff: {
    accessMode: CollectionAccessMode.Private
  }
};

export function getSystemCollectionNames() {
  return Object.keys(SystemCollectionsConfig);
}

export function getCollectionConfig(collectionName) {
  return SystemCollectionsConfig[collectionName];
}

export function isSystemCollection(collectionName) {
  return !!getCollectionConfig(collectionName);
}

export function getSystemCollectionAccessMode(collectionName) {
  const cfg = getCollectionConfig(collectionName);
  return cfg.accessMode;
}

export default SystemCollectionsConfig;