import NanoEvents from 'src/util/NanoEvents';
import { allContentFilters, defaultContentFiltersByPage, approvalPageOrderByFilterName, orderByFilterName } from 'src/api/content/contentObjectsConfig';
import { getPageName, isApprovalPage } from 'src/pages';
import EmptyArray from 'src/util/EmptyArray';
import identity from 'lodash/identity';
import { isString } from 'src/util/misc';
import isEqual from 'lodash/isEqual';
import EmptyObject from 'src/util/EmptyObject';
import isFunction from 'lodash/isFunction';

export default class ContentFilters {
  _emitter = new NanoEvents();
  controller = {};

  constructor(contentObjects) {
    this.contentObjects = contentObjects;
  }

  // ########################################
  // getters
  // ########################################

  isAnyFilterSelected() {
    return !!this.name;
  }

  getWhereArgs() {
    return this.controller.whereArgs?.bind(this.controller) || identity;
  }

  get where() {
    return this.controller.where || EmptyArray;
  }

  isFilterActive(name, args) {
    return this.name === name && isEqual(this.args, args);
  }

  // ########################################
  // manage filters
  // ########################################

  setDefaultController() {
    // chose default controller by page
    const defaultFilter = defaultContentFiltersByPage[getPageName()];
    if (defaultFilter) {
      this.setController(defaultFilter);
    }
    else if (defaultFilter !== null) {
      console.error('page has no entry in defaultContentFiltersByPage');
    }
  }

  setController(filterNameOrConfig) {
    const { contentObjects } = this;
    let filterName;
    let args;
    if (isString(filterNameOrConfig)) {
      filterName = filterNameOrConfig;
    }
    else {
      ({ name: filterName, ...args } = filterNameOrConfig);
    }

    if (this.isFilterActive(filterName, args)) {
      // already selected this filter
      return;
    }

    const newController = allContentFilters[filterName];
    if (!newController) {
      throw new Error('invalid filter: ' + filterNameOrConfig);
    }

    // set filter settings
    this.controller = (isFunction(newController) ? newController(contentObjects) : newController) || EmptyObject;  // pre-defined filter settings
    this.name = filterName;
    this.args = args;           // dynamic arguments

    // notify filter update
    this._notifyFilterChanged();
  }

  // ########################################
  // event handling
  // ########################################

  /**
   * TODO: update this legacy code
   */
  _updateOrderDefault() {
    // filter might affect order (TODO: bad naming - it's not just a filter, but its actually a complete view on the data)
    const orderSettings = isApprovalPage() ? approvalPageOrderByFilterName : orderByFilterName;
    const newOrder = orderSettings[this.name];

    // make sure to reset content order
    if (newOrder) {
      this.contentObjects.setActiveContentOrder(newOrder);
    }
    else {
      this.contentObjects.setDefaultContentOrder();
    }
  }

  _notifyFilterChanged() {
    if (!this.controller.managesOrderItself) {
      this._updateOrderDefault();
    }

    this._emitter.emit('changed');
  }

  onFilterChanged(cb) {
    return this._emitter.on('changed', cb);
  }
}