import { ContentInputForm } from 'src/render/content/ContentInputForm';
import InputItemView from 'src/render/content/InputItemView';
import contentUrlAnalyzer from 'src/api/content/contentUrlAnalyzer';
import { showNotLoggedInUserActionWarning } from 'src/renderUtil/userActions';
import { queryIframely } from 'src/api/third-party/iframely';

// ###########################################################################
// UrlInputForm
// ###########################################################################


export default class UrlInputForm extends ContentInputForm {
  EmptyUrlResult = { contentType: null, url: null };

  sendQuery = queryIframely;

  onInit() {
    this.inputItem = new InputItemView(this, this.$inputFormEl);

    // submit btn click event
    this.inputItem.$submitBtn.on('click', this.tryAddContent);

    // input change event
    // see: https://stackoverflow.com/questions/10270452/jquery-change-not-firing-until-blur
    this.$inputText.on('change textInput input', this.handleInputChange.bind(this));
  }

  async normalizeUrl(url) {
    const result = await queryIframely(url);
    return result?.url || url;
  }

  async processCurrentUrl(silent = false) {
    const originalUrl = this.$inputText.val().trim();

    // analyze + validate url
    let {
      contentType,
      canonicalUrl
    } = await contentUrlAnalyzer.analyze(this.contentType, originalUrl);
    if (!contentType || (!this.cfg?.unrestrictedContentType && !this.validateContentType(contentType, silent))) {
      console.warn('invalid url does not match expected ContentType', originalUrl);
      return this.EmptyUrlResult;
    }

    // NOTE: canonicalUrl is only canonical, according to some very crude heuristics
    // iFramely is a lot more reliable in that regard!
    const url = await this.normalizeUrl(canonicalUrl || originalUrl);

    return {
      contentType,
      url
    }
  }

  async handleInputChange(evt) {
    const { contentType, url } = await this.processCurrentUrl(true);

    await this.inputItem.onUrlUpdate(url);
  }

  tryAddContent = async () => {
    if (showNotLoggedInUserActionWarning()) {
      return;
    }

    const { contentType, url } = await this.processCurrentUrl(false);
    const newContent = await this.sendQuery(url);

    if (!url || !await this.inputItem.handleSubmit(newContent)) {
      return;
    }

    await this.inputItem.addContent(newContent);
  }
}