import { doesCollectionNameRequireApproval } from 'src/api/content/ContentActionConfig';
import { getCurrentPageArg } from 'src/pages';
import ContentType, { isContentTypeReelCategory } from 'src/api/content/ContentType';
import CollectionFilterQueryInterface from 'src/api/content/CollectionFilterQueryInterface';
import ReelFilterQueryInterface from 'src/api/content/ReelFilterQueryInterface';

/**
 * @file
 * 
 * This file handles the special (complex) case of content filters when content collections are in play, as seen on the reel page.
 * 
 */

class ContentColumnFilterController {
  constructor(contentObjects) {
    this.contentObjects = contentObjects;
  }

  /**
   * @abstract
   */
  get managesOrderItself() {
    throw new Error('NYI');
  }
}


class ContentCollectionFilterController extends ContentColumnFilterController {
  ecmEnabled = false;

  get managesOrderItself() {
    return true;
  }

  // ###########################################################################
  // getters
  // ###########################################################################

  // TODO: [brand-reel]
  getReelId() {
    return getCurrentPageArg();
  }

  limit() {
    return this.ecmEnabled ?
      this.contentObjects.ecmOrderFirstPageCount :  // get a lot more
      null;  // default
  }

  isIndirect() {
    return true;
  }

  getCollectionName() {
    const { contentObjects } = this;
    const {
      filters: {
        args: {
          collectionName
        }
      }
    } = contentObjects;

    return collectionName;
  }

  // ###########################################################################
  // whereArgs (used for the "my submitted" category only)
  // ###########################################################################

  /**
   * whereArgs for the "MySubmissions" category.
   * NOTE: will not be called (queryInterface will take care of this), if indirect.
   * NOTE 2: Does not do anything anymore
   */
  whereArgs() {
    // const uid = this.getReelId();
    const collectionName = this.getCollectionName();

    if (!doesCollectionNameRequireApproval(collectionName)) {
      return null;
    }

    // cannot check for private status in collection entries -> do it in `filterArray`
    return null;

    // const isOwn = uid === authState.uid;
    // if (!isOwn) {
    //   // show only published or private items to visitors
    //   //  (do not show items pending approval)
    //   return ['moderationStatus', '==', ModerationStatus.ReviewedPublished];
    // }
    // else {
    //   // show all submitted (but not private) items in the "my submitted" category
    //   // return ['private', '==', false];
    //   return null;
    // }
  }

  // ###########################################################################
  // buildQueryInterface
  // ###########################################################################

  buildQueryInterface() {
    const { contentObjects } = this;
    const collectionName = this.getCollectionName();
    if (!this.isIndirect()) {
      // use default query interface for this filter
      return null;
    }

    const { contentType } = contentObjects;

    if (isContentTypeReelCategory(contentType)) {
      const iface = new ReelFilterQueryInterface(contentObjects);
      iface.init(collectionName, this.getReelId());
      return iface;
    }
    else {
      // use indirect query interface
      const iface = new CollectionFilterQueryInterface(contentObjects);
      iface.init(collectionName, this.getReelId());
      return iface;
    }
  }

  // ###########################################################################
  // toggleFlavor
  // ###########################################################################

  toggleFlavor(flavor) {
    const { contentObjects } = this;
    let result;

    switch (flavor) {
      case 'ecmScore':
        result = this.ecmEnabled = !this.ecmEnabled;
        break;
      default:
        throw new Error('invalid flavor: ' + flavor);
    }



    // set order
    if (this.ecmEnabled) {
      contentObjects.setActiveContentOrder('ecmScore');
    }
    else {
      contentObjects.setDefaultContentOrder();
    }

    // query data again, after filter was updated
    contentObjects.refreshFilter();

    return result;
  }
}

export default ContentCollectionFilterController;