import { authState, waitUntilAuthStateInitialized } from 'src/auth';
import { getContentCollectionQueryInterface } from 'src/api/contentCollections/contentCollections';
import contentCollectionEntryContainer from 'src/api/contentCollections/contentCollectionEntryContainer';
import EmptyArray from 'src/util/EmptyArray';
import reelCollectionsContainer, { getCollectionLabel } from 'src/api/reel/reelCollectionContainer';
import { ModerationStatus, mayAddObjectToCollection, doCollectionNamesRequireApproval } from 'src/api/content/ContentActionConfig';
import { getContentObjectsByType } from 'src/api/content/contentObjects';
import { setSelectedCheckboxEl, isSelectedCheckboxEl, setDisabledCheckboxEl } from 'src/webflow/webflowFormHelpers';
import State from 'src/util/State';
import { ReelCollectionContEl } from 'src/renderUtil/reel/ReelCollectionContEl';
import { buildBrandReelId } from 'src/api/reel/reelUtil';
import reelCollectionContributorContainer from 'src/api/reel/reelCollectionContributorContainer';
import reelInfoContainer, { getReelDisplayName } from 'src/api/reel/reelInfo';
import { cloneTemplateElement, prepareTemplateElement } from 'src/util/domUtil';
import { querySystemCollectionLabels } from 'src/api/contentCollections/systemCollectionLabels';
import { getSelectedReelId } from 'src/api/reel/reelSelection';

const Verbose = true;

// ###########################################################################
// ContentCollectionSelectionView
// ###########################################################################

class ContentCollectionChoiceView {
  /**
   * @param {ContentCollectionEditorTabView} choices 
   */
  constructor(choices, reelId, collectionName, key, $check) {
    this.choices = choices;
    this.$check = $check;
    this.reelId = reelId;
    this.collectionName = collectionName;

    $check.attr('data-reel', reelId);
    $check.attr('data-collection', collectionName);
    $check.attr('name', key);
    $check.attr('id', key);
  }

  isSelected() {
    // return this.$check.is(':checked') && this.$check.is(':visible');
    return isSelectedCheckboxEl(this.$check);
  }

  matchesAny(entries) {
    return entries.some(({ reelId, collectionName }) => reelId === this.reelId && collectionName === this.collectionName);
  }

  setSelected(selected) {
    // this.$check.prop('checked', selected)
    setSelectedCheckboxEl(this.$check, selected);
  }

  render() {
    // NOTE: this is currently handled in `CollectionEditor.render`
    // this.$check.prop('disabled', this.choices.isDisabled);
  }
}

// ###########################################################################
// CollectionEditorTabView
// ###########################################################################

const tabSettings = [
  {
    // selector: '.personal-reel',
    get isSingleReel() { return true; },
    get reelId() { return authState.uid; },
    cfg: {
      includeContributingCollections: false
    }
  },
  {
    // selector: '.brand-reel',
    get isSingleReel() { return true; },
    get reelId() { return buildBrandReelId(authState.uid); },
    cfg: {
      includeContributingCollections: false
    }
  },
  {
    get isSingleReel() { return false; },
    get reelId() { return 'not-a-reel-id-but-a-placeholder-for-contributing-collections'; },
    cfg: {
      includeContributingCollections: true
    }
  }
];

let lastSelectedReelId;

export default class ContentCollectionEditorTabView {
  constructor($cont) {
    this.$cont = $cont;
    this.$toolbar = this.$cont.find('.which-reel-to-save');
    this.$tabCont = this.$cont.find('.lists-ctn');
  }

  getSelectedTabSettings() {
    return tabSettings.find(settings => settings.reelId === lastSelectedReelId);
  }

  /**
   * Is always called first.
   */
  async initRender(contentType, content) {
    this.contentType = contentType;
    this.content = content;

    this.initRenderToolbar();

    // figure out initially selected tab
    lastSelectedReelId = lastSelectedReelId || getSelectedReelId();
    if (!lastSelectedReelId || !this.getSelectedTabSettings()) {
      lastSelectedReelId = tabSettings[0].reelId;
    }

    if (!this.editor) {
      // select tab
      const selectedTabSettings = this.getSelectedTabSettings();
      this._selectTab(selectedTabSettings);
    }
  }

  _setTabButtonText($tabBtn, name) {
    $tabBtn.children().eq(0).text(name);
  }

  initRenderToolbar() {
    const { $toolbar } = this;

    // prep
    prepareTemplateElement($toolbar, '.reel-badge');
    
    // clear
    $toolbar.find('[data-reel-id]').remove();

    // build toolbar
    for (const tabSetting of tabSettings) {
      const $tabBtn = cloneTemplateElement($toolbar, '.reel-badge');
      const { isSingleReel, reelId } = tabSetting;
      $tabBtn.attr('data-reel-id', reelId);

      $tabBtn.on('click', evt => {
        this._selectTab(tabSetting);
      });
      
      if (!isSingleReel) {
        this._setTabButtonText($tabBtn, 'Contributing');
      }
      else {
        // hackfix: take care of this properly?
        reelInfoContainer.queryReelShortName(reelId).then(name => {
          this._setTabButtonText($tabBtn, name);
        });
      }

      $toolbar.append($tabBtn);
    }
  }

  old_initRenderToolbar() {
    // for (const tabSetting of tabSettings) {
    //   const $btn = this.$cont.find(`${tabSetting.selector}`);
    //   const $radio = $btn.find('input');
    //   $radio.prop('name', 'reel-selector');   // make sure they are in the same group
    //   $radio.off('change').on('change', (evt) => {
    //     // if (evt.target !== $btn[0]) {
    //     //   // do not trigger on children
    //     //   return;
    //     // }
    //     if (isSelectedCheckboxEl($radio)) {
    //       // was already selected -> is going to be deselected
    //       return;
    //     }

    //     this._selectTab(tabSetting);
    //   });
    // }
  }

  render() {
    this.editor?.render();
  }

  async _selectTab(tabSetting) {
    const { reelId, isSingleReel } = tabSetting;

    if (this.editor?.reelId === reelId) {
      // nothing to do
      return;
    }

    lastSelectedReelId = reelId;

    this.editor = new ContentCollectionEditor(isSingleReel, reelId, this.$tabCont);

    // assign cfg
    Object.assign(this.editor, tabSetting.cfg);

    await this._reinitEditor();
    return this.editor;
  }

  async _reinitEditor() {
    const { reelId } = this.editor;
    const $allReelBtns = this.$toolbar.find('.reel-badge');
    $allReelBtns.removeClass('active');

    const $reelBtn = this.$toolbar.find(`[data-reel-id=${reelId}]`);
    $reelBtn.addClass('active');

    await this.editor.loadCollections(this.contentType, this.content);
  }
}

// ###########################################################################
// ContentCollectionEditor
// ###########################################################################

/**
 * 
 */
class ContentCollectionEditor {
  /**
   * @type {ContentCollectionChoiceView[]}
   */
  choices = [];

  /**
   * Array of already saved entries, along-side their `reelId`.
   */
  contentCollectionEntries;

  state = new State();

  constructor(isSingleReel, reelId, $cont) {
    this.isSingleReel = isSingleReel;
    this.reelId = reelId;
    this.$cont = $cont;
    this.contsByAccessMode = new ReelCollectionContEl(reelId, $cont);

    this.contsByAccessMode.$contsByAccessMode.forEach(($cont) => {
      prepareTemplateElement($cont, '.collection-template').
        removeClass('hidden');
    });
  }

  // ###########################################################################
  // getters
  // ###########################################################################

  // getChoice(name) {
  //   return this.choices.find(choice => ?) || null;
  // }

  // getChoices(name) {
  //   return this.choices.filter(choice => ?) || EmptyArray;
  // }

  getSelectedCollectionIdRefs() {
    return this.choices.
      filter(choice => choice.isSelected()).
      map(({ reelId, collectionName }) => ({ reelId, collectionName }));
  }

  hasAnySelected() {
    return this.choices.some(choice => choice.isSelected());
  }

  get isDisabled() {
    return this.state.get.busy || false;
  }

  needsApproval() {
    const collectionNames = this.getSelectedCollectionIdRefs().map(ref => ref.collectionName);
    return doCollectionNamesRequireApproval(collectionNames);
  }

  isPrivate() {
    const { content } = this;
    return !content || content.moderationStatus === ModerationStatus.Private;
  }

  needsApprovalButNotBefore() {
    return this.isPrivate() && this.needsApproval();
  }

  // ###########################################################################
  // load
  // ###########################################################################

  async _loadOwnCollections() {
    const { reelId } = this;
    const collections = await reelCollectionsContainer.queryReelAndSystemCollections(reelId);

    return {
      reelId,
      collections
    };
  }

  async _loadAllCollections() {
    const { uid } = authState;
    let [labels, ...collections] = (
      await Promise.all([
        // system collections (+ their labels)
        querySystemCollectionLabels(),

        // own custom collections
        this.isSingleReel && this._loadOwnCollections() || EmptyArray,

        // contributing collections
        this.includeContributingCollections && reelCollectionContributorContainer.queryAllContributorCollections(uid) || EmptyArray
      ])
    );


    collections = collections.flat();

    // hide "MySubmissions" (it has a special place already)
    collections.forEach(({ reelId, collections }) => collections.filter(collection => collection.collectionName !== "MySubmissions"));

    return collections;
  }

  async _loadAllCollectionContentEntries(contentId) {
    const { uid } = authState;
    return (await Promise.all([
      getContentCollectionQueryInterface().queryCollectionContentEntries(this.reelId, contentId),
      this.includeContributingCollections && reelCollectionContributorContainer.queryAllVisibleContentEntries(uid, contentId) || EmptyArray
    ])).flat();
  }


  async loadCollections(contentType, content) {
    this.contentType = contentType;
    this.content = content;

    await waitUntilAuthStateInitialized();

    // load collections
    await this._loadAndRenderCollections();

    // load collection entries to determine which collections this content belongs to
    await this._loadAndRenderCollectionEntries();
  }

  async _loadAndRenderCollections() {
    // query collections
    if (!authState.uid) {
      return;      // nothing to do
    }

    const collectionsByReelId = await this._loadAllCollections();
    Verbose && console.debug('loaded collectionsByReelId', collectionsByReelId);

    // pre-query reel info
    await Promise.all(collectionsByReelId.map(({ reelId }) => reelInfoContainer.queryDoc(reelId)));

    // remove all existing collection entries
    this.$cont.find('[data-custom=1]').remove();

    // render collections
    this.choices = [];

    const {
      contentType,
      content,
      isSingleReel
    } = this;

    for (const { reelId, collections } of collectionsByReelId) {
      for (const { collectionName, accessMode } of collections) {
        const $parent = this.contsByAccessMode.get(accessMode);
        const $collectionEl = cloneTemplateElement($parent, '.collection-template');

        $collectionEl.attr('data-custom', 1);
        const $check = $collectionEl.find('input');
        const $label = $collectionEl.find('span');
        const key = `${reelId}#${collectionName}`;

        $label.attr('for', key);
        $label.text(getCollectionLabel(collectionName, reelId, contentType));

        // set reel name
        const $reelName = $collectionEl.find('._3rd-party-reel-name');
        if (!isSingleReel) {
          // only display reel name if there is multiple reels on tab
          // $reelName.text(reelInfoContainer.getReelName(reelId));
          reelInfoContainer.queryReelName(reelId).then(name => {
            $reelName.text(name);
          });
        }
        else {
          $reelName.remove();
        }

        // add choice view
        const choice = new ContentCollectionChoiceView(this, reelId, collectionName, key, $check);
        this.choices.push(choice);

        // // add change listener
        // $check.on('change', evt => {
        //   // implement pre-configured rules
        //   const cfg = choiceConfig[choice.collectionName];
        //   cfg?.changed?.(choice);
        // });


        $parent.append($collectionEl);
      }
    }
  }

  async _loadAndRenderCollectionEntries() {
    const { content } = this;
    const contentId = content?._id;
    this.state.inc('busy');

    try {
      // reset choices
      this.contentCollectionEntries = null;
      this.choices.forEach(choice => choice.setSelected(false));

      if (!contentId) {
        // this content item is not in the DB -> entry set is empty
        this.contentCollectionEntries = null;
      }
      else {
        // render intermediate state
        this.render();

        // query selected content collections
        this.contentCollectionEntries = await this._loadAllCollectionContentEntries(contentId);
        Verbose && console.debug('loaded contentCollectionEntries', this.contentCollectionEntries); //, this.content?._id, '->', contentId);
        if (contentId !== this.content?._id) {
          // things have already changed -> ignore results of this call
          return;
        }
      }

      this.renderChoices();
    }
    finally {
      this.state.dec('busy');
      this.render();
    }
  }

  renderChoices() {
    // update selections
    const entries = this.contentCollectionEntries;

    this.choices.forEach(choice =>
      choice.setSelected(entries && choice.matchesAny(entries) || false)
    );
  }

  // ###########################################################################
  // checks
  // ###########################################################################

  /**
   * TODO: Move to a better place
   */
  // isPublicAllowed() {
  //   const { content } = this;
  // }

  // ###########################################################################
  // save
  // ###########################################################################

  /**
   * @param {*} content This is an updated version of the previous this.content; i.e. id has been assigned if it was just added to DB
   */
  async saveCollections(content) {
    this.content = content;
    const { _id: contentId } = content;

    const existedBefore = !!contentId;
    if (!existedBefore) {
      // NOTE: we will take care of this once we have the contentId in `saveAfterSubmit`
      return;
    }

    if (this.needsApprovalButNotBefore()) {
      // send to Approval page
      const { type: contentType } = content;
      const contentObjectContainer = getContentObjectsByType(contentType);
      await contentObjectContainer.setModerationStatus(contentId, ModerationStatus.NotReviewed);
    }
    // else if (this.isPublicButNotBefore()) {
    //  // NOTE: the object is visible in a public collection, but not actually public
    // }


    // get existing collection entries
    const newcollectionIdRefs = this.getSelectedCollectionIdRefs();
    const { contentCollectionEntries: oldEntries = EmptyArray } = this;
    if (oldEntries?.length && oldEntries[0].contentId !== contentId) {
      // something went wrong -> we had some sort of race condition
      console.error('Could not store your selected collections :(', oldEntries, contentId);
      alert('Could not store your selected collections :(\n\nPlease try submitting again');
      debugger;
      return;
    }

    // set new collections
    const isNew = !oldEntries?.length;
    console.log(isNew ? 'Adding' : 'Updating', 'contentCollections', newcollectionIdRefs, 'for', contentId);
    this.contentCollectionEntries = await contentCollectionEntryContainer.setItems(
      contentId, this.content, oldEntries, newcollectionIdRefs
    );
  }

  // ###########################################################################
  // render
  // ###########################################################################

  render = () => {
    // disable non-private collections if content object does not fit requirements
    for (const [accessMode, $cont] of this.contsByAccessMode) {
      const disabled = !mayAddObjectToCollection(this.content, accessMode) || this.isDisabled;
      const $inputs = $cont.find('input');
      setDisabledCheckboxEl($inputs, disabled);
    }

    // re-render checkboxes
    // this.choices.forEach(choice => choice.render());
  }
}