import FirestoreContainer from 'src/firebase/FirestoreContainer';
import firebase from 'firebase/app';
import { authState } from 'src/auth';

const FillOutReminderDelay = 12 * 60 * 60 * 1000;
// const FillOutReminderDelay = 20 * 1000;

/**
 * Remind user to fill out basic info
 */
function isItTimeForAnotherReminder(createdAt, settings) {
  const lastReminderTime = settings?.lastReminderTime;
  const lastTime = Math.max(createdAt.toDate() || 0, lastReminderTime?.toDate() || 0);
  const dt = Date.now() - lastTime;
  console.debug('isItTimeForAnotherReminder', dt);
  return dt > FillOutReminderDelay;
}

class UserSettings extends FirestoreContainer {
  constructor() {
    super('userSettings');
  }

  async needsFillOutReminder() {
    const { uid, userInfo: { createdAt } } = authState;
    const settings = await this.queryDoc(uid, true);
    return isItTimeForAnotherReminder(createdAt, settings);
  }

  async storeFillOutReply(yes) {
    const { uid } = authState;

    const lastReminderTime = firebase.firestore.Timestamp.fromDate(new Date());
    return this.setDoc(uid, {
      lastReminderTime,
      lastResult: yes
    }, true);
  }
}

const userSettings = new UserSettings();
export default userSettings;