import { getContentObjectsByType } from 'src/api/content/contentObjects';

export default class DefaultPageController {
  initPromise;

  toggleEcmOrder(contentType) {
    const contentObjects = getContentObjectsByType(contentType);

    if (contentObjects.getActiveContentOrder() === 'ecmScore') {
      // go back to default content order
      contentObjects.setDefaultContentOrder();
      contentObjects.filters.setDefaultController(); // setting filter will trigger update
      return false;
    }
    else {
      // sort by ECM score
      // cache.setActiveContentOrder('ecmScore');
      contentObjects.filters.setController('ecmScore'); // setting filter will trigger update
      return true;
    }
  }
}