let worldNames = [];
let worldPaths = {};
let worldRenderNames = {};
let worldDict = {};

/**
 * In the first version, worlds are stored in Webflow collections and exported straight into the .html files.
 */
// function extractWorldsFromHTML() {
//   worldNames = Array.from($('.container-list-worlds .dynamic-item').map(function () { return this.textContent; }))
//   _buildWorldDict();
// }

function _buildWorldDict() {
  for (const worldName of worldNames) {
    worldDict[worldName] = true;
  }
}

export async function initWorlds() {
  const res = await fetch('/worlds.json');
  const worlds = await res.json();
  
  worldNames = worlds.map(w => w.name);
  worldRenderNames = Object.fromEntries(worlds.map(({ name, renderName }) => ([name, renderName])));
  worldPaths = Object.fromEntries(worlds.map(({name, path}) => ([name, path])));
  _buildWorldDict();
}

export function isWorldName(tagName) {
  return worldDict[tagName] || false;
}

export function getWorldNames() {
  return worldNames;
}

/**
 * @param {string} worldName 
 */
export function getWorldLink(worldName) {
  return worldPaths[worldName];
  // // return `/worlds/${worldName}`;
  // worldName = worldName.replace(/ /g, '-');
  // worldName = worldName.replace(/[().,]/g, '');
  // worldName = worldName.toLowerCase();
  // return `/worlds/${(worldName || '')}`;
}

export function getWorldRenderName(worldName) {
  return worldRenderNames[worldName];
}