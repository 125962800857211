import FirestoreQueryInterface from 'src/firebase/FirestoreContainerCollection';
import { NotLoaded } from 'src/db';
import FirestoreContainer from 'src/firebase/FirestoreContainer';
import { mergeWithArrays } from 'src/util/arrayUtil';

const Verbose = false;
// const Verbose = true;

class ContentCollectionEntryContainerSimple extends FirestoreContainer {
  constructor() {
    super('contentCollectionEntries');
  }

  handleDocsArrayChanged() {
    this._collectionEntriesByContentId = null;
  }

  getCollectionEntryOfContentId(contentId) {
    if (!this._collectionEntriesByContentId) {
      const entries = this.getAllNotNull();
      if (!entries) {
        return NotLoaded;
      }

      this._collectionEntriesByContentId = Object.fromEntries(
        entries.map(entry => [entry.contentId, entry])
      );
    }
    return this._collectionEntriesByContentId[contentId];
  }
}

class ContentCollectionQueryInterface extends FirestoreQueryInterface {
  // ########################################
  // Query playlists by { reelId, contentId }
  // ########################################

  async queryCollectionContentEntries(reelId, contentId) {
    const keys = [reelId, contentId];
    const cont = this.getContainer(...keys);
    if (!cont) {
      const queryArgs = {
        where: [
          // TODO: (migration needed) rename `uid` to `reelId`
          'uid', '==', reelId,
          'contentId', '==', contentId
        ]
      };
      const promise = this.addContainerAndQuery(ContentCollectionEntryContainerSimple, queryArgs, keys);
      //  NOTE: `this.getContainer(...keys)` will not be available instantly
      // if (!this.getContainer(...keys)._activePagePromise) {
      //   console.error('queryCollectionContentEntries failed to generate `_activePagePromise`');
      // }
      await promise;
    }
    else {
      await cont.waitForCurrentPage();
    }

    // return whether we have at least one content item matching the tag
    return this.getCollectionEntries(reelId, contentId);
  }

  getCollectionEntries(reelId, contentId) {
    const keys = [reelId, contentId];
    const cont = this.getContainer(keys);
    if (!cont?.hasLoaded()) {
      // not ready yet
      return NotLoaded;
    }

    // get the first (and only) doc (NOTE: we queried with `limit: 1`)
    return cont.getAllNotNull();
  }

  // ########################################
  // Query contentIds by { uid, collectionName } (independent of content-type etc)
  // ########################################

  /**
   * Check if we have at least one entry for the given uid+collection key.
   */
  async queryHasCollectionEntries(collectionIdRef, contentType = null) {
    const arr = await this.queryContentIds(collectionIdRef, contentType, {
      limit: 1
    });

    // console.debug(collectionName, arr, arr?.length > 0 || false);

    return arr?.length > 0 || false;
  }

  async deleteAllEntries(collectionIdRef) {
    const cont = await this._queryContainer(collectionIdRef);

    const entries = cont.getAllNotNull();
    console.warn(`Deleting ${entries.length} collection entries...`);
    const promises = [];
    for (const entry of entries) {
      promises.push(cont.deleteDoc(entry._id));
    }
    return Promise.all(promises);
  }

  getCollectionEntryCont({reelId, collectionName}, contentType, queryArgs) {
    const keys = [reelId, collectionName, contentType, queryArgs];
    const cont = this.getContainer(...keys);
    return cont;
  }

  getContentIds({reelId, collectionName}, contentType, queryArgs) {
    const keys = [reelId, collectionName, contentType, queryArgs];
    const cont = this.getContainer(...keys);

    if (!cont?.hasLoaded()) {
      // not ready yet
      return NotLoaded;
    }

    return cont.getAllNotNull().map(entry => entry.contentId);
  }

  // ########################################
  // Queries including contentType
  // ########################################

  async _queryContainer({reelId, collectionName}, contentType, queryArgs) {
    const keys = [reelId, collectionName, contentType, queryArgs];
    let cont = this.getContainer(...keys);

    if (!cont) {
      queryArgs = mergeWithArrays({
        where: [
          'collectionName', '==', collectionName,
        ]
      },
        queryArgs
      );

      // hackfix: we add these where args in different places in different ways, so we need to check if it hadnt been added yet :(
      if (!queryArgs.where.includes('uid')) {
        // TODO: migrate to new naming schema (`reelId` instead of `uid`)
        queryArgs.where.push('uid', '==', reelId);
      }
      if (contentType && !queryArgs.where.includes('type')) {
        queryArgs.where.push('type', '==', contentType);
      }


      cont = await this.addContainerAndQuery(ContentCollectionEntryContainerSimple, queryArgs, ...keys);
      Verbose && console.debug('_queryContainer -> after addContainer', cont.getDebugTag());
    }
    else {
      const result = await cont.waitForCurrentPage();
      Verbose && console.debug('_queryContainer -> after wait', cont.getDebugTag(), queryArgs, result);
    }
    return cont;
  }

  async queryContentIds(collectionIdRef, contentType, queryArgs) {
    if (!collectionIdRef.collectionName) {
      throw new Error(`Tried to queryContentIds without collectionName`);
    }
    Verbose && console.debug('queryContentIds', collectionIdRef, queryArgs);
    const cont = await this._queryContainer(collectionIdRef, contentType, queryArgs);

    // return whether we have at least one content item matching the tag
    const result = this.getContentIds(collectionIdRef, contentType, queryArgs);

    // const cont = this.getContainer(...[uid, collectionName, contentType]);

    if (result === NotLoaded) {
      console.error('[INTERNAL ERROR] contentCollection did not wait for query to finish? - loaded:', cont?.hasLoaded());
      // const result2 = this.getContentIds(collectionIdRef, contentType, queryArgs);
    }

    return result;
  }
}

/**
 * @type {ContentCollectionQueryInterface}
 */
let _contentCollectionQueryInterface;

export function getContentCollectionQueryInterface() {
  if (!_contentCollectionQueryInterface) {
    _contentCollectionQueryInterface = new ContentCollectionQueryInterface();
  }
  return _contentCollectionQueryInterface;
}