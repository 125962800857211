import { waitForGoogleApi } from 'src/web-apis/gapi/gapi';

const ApiKey = 'AIzaSyCJkrtYcz6pI0DXziZ3Mt1rz5D5BC_Zoj4';


export async function waitForGoogleMapsInit() {
  const url = `https://maps.googleapis.com/maps/api/js?key=${ApiKey}&libraries=places`;
  const callbackParam = 'callback';
  return waitForGoogleApi(url, callbackParam);
}
