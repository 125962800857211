import { getBrowserParser } from './browserInfo';
// import { isStandaloneMode } from '../web-apis/pwa';

/**
 * @see https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
 */
function isIOS() {
  // TODO: use bowser instead
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

function fixIOSInternalLinks() {
  /**
   * Prevent bug: on mobile full-screen iOS, clicking internal links opens them in a new window.
   * Note: Use `location.assign` to make it work in the latest version.
   * 
   * @see https://gomakethings.com/how-to-prevent-fullscreen-web-apps-from-opening-links-in-a-new-tab-on-ios/
   * @see https://gist.github.com/irae/1042167/fcd09c2e0fa9af91796d65992c785019ec0681fc#gistcomment-2579004
   */
  var eventHandler = function (event) {
    // if (isIOS() && isStandaloneMode())
    {
      // Only run for iOS full screen apps
      //     if (event.target.tagName.toLowerCase() === 'a') {
      //       window.alert(`${isIOS()},
      // target_host: ${event.target.hostname},
      // this_host: ${window.location.hostname},
      // target_path: ${event.target.pathname},
      // this_path: ${window.location.pathname}`
      // );
      //     }
      // Only run if link is an anchor and points to the current page
      // console.log();

      const { target } = event;
      if (target.tagName.toLowerCase() !== 'a' ||
        target.target === '_blank' ||
        target.hostname !== window.location.hostname ||
        /#?/.test(target.getAttribute('href') || '')
      ) {
        // external or empty link
        return;
      }

      // internal link

      // window.alert('redirecting upon internal link');
      // go there right away (don't open a new tab)
      event.preventDefault();
      //window.location.href = event.target.href;
      window.location.assign(event.target.href);
    }
  }
  window.addEventListener('click', eventHandler, false);
}

/**
 * @see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
function mobileVH() {
  function updateVHUnits() {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    requested = false;
  }

  updateVHUnits();

  // listen to the resize event
  // WARNING: "Updating the value of --vh will trigger a repaint of the page and the user may experience a jump as a result."
  let requested = false;
  window.addEventListener('resize', () => {
    // do a bit of throttling before resizing the VH-based elements
    if (requested) {
      return;
    }
    requested = true;
    requestAnimationFrame(updateVHUnits)
  });
}

/**
 * @see https://stackoverflow.com/questions/51993155/redirecting-to-google-oauth-flow-in-progressive-web-app/52286655#52286655
 */
function fixPWAAuthiOS() {
  const browserParser = getBrowserParser();

  const noMatchMedia = browserParser.satisfies({
    mobile: {
      safari: '<13'
    }
  });

  if (noMatchMedia) {
    document.querySelector('link[rel="manifest"]').setAttribute("rel", "no-on-ios");
  }
}

export function installBrowserHacks() {
  fixIOSInternalLinks();
  mobileVH();
  fixPWAAuthiOS();
}