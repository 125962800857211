import SingleFirebaseDropzoneRenderer, { getOrCreateDropzoneRenderer } from 'src/renderUtil/uploads/SingleFirebaseDropzoneRenderer';

export default class SingleFirebaseUploadButton {
  constructor($btn, dropzoneSelector, handleDropzoneOpen, handleUploadCompleted, transformFile) {
    this.$btn = $btn;

    this.$btn.off('click').on('click', evt => {
      handleDropzoneOpen();

      if (!this.dropzoneRenderer) {
        // init dropzone
        this.dropzoneRenderer = SingleFirebaseDropzoneRenderer.getOrCreate(dropzoneSelector);
      }

      // this.dropzoneRenderer.setPath(storagePath);
      this.dropzoneRenderer.handleUploadCompleted = handleUploadCompleted;
      this.dropzoneRenderer.transformFile = transformFile;
      this.dropzoneRenderer.reset();
    });
  }
}