import CollectionAccessMode from 'src/api/reel/CollectionAccessMode';
import reelCollectionsContainer from 'src/api/reel/reelCollectionContainer';
import { decorateClasses, prepareTemplateElement } from 'src/util/domUtil';

const ContSelectorByAccessMode = {
  Public: '.public-collections-ctn',
  Protected: '.protected-collections-ctn',
  Private: '.private-collections-ctn'

  // TODO: '._3rd-party-collections-ctn'
};

export function findContsByAccessMode($cont) {

}

export class ReelCollectionContEl {
  constructor(reelId, $contOfConts) {
    this.reelId = reelId;
    const $conts = new Map(Object.entries(ContSelectorByAccessMode).map((
      [modeName, selector]) => [CollectionAccessMode.valueFrom(modeName), $contOfConts.find(selector)]
    ));

    // show all divs first (hide later)
    $conts.forEach(($cont) => {
      $cont.removeClass('hidden');
    });

    this.$contsByAccessMode = $conts;

  }

  get(key) {
    return this.$contsByAccessMode.get(key);
  }

  *[Symbol.iterator]() {
    yield* this.$contsByAccessMode;
  }

  // hideEmpty() {
  //   const { reelId } = this;
  //   for (const [accessMode, $cont] of this.$contsByAccessMode) {
  //     // TODO: this call does not actually return `collectionEntries`
  //     const collectionEntries = reelCollectionsContainer.getCollectionsOfAccessMode(reelId, accessMode);
  //     decorateClasses($cont, {
  //       // hide empty columns
  //       hidden: !collectionEntries.length
  //     });
  //   }
  // }
}