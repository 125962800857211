import { isMobile } from '../../util/responsive';
import { ResizeObserver } from '@juggle/resize-observer';
import { updateVisibleContentColumns } from 'src/render/content/contentObjectColumns';

// ###########################################################################
// Desktop
// ###########################################################################
// configuration
const DesktopScrollDelta = 400;  // scroll by 400 pixels each time
const scrollAnimTime = 300; // 300 ms

// current horizontal scrolling position
let scrollX = 0;

// three menu bars on the left (can change in width as you open menus inside of it)
let $leftMenu;

// background image
let $backgroundCont;

// all content columns (it's the thing that is being moved around)
let $contentCont;

// vertical bar on the right with scroll controls
let $rightNavBar;


/**
 * Make sure, the given $el (e.g. a content column) is not overlapped by the lefthand-side menu or preview,
 * nor by the righthand-side scroll-controlling "navbar".
 * 
 * NOTE: only currently implemented on desktop
 */
export function scrollIntoViewport($el) {
  if (!$contentCont) {
    return;
  }

  // hackfix: instead of using `setTimeout`, use a temporary `ResizeObserver` to adjust during the animation
  setTimeout(() => {
    const elXLeft = $el.offset().left;
    const elXRight = elXLeft + $el.outerWidth();

    // 1. too far right
    const viewXRight = $rightNavBar.offset().left;
    const distanceRight = elXRight - viewXRight;
    if (distanceRight > 0) {
      scrollToX(scrollX + distanceRight, true);
    }

    // 2. too far left, possibly overlapped by menu
    const viewXLeft = $leftMenu.offset().left + $leftMenu.outerWidth();
    const distanceLeft = viewXLeft - elXLeft;
    if (distanceLeft > 0) {
      scrollToX(scrollX - distanceLeft, true);
    }
  }, 500);
}

function computeMaxScrollX() {
  const leftMenuW = $leftMenu.outerWidth(); // NOTE: leftMenuWidth is already considered in screenW
  const bigImageW = $backgroundCont.outerWidth() || 0;
  // const contentW = $contentCont[0].getBoundingClientRect().width;
  const contentW = $contentCont.outerWidth();
  const barW = $rightNavBar.outerWidth();
  const screenW = window.innerWidth;

  return leftMenuW + bigImageW + contentW + barW - screenW;
}

function scrollToX(x, instant = false) {
  const maxX = computeMaxScrollX();

  // clamp to max
  x = Math.min(maxX, Math.max(0, x));

  //
  // actually do the scrolling!
  // NOTE: we are scrolling by giving a negative margin
  //
  scrollX = x;
  // console.debug('scroll', x, scrollX, maxX);

  $contentCont.stop();  // stop current animation (if any)

  const style = {
    'margin-left': (-scrollX) + 'px'
  };

  let delay;

  if (instant) {
    $contentCont.css(style);
  }
  else {
    // start new animation
    //'margin-left' : '+=' + moveDelta + 'px'
    $contentCont.animate(style, scrollAnimTime, 'easeOutExpo');
  }

  onScroll(x + DesktopScrollDelta);
}

function setupScrollingDesktop() {
  // three menu bars on the left (can change in width as you open menus inside of it)
  $leftMenu = $('.container-3-bars');

  // background image
  $backgroundCont = $('.random-background');

  // all content columns (it's the thing that is being moved around)
  $contentCont = $('#movingContainer');

  // vertical bar on the right with scroll controls
  $rightNavBar = $('.bar-navigation');


  // hackfix: #movingContainer has a fixed width and sometimes does not contain all columns, so we offset them here
  // const missingW = 480;  
  $contentCont[0].style.width = 'fit-content';

  // ########################################
  // event handlers
  // ########################################

  $('.forward').on('click', evt => {
    scrollToX(1e9);
  });
  $('.rewind').on('click', evt => {
    scrollToX(0);
  });
  $('#nextButton').click((evt) => {
    // scroll to the right by moving it to the left
    scrollToX(scrollX + DesktopScrollDelta);
  });
  $('#previousButton').click((evt) => {
    // scroll to the left by moving it to the right
    scrollToX(scrollX - DesktopScrollDelta);
  });

  // fix scroll position on resize: don't overshoot!
  const ro = new ResizeObserver((entries, observer) => {
    scrollToX(Math.min(scrollX, computeMaxScrollX()), true);
  });
  ro.observe($leftMenu[0]);
}

// ###########################################################################
// Mobile
// ###########################################################################

let mobileScrollContEl;

let hackfix_mobileScrollInterrupted = false;
let hackfix_mobileScrollLeft;

/**
 * hackfix: For some reason, on mobile, sometimes when clicking certain 
 * buttons (which in turn make CSS transform changes), a scroll event is generated,
 * catapulting the user back to the scroll position before the current or last swipe.
 */
export function hackfix_setScrollingInterrupted(interrupted) {
  if (!mobileScrollContEl) {
    return;
  }

  hackfix_mobileScrollInterrupted = interrupted;
  if (hackfix_mobileScrollInterrupted) {
    // pausing
    hackfix_mobileScrollLeft = mobileScrollContEl.scrollLeft;
  }
  else {
    // resuming
    mobileScrollContEl.scrollLeft = hackfix_mobileScrollLeft;
  }
}

// Only load content after starting to scroll on mobile
function setupScrollingMobile(firstSwipeCb) {
  let touched = false;
  mobileScrollContEl = $('.container-mobile-columns')[0];

  function handleSwipe(evt) {
    if (!touched) {
      firstSwipeCb();
      touched = true;
    }

    // evt.stopPropagation();
    // evt.preventDefault();
    // evt.returnValue = false;

    // console.debug('[swipe]', evt);

    onScroll(0);

    // hackfix_scrollController(evt);
    // return false;
  }

  mobileScrollContEl.addEventListener("scroll", handleSwipe, true);
  // mobileScrollContEl.addEventListener("scroll", handleSwipe, {
  //   passive: false,
  //   capture: true
  // }, true);

  // function hackfix_scrollController(evt) {
  //   if (hackfix_mobileScrollInterrupted) {
  //     // this is the only way to interrupt scrolling dynamically the way we need to
  //     evt.preventDefault();
  //     evt.returnValue = false;
  //     return false;
  //   }
  // }
  // mobileScrollContEl.addEventListener("touchmove", hackfix_scrollController, {
  //   passive: false
  // });
}

// ###########################################################################
// onScroll event handler
// ###########################################################################

function onScroll(advanceX) {
  updateVisibleContentColumns(advanceX);
}

// ###########################################################################
// init
// ###########################################################################

export function initContentScroller(onFirstMobileSwipe) {
  if (isMobile()) {
    setupScrollingMobile(onFirstMobileSwipe);
  }
  else {
    setupScrollingDesktop();
  }


  // ##################################################################################################################
  // Snap to grid scrolling for mobile
  // ##################################################################################################################

  // function setupScrollingMobile() {
  //   var scrollSnapDelay = 300;
  //   var scrollTimer;

  //   var scrollContainer = $('.mobile-section');
  //   // var scrollContainer = $(window);

  //   function getX(el) {
  //     return el.getBoundingClientRect().x;
  //   }

  //   function snapLeft(container, el) {
  //     var x = getX(el);
  //     var currentLeft = container.scrollLeft();
  //     scrollContainer.animate({
  //       scrollLeft: currentLeft + x
  //     }, 500, 'easeInOutQuart');
  //   }

  //   function snapRight(container, el) {
  //     var x = getX(el);
  //     //var w = $(window).width();
  //     var w = $(el).outerWidth() + 10; // (include space)

  //     var currentLeft = container.scrollLeft();
  //     scrollContainer.animate({
  //       scrollLeft: currentLeft + x - w
  //     }, 500, 'easeInOutQuart');
  //   }

  //   scrollContainer.scroll(() => {
  //     if (scrollTimer) {
  //       clearTimeout(scrollTimer);
  //     }
  //     scrollTimer = setTimeout(() => {
  //       var columns = $('.container-columns').parent().toArray();
  //       var w = $(window).width();

  //       var leftCol = columns.find(col => getX(col) > 0 && getX(col) < w / 2);
  //       if (leftCol) {
  //         // snap!
  //         snapLeft(scrollContainer, leftCol);
  //       }
  //       else {
  //         // try snap right-hand side to the right
  //         var i = columns.findIndex(col => getX(col) > w / 2 && getX(col) < w);

  //         leftCol = columns[i - 1];
  //         if (leftCol && $(leftCol).index() > 1) {  // (ignore first column)
  //           snapLeft(scrollContainer, leftCol);
  //           //snapRight(scrollContainer, rightCol);
  //         }
  //       }

  //       //console.log(x);
  //     }, scrollSnapDelay);
  //   });
  // }
}
