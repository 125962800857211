import firebase from 'firebase/app';
import Dropzone from 'dropzone';

/**
 * 
 */
export default class SingleFirebaseDropzoneRenderer {
  handleUploadCompleted;
  transformFile;

  static getOrCreate(selector) {
    const el = $(selector)[0];
    if (!el) {
      throw new Error('invalid dropzone selector - element not found: ' + selector);
    }

    let dropzonedropzoneRenderer = el._dropzoneRenderer;
    if (!dropzonedropzoneRenderer) {
      dropzonedropzoneRenderer = el._dropzoneRenderer = new SingleFirebaseDropzoneRenderer(selector);
    }

    return dropzonedropzoneRenderer;
  }

  constructor(selector) {
    const el = $(selector)[0];
    if (!el) {
      throw new Error('invalid dropzone selector - element not found: ' + selector);
    }

    if (el._dropzone) {
      throw new Error('element already has a dropzone');
    }


    // create new dropzone
    // see: https://www.dropzonejs.com/#configuration-options
    const dropzone = this.dropzone = el._dropzone = new Dropzone(selector, {
      url: '/',
      addRemoveLinks: true,
      method: 'put',
      chunking: true,
      forceChunking: true,
      autoQueue: false,
      autoProcessQueue: false
    });
    dropzone.autoDiscover = false;

    $(el).addClass('dropzone'); // this is essential! (but not well documented)


    // added file
    dropzone.on("addedfile", async (file) => {
      if (dropzone.files.length > 1) {
        // currently only supports one file
        console.error('tried to add more than one file');
      }

      console.log("added file")
      try {
        await this.startTransformFile(file);
        await this.uploadFile(file);

        this.handleUploadCompleted && this.handleUploadCompleted(this);
      }
      catch (err) {
        // console.error('upload failed :(', err);
        // alert('upload failed :(');
      }
    });

    // dropzone.on("removedfile", function (file) {
    //   var storageRef = firebase.storage().ref("/");
    //   storageRef.child(file.fullPath).delete().then(
    //     function () {
    //       console.log(file.fullPath + " deleted succefuly")
    //     }).catch(function (error) {
    //       console.log("Something is wrong")
    //       console.log(error)
    //     });
    // });
  }


  // ###########################################################################
  // uploading
  // ###########################################################################

  async uploadFile(fileEl) {
    // // firebase storage ref
    // // const uid = authState.uid;
    // // const fileName = fileEl.value;
    // // const storagePath = `${uid}/${fileName}`;
    // const { storagePath } = this;
    // if (!this.storagePath) {
    //   throw new Error('storagePath not set in dropzone ' + fileEl);
    // }
    // const storageRef = firebase.storage().ref(storagePath);
    // const uploadTask = this.upload = storageRef.put(fileEl);

    // // progress bar (https://www.dropzonejs.com/#create-dropzones-programmatically#layout)
    // const progressBarEl = fileEl.previewElement.querySelector('.dz-progress');
    // const progressBarProgressEl = progressBarEl.querySelector('.dz-upload');
    // progressBarEl.opacity = 1;

    // // monitor upload progress
    // // see: https://firebase.google.com/docs/storage/web/upload-files#full_example
    // uploadTask
    //   .on(firebase.storage.TaskEvent.STATE_CHANGED,

    //     // update
    //     (snapshot) => {
    //       // see https://stackoverflow.com/a/41069181
    //       const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //       progressBarProgressEl.style.width = progress + '%';
    //     },

    //     // error
    //     (err) => {
    //       // A full list of error codes is available at
    //       // https://firebase.google.com/docs/storage/web/handle-errors
    //       console.error('upload failed', err);
    //     }
    //   );

    // try {
    //   await uploadTask;

    //   // when finished we can render relevant meta data
    //   // await this._getMetadata(storageRef);
    // }
    // finally {
    //   // hide progress bar
    //   progressBarEl.style.opacity = 0;
    // }
  }
  

  // ###########################################################################
  // upload finished -> allow user to edit
  // ###########################################################################

  /**
   * NOTE: this function is called right after upload finished, allowing us to modify the result.
   */
  // _startTransformFile = async (file, done) => {
  //   if (this.transformFile) {
  //     // do the transform thing
  //     const resultFile = await this.transformFile(file);
  //     done(resultFile);
  //   }
  //   else {
  //     // no transform callback
  //     done(file);
  //   }
  // }

  async startTransformFile(file) {
    return this.transformFile && (await this.transformFile(file)) || file;
  }

  reset() {
    this.dropzone.removeAllFiles(true);
  }


  // ###########################################################################
  // path management
  // ###########################################################################

  // setPath(storagePath) {
  //   // set path
  //   this.storagePath = storagePath;

  //   // remove all files when path is updated
  //   this.dropzone.removeAllFiles(true);

  //   // TODO: render image that currently exists at path
  // }


  // ###########################################################################
  // firebase storage metadata
  // ###########################################################################

/**
 * NOTE: updating `fullPath` would allow us to easily remove existing files through the same interface
 */
  // _getMetadata(targetFileRef) {
    // const meta = await targetFileRef.getMetadata();
    // const downloadUrl = await targetFileRef.getDownloadURL();

    // // storing meta data and download url in object and returning 
    // // it to callign function ... 
    // var result = {
    //   publicURL: downloadUrl,
    //   metainfo: meta
    // }
    // fileEl.fullPath = meta.fullPath
    // return result;
  // }
}

export function getOrCreateDropzoneRenderer(selector) {
  const el = $(selector)[0];
  if (el.dropzoneRenderer) {
    // hackfix: just re-use existing dropzone, if possible
    return el.dropzoneRenderer;
  }
  return el.dropzoneRenderer = new SingleFirebaseDropzoneRenderer(selector);
}