import map from 'lodash/map';
import identity from 'lodash/identity';

/**
 * Simplistic (read: not very robust!) CSV reader implementation.
 * 
 * @see https://stackoverflow.com/questions/27979002/convert-csv-data-into-json-format-using-javascript
 */
export function deserializeCSV(csv, keyCb = identity, valueCb = identity) {
  var rows = csv.split("\n");
  var result = [];
  var headers = rows[0].trim().split(",");
  headers = map(headers, header => header.trim());
  for (var iRow = 1; iRow < rows.length; iRow++) {
    var obj = {};
    var currentline = rows[iRow].split(",");

    for (var iCol = 0; iCol < headers.length; iCol++) {
      const value = currentline[iCol].replace(new RegExp('"', 'g'), '');
      const key = keyCb(headers[iCol], iRow, iCol);
      obj[key] = valueCb(value, iRow, iCol);
    }

    result.push(obj);

  }

  return result;
}