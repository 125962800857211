import FirestoreContainer from 'src/firebase/FirestoreContainer';
import { onAuthStateChanged, authState, onUIDChanged } from '../auth';
import db, { NotLoaded } from '../db';
import userStats from './users/userStats';

const maxShortcutCount = 6;

class Shortcuts extends FirestoreContainer {
  _inited = false;
  constructor() {
    super('shortcuts');
    this.enableCache();
  }

  initShortcuts() {
    if (this._inited) {
      throw new Error('tried to initialize shortcuts more than once');
    }
    this._inited = true;
    // perfLog('[init] shortcuts');
    onUIDChanged(uid => {
      this.clear();

      // only get shortcuts if logged in
      // perfLog('[query] shortcuts');
      this.where('uid', '==', uid);
    }, true);
  }

  getMaxShortcutCount() {
    return maxShortcutCount;
  }

  hasMaxShortcuts() {
    return this.count() >= maxShortcutCount;
  }

  hasTag(tag) {
    return !!this.getShortcutIdOfTag(tag);
  }

  getShortcutIdOfTag(tag) {
    const docsById = this.getAllNowNotNull();
    return docsById && (Object.keys(docsById).find(shortcutId => docsById[shortcutId].tag === tag) || null);
  }

  getOldestShortcutId() {
    const shortcutIds = this._getShortcutIds();
    const docsById = this.getAllNowNotNull();
    return shortcutIds && (shortcutIds.reduce((a, x) => docsById[x].num < docsById[a].num && x || a) || null);
  }

  getLatestShortcutId() {
    const docsById = this.getAllNowNotNull();
    const shortcutIds = this._getShortcutIds();
    return shortcutIds && (shortcutIds.reduce((a, x) => docsById[x].num > docsById[a].num && x || a) || null);
  }

  _getShortcutIds() {
    const docsById = this.getAllNowNotNull();
    if (!docsById) {
      return NotLoaded;
    }
    const shortcutIds = Object.keys(docsById);
    if (!shortcutIds.length) {
      return null;
    }
    return shortcutIds;
  }

  /**
   * Add shortcut for tag, if not in user's shortcut list yet,
   * Deletes it otherwise.
   */
  toggleShortcut(tag, style, link) {
    const existingShortcutId = this.getShortcutIdOfTag(tag);
    if (existingShortcutId === NotLoaded) {
      return NotLoaded;
    }

    if (existingShortcutId) {
      // delete
      return this.deleteShortcut(existingShortcutId);
    }
    else {
      // add
      return this.addShortcut(tag, style, link);
    }
  }

  addShortcut(tag, style, link) {
    const oldestId = this.getOldestShortcutId();
    if (oldestId === NotLoaded) {
      return NotLoaded;
    }

    // keep nums running
    const latestId = this.getLatestShortcutId();
    let num;
    if (latestId) {
      const latestShortcut = this.getDocNowOrQuery(latestId);
      num = latestShortcut.num + 1;
    }
    else {
      num = this.count() + 1;
    }

    if (this.hasMaxShortcuts()) {
      // delete oldest, if there are too many
      this.deleteDoc(oldestId);
    }

    // actually add new shortcut
    const ShorthandLength = 3;
    const { uid } = authState;
    const shortName = tag.length > ShorthandLength ? tag.substring(0, ShorthandLength) : tag;

    console.debug('adding shortcut', tag, num)

    const shortcut = {
      uid,
      num,
      tag,
      link,
      shortName,
      style
    };

    return userStats.addStatDefault('shortcuts', 'shortcuts', shortcut);
  }

  async deleteShortcut(shortcutId) {
    return userStats.deleteStatDefault('shortcuts', 'shortcuts', shortcutId);
  }
}


const shortcuts = new Shortcuts();

export function initShortcuts() {
  shortcuts.initShortcuts();
}

export default shortcuts;