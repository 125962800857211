import { showNotLoggedInUserActionWarning } from 'src/renderUtil/userActions';
import SearchRenderer from '../../renderUtil/SearchRenderer';
import { searchMovies, searchTv } from '../../api/third-party/tmdb';
import MultiCollapse from '../../util/MultiCollapse';
import ContentType from 'src/api/content/ContentType';
import InputItemView from 'src/render/content/InputItemView';
import { ContentInputForm } from 'src/render/content/ContentInputForm';
import { postProcessContent } from 'src/api/content/contentPostProcessing';

// ###########################################################################
// SearchInputForm
// ###########################################################################
class SearchInputForm extends ContentInputForm {
  search;
  /**
   * @type {MultiCollapse}
   */
  collapseResults;
  /**
   * @type {InputItemView[]}
   */
  inputItems = [];

  onInit() {
    const search = this.search = new SearchRenderer(
      this.$inputFormEl,
      'input', '.results-list', '.div-template-wiki',
      {
        startSearch: this.startSearch,
        transformResult: this.transformResult,
        initRenderResult: this.initRenderResult,
        renderResult: this.renderResult
      }
    );
    search.$resultList.removeClass('hidden');

    // prepare multi-collapse for rendering all results
    this.collapseResults = new MultiCollapse(this.$resultList,
      '.wiki-title h3', '.expandable-wiki-title-section', {
      onExpand: this.onExpand
    });
  }

  startSearch = (searchTerm) => {
    throw new Error('abstract method NYI');
  }

  initRenderResult = ($el, result, i) => {
    // const $submitBtn = $el.find('a');

    // setup all default events + decorations

    // create InputItem
    this.inputItems[i] = new InputItemView(this, $el);
    this.inputItems[i].onUrlUpdate(result.url);

    // add accordion-like functionality
    this.collapseResults.toggleOnClick($el);

    if (i == 0) {
      // expand first by default
      this.collapseResults.setExpanded($el, true);
    }

    $el.show();
  }

  renderResult = ($el, result, i) => {
    // render content
    const $titleEl = $el.find('.wiki-title h3');
    const $descriptionEl = $el.find('.wikipedia-description');
    const $thumbEl = $el.find('.poster-preview');

    $titleEl.text(result.title);
    $descriptionEl.text(result.description);
    $thumbEl.attr('src', result.thumbnail_url);

    const inputItem = this.inputItems[i];
    inputItem.busy = !result._complete;

    // render other stuff
    inputItem.render();

    // click behavior changes over time, so we hook it up here (?)
    inputItem.$submitBtn.off('click').on('click', (evt) => {
      evt.preventDefault();
      this.tryAddContent(result, this.inputItems[i]);
    });
  }

  async tryAddContent(data, inputItem) {
    if (showNotLoggedInUserActionWarning()) {
      return false;
    }

    const url = data.url;
    
    if (!await inputItem.handleSubmit(data)) {
      return;
    }

    // automatic tagging et al.
    await postProcessContent(data);

    // hackfix: handle internally used `_complete` property -> copy and delete
    const contentObject = { ...data };
    delete contentObject._complete;
    await inputItem.addContent(contentObject);
  }

  onExpand = ($el) => {
    // nothing to do
  }
}

// ###########################################################################
// Film
// ###########################################################################

export class FilmInputForm extends SearchInputForm {
  contentType = ContentType.Film;
  startSearch = searchMovies;
}

// ###########################################################################
// Tv Series
// ###########################################################################

export class SeriesInputForm extends SearchInputForm {
  contentType = ContentType.Series;
  startSearch = searchTv;

  transformResult(result) {
    // console.debug('series', result);
  }
}
