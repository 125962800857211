import { authState } from 'src/auth';
import UserStatus, { isUserStatusNew } from 'src/api/users/UserStatus';
import { getMyReelHref } from 'src/api/links';
import { doesPageRequireAuth, isApprovalPage } from 'src/pages';
import userSettings from 'src/api/users/userSettings';
import { startWebflowAnimNotResponsive } from 'src/renderUtil/animUtil';
import { redirectButKeepHistory } from 'src/util/historyUtil';

export async function routingAfterAuth() {
  forcedRouting();

  if (authState.uid) {
    const status = authState.userInfo?.status;
    if (isUserStatusNew(status)) {
      // ask user if they want to be sent to reel page to fill out info
      await askUserToFillOutInfo();
    }
  }
}

// ###########################################################################
// forced routing
// ###########################################################################

function forcedRouting() {
  if (doesPageRequireAuth() && !authState.uid) {
    alert('Not logged in. Redirecting...');
    window.location.replace('/');
  }
  if (isApprovalPage() && !authState.isModerator()) {
    alert('Moderators only. Redirecting...');
    window.location.replace('/');
  }
}

// ###########################################################################
// reminders for new users
// ###########################################################################

async function askUserToFillOutInfo() {
  const myReelPath = getMyReelHref();

  if (location.pathname !== myReelPath) {
    if (!await userSettings.needsFillOutReminder()) {
      return;
    }
    const yes = confirm('You have not filled out your basic information yet. You can fill them out in the settings on the left, and then press "Save Changes".');
    await userSettings.storeFillOutReply(yes);
    if (yes) {
      // redirect, but with old location staying in history
      redirectButKeepHistory(myReelPath);
    }
  }
  else {
    // open settings
    // $('.settings').click();
    startWebflowAnimNotResponsive('settings-intro');
  }
}