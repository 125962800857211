import reelCollectionsContainer, { getCollectionLabel } from 'src/api/reel/reelCollectionContainer';
import { waitUntilAuthStateInitialized } from 'src/auth';
import { prepareTemplateElement, cloneTemplateElement, decorateClasses, getTemplateElement, copyTemplateElement } from 'src/util/domUtil';
import { getSelectedCheckboxEl, setSelectedCheckboxEl, isSelectedCheckboxEl } from 'src/webflow/webflowFormHelpers';
import State from 'src/util/State';
import { SaveChangesButton } from 'src/render/userSettings/SubmitButton';
import ContentType from 'src/api/content/ContentType';
import WebflowTabView, { WebflowTab } from 'src/renderUtil/WebflowTabView';
import CollectionAccessMode from 'src/api/reel/CollectionAccessMode';
import { findContsByAccessMode, ReelCollectionContEl } from 'src/renderUtil/reel/ReelCollectionContEl';
import { startWebflowAnimation } from 'src/renderUtil/animUtil';
import { sleep } from 'src/util/sleep';
import { openCollectionContributorModal } from 'src/render/collections/CollectionContributorEditor';
import { querySystemCollectionLabels } from 'src/api/contentCollections/systemCollectionLabels';
import { isSystemCollection } from 'src/api/contentCollections/SystemCollectionConfig';

function getCollectionElFromAncestor($el) {
  return $el.closest('.individual-collection-settings');
}

function getCollectionNameFromAncestorEl($el) {
  return getCollectionElFromAncestor($el).attr('data-name');
}


export default class ReelCollectionSettingsRenderer extends WebflowTabView {
  // ########################################
  // basic WebflowTabView setup
  // ########################################
  static contSelector = '.collections-settings-ctn';

  static getWebFlowTabClass(buttonEl, tabEl) {
    if (tabEl.querySelector('.settings-tab')) {
      return SettingsTab;
    }
    return ContentTab;
    // return () => { init() {} };
  }

  // ########################################
  // props + ctor
  // ########################################

  state = new State();
  tabsByContentType;

  constructor(reelId, $parent) {
    super({ parent: $parent[0] });

    this.reelId = reelId;
    this.$parent = $parent;
  }


  // ########################################
  // getters
  // ########################################

  get settingsTab() {
    return this.getTabsOfClass(SettingsTab)[0];
  }

  getContentTab(contentType) {
    return this.tabsByContentType[contentType];
  }

  // ########################################
  // init
  // ########################################

  /**
   * NOTE: init is called after tabs are initialized
   */
  async init() {
    super.init();

    this.saveBtn = new SaveChangesButton(this.$cont, this.state, this.submit);

    // add tabs
    const contentTabs = this.getTabsOfClass(ContentTab);
    this.tabsByContentType = Object.fromEntries(
      contentTabs
        .filter(tab => tab.valid)
        .map(tab => [tab.contentType, tab])
    );

    this.state.inc('busy');
    try {
      await Promise.all([
        waitUntilAuthStateInitialized(),
        querySystemCollectionLabels()
      ]);

      // onUIDChanged(() => {
      // start loading (if not loaded already)
      this.saveBtn.setBusyWhile(this.load());
      // }, false, false);
    }
    finally {
      this.state.dec('busy');
    }
  }

  load = async () => {
    await reelCollectionsContainer.queryDoc(this.reelId, true);
  }


  // ########################################
  // submit
  // ########################################

  submit = async () => {
    // get data from content tabs
    const defaultCollections = new Array(ContentType.getCount()).fill(null).map((_, contentType) => {
        const tab = this.tabsByContentType[contentType];
        const defaultCollectionName = tab?.getSelectedDefaultCollectionName();
        return defaultCollectionName || null;
      });

    return await reelCollectionsContainer.updateDefaultCollections(this.reelId, defaultCollections);
  }
}

// ###########################################################################
// Reel collection TabBase
// ###########################################################################

class TabBase extends WebflowTab {
  /**
   * Container divs of each `AccessMode`.
   * @type {Map}
   */
  $contsByAccessMode;

  templateSelector = '[data-name="newCollection"]';

  get reelId() {
    return this.tabView.reelId;
  }

  getCollectionEl(collectionName) {
    return this.$tab.find(`[data-name="${collectionName}"]`);
  }

  makeCollectionIdRef(collectionName) {
    return {
      reelId: this.reelId,
      collectionName
    };
  }

  init() {
    // TODO: selectedFirstTime event is currently not triggered when the entire TabView comes into view the first time (with first tab already selected)
    this.initRender();
    if (!this.valid) {
      return;
    }

    // force a first render
    this.renderCollections(true);

    reelCollectionsContainer.onUpdate(() => {
      this.render();
    });
  }

  /**
   * 1. calls `prepareTemplateElement`
   * 2. registers `userCollectionsContainer` update listener
   */
  initRender() {
    const { $tab } = this;
    
    this.$contsByAccessMode = new ReelCollectionContEl(this.reelId, $tab);

    // NOTE: for now, only public collections cont contains the template, so we copy it to protected + private
    const $publicCollectionsCont = this.$contsByAccessMode.get(CollectionAccessMode.Public);
    
    // TODO: remove some outdated (construction site) code
    // if (!$publicCollectionsCont.length) {
    //   // empty tab
    //   // console.debug('UserCollectionSettings tab is empty', $tab[0]);
    //   this.valid = false;
    //   $tab.append('👷‍♀️ construction site 👷‍♀️');
    //   return;
    // }
    this.valid = true;
    this.$collectionsCont = $publicCollectionsCont.parent();
    prepareTemplateElement($publicCollectionsCont, this.templateSelector);

    const $privateCollectionsCont = $tab.find('.private-collections-ctn');

    if ($privateCollectionsCont.length) {
      const $protectedCollectionsCont = $tab.find('.protected-collections-ctn');
      copyTemplateElement($publicCollectionsCont, $privateCollectionsCont, this.templateSelector);
      copyTemplateElement($publicCollectionsCont, $protectedCollectionsCont, this.templateSelector);
    }

    // re-render on state change
    this.tabView.state.onUpdate(() => this.render());

    // // add new div to contain custom collections
    // this.$customCollectionCont = $('<div></div>');
    // $collections.parent().append(this.$customCollectionCont);
  }


  // ###########################################################################
  // render
  // ###########################################################################

  render() {
    if (!this.valid) {
      return;
    }

    this.renderCollections();
  }

  selected() {
    this.render();
  }

  renderCollections = (force = false) => {
    // if (!force && (this.busy || !this.isVisible())
    // ) {
    //   // don't re-render user collections while its still working on stuff
    //   return;
    // }

    const { $tab } = this;

    // clear
    $tab.find('[data-custom=1]').remove();

    for (const [accessMode, $cont] of this.$contsByAccessMode) {
      if (!$cont.length) {
        // NOTE: we are only rendering public collections for the `default` selection
        continue;
      }

      let collections = reelCollectionsContainer.getCollectionsOfAccessMode(this.reelId, accessMode);
      if (!collections) {
        continue;
      }

      collections = this._filterCollections(collections);

      for (const collection of collections) {
        this._initRenderCollection(collection, $cont);
      }
    }
  }

  _filterCollections(x) {
    return x;
  }

  _initRenderCollection(collection, $cont) {
    // create new element
    const $collection = cloneTemplateElement($cont, this.templateSelector);
    let { collectionName } = collection;

    // set attributes
    $collection.attr('data-name', collectionName);
    $collection.attr('data-custom', 1); // allows us to easily identify/delete them

    // get children
    const $label = $collection.find('span');

    // label
    $label.text(getCollectionLabel(collectionName, this.reelId, this.contentType));   // NOTE: system tab has no contentType

    // append to parent
    $collection.removeClass('hidden');
    $cont.append($collection);

    // render per-tab stuff
    this.renderCollection(collectionName);
  }
}


// ###########################################################################
// SettingsTab (the first tab)
// ###########################################################################


class SettingsTab extends TabBase {
  init() {
    super.init();

    // NOTE: disabledWhileBusy won't work, because collection element is deleted + re-added upon change
    // disabledWhileBusy(this.getPrivateButtonEls(), this.tabView.state);
  }

  // getPrivateButtonEls() {
  //   const $customCollections = this.$collectionsCont.find('[data-custom=1]');
  //   return $customCollections.find('.private');
  // }

  // getCollections() {
  //   const $customCollections = this.$collectionsCont.find('[data-custom=1]');
  //   const allArr = Array.from($customCollections)
  //     .map(el => el.getAttribute('data-name'));
  //   const privateArr = Array.from(this.getPrivateButtonEls())
  //     .map(el => isSelectedCheckboxEl($(el)));

  //   return [allArr, privateArr];
  // }

  /**
   * Ignore system collections
   */
  _filterCollections(collections) {
    return collections.filter(({ collectionName }) => !isSystemCollection(collectionName));
  }


  // ########################################
  // initRender
  // ########################################

  initRender() {
    super.initRender();

    // init all parts
    this.initAddCollectionForm();
    this.renderBuiltinCollections();
  }


  initAddCollectionForm() {
    const { $tab } = this;

    this.$addCollectionCont = $tab.find('.creating-new-collection');

    const $collectionNameInput = $tab.find('.creating-new-collection input[type="text"]');
    const $addCollectionBtn = $tab.find('.creating-new-collection a');

    $addCollectionBtn.on('click', async evt => {
      if ($addCollectionBtn.hasClass('disabled')) {
        return;
      }

      const collectionName = $collectionNameInput.val();

      if (!collectionName) {
        alert('Collection name cannot be empty. Suggestion: pick a name before pressing the button');
        return;
      }

      if (reelCollectionsContainer.hasCollection(this.makeCollectionIdRef(collectionName))) {
        alert('Collection of same name already exists. Try choosing another name: ' + collectionName);
        return;
      }

      try {
        $addCollectionBtn.addClass('disabled');
        await reelCollectionsContainer.addCollection(this.makeCollectionIdRef(collectionName), CollectionAccessMode.Public);
      }
      finally {
        $addCollectionBtn.removeClass('disabled');
      }

      $collectionNameInput.val('');
    });
  }

  /**
   * show private status of built-in collections
   */
  renderBuiltinCollections() {
    // NOT necessary anymore

    // const { $tab } = this;

    // this.$builtinCollectionPrivateButtons = $tab.find('.private');
    // this.$builtinCollectionPrivateButtons.each(function (i, privateBtnEl) {
    //   const $privateBtn = $(privateBtnEl);
    //   const collectionName = getCollectionNameFromAncestorEl($privateBtn);

    //   $privateBtn.prop('disabled', true);
    //   $privateBtn.prop('checked', isSystemCollectionPrivate(collectionName));
    // });
  }

  // ########################################
  // deleteCollection
  // ########################################

  async deleteCollection(collectionName) {
    if (!confirm(`Are you sure you want to delete collection "${collectionName}"`)) {
      return;
    }

    // try {
    await reelCollectionsContainer.deleteCollection(this.makeCollectionIdRef(collectionName));
    // }
    // finally {

    // }
  }

  // ###########################################################################
  // setAccessMode
  // ###########################################################################

  async setAccessMode(collectionName, accessMode) {
    const modeName = CollectionAccessMode.nameFromForce(accessMode).toLowerCase();
    if (!confirm(`Are you sure you want to make collection "${collectionName}" ${modeName}? 👁️👁️`)) {
      return;
    }

    await reelCollectionsContainer.setAccessMode(this.makeCollectionIdRef(collectionName), accessMode);
  }

  // ########################################
  // render
  // ########################################

  /**
   * NOTE: Called by TabBase.renderCollections when collection data changed.
   */
  renderCollection(collectionName) {
    // access mode buttons
    const accessModeCfg = {
      Private: '.private',
      Protected: '.protect',
      Public: '.public'
    };

    const $collection = this.getCollectionEl(collectionName);

    // contributor button
    const $contributorBtn = $collection.find('.user-permissions');
    $contributorBtn.off('click').on('click', async () => {
      startWebflowAnimation('user-permissions');
      await sleep();

      openCollectionContributorModal(this.makeCollectionIdRef(collectionName));
    });

    // access mode buttons
    for (const accessModeName in accessModeCfg) {
      const selector = accessModeCfg[accessModeName];
      const newAccessMode = CollectionAccessMode.valueFromForce(accessModeName);
      const $accessBtn = $collection.find(selector);
      const isThisMode = reelCollectionsContainer.hasCollectionAccessMode(this.makeCollectionIdRef(collectionName), newAccessMode);
      // console.debug(collectionName, accessModeName, isThisMode);
      decorateClasses($accessBtn, {
        hidden: isThisMode
      });
      if (!isThisMode) {
        $accessBtn.off('click').on('click', () => {
          this.setAccessMode(collectionName, newAccessMode);
        });
      }
    }

    // delete button event handler
    const $deleteBtn = $collection.find('.delete');
    $deleteBtn.on('click', evt => {
      this.deleteCollection(collectionName);
    });
  }
}



// ###########################################################################
// ContentTab (one tab per content type to select default collection for content columns)
// ###########################################################################

class ContentTab extends TabBase {
  init() {
    const contentTypeName = this.$tab.children().attr('data-content-type');
    this.contentType = ContentType.valueFrom(contentTypeName);
    this.$defaultButtons = this.$tab.find('.individual-collection-settings [type="radio"]');

    if (!this.contentType) {
      this.valid = false;
      return;
    }

    super.init();
  }

  getSelectedDefaultCollectionName() {
    // default collection
    // const $radio = getSelectedCheckboxEl(this.$collectionsCont);
    // return getCollectionNameFromAncestorEl($radio);
    return this._defaultSelection;
  }

  // ########################################
  // initRender
  // ########################################

  render() {
    // NOTE: `super.render()` will also render custom collections
    super.render();

    if (!this.valid) {
      return;
    }

    this.renderSystemCollections();
  }


  renderSystemCollections() {
    const {
      contentType,
      $defaultButtons
    } = this;

    // let defaultCollectionName = reelCollectionsContainer.getDefaultCollection(this.reelId, contentType);
    // if (!defaultCollectionName) {
    //   // select first, if none was selected
    //   defaultCollectionName = getCollectionNameFromAncestorEl($defaultButtons.eq(0));
    // }

    // Array.from($defaultButtons).forEach((btnEl) => {
    $defaultButtons.each((i, btnEl) => {
      const $btn = $(btnEl);
      const collectionName = getCollectionNameFromAncestorEl($btn);

      this.renderCollection(collectionName);
    });
  }


  /**
   * NOTE: Called by TabBase.renderCollections upon `render`.
   */
  renderCollection(collectionName) {
    const { contentType } = this;
    const $collection = this.getCollectionEl(collectionName);
    const $defaultBtn = $collection.find('input[type="radio"]');
    const ref = this.makeCollectionIdRef(collectionName);
    const isDefault = reelCollectionsContainer.isDefaultCollection(ref, contentType);

    setSelectedCheckboxEl($defaultBtn, isDefault);

    // store + update default selection
    if (isDefault) {
      this._defaultSelection = collectionName;
    }
    $defaultBtn.off('click').on('click', () => {
      this._defaultSelection = collectionName;
    });

    // hide collection if empty
    this.renderEmptyState($defaultBtn, collectionName);
  }


  async renderEmptyState($defaultBtn, collectionName) {
    const { contentType } = this;
    const ref = this.makeCollectionIdRef(collectionName);
    const isEmpty = await reelCollectionsContainer.queryIsCollectionEmpty(ref, contentType);
    // console.debug('collection empty', isEmpty, collectionName, contentType);

    const $collection = getCollectionElFromAncestor($defaultBtn);
    decorateClasses($collection, {
      hidden: isEmpty
    });
  }
}
