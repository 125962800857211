import { queryIframely } from 'src/api/third-party/iframely';
import { ytSearchItems } from 'src/web-apis/youtube/YtAPI';
import EmptyObject from 'src/util/EmptyObject';
import { getYoutubeListIdFromUrl } from 'src/api/content/contentUrlAnalyzer';
import { queryYoutubePlaylist, queryAdditionalDataFromYoutubeOrPromoUrl } from 'src/api/third-party/youtubeContent';

// ###########################################################################
// Evaluate results
// ###########################################################################

const criticalDataPoints = [
  'title', 'description', 'thumbnail_url'
];

const moreDataPoints = [
  'author', 'author_url', 'promo'
];


function isGoodResult(result) {
  return isOkResult(result) && moreDataPoints.every(name => !!result[name]);
}

function isOkResult(result) {
  return criticalDataPoints.every(name => !!result[name]);
}

// ###########################################################################
// Youtube lookup + matching
// ###########################################################################

async function getPromoOrYoutubeUrl(url, urlObj, result) {
  return result?.promo ||
    await findBestMatchingYoutubeUrl(url, urlObj, result);
}


const matchHeuristics = [
  // 1. description references the url
  (url, urlObj, result, items) => items.find(item => item.snippet?.description?.includes(url)),


  // 2. channel name matches author or domain name
  (url, urlObj, result, items) => items.find(item => {
    const channel = item.snippet?.channelTitle?.toLowerCase();
    return result?.author?.toLowerCase()?.includes(channel) || urlObj.host.toLowerCase().includes(channel);
  }),


  // 3. title match (original title is included in youtube title)
  (url, urlObj, result, items) => items.find(item => result?.title && item.snippet?.title?.includes(result.title))
];

async function findBestMatchingYoutubeUrl(url, urlObj, result) {
  const items = await ytSearchItems(url, { type: 'video' });
  if (!items?.length) {
    return null;
  }

  // match against all heuristics
  let item;
  for (const cb of matchHeuristics) {
    const res = await cb(url, urlObj, result, items);
    if (res) {
      item = res;
      break;
    }
  }

  // // could not find any item
  // if (!item) {
  //   // could not find a match, just take first video
  //   item = items[0];
  // }
  const videoId = item?.id?.videoId;
  if (!videoId) {
    return null;
  }

  return `https://www.youtube.com/watch?v=${videoId}`;
}


// ###########################################################################
// Specialized patterns
// ###########################################################################

async function queryCourseDefault(origUrl) {
  let result;
  try {
    result = await queryIframely(origUrl);
  }
  catch (err) {
    // iframely might return a 403, resulting in an error
    // console.error('iframely was u');
    result = EmptyObject;
  }

  // clearn URL
  let [urlObj, url] = cleanUrl(origUrl);


  if (!isGoodResult(result)) {
    // didn't get all data -> also try to get more data from youtube

    // e.g.: http://debug.iframely.com/?uri=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DWZQc7RUAg18

    const promoOrYoutubeUrl = await getPromoOrYoutubeUrl(url, urlObj, result);
    if (promoOrYoutubeUrl) {
      result = await queryAdditionalDataFromYoutubeOrPromoUrl(url, promoOrYoutubeUrl, result);
    }

    if (!isOkResult(result)) {
      // still not enough data available
      console.warn('[course] could not find enough data - actual result:', result);
      result = null;
    }
  }

  return result;
}

// ###########################################################################
// queryCourse
// ###########################################################################

/**
 * clean URL: sometimes there are parameters that occlude the youtube query (e.g. utm_source etc)
 */
function cleanUrl(url) {
  let urlObj;
  try {
    urlObj = new URL(url);
  }
  catch (err) {
    console.error('could not parse url', url, '\n\n', err);
    return null;
  }

  urlObj.search = '';
  urlObj.hash = '';
  url = urlObj.href;

  return [urlObj, url];
}

export default async function queryCourse(url) {
  let result;

  const youtubePlaylistId = getYoutubeListIdFromUrl(url);
  if (youtubePlaylistId) {
    // YouTube Playlist
    result = await queryYoutubePlaylist(youtubePlaylistId, url);
  }
  else {
    result = await queryCourseDefault(url);
  }

  if (result && !result.url) {
    result.url = url;
  }

  return result;
}

// [debug-global]
window.queryCourse = queryCourse;
window.queryIframely = queryIframely;