// TODO: seperate render + API/state management code

import firebase from 'firebase/app';
import { UserRole } from 'src/api/roles';
import { getOrSaveUserData } from './api/users/users';
import { setLocalStorageItem, getLocalStorageItem } from './util/localStorage';
import { perfLog } from 'src/util/perf';
import { sleep } from 'src/util/sleep';

// firebase = null;

const MaxCacheLifeTime = 7 * 24 * 60 * 60 * 1000;// in milliseconds

// ###########################################################################
// AuthState
// ###########################################################################

/**
 * @type {AuthState}
 */
let authState;
let authInitQueue = [];
const authStateChangedEventHandlers = [];

class AuthState {
  constructor() {
    this.isInitialized = false;
    this.uid = null;
    this.lastUid = null;
    this.userInfo = null;
    this._unsubscribe = null;
    this._fromCache = false;
  }

  isFromCache() {
    return this._fromCache;
  }

  isLoggedIn() {
    return !!this.userInfo;
  }

  isUser() {
    return this.userInfo && this.userInfo.displayRole >= UserRole.User;
  }

  getEmail() {
    return firebase.auth()?.currentUser?.email || null;
  }

  isModerator() {
    return this.userInfo && this.userInfo.displayRole >= UserRole.Moderator;
  }
}




async function getOrSaveCurrentUserData(user) {
  let userInfo;

  if (!user) {
    userInfo = null;
  }
  else {
    userInfo = await getOrSaveUserData(user);
  }

  authState.lastUid = authState.uid;
  authState.uid = user && user.uid;
  authState.userInfo = userInfo;
  authState._fromCache = false; // TODO: userInfo might actually be from cache
  return userInfo;
}

function writeAuthCache() {
  // update cache
  try {
    setLocalStorageItem('auth', {
      time: new Date(),
      uid: authState.uid,
      userInfo: authState.userInfo
    });
  }
  catch (err) {
    console.error('could not write to cache:', err);
  }
}

export function loadAuthFromCache() {
  try {
    const cached = getLocalStorageItem('auth');
    if (!cached) {
      return;
    }

    let { uid, time, userInfo } = cached;

    if ((new Date(time).getTime() < Date.now()) > MaxCacheLifeTime) {
      throw new Error('cache outdated ' + MaxCacheLifeTime);
    }

    authState.lastUid = authState.uid;
    authState.uid = uid;
    authState.userInfo = userInfo;
    authState._fromCache = true;

    setTimeout(() => handleAuthChanged(null, true));

    console.debug('loaded user from cache');
  }
  catch (err) {
    setLocalStorageItem('auth', '');
    console.error('ignoring cache due to error:', err);
  }
}

export function initAuth(onAuthChange) {
  // perfTest();

  // var x = false;
  // listen for changes in user login status
  // x && 
  firebase.auth().onAuthStateChanged(async function (user) {
    if (user) {
      // User is signed in.
      console.log('User is logged in!', 'Email: ' + user.email, 'UID: ' + user.uid);
      //window.location.replace('/profile');
    } else {
      // User is signed out.
      console.log('No user is logged in');
      //sectionhome.style.display = 'block';
    }

    // perfLog('Logged in, getting user data...');
    await getOrSaveCurrentUserData(user);
    // perfLog('Finished getting user data.');

    // listen for changes in user data as well
    if (authState._unsubscribe) {
      authState._unsubscribe();
    }

    handleAuthChanged(onAuthChange);

    if (!authState.isInitialized) {
      handleAfterAuthInitialize();
    }
  });
}


function handleAuthChanged(onAuthChange, isFromCache) {
  if (!isFromCache) {
    writeAuthCache();
  }

  onAuthChange && onAuthChange();

  // console.log('auth changed', authState.userInfo);

  for (let cb of authStateChangedEventHandlers) {
    cb(authState);
  }
}

/**
 * Only called on first login
 */
async function handleAfterAuthInitialize() {
  authState.isInitialized = true;

  // run all queued up callbacks
  for (let cb of authInitQueue) {
    cb(authState);
  }

  // empty queue
  authInitQueue = [];
}

/**
 * Add auth state changed event handler
 */
export function onAuthStateChanged(cb) {
  authStateChangedEventHandlers.push(cb);
  cb(authState);
}

/**
 * Similar to `onAuthStateChanged`, but will only invoke callback, if
 * uid has changed.
 * Will be invoke right away, if auth is already initialized.
 * @return {Promise} resolves when UID change detected the first time.
 */
export function onUIDChanged(cb, allowCached = true, allowNotLoggedIn = false) {
  function isAllowed() {
    return (allowCached || !authState.isFromCache()) && 
      (allowNotLoggedIn || !!authState.uid);
  }

  // return a promise that triggers for the first time UID changed
  let triggered = false; // only resolve promise once
  return new Promise((resolve) => {
    let lastUid;
    
    async function done() {
      lastUid = authState.uid;
      cb(authState.uid);

      if (!triggered) {
        triggered = true;
        resolve(authState.uid);
      }
    }

    // add callback
    authStateChangedEventHandlers.push(() => {
      const { uid } = authState;
      if (isAllowed() && uid !== lastUid) {
        done();
      }
    });

    if (authState.isInitialized && isAllowed()) {
      // call right away
      done();
    }
  });
}


export function logout() {
  setLocalStorageItem('auth', '');

  firebase.auth().signOut()
    .then(function () {
      location.reload(true);
    })
    .catch(function (err) {
      console.error(err && err.stack || err);
    });
}

// /**
//  * Remove auth state changed event handler
//  */
// function offAuthStateChanged(cb) {
//   const idx = authStateChangedEventHandlers.indexOf(cb);
//   if (idx >= 0) {
//     authStateChangedEventHandlers.splice(idx, 1);
//   }
// }

/**
 * Calls the given function either immediately (if authInit has already finished),
 * or after authInit has been called for the first time.
 */
export async function waitUntilAuthStateInitialized() {
  return new Promise(resolve => {
    if (authState.isInitialized) {
      resolve(authState);
    }
    else {
      authInitQueue.push((resolve));
    }
  });
}

export async function forgotPassword(email) {
  const confirmUrl = `${location.origin}/`;
  await firebase.auth().sendPasswordResetEmail(email, { url: confirmUrl });
}

authState = new AuthState();
export { authState };

// [debug-global]
window.authState = authState;