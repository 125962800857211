export function isMobile() {
  const $mobile = $('.mobile-section');
  return $mobile.length && !$mobile.is(":hidden") || false;
}



export function getContentColumnWidth() {
  if (isMobile()) {
    return window.innerWidth;
  }
  // 480px on desktop (and on wide-screen?)
  return 480;
}

export function isLocalhost() {
  const { origin } = location;
  return (origin.includes('localhost') || origin.includes('127.0.0.1'));
}