import { getOrQueryContentByUrl, getContentObjectsByType } from '../../api/content/contentObjects';

import { startRenderContentPreview, startRenderContentPreviewLoader } from './contentPreview';
import { decorateClasses } from '../../util/domUtil';
import Enum from 'src/util/Enum';
import ContentCollectionEditorTabView from 'src/render/collections/ContentCollectionEditor';
import { onUIDChanged, authState } from 'src/auth';
import SubmitButton from 'src/render/userSettings/SubmitButton';
import State from 'src/util/State';
import { ModerationStatus, isStatusPrivate } from 'src/api/content/ContentActionConfig';
import { initRenderContentCollectionModal, showContentCollectionModal, showAndWaitForContentCollectionModal } from 'src/render/collections/ContentCollectionModal';


// ###########################################################################
// InputItemView
// ###########################################################################

let InputItemState = {
  None: 0,
  ContentDoesNotExist: 1,
  ContentExistsChoice: 2,
  ContentExistsShowLists: 3,
};
InputItemState = new Enum(InputItemState); // we do this to get better intellisense
export { InputItemState }

// function isContentExistState(inputItemState) {
// }

/**
 * Represents a single item to be input
 * (e.g. a URL to be input for videos and articles; one film in the search result list for films)
 */
export default class InputItemView {
  url;
  $el;
  content = null;
  inputItemState = InputItemState.None;
  state = new State();

  /**
   * @type {ContentCollectionEditorTabView}
   */
  contentCollectionEditor;

  constructor(inputForm, $el) {
    this.inputForm = inputForm;

    this.$el = $el;

    this.$allButtons = $el.find('a');
    this.$hiddenIfExisting = $el.find('.hidden-if-existing');
    this.$hiddenIfNotPrivate = $el.find('.hidden-if-not-private');
    this.$listsCont = $el.find('.lists-ctn');

    this.submitBtn = new SubmitButton(this.$el, this.state, null, {
      selector: '.submit-button'
    });
    this.$submitBtn = this.submitBtn.$btn;

    this.$existingContentChoicesCont = $el.find('.existing-content-buttons');

    // actions to be taken for already added content
    this.$previewContentButton = $el.find('.preview-content-button');
    this.$showListsButton = $el.find('.show-lists-button');


    // ########################################
    // register event handlers
    // ########################################

    this.$previewContentButton.on('click', evt => {
      // preview existing content
      evt.preventDefault();
      startRenderContentPreview(this.content._id);
    });

    this.$showListsButton.on('click', evt => {
      // choose to show lists
      evt.preventDefault();

      this.inputItemState = InputItemState.ContentExistsShowLists;
      this.render();
      this._renderCollectionsModal();
    });

    this.initRender();
  }

  // ###########################################################################
  // getters
  // ###########################################################################

  get contentType() {
    return this.inputForm.contentType;
  }

  isInputItemState(namesString) {
    return InputItemState.isAnyOf(this.inputItemState, namesString);
  }
  // ###########################################################################
  // render
  // ###########################################################################

  async initRender() {
    // onUIDChanged(async () => {
    //   // this.busy = true;
    //   // this.render();

    //   // try {
    //   //   await this.contentCollectionEditors.editor.initLoadAndRender();
    //   // }
    //   // finally {
    //   //   this.busy = false;
    //   //   this.render();
    //   // }
    // }, false);
  }

  render() {
    // TODO: this.contentCollectionEditors.editor.state.onUpdate(() => this.render());

    // const { busy } = this.state.get;
    // decorateClasses(this.$allButtons, {
    //   disabled: busy || !this.contentCollectionEditors.editor.hasAnySelected()
    // });

    decorateClasses(this.$hiddenIfExisting, {
      hidden: !this.isInputItemState('ContentDoesNotExist')
    });

    decorateClasses(this.$hiddenIfNotPrivate, {
      hidden: this.content && !isStatusPrivate(this.content.moderationStatus)
    });

    decorateClasses(this.$existingContentChoicesCont, {
      hidden: !this.isInputItemState('ContentExistsChoice')
    });
    decorateClasses(this.$listsCont, {
      hidden: !this.isInputItemState('ContentExistsShowLists ContentDoesNotExist')
    });

    // this.contentCollectionEditors.render();
  }

  async onUrlUpdate(url) {
    if (url == this.url) {
      return; //this.content;
    }

    this.url = url;
    try {
      this.inputItemState = InputItemState.None;
      this.state.inc('busy');
      if (!url) {
        this.content = null;
      }
      else {
        // render busy
        this.render();

        // get video from database
        this.content = await getOrQueryContentByUrl(this.contentType, url);
        if (this.content) {
          this.inputItemState = InputItemState.ContentExistsChoice;

          // hackfix: we don't really want ItemView to be dependent on the editor and modal
          this.contentCollectionEditorsModal = initRenderContentCollectionModal(this.contentType, this.content);
          this.contentCollectionEditors = this.contentCollectionEditorsModal.contentCollectionEditors;
        }
        else {
          this.inputItemState = InputItemState.ContentDoesNotExist;
        }
      }
    }
    finally {
      this.state.dec('busy');

      // finished -> render again
      this.render();
    }

    // return this.content;
  }

  previewContent() {

  }


  // ###########################################################################
  // submitting
  // ###########################################################################


  /**
   * @param {*} data 
   * @param {InputItemView} inputItem 
   */
  async addContent(data) {
    if (!data) {
      // failed to fetch data
      return;
    }

    const { contentType } = this;

    // TODO: get state from modal
    if (this.contentCollectionEditors.editor.needsApproval()) {
      // trying to publish for general public -> send in for review
      data.moderationStatus = ModerationStatus.NotReviewed;
    }
    // NOTE: cannot add to public collection initially (because it needs 5 tags first)
    // else if (this.contentCollectionEditors.editor.hasPublicCollections()) {
    //   // add to non-visible public collection
    //   data.moderationStatus = ModerationStatus.ReviewedPublished;
    // }
    else {
      // no public collection -> don't make it public
      data.moderationStatus = ModerationStatus.Private;
    }

    console.log('Adding new content:', data);

    try {
      // store content
      const contentObjectContainer = getContentObjectsByType(contentType);
      const content = await contentObjectContainer.addContentObject(data);

      if (!content) {
        // adding content failed
        return;
      }

      // store new content object
      this.content = content;

      // store contentCollections
      await this.saveAfterSubmit();
      // show preview
      this.finishSubmit();
    }
    catch (err) {
      this.handleSubmitError(err);
    }
  }

  async _renderCollectionsModal() {
    // hackfix: we don't really want ItemView to be dependent on the editor and modal
    this.contentCollectionEditorsModal = initRenderContentCollectionModal(this.contentType, this.content);
    this.contentCollectionEditors = this.contentCollectionEditorsModal.contentCollectionEditors;

    return await showAndWaitForContentCollectionModal();
  }


  async handleSubmit(newContent) {
    const url = this.url = newContent.url;
    this.content = newContent;

    this.state.inc('busy');

    try {
      // let user select collections
      const hasSelectedCollections = await this._renderCollectionsModal();
      if (!hasSelectedCollections) {
        return false;
      }

      // sanity checks
      if (!this.contentCollectionEditors.editor.hasAnySelected()) {
        alert('Please select where you want this content to be submitted');
        return false;
      }
      if (this.contentCollectionEditors.editor.needsApprovalButNotBefore()) {
        const confirmed = confirm('This content will be reviewed by moderators, do you want to submit?');
        if (!confirmed) {
          return false;
        }
      }

      // start showing content preview (loader)
      startRenderContentPreviewLoader();

      // check if video is in database
      const content = this.content = await getOrQueryContentByUrl(this.contentType, url);

      if (content) {
        // content item already exists
        await this.handleSubmitAgain();

        return false;
      }
      return true;
    }
    finally {
      this.state.dec('busy');
    }
  }

  async handleSubmitAgain() {
    try {
      // store contentCollections
      await this.saveAfterSubmit();
      // show preview
      this.finishSubmit();

    }
    catch (err) {
      this.handleSubmitError(err);
    }
  }

  handleSubmitError(err) {
    console.error('Submission failed', err);
    alert(`Something went wrong while saving content ("${err.message}"). Please try again or reach out to us for help. Apologies for the inconvinience!`);
  }

  async saveAfterSubmit() {
    return this.contentCollectionEditors.editor.saveCollections(this.content);
  }

  async finishSubmit() {
    // clear search text
    this.inputForm.$inputText.val('');
    this.$listsCont.addClass('hidden');

    // render preview
    const contentId = this.content._id;
    if (!startRenderContentPreview(contentId)) {
      alert("Saved URL successfully!");
    }
  }
}
