import {
  getContentTypeName
} from '../../api/content/contentObjects';


// ###########################################################################
// ContentInputForm
// ###########################################################################

export class ContentInputForm {
  init(contentType, $inputForm, cfg) {
    // get all relevant info
    this.contentType = contentType;
    this.$inputFormEl = $inputForm;
    this.cfg = cfg;
    this.$inputText = $inputForm.find('input[type=text]');

    // clear text when closing the tab
    const $closeBtn = $inputForm.closest('.welcome-div').find('.anim-close-panels');
    $closeBtn.on('click', evt => {
      this.$inputText.val('');
    });

    // call polymorphic initializer (initializer for each category)
    this.onInit();
  }

  onInit() { }

  validateContentType(contentType, silent = false) {
    // add content type validation
    if (!contentType) {
      if (!silent) {
        alert(`Could not add url because we could not determine the content type :(`);
      }
      return false;
    }
    if (contentType !== this.contentType) {
      // const typeName = getContentTypeName(contentType);
      const thisTypeName = getContentTypeName(this.contentType);
      if (!silent) {
        alert(`Invalid content type - Expected: ${thisTypeName}. Please select the right content type.`);
      }
      return false;
    }

    return true;
  }
}