import db from 'src/db';
import firebase from 'firebase/app';
import {
  contentCollection
} from './contentObjects';
import {
  ContentActionType,
  ModerationStatus,
  getContentActionVoteCount
} from './ContentActionConfig.js';

import { 
  toggleContentActionVoteTransaction,
  debugResetAllTransaction,
  contentReviews
} from './contentReviews';


// ########################################################################################################################
// Apply content actions
// ########################################################################################################################

async function publishContentItemTransaction(transaction, contentRef) {
  return transaction.update(contentRef, {
    moderationStatus: ModerationStatus.ReviewedPublished,
    private: false,
    statusChangedAt: firebase.firestore.Timestamp.fromDate(new Date()),
  });
}

async function unpublishContentItemTransaction(transaction, contentRef) {
  return transaction.update(contentRef, {
    moderationStatus: ModerationStatus.ReviewedNotPublished,
    private: false,
    statusChangedAt: firebase.firestore.Timestamp.fromDate(new Date())
  });
}

async function featureContentItemTransaction(transaction, contentRef) {
  return transaction.update(contentRef, {
    isFeatured: true,
    private: false,
    statusChangedAt: firebase.firestore.Timestamp.fromDate(new Date())
  });
}

async function stopFeatureContentItemTransaction(transaction, contentRef) {
  return transaction.update(contentRef, {
    isFeatured: false
  });
}

/**
 * Usually, the delete function will not be used very heavily.
 */
async function deleteContentItemTransaction(transaction, contentRef) {
  return transaction.delete(contentRef);
}

const ContentActionsByTypeTransaction = {
  Publish: publishContentItemTransaction,
  Unpublish: unpublishContentItemTransaction,
  Feature: featureContentItemTransaction,
  StopFeature: stopFeatureContentItemTransaction,
  Delete: deleteContentItemTransaction
};


/**
 * Cast vote, and if, vote count has surpassed threshold, apply action.
 */
export async function toggleContentActionVote(uid, actionType, contentId, feedback, isPrivileged) {
  actionType = ContentActionType.valueFromForce(actionType); // make sure it's a valid action type name or value, and get the value
  // const actionName = ContentActionType.nameFromForce(actionType);
  const reqVoteCount = getContentActionVoteCount(actionType);

  // optimistic UI: because the transaction is slow, let's show something to the user right away
  contentReviews.overrideCurrentUserHasVoted(actionType, contentId);

  // do everything in one transaction, to make sure, everything happens together, or not at all
  // -> NOTE: we use transactions instead of batched writes to make sure no one CAN POSSIBLY accidentally cast twice (thereby incrementing twice but only arrayUnion once)
  return db.runTransaction(async transaction => {
    const [castedNotRevoked, voteCount] = await toggleContentActionVoteTransaction(transaction, uid, actionType, contentId, feedback);
    if (castedNotRevoked && (voteCount >= reqVoteCount || isPrivileged)) {
        // casted vote + has passed the voting threshold -> actually apply action!
        await applyContentActionTransaction(transaction, actionType, contentId);
    }
    else if (!castedNotRevoked) {
      // revoked vote
      // TODO: Undo vote action, if within timeout timer
    }
  });
}


/**
 * Apply given action immediately.
 * 
 * @param {ContentActionType} actionType
 */
export async function applyContentActionNow(actionType, contentId) {
  return db.runTransaction(async transaction => {
    // const dontRetractExistingVote = true;  // do not retract vote, if already casted
    
    // cast vote, update feedback and also apply actual content action, independent on whether vote count is sufficient
    await Promise.all([
      //toggleContentActionVoteTransaction(transaction, uid, actionType, contentId, feedback, dontRetractExistingVote),
      applyContentActionTransaction(transaction, actionType, contentId)
    ]);
    
    // all done!
    return true;
  });
}


/**
 * Apply given action immediately (either after voting, or by moderator).
 */
export async function applyContentActionTransaction(transaction, actionType, contentId) {
  const actionName = ContentActionType.nameFromForce(actionType); // make sure it's a valid action type name or value, and get the name
  const contentRef = contentCollection.doc(contentId);
  const actionCb = ContentActionsByTypeTransaction[actionName];
  return await actionCb(transaction, contentRef);
}

export async function debugResetAll(contentId) {
  const contentRef = contentCollection.doc(contentId);
  return db.runTransaction(async transaction => {
    return Promise.all([
      debugResetAllTransaction(transaction, contentId),
      transaction.update(contentRef, {
        isFeatured: false,
        moderationStatus: ModerationStatus.NotReviewed,
        private: false
      })
    ]);
  });
}