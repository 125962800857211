import { authState } from 'src/auth';
import { getPageReelId } from 'src/render/pages/reel/reelPageUtils';
import ReelType from 'src/api/reel/ReelType';

export function isPersonalReelId(reelId) {
  // NOTE: Firestore uuids don't contain dashes (see https://github.com/firebase/firebase-js-sdk/blob/73a586c92afe3f39a844b2be86086fddb6877bb7/packages/firestore/src/util/misc.ts#L36)
  return !reelId.startsWith('b-');
}

export function isThisAPersonalReel() {
  const reelId = getPageReelId();
  return isPersonalReelId(reelId);
}

export function getReelType(reelId) {
  return isPersonalReelId(reelId) ? ReelType.Personal : ReelType.Brand;
}

export function buildBrandReelId(uid) {
  return `b-${uid}`;
}

export function isOwnBrandReelId(reelId, uid = authState.uid) {
  return reelId === buildBrandReelId(uid);
}

export function isReelOwner(reelId, uid = authState.uid) {
  return reelId === uid || isOwnBrandReelId(reelId, uid);
}