const requests = [];
const requestsByUrl = {};

const uniqueCallbackNamePrefix = '__gapi_callback';

/**
 * Google does things the ugly way, so we work around it...
 */
class GapiRequest {
  constructor(url, callbackParam) {
    const id = this.id = requests.length + 1;
    this.callbackName = `${uniqueCallbackNamePrefix}_${id}`;
    window[this.callbackName] = this._handleGapiLoaded;
    this.url = url;
    this.requestUrl = `${url}${callbackParam && `&${callbackParam}=${this.callbackName}` || ''}`;
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;

      // TODO: don't use jquery here, since we want to use this in testing/on Node as well.
      $.getScript(this.requestUrl)
        .done(() => {
          if (!callbackParam) {
            resolve(this);
          }
        })
        .fail(reject);
    });
  }

  _handleGapiLoaded = () => {
    this.resolve(this);
  }
}

export function startLoadGapiJs(url, callbackParam) {
  const request = new GapiRequest(url, callbackParam);
  requests.push(request.promise);
  requestsByUrl[request.url] = request.promise;
  return request;
}

export async function waitForGoogleApi(url, callbackParam) {
  if (!requestsByUrl[url]) {
    startLoadGapiJs(url, callbackParam);
  }
  return await requestsByUrl[url];
}

export async function waitForGapiClientJs() {
  const url = 'https://apis.google.com/js/client.js?';
  const callbackParam = null;
  return waitForGoogleApi(url, callbackParam);
}