

// ###########################################################################
// init content columns + filters
// ###########################################################################

import { getPageReelId } from './reelPageUtils';
import reelCollectionsContainer, { getCollectionLabel, getCollectionAccessMode } from 'src/api/reel/reelCollectionContainer';
import { getClosestContentColumn } from 'src/render/content/contentObjectColumns';
import { decorateClasses } from 'src/util/domUtil';
import { onUIDChanged, authState } from 'src/auth';
import { getRenderQueue } from 'src/render/renderQueue';
import { hackfix_setScrollingInterrupted } from 'src/render/content/contentScroller';
import { isReelOwner } from 'src/api/reel/reelUtil';
import { ReelCollectionContEl } from 'src/renderUtil/reel/ReelCollectionContEl';
import { NotLoaded } from 'src/db';
import CollectionAccessMode from 'src/api/reel/CollectionAccessMode';
import reelCollectionContributorContainer from 'src/api/reel/reelCollectionContributorContainer';
import EmptyArray from 'src/util/EmptyArray';
import ContributorPrivilege from 'src/api/reel/ContributorPrivilege';
import { querySystemCollectionLabels } from 'src/api/contentCollections/systemCollectionLabels';

let $columnMenus;


function hasAccess(collectionIdRef, uid) {
  const accessMode = getCollectionAccessMode(collectionIdRef);

  if (accessMode === NotLoaded) {
    console.error('[WEIRD ERROR] collection not loaded after calling queryCollectionAccessMode?!', collectionIdRef);
    // NOTE: should not happen
    return false;
  }
  return accessMode === CollectionAccessMode.Public ||
    reelCollectionContributorContainer.getContributorPriv(collectionIdRef, uid) >= ContributorPrivilege.View;
}

function shouldShowCollection(contentType, collectionName) {
  const reelId = getPageReelId();
  const { uid } = authState;
  const collectionIdRef = { reelId, collectionName };

  if (!hasAccess(collectionIdRef, uid)) {
    return false;
  }

  // show!
  return true;

  // // hide if empty
  // const isEmpty = reelCollectionsContainer.queryIsCollectionEmpty(collectionIdRef, contentType);
  // return !isEmpty;
}

function makeCollectionIdRef(collectionName) {
  return {
    reelId: getPageReelId(),
    collectionName
  };
}

function setCurrentCollectionLabel($menuEl, label) {
  const $collectionNameEl = $menuEl.closest('.collections-ctn').find('.current-collection');
  $collectionNameEl.text(label);
}


function renderSelectedCollection($btn, $menuEl) {
  const title = $btn.text();

  // update title
  setCurrentCollectionLabel($menuEl, title);

  // contract menu
  collapseMenu($btn);
}

// ###########################################################################
// init render
// ###########################################################################

function initRenderReelCollections($menuEl, $contsByAccessMode, reelCollections) {
  const reelId = getPageReelId();
  const $template = $menuEl.find('a').eq(0);
  $menuEl.find('[data-custom=1]').remove();

  const contentColumn = getClosestContentColumn($menuEl);
  const { contentType } = contentColumn;

  // // hide empty container divs (NOTE: we are doing that below, where we also consider whether the collection is empty)
  // $contsByAccessMode.hideEmpty();

  // generate buttons for all collections
  for (const { collectionName, accessMode } of reelCollections) {
    const $collectionEl = $template.clone();

    $collectionEl.removeClass('hidden');
    $collectionEl.text(getCollectionLabel(collectionName, reelId, contentType));
    $collectionEl.attr('data-name', collectionName);
    $collectionEl.attr('data-custom', 1);

    const $parent = $contsByAccessMode.get(accessMode);
    $parent.append($collectionEl);
  }
}


// ###########################################################################
// init+render collection buttons
// ###########################################################################

function initCollectionButton(contentColumn, $menuEl, $collectionBtn) {
  // const reelId = getReelId();
  const collectionName = $collectionBtn.attr('data-name');

  if (!collectionName) {
    debugger;
    return;
  }

  // hide if user has access to this collection
  const { contentType } = contentColumn;
  const hidden = !shouldShowCollection(contentType, collectionName);

  // console.debug(ContentType.nameFrom(contentType), hidden);

  decorateClasses($collectionBtn, {
    hidden
  });

  $collectionBtn.off('click');

  // filter out all empty collections
  if (hidden) {
    // empty and not default -> do not show this option
    return;
  }

  $collectionBtn.on('click', evt => {
    // NOTE: we are triggering a click initially, right after loading!
    // selected a collection
    evt.preventDefault();

    // set filter
    const filter = {
      name: 'contentCollection',
      collectionName
    };
    contentColumn.contentObjects.filters.setController(filter);

    // set title
    renderSelectedCollection($collectionBtn, $menuEl);
  });
}


async function hideEmptyCollectionButtons($collectionBtn, contentColumn) {
  const { contentType } = contentColumn;
  const collectionName = $collectionBtn.attr('data-name');

  const ref = makeCollectionIdRef(collectionName);
  const isEmpty = await reelCollectionsContainer.queryIsCollectionEmpty(ref, contentType);
  // console.debug('collection empty', isEmpty, collectionName, contentType);

  decorateClasses($collectionBtn, {
    hidden: isEmpty
  });
}



// ###########################################################################
// select default collection
// ###########################################################################

async function selectDefaultCollectionFilter(contentType, $menuEl) {
  const reelId = getPageReelId();
  let collectionName = reelCollectionsContainer.getDefaultCollection(reelId, contentType);

  const isOwnReel = isReelOwner(reelId); // NOTE: this depends on `authState.uid`
  if (isOwnReel) {
    const isEmpty = !collectionName || await reelCollectionsContainer.queryIsCollectionEmpty(makeCollectionIdRef(collectionName), contentType);
    
    if (isEmpty) {
      // when visiting my own reel, and default collection is empty -> select "MyPrivateStuff" instead
      collectionName = 'MyPrivateStuff';
    }
  }

  // console.debug('ReelColumnMenu', contentType, collectionName);

  let $collectionBtn;
  if (collectionName) {
    // select default collection
    $collectionBtn = $menuEl.find(`[data-name="${collectionName}"]`);
    if ($collectionBtn.hasClass('hidden')) {
      $collectionBtn = null;
    }
  }

  if (!$collectionBtn?.length) {
    // could not look up default collection button -> find the next available collection

    // NOTE: this won't quite work because the next available collection might be empty
    // NOTE: we are getting data from the HTML here, because this way designer can also add system collections
    // select first available as default if default is empty or none was set by user
    const $collectionBtns = $menuEl.find('a');
    const collectionNames = Array.from($collectionBtns).map(btn => btn.getAttribute('data-name'));
    for (const collectionName of collectionNames) {
      if (shouldShowCollection(contentType, collectionName)) {
        $collectionBtn = collectionName && $menuEl.find(`[data-name="${collectionName}"`) || null;
        break;
      }
    }
  }

  if ($collectionBtn?.length) {
    // "click" (i.e. select) default collection
    setTimeout(() => {
      $collectionBtn.trigger('click');
    });
  }
  else {
    // there is nothing here -> hide the column
    const contentColumn = getClosestContentColumn($menuEl);

    contentColumn.setEmpty();
    setCurrentCollectionLabel($menuEl, '(nothing here)');
  }
}

// ###########################################################################
// hackfix the menu button (toggles menu when clicked)
// ###########################################################################

function hackfix_toggleMenuButton($menuEl) {
  const $menuRoot = getMenuRoot($menuEl);
  const $toggleMenuButton = $menuRoot.find('.top-bar-collections');
  $toggleMenuButton.on('click', () => {
    // hackfix: for some reason, when the class is added, mobile phones (and even simulators)
    //    tend to catapult the user back to the scroll position before the current (or last) swipe.
    //    This prevents that.
    hackfix_setScrollingInterrupted(true);
    try {
      if (!$menuEl.hasClass('height-auto')) {
        // opening menu
        handleMenuOpen($menuEl);
      }
      $menuRoot.toggleClass('height-auto');
    }
    finally {
      // keep it in place for some 100ms, since on some devices, it does not generate the scroll synchronously
      // setTimeout(() => hackfix_setScrollingInterrupted(false), 100);
      hackfix_setScrollingInterrupted(false);
    }
  });
}

// ###########################################################################
// menu render + controls
// ###########################################################################

function initRenderMenu(contentColumn, $menuEl) {
  // const filterSettings = contentColumn.contentObjects.filters.settings;
  const $collectionFilterButtons = $menuEl.find('a');
  Array.from($collectionFilterButtons).forEach(async (btnEl) => {
    initCollectionButton(contentColumn, $menuEl, $(btnEl));
  });
}

function handleMenuOpen($menuEl) {
  const contentColumn = getClosestContentColumn($menuEl);
  const $collectionFilterButtons = $menuEl.find('a');
  return Promise.all(
    Array.from($collectionFilterButtons).map(async (btnEl) => {
      return hideEmptyCollectionButtons($(btnEl), contentColumn)
      // initCollectionButton(contentColumn, $menuEl, $(btnEl));
    })
  );
}


// ###########################################################################
// main
// ###########################################################################

function getMenuRoot($el) {
  return $el.closest('.collections-ctn');
}


function collapseMenu($el) {
  const $menu = getMenuRoot($el);
  $menu.removeClass('height-auto');
}

/**
 * 
 */
export async function initRenderReelColumns() {
  const renderQueue = getRenderQueue();

  // init filter menu buttons
  $columnMenus = $('.collections-menu');

  // init menu controls at the top
  $columnMenus.each((i, menuEl) => hackfix_toggleMenuButton($(menuEl)));

  // hook to data events
  const synchronizedRenderMenu = renderQueue.synchronizedFunction(900, initRenderReelColumnMenu);
  onUIDChanged(synchronizedRenderMenu, true, true);
}

let reelCollections;

function getProtectedContributorCollections(reelId) {
  const isOwnReel = isReelOwner(reelId); // NOTE: this depends on `authState.uid`
  const collections = !isOwnReel &&
    (reelCollectionContributorContainer.getVisibleReelContributorCollections(reelId, authState.uid))?.
      filter(col => col.accessMode === CollectionAccessMode.Protected)
    || EmptyArray;

  return collections;
}

function hasProctectedContributorCollections(reelId) {
  return !!reelCollectionContributorContainer.getVisibleReelContributorCollections(reelId, authState.uid)?.length;
}

// ###########################################################################
// initRenderMenu + renderMenu
// ###########################################################################

async function initRenderReelColumnMenu() {
  const reelId = getPageReelId();
  reelCollectionsContainer.onDoc(reelId, renderMenu);
  reelCollectionContributorContainer.onDoc(reelId, renderMenu);

  await Promise.all([
    querySystemCollectionLabels(),
    reelCollectionsContainer.waitForDoc(reelId),
    reelCollectionContributorContainer.waitForDoc(reelId)
  ]);
}


function renderMenu() {
  const reelId = getPageReelId();
  const isOwnReel = isReelOwner(reelId); // NOTE: this depends on `authState.uid`
  const showPrivate = isOwnReel;

  // don't show any private filters if on someone else's reel
  decorateClasses($('.collections-menu .private-collections-ctn'), {
    hidden: !showPrivate
  });

  // TODO: hide protected if needed

  // get all reel collections.
  reelCollections = [
    (showPrivate ?
      reelCollectionsContainer.getReelCollections(reelId) :
      reelCollectionsContainer.getPublicCollections(reelId)
    ),

    // if not own reel: this will return contributing protected collections
    getProtectedContributorCollections(reelId)
  ].
    flat(); // merge public + protected collections (if necessary)


  // // don't show any private filters if on someone else's reel
  // decorateClasses($('.collections-menu .private-collections-ctn'), {
  //   hidden: !showPrivate
  // });
  // decorateClasses($('.collections-menu .protected-collections-ctn'), {
  //   hidden: !showPrivate && !hasProctectedContributorCollections(reelId)
  // });


  // render each column's menu
  /* await */ Array.from($columnMenus).forEach((menuEl) => {
      const $menuEl = $(menuEl);
      const contentColumn = getClosestContentColumn($menuEl);

      if (!contentColumn) {
        // column is not hooked up or enabled yet
        console.warn('Could not get `contentColumn` for menu (is it disabled?) -', $menuEl[0]);
        return;
      }

      // render

      // await contentColumn.contentObjects.waitForPage();

      const $contsByAccessMode = new ReelCollectionContEl(reelId, $menuEl);

      // add custom user collections to this menu
      initRenderReelCollections($menuEl, $contsByAccessMode, reelCollections);

      // initialize + render all menu buttons
      initRenderMenu(contentColumn, $menuEl);

      // [quick-n-dirty] hide empty conts
      for (const [accessMode, $cont] of $contsByAccessMode) {
        // hide cont, if it contains no visible collection
        // NOTE: individual collection visibility is determined by `shouldShowCollection`
        decorateClasses($cont, {
          hidden: !$cont.find('a:not(.hidden)').length
        });
      }

      // select default content collection (+ replace "loading..." with active collection name)
      const { contentType } = contentColumn;
      /* await */ selectDefaultCollectionFilter(contentType, $menuEl);
    });
}