import Enum from 'src/util/Enum';

// ###########################################################################
// tag voting + actions
// ###########################################################################

export const TagMaxVotes = {
  Yea: 3 ,    // need 3 to add
  Nay: 3      // need 3 to remove
};

export const TagActionType = new Enum({
  Yea: 1,   // want the tag
  Nay: 2    // don't want the tag
});


export const AvailableTagActions = {
  Added: [
    TagActionType.Nay
  ],
  Proposed: [
    TagActionType.Yea,
    TagActionType.Nay
  ],
  ContentSearch: [
    TagActionType.Yea,
    TagActionType.Nay
  ]
};

export const TagActionPrivileges = {
  // Yea: 'voteAddTag',
  // Nay: 'voteRemoveTag'
  Yea: 'voteTag',
  Nay: 'voteTag'
};

/**
 * Used to show text to the user.
 */
export const TagActionVerb = {
  Yea: 'add tag',
  Nay: 'remove tag'
}

// ###########################################################################
// TagRenderContext
// ###########################################################################

export const TagRenderContext = new Enum({
  Added: 1,       // tag has already survived voting and was added to content item
  Proposed: 2,    // tag has been proposed, has at least one vote and is still being voted on
  ContentSearch: 3,

  ECM: 4,         // tag rendered in ECM tab
  ECMSearch: 5,

  WorldList: 6,    // tag rendered in world list on the left-hand side
  WorldSearch: 7
});

const searchContexts = new Array(TagRenderContext.getCount()).fill(false);
searchContexts[TagRenderContext.ContentSearch] = true;
searchContexts[TagRenderContext.ECMSearch] = true;
searchContexts[TagRenderContext.WorldSearch] = true;

export function isTagSearchContext(tagRenderContext) {
  return searchContexts[tagRenderContext];
}


const worldContexts = new Array(TagRenderContext.getCount()).fill(false);
worldContexts[TagRenderContext.WorldList] = true;
worldContexts[TagRenderContext.WorldSearch] = true;

export function isWorldContext(tagRenderContext) {
  return worldContexts[tagRenderContext];
}