import { decorateClasses, decorateAttr } from 'src/util/domUtil';
import { sleep } from 'src/util/sleep';

/**
 * Popup to add contentObject to your own content collections.
 */
class ModalBase {
  constructor($modal, cbs, args) {
    this.$modal = $modal;
    this.$submitBtn = $modal.find('.submit-button');
    this.renderer = cbs.createRenderer($modal, ...args);
    if (this.renderer?.init) {
      this.renderer.init($modal, ...args);
    }

    const save = cbs.save || this.renderer.save;
    if (save) {
      // TODO: probably don't want to unregister all other click handlers here
      this.$submitBtn.off('click').on('click', async evt => {
        await save(); // save
        // $modal.hide();
      });
    }
  }

  hide() {
    this.$modal.hide();
  }
}

/**
 * @param {*} $modal 
 * @param {*} cbs 
 */
export async function initRenderModal($modal, cbs, ...args) {
  const modal = new ModalBase($modal, cbs, args);

  // const $inputs = $modal.find('input');

  // modal.renderer?.$loader?.addClass('hidden');
  // decorateAttr($inputs, { disabled: true });
  // decorateClasses($inputs, { disabled: true });

  // await ((cbs.load && cbs.load()) || (modal.renderer.load && modal.renderer.load()));         // load
  await cbs.load && cbs.load(modal);         // load
  // await sleep(100); // debug-only

  // modal.renderer?.$loader?.removeClass('hidden');
  // decorateAttr($inputs, { disabled: false });
  // decorateClasses($inputs, { disabled: false });

  return modal;
}