import { queryDetails } from './wikiApi';

export async function lookupFilmGenreTags(genreNames) {
  let tags = await Promise.all(genreNames.map(async genre => {
    const tagName = `${genre} film`;
    const entry = await queryDetails(tagName);
    
    // NOTE: sometimes we see queryDetails finishing here before it actually finished!? (we see a "Found redirect" in console, long after this has finished)
    //    (explanation: that is because it is cached!)

    if (!entry) {
      return null;
    }
    if (entry.redirectTo && entry.redirectTo.toLowerCase() !== entry.name.toLowerCase()) {
      // add redirect as well as tag itself (if it is different, not just in case)
      return [entry.redirectTo, entry.name];
    }

    // prefer redirect over our query input
    return entry.redirectTo || entry.name;
    // return tagName;
  }));
  tags = tags.flat();

  if (tags.find(tag => !tag)) {
    console.warn('could not query tag for genre:', genreNames, tags);
    tags = tags.filter(tag => !!tag);
  }

  // remove duplicates (sometimes we have a redirectTo == name, for some reason)
  tags = Array.from(new Set(tags));
  
  // sort alphabetically, in desc order
  tags.sort((a, b) => a.localeCompare?.(b));

  return tags;
}