import Enum from '../../util/Enum';

// ContentType enum
const ContentType = new Enum({
  Video: 1,
  Article: 2,
  Film: 3,
  Series: 4,
  Course: 5,
  Book: 6,
  App: 7,
  Product: 8,

  // `contentType` of content that is not in `contentObjects` (`videos`) collection
  Human: 101
});

const collectionNameByContentType = {
  [ContentType.Human]: 'userInfo' // TODO: fix this name
};

export function getCollectionNameByContentType(contentType) {
  return collectionNameByContentType[contentType] || 'videos';
}


export function isContentTypeReelCategory(contentType) {
  return contentType >= ContentType.Human;
}



export default ContentType;


/**
 * 
 */
export const EnabledContentTypes = [
  ContentType.Video,
  ContentType.Article,
  ContentType.Film,
  ContentType.Series,
  ContentType.Course,
  ContentType.Book,
  ContentType.App,
  ContentType.Product,
  ContentType.Human
];