import { authState } from 'src/auth';
import { startWebflowAnimation } from 'src/renderUtil/animUtil';
import { doesUserHavePrivAsync } from '../api/privileges';

/**
 * Returns wether it showed the popup (true, if not logged in)
 */
export function showNotLoggedInUserActionWarning() {
  if (!authState.isLoggedIn()) {
    startWebflowAnimation('need-login-popup');
    return true;
  }
  return false;
}

export async function showECMOrderNeedsPrivWarningAsync() {
  if (!await doesUserHavePrivAsync(authState.uid, 'ecmSorting')) {
    startWebflowAnimation('premium-ecm');
    return true;
  }
  return false;
}

export function showECMOrderNotLoggedInUserActionWarning() {
  if (!authState.isLoggedIn()) {
    startWebflowAnimation('ecm-order-not-logged-in');
    return true;
  }
  return false;
}