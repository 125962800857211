import Enum from 'src/util/Enum';

const UserStatus = new Enum({
  None: 0,
  FilledOutBasicInformation: 1
});

export function isUserStatusNew(status) {
  return !status || status < UserStatus.FilledOutBasicInformation;
}

export default UserStatus;