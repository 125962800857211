import { authState } from 'src/auth';
import { ModerationStatus } from './ContentActionConfig';

import { ecmContainer, ecmCalculator } from 'src/api/ecm';
import { getCurrentPageTag, getCurrentPageArg } from '../../pages';
import { getFirebaseDate } from '../../firebase/firebaseUtil';
import EmptyArray from 'src/util/EmptyArray';
import ContentCollectionFilterController from 'src/api/content/ContentCollectionFilterController';


// ##################################################################################################################
// Content filter configuration
// ##################################################################################################################

/**
 * These will apply to all queries on a page.
 */
export const pageWhereArgs = {
  index: () => EmptyArray,
  worlds: () => ['tags', 'array-contains', getCurrentPageTag()],
  tags: () => ['tags', 'array-contains', getCurrentPageTag()],
  approval: () => EmptyArray,
  submitted: () => ['uid', '==', authState.uid],
  share: () => ['isFeatured', '==', true],
  reel: () => {
    const uid = getCurrentPageArg();
    return ['uid', '==', uid];
  }
};

/**
 * These select a default filter that can possibly be changed by the user through the UI
 */
export const defaultContentFiltersByPage = {
  index: 'featured',
  worlds: 'published',
  tags: 'published',
  approval: 'notReviewed',
  share: 'featured',

  // pages with dynamic filters
  reel: null
};

// TODO: clean this up
export const approvalPageOrderByFilterName = {
  published: 'mostRecentFirst',
  notPublished: 'mostRecentFirst',
  unpublished: 'mostRecentFirst',
  featured: 'mostRecentFirst'
};

export const orderByFilterName = {
  ecmScore: 'ecmScore'
};

export const allContentFilters = {
  submitted: {
    // a little hack here -> the submitted filter is always applied on the submitted/ page anyway
    whereArgs: () => EmptyArray
  },
  notReviewed: {
    whereArgs: () => ['moderationStatus', '==', ModerationStatus.NotReviewed],
  },
  published: {
    whereArgs: () => ['moderationStatus', '==', ModerationStatus.ReviewedPublished],
  },
  notPublished: {
    whereArgs: () => ['moderationStatus', '==', ModerationStatus.ReviewedNotPublished],
  },
  unpublished: {
   whereArgs: () => ['moderationStatus', '==', ModerationStatus.ReviewedNotPublished],
  },
  featured: {
    whereArgs: () => ['isFeatured', '==', true],
  },
  ecmScore: contentObjects => {
    return {
      whereArgs() {
        return ['moderationStatus', '==', ModerationStatus.ReviewedPublished];
      },
      limit() {
        return contentObjects.ecmOrderFirstPageCount;
      }
    };
  },

  contentCollection: contentObjects => new ContentCollectionFilterController(contentObjects)
}

// ##################################################################################################################
// Content sorting configuration
// ##################################################################################################################

export const DefaultOrderProp = 'createdAt';

function getDate(obj) {
  return getFirebaseDate(obj, DefaultOrderProp);
}

export const allContentOrders = {
  mostRecentFirst: {
    cmp: (a, b) => getDate(b) - getDate(a),
    queryDecorator: () => [DefaultOrderProp, 'desc']
  },
  oldestFirst: {
    cmp: (a, b) => getDate(a) - getDate(b),
    queryDecorator: () => [DefaultOrderProp, 'asc']
  },
  ecmScore: {
    cmp: (a, b) => {
      console.assert(a._id && b._id);
      const aScore = ecmCalculator.getECMScoreForContent(a._id, a);
      const bScore = ecmCalculator.getECMScoreForContent(b._id, b);
      let d = bScore - aScore;
      console.debug('[ecmScore cmp]', a.title, ' <=> ', b.title, [aScore, bScore, d].map(x => x?.toFixed(2)));
      if (!d) {
        // settle draw by timestamp
        d = getDate(b) - getDate(a);
      }
      return d;
    },
    queryDecorator: () => [DefaultOrderProp, 'desc']
  }
};

export const defaultContentOrdersByPage = {
  'index': 'mostRecentFirst', // home page
  'worlds': 'mostRecentFirst',
  'approval': 'oldestFirst',
  'submitted': 'mostRecentFirst',
  'share': 'mostRecentFirst'
}