let messageSubscription;

export function onServiceWorkerMessage(message, event) {
  if (!messageSubscription) {
    messageSubscription = navigator.serviceWorker.addEventListener('message', event => {
      console.log('Received message from service-worker:', event);
    });
  }
}

/**
 * E.g.:
    sendCommandToServiceWorker(
      'showNotification',
      {  title: 'hi', body: 'hi', actions: [ { action: 'action', title: 'action1' } ] }
    );
 */
export function sendCommandToServiceWorker(command, args) {
  navigator.serviceWorker.controller.postMessage({
    command,
    args
  });
}

export function initServiceWorkerUtilities(swRegistration) {
  // [debug-global]
  window.sendCommandToServiceWorker = sendCommandToServiceWorker;
}