import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import FirestoreContainer from 'src/firebase/FirestoreContainer';
import { isOwnBrandReelId, isReelOwner } from 'src/api/reel/reelUtil';
import EmptyArray from 'src/util/EmptyArray';
import EmptyObject from 'src/util/EmptyObject';
import { authState } from 'src/auth';
import ContributorPrivilege from 'src/api/reel/ContributorPrivilege';
import CollectionAccessMode from 'src/api/reel/CollectionAccessMode';
import { NotLoaded } from 'src/db';
import reelInfoContainer from 'src/api/reel/reelInfo';
import reelCollectionsContainer from 'src/api/reel/reelCollectionContainer';
import { getContentCollectionQueryInterface } from 'src/api/contentCollections/contentCollections';

class ReelCollectionContributorContainer extends FirestoreContainer {
  constructor() {
    super('reelCollectionContributors');
    this.enableCache();
  }


  // ###########################################################################
  // queries
  // ###########################################################################

  async queryReelEntry(reelId) {
    return this.queryDoc(reelId);
  }

  async queryContributorEntries(collectionIdRef) {
    await this.queryReelEntry(collectionIdRef.reelId);
    return this.getContributorEntries(collectionIdRef);
  }

  async queryCompleteContributorInfos(collectionIdRef) {
    const entries = await this.queryContributorEntries(collectionIdRef);

    await Promise.all(entries?.map(({ uid }) => reelInfoContainer.queryDoc(uid)));

    return this.getCompleteContributorInfos(collectionIdRef);
  }

  /**
   * @returns all contributors of that collection of given privilege.
   */
  async queryUserContributorEntriesOfPrivMin(collectionIdRef, contributorPrivilege) {
    await this.queryReelEntry(collectionIdRef.reelId);
    return this.getUserContributorEntriesOfPrivMin(collectionIdRef, contributorPrivilege);
  }

  async queryVisibleReelContributorCollections(reelId, uid) {
    await Promise.all([
      this.queryReelEntry(reelId),
      reelCollectionsContainer.queryReelProtectedCollections(reelId)
    ]);
    return this.getVisibleReelContributorCollections(reelId, uid);
  }

  async queryContributorPriv(collectionIdRef, uid) {
    await this.queryReelEntry(collectionIdRef.reelId);
    return this.getContributorPriv(collectionIdRef, uid);
  }

  /**
   * Find all collections that user is a contributor of.
   * WARNING: Clears all previous results of this collection.
   * NOTE: Also depends on `reelCollectionsContainer`
   */
  async queryAllContributorCollections(uid, minPriv = ContributorPrivilege.Edit) {
    // NOTE: need to get rid of previous results before querying again
    this.clear();
    console.error(`NOTE: queryAllContributorEntries clearing before querying`, uid, minPriv);

    await this.query({
      orderBy: `contributors.${uid}`,
      startAfter: null
    });

    const allEntries = this.getAllNotNull();
    if (!allEntries) {
      return allEntries;
    }

    // get all of our own
    const allCollections = await Promise.all(
      allEntries.
        map(async entry => {
          const reelId = entry._id;
          const collectionEntries = pickBy(entry.contributors?.[uid], ({ priv }) => priv >= minPriv);

          if (isEmpty(collectionEntries)) {
            // does not contribute anything
            return null;
          }

          const collections = await reelCollectionsContainer.queryMatchingReelCollectionEntries(reelId, collectionEntries);

          return {
            reelId,
            collections
          };
        })
    )

    return allCollections.filter(entries => !!entries);
  }

  async queryAllVisibleContentEntries(uid, contentId) {
    const collectionsByReel = await this.queryAllContributorCollections(uid, ContributorPrivilege.View);
    const reelIds = collectionsByReel?.map(entry => entry.reelId) || EmptyArray;

    const allEntries = await Promise.all(
      reelIds.map(async reelId => {
        const entries = await getContentCollectionQueryInterface().queryCollectionContentEntries(reelId, contentId);
        return entries;
      })
    );

    return allEntries.flat();
  }


  // ###########################################################################
  // getters
  // ###########################################################################

  /**
   * 
   * @returns { [{ uid: string, priv: number }] }
   */
  getContributorEntries(collectionIdRef) {
    const { reelId, collectionName } = collectionIdRef;
    const reelEntry = this.getDocById(reelId);
    if (reelEntry === NotLoaded) {
      return NotLoaded;
    }

    const contributors = reelEntry?.contributors;
    return (
      contributors &&
      Object.
        entries(contributors).
        filter(([uid, collectionEntries]) => !!collectionEntries[collectionName]).
        map(([uid, collectionEntries]) => ({
          uid,
          ...collectionEntries[collectionName]
        })) ||
      null
    );
  }

  getCompleteContributorInfos(collectionIdRef) {
    const entries = this.getContributorEntries(collectionIdRef);
    if (entries === NotLoaded) {
      return NotLoaded;
    }

    const infoEntries = entries && entries.map(entry => {
      const { uid } = entry;
      const info = reelInfoContainer.getDocById(uid);

      if (info === NotLoaded) {
        return NotLoaded;
      }

      return {
        ...entry, // ...{ uid, priv }
        info
      };
    }) || null;

    if (infoEntries?.includes(NotLoaded)) {
      return NotLoaded;
    }
    return infoEntries;
  }

  getUserContributorEntriesOfPrivMin(collectionIdRef, contributorPrivilege) {
    const entries = this.getContributorEntries(collectionIdRef);
    if (entries === NotLoaded) {
      return NotLoaded;
    }

    return entries?.filter(entry => entry.priv >= contributorPrivilege) || null;
  }

  /**
   * Returns all protected collections that given user has access to.
   */
  getVisibleReelContributorCollections(reelId, uid) {
    const reelEntry = this.getDocById(reelId);
    if (reelEntry === NotLoaded) {
      return NotLoaded;
    }

    const collectionEntries = reelEntry?.contributors?.[uid];
    if (!collectionEntries) {
      return null;
    }

    return reelCollectionsContainer.getMatchingReelCollectionEntries(reelId, collectionEntries);
  }


  getContributorPriv(collectionIdRef, uid) {
    const { reelId, collectionName } = collectionIdRef;

    if (isReelOwner(reelId, uid)) {
      return ContributorPrivilege.Owner;
    }

    const reelEntry = this.getDocById(reelId);
    if (reelEntry === NotLoaded) {
      return NotLoaded;
    }

    const contributors = reelEntry?.contributors;
    return contributors?.[uid]?.[collectionName]?.priv || null;
  }

  // ###########################################################################
  // actions
  // ###########################################################################

  async addContributor(collectionIdRef, uid, priv) {
    const { reelId, collectionName } = collectionIdRef;

    let entry = await this.queryDoc(reelId) || EmptyObject;
    const contributors = { ...(entry?.contributors || EmptyObject) };  // copy contributors
    contributors[uid] = contributors[uid] || {};
    contributors[uid][collectionName] = {
      priv
    };

    entry = {
      ...entry,
      contributors,
      uid: authState.uid               // last user to have written to this reel
    };

    await this.saveDoc(reelId, entry);
  }

  async removeContributor(collectionIdRef, uid) {
    const { reelId, collectionName } = collectionIdRef;

    let entry = await this.queryDoc(reelId);
    const contributorEntry = entry?.contributors?.[uid];
    if (!contributorEntry) {
      return;
    }

    const contributors = { ...entry.contributors };  // copy contributors
    contributors[uid] = contributors[uid] || {};
    delete contributors[uid][collectionName];

    entry = {
      ...entry,
      contributors,
      uid: authState.uid               // last user to have written to this reel
    };

    await this.saveDoc(reelId, entry);
  }

  async updateContributorPrivileges(collectionIdRef, cfg) {
    const { reelId, collectionName } = collectionIdRef;

    let entry = await this.queryDoc(reelId);

    const contributors = { ...entry.contributors };  // copy contributors

    for (const [uid, { priv }] of Object.entries(cfg)) {
      const collectionEntry = contributors?.[uid]?.[collectionName];
      if (!collectionEntry) {
        throw new Error(console.error('invalid data for `updateContributorPrivileges` - user is not added to collection:', uid, collectionName));
      }
      collectionEntry.priv = priv;
    }

    entry = {
      ...entry,
      contributors,
      uid: authState.uid               // last user to have written to this reel
    };
    await this.saveDoc(reelId, entry);
  }
}

export function getDefaultContributorPrivilege(collectionAccessMode) {
  if (collectionAccessMode === CollectionAccessMode.Public) {
    return ContributorPrivilege.Edit;
  }
  return ContributorPrivilege.View;
}

const reelCollectionContributorContainer = new ReelCollectionContributorContainer();
export default reelCollectionContributorContainer;

window.reelCollectionContributorContainer = reelCollectionContributorContainer;