import { onUIDChanged, authState } from 'src/auth';
import { showNotLoggedInUserActionWarning } from 'src/renderUtil/userActions';
import { buildBrandReelId } from 'src/api/reel/reelUtil';
import { initReelSelectionButtons } from 'src/render/reel/reelSelection';
import { getSelectedReelId, onReelSelected } from 'src/api/reel/reelSelection';
import { applyReelImage } from 'src/api/reel/reelImages';
import reelInfoContainer, { getReelShortDisplayName, getVeryShortReelName, getReelUrl } from 'src/api/reel/reelInfo';

/**
 * Initialize menu buttons that don't fit any other category
 */
export function initMenuButtons() {
  onReelSelected(updateGotoReelButton);

  onUIDChanged(uid => {
    updateGotoReelButton();

    // const $personalReelBtn = $('.reel-link-button');
    // const $brandReelBtn = $('.brand-reel-link-button');
    // $personalReelBtn.off('click');
    // $brandReelBtn.off('click');

    // if (uid) {
    //   $personalReelBtn.attr('href', `/reel/${uid}`);
    //   $brandReelBtn.attr('href', `/reel/${buildBrandReelId(uid)}`);
    // }
    // else {
    //   const cb = evt => {
    //     evt.preventDefault();
    //     showNotLoggedInUserActionWarning();
    //   };
    //   $personalReelBtn.on('click', cb);
    //   $brandReelBtn.on('click', cb);
    // }

    
    initReelSelectionButtons();
  }, true, true);
}


async function updateGotoReelButton() {
  const $reelBtn = $('.reel-icon-btn');
  const $img = $reelBtn.find('img');

  $reelBtn.off('click');

  if (authState.uid) {
    // update reel url
    const reelId = getSelectedReelId();
    $reelBtn.attr('href', getReelUrl(reelId));

    const $reelTitle = $('.reel-first-letters');

    // render reel image
    await Promise.all([
      reelInfoContainer.queryReel(reelId).
        then(reelInfo => {
          if (!reelInfo) {
            return;
          }

          $reelTitle.text(getVeryShortReelName(reelInfo));
        }),
      applyReelImage($img, reelId, 'reel-small.jpg', 'src')
    ]);
  }
  else {
    $reelBtn.on('click', evt => {
      evt.preventDefault();
      showNotLoggedInUserActionWarning();
    });
  }
}