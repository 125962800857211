import firebase from 'firebase/app';

/**
 * NOTE: Our current performance counter mechanism accounts for:
 *    -> most types of reads (onSnapshot)
 *    -> includes Users, ContentObjects, Shortcuts, ECM, reviews and more
 * 
 * but does not account for:
 *    -> any writes
 *    -> reads + writes for/by the ContentEditor (displaying, modifying, updating, or adding specific content items)
 */


export class FirestorePerformanceCounter {
  _name = '(unnamed)';
  _reads = 0;

  constructor(name) {
    this._name = name;
  }

  onSnapshot(snap, sourceName) {
    let delta;
    if (snap instanceof firebase.firestore.DocumentSnapshot) {
      delta = 1;
    }
    else {
      const changes = snap.docChanges();
      delta = changes.length;
    }

    this.countReads(delta, sourceName);
  }

  countReads(delta, sourceName) {
    this._reads += delta;
    this.reportReads(delta, sourceName);
  }

  reportReads(delta, sourceName) {
    console.warn('[firestore.perf]', this._name, 'reads', this._reads, `(+${delta})`, sourceName && `by ${sourceName}`);
  }

}
  
const perfDefault = new FirestorePerformanceCounter('default');

export default perfDefault;