/**
 * This file houses (1) voting mechanics + (2) voting and feedback bookkeeping.
 */

import firebase from 'firebase/app';
import db from 'src/db';
import { authState } from 'src/auth';
import FirestoreContainer from 'src/firebase/FirestoreContainer';
import NanoEvents from 'src/util/NanoEvents';
import EmptyArray from 'src/util/EmptyArray';
import Enum from 'src/util/Enum';

import {
  ContentActionType,
  ReasonsForNegativeActions,
  doesContentActionRequireFeedback,
  getInverseActionTypes,
  getRevokeActionTypes
} from './ContentActionConfig.js';
import State from 'src/util/State';


// ########################################################################################################################
// Collections
// ########################################################################################################################

const reviewCollection = db.collection('contentReviews');
const contentFeedbackCollection = db.collection('contentFeedback');

// List all reviewed videos: `db.collection('contentReviews').get().then(snap => Promise.all(snap.docs.map(d => d.id).map(id => db.collection('videos').doc(id).get())).then(docs => docs.map(d => console.log(d.id, d.data()))));`


// ########################################################################################################################
// Basic Voting
// ########################################################################################################################


function makeVotesProp(actionType) {
  actionType = ContentActionType.valueFromForce(actionType); // make sure it's a valid action type name or value, and get the value
  return `votes-${actionType}`;
}

function makeVoteCountProp(actionType) {
  actionType = ContentActionType.valueFromForce(actionType); // make sure it's a valid action type name or value, and get the value
  return `voteCount-${actionType}`;
}

/**
 * Remove all votes for the given content item.
 */
export async function debugResetAllTransaction(transaction, contentId) {
  const reviewRef = reviewCollection.doc(contentId);
  const contentFeedbackRef = contentFeedbackCollection.doc(contentId);
  
 return Promise.all([
   transaction.delete(reviewRef),
   transaction.delete(contentFeedbackRef)
 ]); 
}

function applyFeedbackChange(uid, oldReasonsData, actionType, newReasons, feedbackUpdate) {
  const oldReasons = oldReasonsData && oldReasonsData[actionType] || null;

  const reasonsUpdate = {};

  // remove reasons that were previously casted by this person but are not anymore
  for (let reasonId of ReasonsForNegativeActions.map(reason => reason.id)) {
    const oldReason = oldReasons && oldReasons[reasonId];
    const votes = oldReason && oldReason.votes || [];
    let count = oldReason && oldReason.count || 0;
    const selectedBefore = votes.includes(uid);
    const selectingNow = newReasons && newReasons.includes(reasonId) || false;

    if (selectedBefore !== selectingNow) {
      // user changed opinion on this reason
      if (selectingNow) {
        // selected reason, but did not select in the past
        votes.push(uid);
        ++count;
      }
      else {
        // unselected reason, but did select in the past
        votes.splice(votes.indexOf(uid), 1);
        --count;
      }

      // merge into update
      reasonsUpdate[reasonId] = {
        votes,
        count
      };
    }
  }

  // merge back into feedback update
  feedbackUpdate[actionType] = reasonsUpdate;
}

/**
 * Add feedback inside of given transaction.
 */
export async function storeFeedbackTransaction(transaction, uid, actionType, contentId, oldFeedbackDoc, newReasons) {
  // get previous state + reasons
  let oldReasonsData = oldFeedbackDoc && oldFeedbackDoc.data();

  // store all reasons
  const feedbackUpdate = { };
  applyFeedbackChange(uid, oldReasonsData, actionType, newReasons, feedbackUpdate)

  // remove any reasons of inverse actions
  const inverseActions = getInverseActionTypes(actionType);
  inverseActions.forEach(inverseActionType => {
    applyFeedbackChange(uid, oldReasonsData, inverseActionType, null, feedbackUpdate);
  });

  // console.log('storeFeedbackTransaction', feedbackUpdate);
  const contentFeedbackRef = contentFeedbackCollection.doc(contentId);
  return transaction.set(contentFeedbackRef, feedbackUpdate, { merge: true });
}


/**
 * Lets the given user toggle their vote of given type on given content item.
 * Returns the new vote count of given content item and ActionType.
 */
export async function toggleContentActionVoteTransaction(transaction, uid, actionType, contentId, feedback, noopIfAlreadyCast) {
  // get ready
  const reviewRef = reviewCollection.doc(contentId);
  const contentFeedbackRef = contentFeedbackCollection.doc(contentId);
  const [
    oldVoteDoc,
    oldFeedbackDoc
  ] = await Promise.all([
    transaction.get(reviewRef),
    transaction.get(contentFeedbackRef)
  ]);

  const oldVoteData = oldVoteDoc.data();
  const oldVotes = oldVoteData && oldVoteData[makeVotesProp(actionType)] || null;
  
  // check if we are adding/casting (or revoking) a vote?
  const notCastedBefore = !oldVotes || !oldVotes.includes(uid);  // whether we are adding (casting) or revoking a vote
  
  // make sure that, if we are casting, and the cast requires feedback, that feedback is actually provided
  if (notCastedBefore && doesContentActionRequireFeedback(actionType) && !feedback) {
    throw new Error('Tried to cast vote without providing reason');
  }
  
  let newVoteCount;
  if (!noopIfAlreadyCast || notCastedBefore) {
    // build update according to the rules of the game
    const voteUpdateData = buildUpdateForActionVote(notCastedBefore, uid, actionType, oldVoteData);

    // console.log("toggleVote", voteUpdateData, ' -- feedback:', feedback);

    // prepare changes
    const promises = [
      transaction.set(reviewRef, voteUpdateData, { merge: true }),
      storeFeedbackTransaction(transaction, uid, actionType, contentId, oldFeedbackDoc, feedback)
    ];

    // send vote and feedback data to the database
    await Promise.all(promises);
    
    newVoteCount = (oldVoteData && oldVoteData[makeVoteCountProp(actionType)] || 0) + (notCastedBefore ? 1 : -1);
  }
  else {
    // don't do anything
    newVoteCount = oldVoteData[makeVoteCountProp(actionType)];
  }

  // return whether vote was casted (or retracted), and the new vote count
  return [notCastedBefore, newVoteCount];
}


/**
 * Build the update object for given vote toggle.
 */
function buildUpdateForActionVote(isCasting, uid, actionType, review) {
  const update = {
    updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
  };
  if (isCasting) {
    // [Toggle On] has not voted before -> cast vote! (while revoking previous votes)
    addVoteUpdate(uid, actionType, review, update);
    
    // revoke all votes on all inverse actions
    const inverseActions = getInverseActionTypes(actionType);
    revokeAllVoteUpdate(uid, inverseActions, review, update);
  }
  else {
    // [Toggle Off] has voted before -> revoke vote!
    revokeVoteUpdate(uid, actionType, review, update);

    // also revoke all votes on "revoke inverse actions"
    const revokeActions = getRevokeActionTypes(actionType);
    revokeAllVoteUpdate(uid, revokeActions, review, update);
  }
  return update;
}


/**
 * Cast vote in favor of given action.
 */
function addVoteUpdate(uid, actionType, review, update) {
  // increment vote count, and add to votes
  update[makeVoteCountProp(actionType)] = firebase.firestore.FieldValue.increment(1);
  update[makeVotesProp(actionType)] = firebase.firestore.FieldValue.arrayUnion(uid);
}


/**
 * Revoke vote of multiple actions.
 */
function revokeAllVoteUpdate(uid, revokeActions, review, update) {
  revokeActions.forEach(actionType => {
    const castedVotes = review && review[makeVotesProp(actionType)];
    if (castedVotes && castedVotes.includes(uid)) {
      // revoke vote of all inverse actions, only if this guy casted a vote to begin with
      revokeVoteUpdate(uid, actionType, review, update);
    }
  });
}

/**
 * Revoke vote of given action.
 */
function revokeVoteUpdate(uid, actionType, review, update) {
  // increment vote count, and add to votes
  update[makeVoteCountProp(actionType)] = firebase.firestore.FieldValue.increment(-1);
  update[makeVotesProp(actionType)] = firebase.firestore.FieldValue.arrayRemove(uid);
}


// ########################################################################################################################
// ContentReviews + collections
// ########################################################################################################################

/**
 * contentReviews data structure:
 * {
 *    updatedAt: date,
 *    votes-{actionType}: array of voter uid,
 *    voteCount-{actionType}: int
 * }
 */
class ContentReviews {
  logItems = {};

  constructor() {
    this.state = new State();
    this.reviews = new FirestoreContainer('contentReviews', this.state);
    this.reviews.onBeforeDocUpdateCb = this.onReviewUpdate;
    this.feedback = new FirestoreContainer('contentFeedback', this.state);
  }

  /**
   * Purpose: Logging of review actions.
   * Called when any review data changed, to log the action.
   */
  onReviewUpdate = (fsCont, contentId, newData, oldData, isOverride) => {
    // if (oldData) {
    if (newData) {
      //   // item got deleted
      //   this.log(contentId, ReviewChangeType.Delete);
      // }
      // else {
        // item data changed
        //review[makeVoteCountProp(actionType)]
        const allActionTypes = ContentActionType.values;
        // const actionTypes = new Set(Object.keys(oldData).concat(Object.keys(newData)));
        for (const actionType of allActionTypes) {
          const countProp = makeVoteCountProp(actionType);
          const oldCount = oldData && oldData[countProp] || 0;
          const newCount = newData[countProp];
          const value = newCount - oldCount;
          if (value) {
            let uid;
            const newVotes = newData[makeVotesProp(actionType)] || EmptyArray;
            const oldVotes = oldData && oldData[makeVotesProp(actionType)] || EmptyArray;
            if (value > 0) {
              // added vote
              uid = newVotes.find(uid => !oldVotes.includes(uid));
            }
            else {
              // removed vote
              uid = oldVotes.find(uid => !newVotes.includes(uid));
            }
            if (uid) {
              this.log(contentId, ReviewChangeType.ActionVote, uid, {
                actionType,
                value
              });
            }
          }
        }
      // }
    }
  };

  getLastLogMessage(contentId) {
    const items = this.logItems[contentId];
    return items && items[items.length-1];
  }

  log(contentId, changeType, uid, other) {
    const newItem = new LogMessage({
      contentId,
      changeType,
      uid,
      ...other
    });
    const items = this.logItems[contentId] || (this.logItems[contentId] = []);
    items.push(newItem);
  }
  
  _init() {
    // nothing to do
  }

  onUpdate(cb) {
    this.state.onUpdate(cb);
  }

  addListener(docId, cb) {
    return this.state.addListener(docId, cb);
  }

  /**
   * Used for optimistic UI: force-render the optimal result first.
   */
  overrideCurrentUserHasVoted = (actionType, contentId) => {
    const uid = authState.uid;
    const hasVoted = this.getHasVoted(actionType, contentId, uid);
    let votes = this.getVotes(actionType, contentId);
    let voteCount = this.getVoteCount(actionType, contentId);
    if (hasVoted) {
      // retract vote
      --voteCount;
      votes = votes.filter(v => v !== uid);
    }
    else {
      // cast vote
      ++voteCount;
      votes = [...votes, uid];
    }

    this.reviews.overrideDoc(contentId, {
      [makeVoteCountProp(actionType)]: voteCount,
      [makeVotesProp(actionType)]: votes
    }, true);
  }

  getVoteCount = (actionType, contentId) => {
    const review = this.reviews.getDocNowOrQuery(contentId);
    if (review === undefined) {
      return undefined;
    }
    return review && review[makeVoteCountProp(actionType)] || 0;
  }

  getVotes = (actionType, contentId) => {
    const review = this.reviews.getDocNowOrQuery(contentId);
    if (review === undefined) {
      return undefined;
    }
    return review && review[makeVotesProp(actionType)] || EmptyArray;
  }

  getCurrentUserHasVoted = (actionType, contentId) => {
    const uid = authState.uid;
    return this.getHasVoted(actionType, contentId, uid);
  };
  
  getHasVoted = (actionType, contentId, uid) => {
    const uids = this.getVotes(actionType, contentId);
    return uids && uids.includes(uid) || false;
  }

  getFeedbackReasons = (actionType, contentId) => {
    const feedback = this.feedback.getDocNowOrQuery(contentId);
    if (!feedback) {
      return feedback;
    }
    const reasons = feedback[actionType];
    return reasons || null;
  }

  getFeedbackReason = (actionType, contentId, reasonId) => {
    const reasons = this.getFeedbackReasons(actionType, contentId);
    return reasons && reasons[reasonId] || null;
  }

  getFeedbackReasonCount = (actionType, contentId, reasonId) => {
    const oldReason = this.getFeedbackReason(actionType, contentId, reasonId);
    return oldReason && oldReason.count || 0;
  }
/**

* For new content submission
  * Reviewers have buttons to: (A) publish or to (B) delete
  * If reviewer has privilege "publishContent": can directly publish or delete the content
  * If reviewer has privilege "reviewNewContent": can only cast vote.
  * When deleting, reviewer must also give reason.
  * Once published or deleted, user will be notified.
  
*/
}




// ########################################################################################################################
// Logging utilities
// ########################################################################################################################

export const ReviewChangeType = new Enum({
  New: 1,
  Delete: 2,
  ActionVote: 3
});

const MessageFormatByChangeType = {
  New: obj => {
    return `Content item was submitted!`;
  },
  ActionVote: obj => {
    const who = obj.uid === authState.uid ? 'You' : 'Someone';
    const verb = obj.value > 0 ? 'voted' : 'revoked vote';
    const action = ContentActionType.nameFrom(obj.actionType);
    return `${who} ${verb} to ${action}`;
  }
};

class LogMessage {
  constructor(cfg) {
    Object.assign(this, cfg);
  }

  getStatusText() {
    const changeName = ReviewChangeType.nameFromForce(this.changeType);
    const f = MessageFormatByChangeType[changeName];
    return f(this);
  }
}

export const contentReviews = new ContentReviews();