export default class ContentQueryInterface {
  constructor(contentObjects) {
    this.contentObjects = contentObjects;
  }
}

export class DefaultContentQueryInterface extends ContentQueryInterface {
  constructor(contentObjects, cfg) {
    super(contentObjects);
    
    Object.assign(this, cfg);
  }
}