import { initRenderContentColumns, initRenderVisibleContentColumns } from './content/contentObjectColumns';
import { initRenderGlobalContentEditors } from './content/contentEditor';

import { initRenderShortcuts } from './shortcutRenderer';
import { initRenderWorldList } from './worlds';
import { isMobile } from '../util/responsive';
import { initModeratorTools } from './moderation';
import { initTagRenderSystem } from './tags/tags';
import { isApprovalPage } from '../pages';
import { initLoginControls } from './login';
import { initNotificationsRender } from './notificationRenderer';
import { initContentScroller } from './content/contentScroller';
import { initSettingsTabs } from 'src/render/userSettings/index';
import { initMenuButtons } from 'src/render/menu';
import { initRenderThirdParty } from 'src/render/thirdParty';
import { initRenderECM } from 'src/render/ecm';
import startQueue from 'src/taskScheduler';

// ###########################################################################
// fix things up
// ###########################################################################

let $outerContainerNormal; // = $('.gallery-container');
let $outerContainerMobile; // = $('.mobile-section');

/**
 * In webflow, mobile and non-mobile version are both produced -> remove the other one
 */
function fixUpViewContainers() {
  // remove mobile container on non-mobile and vice versa
  $outerContainerNormal = $('.gallery-container');
  $outerContainerMobile = $('.mobile-section');
  // $outerContainer = $outerContainerMobile.is(':visible') ? $outerContainerMobile : $outerContainerNormal;
  (function () {
    const $otherCont = !$outerContainerMobile.is(':visible') ? $outerContainerMobile : $outerContainerNormal;
    $otherCont.remove();
  })();
}

// ##################################################################################################################
// Do the whole thing!
// ##################################################################################################################

let initContentRender, shouldRenderContentRightAway;

/**
 * High priority tasks that must complete before showing anything.
 */
export function initRenderAll(columnConfig) {
  fixUpViewContainers();

  // some render dependencies that we have
  initRenderThirdParty();

  // listen on world menu button
  initRenderWorldList();

  // initialize content stuff (no DB loading)
  initRenderContentColumns(columnConfig);

  //ecmCache.onUpdate(updateContentRender); // make sure to update video scores when ECM data changed
  initContentRender = () => {
    // load what is visible
    initRenderVisibleContentColumns();
  };

  shouldRenderContentRightAway = !isMobile() || isApprovalPage();

  // content scroller
  initContentScroller(!shouldRenderContentRightAway && initContentRender);

  // get the tag render system ready
  initTagRenderSystem();
}


/**
 * The many many "init" (or "start") tasks, sorted by priority, put in the queue...
 */
export function initStartQueue() {
  // per-page rendering (e.g. `reel` page)
  // initTasks.addPreTask(initRenderPage);

  startQueue.addTasks(
    // shortcuts
    initRenderShortcuts,

    // render notification bell + open notification dialog if `notification` parameter in URL
    initNotificationsRender,

    // reel page button (in the future there might be more)
    initMenuButtons,

    // login, signup + logout forms/buttons (register event for after login has finished)
    initLoginControls
  );

  if (shouldRenderContentRightAway) {
    // don't render content columns on mobile unless we swiped, or we are on approval page
    startQueue.addBackgroundTask(initContentRender);
  }

  startQueue.addBackgroundTasks(
    // init ECM: menu button handler + addBackgroundTask to get ECM data (for content column `ecm-score`)
    initRenderECM,

    // moderator tools
    initModeratorTools,

    // init user settings tab button -> when open start rendering
    initSettingsTabs,

    // content editor
    initRenderGlobalContentEditors
  );
}


// $(document).ready(() => {
//   OverlayScrollbars(document.querySelectorAll(".column2 .overlay"), { }); 
// });