import { DefaultSlideDelay } from './domUtil';
import defaults from 'lodash/defaults';

const cfgDefaults = {
  singleSelection: true,
  onExpand: null
}

export default class MultiCollapse {
  expanded = new Map();

  constructor($cont, titleSelector, bodySelector, cfg) {
    this.titleSelector = titleSelector;
    this.bodySelector = bodySelector;
    this.$cont = $cont || $(document.body);

    // cfg
    Object.assign(this, defaults(
      cfg,
      cfgDefaults
    ));
  }

  /**
   * Collapse all matching elements
   */
  collapseAll() {
    for (const [node, $expandedSection] of this.expanded) {
      $expandedSection.slideUp(DefaultSlideDelay);
    }
    this.expanded.clear();
  }

  toggleExpand($el) {
    const node = $el[0];
    const wasExpanded = this.expanded.has(node);

    if (this.singleSelection) {
      // collapse all
      this.collapseAll();
    }

    if (!wasExpanded) {
      // expand this one if it was collapsed before
      this.setExpanded($el, true);
    }
  }

  setExpanded($el, expand = true) {
    const node = $el[0];
    const $body = $el.find(this.bodySelector);
    if (expand) {
      // expand
      $body.slideDown(DefaultSlideDelay);
      this.expanded.set(node, $body);
      this.onExpand && this.onExpand($el);
    }
    else {
      // collapse
      $body.slideUp(DefaultSlideDelay);
      this.expanded.delete(node);
    }
  }

  toggleOnClick($el) {
    const $titleEl = $el.find(this.titleSelector);
    const $body = $el.find(this.bodySelector);
    
    $titleEl.on('click', async evt => {
      // tag has been clicked
      this.toggleExpand($el);
    });

    if (this.singleSelection) {
      // hide by default
      $body.hide();
    }
  }
}