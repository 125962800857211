import { ytFetchPlaylists, ytFetchPlaylistItems } from 'src/web-apis/youtube/YtAPI';
import { ytExtractBiggestThumbnail, buildYoutubePlaylistUrl, buildYoutubeChannelUrl, buildYoutubeVideoUrl } from 'src/web-apis/youtube/YtResourceUtil';
import EmptyObject from 'src/util/EmptyObject';
import { queryIframely } from 'src/api/third-party/iframely';

// function isYouTubePlaylist(url) {
//   getYoutubeListIdFromUrl(url)
// }

/**
 * Load content data from youtube playlist.
 */
export async function queryYoutubePlaylist(youtubePlaylistId) {
  const playlistResults = await ytFetchPlaylists(youtubePlaylistId);
  const playlist = playlistResults?.[0];

  if (!playlist?.snippet) {
    return null;
  }

  let {
    snippet: {
      // publishedAt
      title,
      description,
      thumbnails,
      channelId,
      channelTitle: author
    },
    status: {
      privacyStatus
    },
    contentDetails: {
      itemCount: videoCount
    }
  } = playlist;

  const statusDescription =
    (privacyStatus !== 'public' ? (privacyStatus.toUpperCase() + '. ') : '') +
    `${videoCount} videos.`;

  let firstVid;
  let trailerUrl = null;
  let videoDescription
  try {
    // get first video as trailer
    const videoItems = await ytFetchPlaylistItems({ maxResults: 1 }, youtubePlaylistId);
    firstVid = videoItems?.[0];

    if (firstVid) {
      const {
        resourceId: { videoId },
        thumbnails: vidThumbnails
      } = firstVid?.snippet;

      videoDescription = firstVid?.snippet.description;
      
      trailerUrl = videoId && buildYoutubeVideoUrl(videoId) || null;

      // hackfix: YouTube API has a bug, where it sends thumbnail of second video, instead of first
      // see: https://stackoverflow.com/questions/58217013/youtube-is-sending-wrong-thumbnail-when-when-retrieving-data-using-oembed
      thumbnails = vidThumbnails;
    }
  }
  catch (err) {
    console.error(`Unable to fetch first video of playlist "${title || youtubePlaylistId}":\n\n`, err);
  }

  description = `${statusDescription} ${description || videoDescription || ''}`;

  // rebuild URL to make sure, it is the actual playlist's own URL (rather than a video URL with a playlist argument which would be wrong)
  const url = buildYoutubePlaylistUrl(youtubePlaylistId);

  // 'title', 'author', 'description', 'thumbnail_url'
  // 'author_url', 'promo'
  return {
    title,
    author,
    description,
    thumbnail_url: ytExtractBiggestThumbnail(thumbnails),
    author_url: buildYoutubeChannelUrl(channelId),
    trailerUrl,
    promo: trailerUrl,
    url
  };
}

// ###########################################################################
// Query additional content from youtube video
// ###########################################################################

export async function queryAdditionalDataFromYoutubeOrPromoUrl(url, promoOrYoutubeUrl, result) {
  try {
    const result2 = await queryIframely(promoOrYoutubeUrl);
    if (result === EmptyObject) {
      result = {};
    }

    // only override missing data entries from result2
    // defaultsDeep(result, result2); // defaultsDeep only works if key does not exist yet
    for (const name in result2) {
      if (!result[name]) {
        // do NOT override URL
        result[name] = name === 'url' ? url : result2[name];
      }
    }
    result.trailerUrl = result.promo = result.promo || promoOrYoutubeUrl;
  }
  catch (err) {
    console.error('could not fetch url', promoOrYoutubeUrl, '\n', err);
  }
  return result;
}