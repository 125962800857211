import {
  isTagNew
} from 'api/tags/tags';

import tagReviews from 'api/tags/tagReviews';

import { getContentById, queryHasContentWithTag, hasContentWithTag } from 'api/content/contentObjects';

import {
  DefaultSlideDelay,
  prepareTemplateElement,
  cloneTemplateElement,
  decorateClasses,
  selectTextOnClick,
  sortChildren
} from 'util/domUtil';

import {
  TagRenderContext, isTagSearchContext, isWorldContext
} from 'api/tags/TagConfig';

import {
  renderTagModerationControls, initRenderTagControls, resetRenderTagControls
} from 'render/tags/tagControls';

import postUpdate from 'src/postUpdate';
import { userHasECM, ecmContainer } from 'api/ecm';
import { getWorldNames, isWorldName, getWorldLink, getWorldRenderName } from 'api/worlds';
import { startWebflowAnimation as startWebflowAnimation } from 'src/renderUtil/animUtil';
import { getTagLink } from 'api/links';
import {
  onTagUpdate,
  getWikiEntry,
  isQuerying,
  getRealTagName,
} from 'api/third-party/wikiApi';
import { getTagElInList } from 'src/renderUtil/tags';
import { renderTagToggleECMButtons } from 'src/renderUtil/ecm';
import { getRenderQueue } from '../renderQueue';
import { NotLoaded } from 'src/db';

// const Verbose = true;
const Verbose = false;

// ##################################################################################################################
// tag container in content elements
// ##################################################################################################################

export function initRenderTagToggle(contentId, $contentEl, expandTagsByDefault) {
  const $tagsContainer = $contentEl.find('.container-tags-object');
  if (!expandTagsByDefault) {
    $tagsContainer.slideUp(DefaultSlideDelay);    // hide initially
    // $tagsContainer.attr('data-show-tags', 'f');
  }
  else {
    // hackfix: we are using this to tell tag render engine to show tags initially
    $tagsContainer.attr('data-show-tags', 't');
  }

  addToggleTagsClickHandler(contentId, $contentEl, $contentEl.find('.show-tags-button'));
}

export function addToggleTagsClickHandler(contentId, $contentEl, $tagsToggleButton) {
  const $tagsContainer = $contentEl.find('.container-tags-object');

  // observeEl($tagsContainer);
  $tagsToggleButton.off('click').on('click',
    toggleTagContainer.bind(null, $tagsContainer, contentId, $contentEl)
  );
}

export function maybeRenderContentTags(contentId, $contentEl) {
  const $tagsContainer = $contentEl.find('.container-tags-object');
  if ($tagsContainer.attr('data-show-tags') === 't') {
    // HACKFIX: must be on a timer to make sure, parent element got rendered once before function can find it
    setTimeout(() => renderContentItemTags(contentId, $contentEl));
  }
}

export function toggleTagContainer($tagsContainer, contentId, $contentEl) {
  $tagsContainer.slideToggle(DefaultSlideDelay);

  // hide all tags
  collapseAllTags();

  // don't forget: HTML attributes must always be strings o_X
  const showTags = $tagsContainer.attr('data-show-tags') !== 't'; // was not showing before?
  // console.log('tag toggle clicked', $tagsContainer[0]);
  if (showTags) {
    // hide (slide up) all other tags
    const $openContainers = $('.container-tags-object[data-show-tags=t]'); // get *ALL* open tags
    $openContainers.attr('data-show-tags', 'f');
    $openContainers.slideUp(DefaultSlideDelay);

    // open this one
    $tagsContainer.attr('data-show-tags', 't');
    renderContentItemTags(contentId, $contentEl);
  }
  else {
    // hide this one
    $tagsContainer.attr('data-show-tags', 'f');
  }
}

// ##################################################################################################################
// content tag rendering
// ##################################################################################################################


const expandedClass = 'tag-details-expanded';

/**
 * Collapse all tag elements that are currently open and reset them
 */
function collapseAllTags(tagRenderContext) {
  const $openTags = $('.' + expandedClass);
  $openTags.each(function () {
    resetRenderTagControls(tagRenderContext, $(this));
  });
  $openTags.removeClass(expandedClass).slideUp(DefaultSlideDelay);
}

function initRenderTagTitleAndDescription(tagRenderContext, tagName, $tagEl, contentId = undefined) {
  // hide details
  const $expandableSection = $tagEl.find('.expandable-wiki-title-section');
  $expandableSection.hide();

  // '<div class="wiki-title-object" data-world-id="' + world.name + '" data-world-types="' + world.types + '" data-world-current="' + isCurrentWorld + '">';
  const $titleEl = $tagEl.find('.wiki-title h3');
  // const $descriptionEl = $outputEl.find('.wikipedia-description');

  // TODO: show animation for new tags

  $titleEl.on('click', async evt => {
    // tag has been clicked
    const wasCollapsed = !$expandableSection.hasClass(expandedClass);

    // first: collapse all tag containers, tags and in-tag ECM controls
    collapseAllTags(tagRenderContext);

    if (wasCollapsed) {
      // expand this one if it was collapsed before
      $expandableSection.slideDown(DefaultSlideDelay);
      $expandableSection.addClass(expandedClass);

      // query data from wiki + database, if not got before
      const realTagName = await getRealTagName(tagName);
      if (isWorldContext(tagRenderContext) // && !isWorldName(realTagName)
      ) {
        // check if we have at least one content item of given tag
        await queryHasContentWithTag(realTagName);
      }

      renderTag($tagEl);
    }
  });
}

function initRenderTag(tagRenderContext, tagName, $tagEl, contentId = undefined) {
  // title + description
  initRenderTagTitleAndDescription(tagRenderContext, tagName, $tagEl, contentId);

  // init tag buttons
  initRenderTagControls(tagRenderContext, tagName, $tagEl, contentId);
}

let counter = 0;
function initECMForTag(tagRenderContext, $tagEl, tagName) {
  // re-render ECM-related data when ecm data changes
  const $titleEl = $tagEl.find('.wiki-title h3');
  const $descriptionEl = $tagEl.find('.wikipedia-description');

  ecmContainer.onTagUpdate(tagName, () => {
    // re-render tag
    renderTagTitleAndDescription(tagName, $titleEl, $descriptionEl);

    renderTagToggleECMButtons(tagRenderContext, $tagEl, tagName);
    // console.warn('tag ECM', ++counter);
    // _updateECMButtons(tagRenderContext, $tagEl, tagName);
  });
}

function createTagEl($list, tagName, tagRenderContext, contentId = undefined) {
  const $tagEl = cloneTemplateElement($list, '.wiki-title-object');
  // console.debug(tagName, tagRenderContext, contentId = undefined);

  $tagEl.addClass('added-tag');

  if (isTagNew(contentId, tagName)) {
    $tagEl.addClass('new-tag');
    $tagEl.addClass('flash-highlight');

    // hackfix: remove class after 3s (so it won't flash again)
    setTimeout(() => $tagEl.removeClass('flash-highlight'), 3000);
  }

  $tagEl.attr('data-tag', encodeURIComponent(tagName));

  const order = $list.children().length + 1;
  $tagEl.css({ order });
  $tagEl.appendTo($list);

  // go!
  $tagEl.show();

  // hackfix: remember some data (really bad design; but we don't have a unified API to query all different types of tag data)
  $tagEl[0]._tagRenderContext = tagRenderContext;
  $tagEl[0]._tagName = tagName;
  $tagEl[0]._contentId = contentId;


  initRenderTag(tagRenderContext, tagName, $tagEl, contentId);
  initECMForTag(tagRenderContext, $tagEl, tagName);

  return $tagEl;
}

function renderTagTitleAndDescription(tagName, $titleEl, $descriptionEl) {
  // set title
  // const tagNameOverride = getWorldRenderName(tagName);
  // let title = tagNameOverride || tagName;
  let title = tagName;

  // get tag entry from wiki
  // console.warn(performance.now(), 'renderTagTitleAndDescription', tagName);
  const entry = getWikiEntry(tagName);
  if (entry && entry.redirectTo) {
    title += ' -> ' + entry.redirectTo;
  }
  $titleEl.text(title);

  // set description
  let description;
  if (entry?.description || entry?.redirectTo) {
    description = entry.description || '';
  }
  else {
    description = 'loading...';
  }
  $descriptionEl.text(description);

  // highlight, if the tag is in your ECM set
  decorateClasses($titleEl, {
    'ecm-tag': userHasECM(tagName)
  });
}

function renderGotoRelatedContentButtons($tagEl, tagName, busy) {
  // show/hide world related/not content buttons
  const $relatedCtn = $tagEl.find('.there-is-content-ctn');
  const $noRelatedCtn = $tagEl.find('.there-is-no-content-ctn');

  if (isWorldName(tagName)) {
    // hide "related" and "be first to submit" buttons
    $relatedCtn.hide();
    $noRelatedCtn.hide();
  }
  else {
    const has = hasContentWithTag(tagName); // NOTE: we sent out the query on `click`
    if (has === NotLoaded) {
      $relatedCtn.hide();
      $noRelatedCtn.hide();
    }
    else {
      let $a;
      if (has) {
        // show "go to related", hide "be first to submit"
        $a = $relatedCtn.find('a');
        $a.attr('href', getTagLink(tagName));
        $a.off('click').on('click', () => {
          startWebflowAnimation('loader-world');
        });
        $relatedCtn.show();
        $noRelatedCtn.hide();
      }
      else {
        // hide "go to related", show "be first to submit"
        // $noRelatedCtn.find('a').attr('href', getTagLink(tagName));
        $a = $noRelatedCtn.find('a');
        $a.off('click').on('click', () => {
          startWebflowAnimation('add-content');
        });
        $relatedCtn.hide();
        $noRelatedCtn.show();
      }

      // disable buttons while busy
      decorateClasses($a, {
        disabled: busy
      });
    }
  }
}

function renderGotoWorldButtons($tagEl, tagName) {
  // visit world button(s)
  const $worldCont = $tagEl.find('.there-is-a-world-ctn');
  const $worldBtn = $tagEl.find('.check-world-button,.world-link');

  if (isWorldName(tagName)) {
    $worldBtn.attr('href', getWorldLink(tagName));
    $worldBtn.off('click').on('click', () => {
      // also show an animation
      startWebflowAnimation('loader-world');
    });
    $worldBtn.show();
    $worldCont.show();
  }
  else {
    $worldBtn.hide();
    $worldCont.hide();
  }
}

/**
 * (Re-)render tag element title and description
 */
function renderTag($tagEl) {
  const {
    _tagRenderContext: tagRenderContext,
    _tagName: tagName,
    _contentId: contentId
  } = $tagEl[0];

  const $titleEl = $tagEl.find('.wiki-title h3');
  const $descriptionEl = $tagEl.find('.wikipedia-description');

  // title + description
  renderTagTitleAndDescription(tagName, $titleEl, $descriptionEl);

  const tagEntry = getWikiEntry(tagName);
  const busy = isQuerying(tagName); // TODO: this is not really working yet

  Verbose && console.debug('[render/tags]', TagRenderContext.nameFromForce(tagRenderContext), tagName, !!tagEntry, tagEntry);

  if (!tagEntry) {
    // still loading, or not opened yet
    return;
  }

  // use tag name after redirects
  const actualTagName = tagEntry.redirectTo || tagEntry.name;

  // "go to world" buttons
  renderGotoWorldButtons($tagEl, actualTagName, busy);

  // "go to related content" buttons
  switch (tagRenderContext) {
    // case TagRenderContext.WorldList:
    case TagRenderContext.WorldSearch:
      {
        renderGotoRelatedContentButtons($tagEl, actualTagName, busy);
        break;
      }
  }

  // ECM controls
  renderTagToggleECMButtons(tagRenderContext, $tagEl, tagName);

  // render/update buttons below tag information
  renderTagModerationControls(tagRenderContext, $tagEl, tagName, contentId);
}

/**
 * Render the worlds list when no search term is entered.
 */
export function renderWorldsDefault($list) {
  renderTagListNotEmpty(getWorldNames(), $list, TagRenderContext.WorldList);
}

function renderTagListEmpty($list, tagRenderContext) {
  switch (tagRenderContext) {
    case TagRenderContext.WorldList: {
      renderWorldsDefault($list);
      break;
    }
    default: {
      let $tagEl = $list.find('.no-tags');
      if (!$tagEl.length) {
        // haven't rendered the `no-tags` element yet

        // determine text to be shown
        let msg;
        switch (tagRenderContext) {
          case TagRenderContext.Proposed:
            msg = '(no tags proposed)';
            break;
          default:
            msg = '(no tags yet)';
            break;
        }

        // create '(no tags ....)' element
        const $tagEl = cloneTemplateElement($list, '.wiki-title-object');

        // clear list (IMPORTANT: after calling `cloneTemplateElement`)
        $list.empty();


        $tagEl.addClass('no-tags');

        const $titleEl = $tagEl.find('.title-wiki-dark');
        if (!$titleEl.length) {
          console.error('invalid tag list does not have an element with class "title-wiki-dark"', $list);
        }
        const $expandableSection = $tagEl.find('.expandable-wiki-title-section');
        $expandableSection.empty(); // clear expandable section

        $titleEl.css('color', 'grey');
        $titleEl.text(msg);

        $tagEl.appendTo($list);
        $tagEl.show();
      }
      break;
    }
  }
}


export async function createOrRenderTagEls(tagRenderContext, $list, tagNames, contentId = undefined) {
  // sort tags alphabetically, case-insensitively
  if (!isTagSearchContext(tagRenderContext)) {
    // we prefer existing tags to be sorted properly; and search results to stay in the order that wiki gives them to us
    tagNames.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }

  const version = $list[0]._version;

  // render asynchronously
  return renderQueue.enqueueRenderTasks(
    tagNames.map(tagName => (
      createOrRenderTagEl.bind(null, tagRenderContext, $list, tagName, contentId, version)
    ))
  );
}

export function createOrRenderTagEl(tagRenderContext, $list, tagName, contentId = undefined, version = null) {
  if (version && $list[0]._version !== version) {
    return;
  }

  let $tagEl = getTagElInList($list, tagName);
  if (!$tagEl.length) {
    // create new element
    $tagEl = createTagEl($list, tagName, tagRenderContext, contentId);
  }

  // update tag element content
  renderTag($tagEl);

  return $tagEl;
}

export function renderTagList(tags, $list, tagRenderContext, contentId = undefined) {
  if (!tags || !tags.length) {
    // no tags to show!
    renderTagListEmpty($list, tagRenderContext);
  }
  else {
    renderTagListNotEmpty(tags, $list, tagRenderContext, contentId);
  }
}

async function renderTagListNotEmpty(tagNames, $list, tagRenderContext, contentId = undefined) {
  $list.find('.no-tags').remove();

  // prepare template element first
  prepareTemplateElement($list, '.wiki-title-object');

  // remove all removed (but previously rendered) tags
  const tagNameSet = new Set(tagNames); // allows us to do this in O(n)
  $list.children().each((i, tagEl) => {
    const tagName = getTagName(tagEl);
    !tagNameSet.has(tagName) && removeTagEl(tagName, $(tagEl));
  });

  // render all tags
  await createOrRenderTagEls(tagRenderContext, $list, tagNames, contentId);

  if (!$list.is(':visible')) {
    // NOTE: due to asynchronous rendering, list might not be visible anymore
    return;
  }

  // sort $list -> newest first
  sortChildren($list, (a, b) => {
    // const $a = $(a), $b = $(b);
    // const tagA = getTagName($a);
    // const tagB = getTagName($b);

    // put new tags at the top
    // if ($b.hasClass('new-tag')) {
    if (b.classList.contains('new-tag')) {
      return -1;
    }
    // if ($a.hasClass('new-tag')) {
    if (a.classList.contains('new-tag')) {
      return 1;
    }

    return 0;
  });
}

const unsubscribeCallbacks = {};

/**
 * Render tags under content item
 */
function renderContentItemTags(contentId, $contentEl) {
  const contentData = getContentById(contentId);

  // // $contentEl sometimes returns the wrong element when displaying things not uniquely:
  // //    (1) in ContentColumn, and also
  // //    (2) in ContentEditor
  // const $contentEl = getContentEl(contentId);

  // console.log('renderContentItemTags', contentId);

  if (!$contentEl.length) {
    console.warn('Trying to call `renderContentItemTags` after content is not rendered anymore:', contentId, contentData);
    return;
  }

  const $tagsCont = $contentEl.find('.container-tags-object');
  // if (!$tagsCont.length || !$tagsCont.height()) {
  //   return;
  // }

  const $approvedTagsCont = $tagsCont.find('.approved-tags');
  const $pendingTagsCont = $tagsCont.find('.pending-tags');

  if (!unsubscribeCallbacks[contentId]) {
    // add listener
    const unsubscribe = tagReviews.addListener(contentId,
      () => {
        // console.log('tagReviews UPDATE', contentId);
        return renderContentItemTags(contentId, $contentEl);
      }
    );
    unsubscribeCallbacks[contentId] = unsubscribe;
  }

  // render approved tags
  const { tags } = contentData;
  renderTagList(tags, $approvedTagsCont, TagRenderContext.Added, contentId);

  if ($pendingTagsCont.length) {
    // render pending tags
    const pendingTags = tagReviews.getPendingTags(contentId, tags);
    renderTagList(pendingTags, $pendingTagsCont, TagRenderContext.Proposed, contentId);
  }

  // console.log('tags-container', $tagsCont[0]);
}

function getTagName(tagEl) {
  return decodeURIComponent(tagEl.getAttribute('data-tag'));
}

function removeTagEl(tagName, $tagEl) {
  $tagEl.remove();
}

export function clearTagList($list) {
  $list.empty();

  // NOTE: we use version to make sure, already queued (but unfinished) render calls will be ignored for this list
  $list[0]._version = ($list[0]._version || 0) + 1;
}


function onAnyTagUpdate(changed) {
  for (const tagName in changed) {
    const $tagEls = $(`[data-tag="${encodeURIComponent(tagName)}"]`);

    // smooth render
    renderQueue.enqueueRenderTasks($tagEls.map(
      (i, tagEl) => {
        // if (!tagEl) {
        //   return;
        // }
        const $tag = $(tagEl);
        renderTag($tag);
      }
    ));
  }
}

let renderQueue;

export function initTagRenderSystem() {
  // get renderQueue
  renderQueue = getRenderQueue();

  // hook up tag data events
  onTagUpdate(onAnyTagUpdate);
}