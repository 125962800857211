import { getTagsOfNetwork } from 'src/api/third-party/networks';
import EmptyArray from 'src/util/EmptyArray';
import { lookupFilmGenreTags } from 'src/api/third-party/films';

export async function postProcessContent(data) {
  const {
    genreNames,
    networkName,
    movieId
  } = data;

  let movieTags;
  if (movieId) {
    // WARNING: only works for films for now
    movieTags = genreNames && await lookupFilmGenreTags(genreNames);
  }

  // auto tagging
  data.tags = [
    ...(networkName && getTagsOfNetwork(networkName) || EmptyArray),
    ...(movieTags || EmptyArray)
  ];

  delete data.genreNames;
}