import { regexIndexOf, parseQueryString } from './util/misc';


// ###############################################################
// Page config
// ###############################################################

export const PageRequiresAuth = {
  approval: true,
  submitted: true
};

export const PageModeratorToolsByDefault = {
  approval: true
};


// ###############################################################
// Config accessor functions
// ###############################################################

export function doesPageRequireAuth() {
  return !!PageRequiresAuth[getPageName()];
}

export function shouldPageShowModeratorToolsByDefault() {
  return !!PageModeratorToolsByDefault[getPageName()];
}


// ###############################################################
// Some utilities
// ###############################################################

export function isApprovalPage() {
  return getPageName() === 'approval';
}

export function isSubmittedPage() {
  return getPageName() === 'submitted';
}

export function isHomePage() {
  return getPageName() === 'index';
}

export function isWorldsPage() {
  return getPageName() === 'worlds';
}

export function isTagsPage() {
  return getPageName() === 'tags';
}

export function isSharePage() {
  return getPageName() === 'share';
}

export function isReelPage() {
  return getPageName() === 'reel';
}


// ###############################################################
// Crude routing utilities: Get current page name, url, arguments and more
// ###############################################################

let pageName = undefined;
let pageArg = undefined;
let queryString = undefined;

export function getPageName() {
  if (pageName === undefined) {
    // produce page name once, then store in cache
    const match = (location.pathname || '/').match(/\/([^/]*)(?:\/(.*))?/);
    pageName = match[1] || 'index'; // empty path means "index" page
    pageArg = match[2] && decodeURIComponent(match[2]) || null;
    // console.log('pageName', pageName, pageArg);
  }
  
  return pageName;
}

/**
 * Returns the current URL argument after the first slash
 */
export function getPageUrlArg() {
  if (pageArg === undefined) {
    getPageName();
  }
  return pageArg;
}

export function getQueryStringArg(key) {
  if (!queryString) {
    queryString = parseQueryString(location.search);
  }
  return queryString[key];
}





/**
 * On 'worlds/' and 'tags/' page, this represents the world/tag that is being rendered on that page.
 */
export function getCurrentPageTag() {
  if (isWorldsPage()) {
    return document.title.replace(' World (Curated content) - Discovereel', '');
  }
  if (isTagsPage()) {
    return getCurrentPageArg();
  }
  return null;
}

export function getCurrentPageArg() {
  return getPageUrlArg(); // || getQueryStringArg('x');
}

// export function getWorldLink(tag) {
//   return `${window.location.origin}/worlds/${tag}`;
// }