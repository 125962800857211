// import Ellipsis from 'ellipsis.js';

/**
 * Generic render utilities.
 * Not to be confused with the `renderUtil/` folder which contains *specific* render utilities for specific parts (business logic) of the app.
 */

export const DefaultSlideDelay = 200;


// ##################################################################################################################
// HTML template rendering utilities
// ##################################################################################################################

const templateElements = new Map();

// function validateElExists($cont) {

// }

function lookupTemplate($cont, selector) {
  if (!$cont.length) {
    debugger;
    throw new Error(`invalid template CONT does not exist; trying to get: ` + selector)
  }
  const key = $cont[0];
  const candidates = templateElements.get(key);
  return candidates && candidates.get(selector) || null;
}

function storeTemplate($cont, selector, $template) {
  if (!$cont.length) {
    debugger;
    throw new Error(`invalid template CONT does not exist; trying to get/store: ` + selector)
  }
  const key = $cont[0];
  let candidates = templateElements.get(key);
  if (!candidates) {
    templateElements.set(key, candidates = new Map());
  }
  candidates.set(selector, $template);
}



export function copyTemplateElement($contSrc, $contDst, templateSelector) {
  $contDst.append(cloneTemplateElement($contSrc, templateSelector));
  prepareTemplateElement($contDst, templateSelector);
}

/**
 * @param {String} keyPrefix Must provide a key that, in combination with `selector`, allows to uniquely identify the element template.
 * @param {*} $cont The element that is an ancestor of given element.
 * @param {*} selector The selector to find the element in `$cont`
 */
export function getOrCreateTemplateElement( $cont, selector) {
  // const key = `${keyPrefix} ${selector}`;
  const isFirstRender = !lookupTemplate($cont, selector);

  const $el = getTemplateElement($cont, selector);
  return [isFirstRender, $el];
}

/**
 * Extract, remove and cache HTML template element, ready for further use later on.
 * (Actually does the same as getTemplateElement, but discards the return value.
 * We have it separate from "getTemplateElement" because the naming is more accurate for where it is being used.)
 */
export function prepareTemplateElement($cont, selector) {
  return getTemplateElement($cont, selector);
}

/**
 * @param {*} keyPrefix Used as a unique identifier, to find it in the template cache.
 * @param {*} $cont A jquery element that is any ancestor of the template element
 * @param {*} selector The selector to get the element.
 */
export function getTemplateElement($contOrSelector, selector) {
  // const key = `${keyPrefix} ${selector}`;
  let $cont;
  if (selector) {
    // normal usage
    $cont = $contOrSelector;
  }
  else {
    // only first argument (a selector) has been given
    $cont = $(document);
    selector = $contOrSelector;
  }

  let $template = lookupTemplate($cont, selector);
  if (!$template) {
    // using the template the first time -> find in and remove from DOM
    $template = $cont.find(selector);
    if (!$template.length) {
      debugger;
      console.error('template element cont:', $cont, $cont[0]);
      throw new Error(`unable to find template element ${selector} in cont ${$cont[0]} (${$cont.length})`)
    }
    storeTemplate($cont, selector, $template);

    // detach from container
    $template.remove();
  }
  return $template;
}

export function cloneTemplateElement($contOrSelector, selector) {
  // clone the template element
  // console.error('cloneTemplateElement', selector);
  return getTemplateElement($contOrSelector, selector).clone(true);
}



// ##################################################################################################################
// Conditional CSS classes
// ##################################################################################################################

export function decorateClasses($el, cfg) {
  for (let clazz in cfg) {
    let val = cfg[clazz];

    if (val) {
      $el.addClass(clazz);
    }
    else {
      $el.removeClass(clazz);
    }
  }
}

export function decorateAttr($el, cfg) {
  for (let attr in cfg) {
    let val = cfg[attr];
    $el.attr(attr, val || false);
  }
}



// ##################################################################################################################
// Accordion
// ##################################################################################################################


/**
 * Structure of list must be (they must be direct children):
 *   $list > item > btn, content
 * @see https://codepen.io/Domiii/pen/bPmJMq?editors=0010
 */
export function makeAccordion($list, itemSelector, btnSelector, contentSelector, onlyOneAtATime = true) {
  $list = $($list);

  const showClass = 'show';
  const $items = $list.children(itemSelector);
  $items.children(btnSelector).off('click').on('click', function (e) {
    e.preventDefault();

    const $item = $(this).closest(itemSelector);
    const $el = $item.children(contentSelector).not(':animated');
    if ($el.length) {
      if (onlyOneAtATime) {
        const wasOpen = $el.hasClass(showClass);

        // close all open elements
        const $allContent = $items.children(contentSelector);
        $allContent.filter('.' + showClass).removeClass(showClass).slideUp();

        if (!wasOpen) {
          $el.addClass(showClass);
          $el.slideDown();
        }
      }
      else {
        // just toggle anyway
        $el.toggleClass(showClass);
        $el.slideToggle();
      }
    }
  });
}

// ##################################################################################################################
// More rendering (HTML, CSS + color) utilities
// ##################################################################################################################


/**
 * A linear interpolator for hexadecimal colors
 * @param {String} a
 * @param {String} b
 * @param {Number} amount
 * @example
 * // returns #7F7F7F
 * lerpColor('#000000', '#ffffff', 0.5)
 * @returns {String}
 * @see https://gist.github.com/rosszurowski/67f04465c424a9bc0dae
 */
export function lerpColor(a, b, amount) {
  var ah = parseInt(a.replace(/#/g, ''), 16),
    ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
    bh = parseInt(b.replace(/#/g, ''), 16),
    br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
    rr = ar + amount * (br - ar),
    rg = ag + amount * (bg - ag),
    rb = ab + amount * (bb - ab);

  return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
}


// ##################################################################################################################
// jquery extensions
// ##################################################################################################################

// see: https://stackoverflow.com/questions/9614622/equivalent-of-jquery-hide-to-set-visibility-hidden
export function makeElVisible($el) {
  return $el.css('visibility', 'visible');
}
export function makeElInvisible($el) {
  return $el.css('visibility', 'hidden');
}
export function toggleElVisible($el) {
  return $el.css('visibility', function (i, visibility) {
    return (visibility == 'visible') ? 'hidden' : 'visible';
  });
}

// more stuff

// select text on click
// see: https://stackoverflow.com/a/20079910
export function selectTextOnClick(el) {
  // select text on Click
  window.getSelection().selectAllChildren(el);

  // old version
  // see: https://stackoverflow.com/questions/4067469/selecting-all-text-in-html-text-input-when-clicked
  // $inputEl[0].onclick = function () { this.setSelectionRange(0, this.value.length) };
}

// ##################################################################################################################
// Render computation utilities
// ##################################################################################################################

//import ResizeSensor from 'css-element-queries/src/ResizeSensor';

// /**
//  * @see https://stackoverflow.com/questions/3553776/calculate-height-of-divs-children-using-jquery
//  */
// export function computeTotalChildrenHeight($el) {
//   var totalHeight = 0;

//   $el.children().each(function(){
//       totalHeight += $(this).outerHeight(true);
//   });
//   return totalHeight;
// }

// export function isCollapsedEl($el) {
//   const hStr = $el.css('height');
//   return !hStr || !parseInt(hStr);
// }

// export function toggleCollapseEl($el) {
//   if (isCollapsedEl($el)) {
//     expandEl($el);
//     return true;
//   }
//   else {
//     collapseEl($el);
//     return false;
//   }
// }

// // see: https://stackoverflow.com/a/8331169
// export function expandEl($el) {
//   const h = computeTotalChildrenHeight($el);
//   //$el.innerHeight(h);
//   //$el[0].__targetHeight = h;
//   $el.css('border', '');
//   //$el.maxHeight(9999);
//   $el.css('max-height', 9999);
//   $el.css('height', '');
//   //console.log('expandEl', h);

//   // always resize when children size changes!
//   // uses ResizeSensor (http://marcj.github.io/css-element-queries/)
//   // for (let i = 0; i < $el.length; ++i) {
//   //   const child = $el[i];
//   //   if (!child.____$$_$_$_$$ResizeSensor) {
//   //     child.____$$_$_$_$$ResizeSensor = new ResizeSensor(child, () => {
//   //       // update height if children height changed
//   //       if ($el.innerHeight() === $el[0].__targetHeight) {
//   //         expandEl($el);
//   //       }
//   //     });
//   //   }
//   // }
//   //$el.css('max-height', 99999);
//   //$el.css('height', '');
// }

// export function collapseEl($el) {
//   // for (let i = 0; i < $el.length; ++i) {
//   //   const child = $el[i];
//   //   ResizeSensor.detach(child);
//   //   child.____$$_$_$_$$ResizeSensor = null;
//   // }

//   $el.css('border', '0', 'important');
//   //$el.innerHeight(0);

//   $el.css('max-height', 0);
// }

/**
 * Sorts all children of given element in place.
 */
export function sortChildren($parent, cmp) {
  // use jquery.sort - https://www.shift8web.ca/2017/01/use-jquery-sort-reorganize-content/
  let $els = $parent.children();
  $els = $els.sort(cmp);
  $els.detach().appendTo($parent);
}

// ###########################################################################
// Forms
// ###########################################################################

export function formOnEnter($form, cb) {
  $form.on('keydown', evt => {
    // console.log($(evt.target).closest('form')[0]);
    if (evt.keyCode === 13 && $(evt.target).closest('form')[0] === $form[0]) {
      cb(evt);
    }
  });
}


// ###########################################################################
// events
// ###########################################################################

export async function waitForClick($btn) {
  return new Promise(r => $btn.off('click').on('click', r));
}

export async function addInputTextChangedEvent($input, cb) {
  $input.off('keyup').on('keyup', evt => {
    if (evt.keyCode && !evt.keyCode.toString().match(/^(37|38|39|40|13|16|17|18|224)$/)) {
      cb(evt);
    }
  });
}

// ###########################################################################
// ellipsis
// ###########################################################################

// let ellipsis;

// function initEllipsis() {
//   // NOTE: for now, we just assume a single ellipsis setting
//   if (!ellipsis) {
//     ellipsis = Ellipsis({
//       debounce: 30,
//       lines: 5
//     });
//   }
// }

// export function addEllipsisEl($el) {
//   initEllipsis();

//   ellipsis.add($el.length && $el[0] || $el);
// }

// export function addEllipsisElements($els) {
//   initEllipsis();

//   ellipsis.add($els);
// }