import { waitUntilAuthStateInitialized, authState } from '../../auth';
import { initFCM, addFCMEventListeners } from './FCM';
import tokenContainer from './tokenContainer';
import { isStandaloneMode } from '../../web-apis/pwa';
import { isLocalhost } from '../../util/responsive';


function onToken(token) {
  if (!token) {
    console.warn('[FCM] token is null. You probably want to call `await Notification.requestPermission()`?');
  }
  console.debug('[FCM token]', token);
  tokenContainer.maybeUpdateToken(token);
}

function onMessage(payload) {
  console.debug(`
[FCM message] ${JSON.stringify(payload, null, 2)}
`);
}


async function manageSubscriptions(swRegistration) {
  // try {
  //   const subscription = await swRegistration.pushManager.subscribe({
  //     userVisibleOnly: true
  //   });
  //   console.log('User is subscribed:', subscription);
  // }
  // catch (err) {
  //   if (Notification.permission === 'denied') {
  //     console.warn('Permission for notifications was denied');
  //   } else {
  //     console.error('Failed to subscribe the user: ', err);
  //   }
  // }
}

/**
 * Only enable messaging if they are on the PWA installed PWA or if they are logged in
 * (or on localhost (for development purposes))
 */
export function isMessagingEnabled() {
  return isLocalhost() || isStandaloneMode() || authState.isLoggedIn();
}

export async function askForPermissionIfNeeded() {
  if (Notification.permission === 'default') {
    // ask for permission, if we did not ask before
    await Notification.requestPermission();
  }
  return Notification.permission === 'granted';
}

/**
 * Ask for permission if needed, then initialize messaging.
 */
export async function initMessaging(swRegistration) {
  // make sure, we are know the current login status
  await waitUntilAuthStateInitialized();

  if (!isMessagingEnabled()) {
    // we don't want messaging in this context
    return;
  }

  if (!await askForPermissionIfNeeded()) {
    // user did not give us permission
    return;
  }


  // init FCM
  await initFCM(swRegistration);
  addFCMEventListeners(onToken, onMessage);

  // manage subscriptions
  await manageSubscriptions(swRegistration);
}
