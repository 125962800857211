import {
  ContentActionType,
  ModerationStatus,
  ReasonsForNegativeActions,
  getContentActionVoteCount,
  doesContentActionRequireFeedback,
  isContentActionAllowed,
  getContentActionVotingPrivilege
} from '../api/content/ContentActionConfig';

import { NotLoaded } from 'src/db';
import { authState } from 'src/auth';
import { isApprovalPage, shouldPageShowModeratorToolsByDefault } from 'src/pages';

import { doesUserHavePriv, doesUserHavePrivAsync } from 'src/api/privileges';
import { contentReviews } from '../api/content/contentReviews';
import { toggleContentActionVote } from '../api/content/contentModeration';
import { getContentById } from '../api/content/contentObjects';

import {
  initDebugFeatures, 
  removeDebugFeatures, 
  debugDecorateContent, 
  decorateDebugFeature
} from 'src/render/devtools';

import State from 'src/util/State';
import { DefaultSlideDelay } from '../util/domUtil';
import { getContentEl } from 'src/renderUtil/contentObjects';
import { getOrCreateTemplateElement } from '../util/domUtil';
import { renderVoteLines } from '../renderUtil/voteLines';

import { perfLog } from 'src/util/perf';
import { usersPrivateContainer } from '../api/users/users';



// ##################################################################################################################
// Config
// ##################################################################################################################


const ActionButtonTexts = {
  Publish: (moderationStatus) => {
    return 'Publish';
  },
  Unpublish: (moderationStatus) => {
    switch (moderationStatus) {
      case ModerationStatus.NotReviewed:
        return 'Don\'t Publish';
      default:
        return 'Unpublish';
    }
  },
  Feature: (moderationStatus) => {
    return 'Feature';
  },
  StopFeature: (moderationStatus) => {
    return 'STOP Featuring';
  },
  Delete: (moderationStatus) => {
    return 'Delete';
  }
};


// ##################################################################################################################
// ModeratorTools
// ##################################################################################################################

// hacked together some "react-like" state object
const thisState = new State({});

thisState.addListener('currentFeedback', renderFeedback);


// ##################################################################################################################
// ModeratorTools
// ##################################################################################################################

class ModeratorTools {
  constructor() {
    this.enabledTools = {};
    this.isModerating = shouldPageShowModeratorToolsByDefault();
  }

  toggleModeratorTools($contentObj, contentId) {
    const $approvals = $contentObj.find('.approval-div');
    $approvals.slideToggle(DefaultSlideDelay);
    this.isModerating = authState.isModerator() && !this.isModerating;
    const $videoCont = $contentObj.closest('.individual-object-div');
    this.enabledTools[$videoCont[0].id] = this.isModerating;
    this.invalidateView();
    
    renderModeratorTools(contentId);
  }

  invalidateView = () => {
    if (authState.isModerator()) {
      $('.moderator').show();

      if (this.isModerating) {
        // $('.moderator-toggle').show();
      }
      else {
        // $('.moderator-toggle').hide();
        // for (let id in this.enabledTools) {
        //   if (this.enabledTools[id]) {
        //     $('#' + id + ' .moderator-toggle').show();
        //   }
        // }
      }
      initDebugFeatures();
    }
    else {
      // $('.moderator-toggle').hide();
      $('.moderator').hide();
      removeDebugFeatures();
    }
  }
}


// function validateAuthorUrl(url) {
//   // very basic validation
//   return url && url.length > 0;
// }

function renderUpdateButton($btn, content) {
  // NOTE: Update button will be moved and changed

  // const { contentType } = content;
  // if (doesUserHavePriv(uid, "updateContent", contentType)) {
  //   $btn.show();
  // }
  // else {
  //   $btn.hide();
  // }


  // hide Update button for videos that are not approved yet
  //   switch (moderationStatus) {
  //     case ModerationStatus.NotReviewed:
  //       $updateBtn.hide();
  //       break;
  //     default:
  //       $updateBtn.show();
  //       break;
  //   }

  //   $updateBtn.off('click').on('click', () => {
  //     const url = $videoInput.val();
  //     const author_about_video_url = $authorObjectInput.val();

  //     if (!validateAuthorUrl($authorObjectInput.val()) || !validateAuthorUrl($videoInput.val())) {
  //       alert("Cannot update - Please make sure to enter valid content URL and author URL!");
  //       return;
  //     }

  //     db.collection('videos').doc(videoId).update({
  //       url,
  //       author_about_video_url
  //     });
  //   });
}

function hideFeedback(contentId) {
  const $feedbackCont = getContentEl(contentId).find('.reasons');
  $feedbackCont.slideUp(DefaultSlideDelay);
}

function toggleFeedbackContainer(actionType, contentId, feedbackInteractable = false) {
  const currentFeedback = thisState.get.currentFeedback ? null : { actionType, contentId };
  thisState.setState({
    currentFeedback,
    feedbackInteractable
  });
}

async function tryCastVote(uid, actionType, contentId, selectedReasons) {
  const hasVoted = contentReviews.getHasVoted(actionType, contentId, uid);
  const voteCount = contentReviews.getVoteCount(actionType, contentId);
  const maxVoteCount = getContentActionVoteCount(actionType);
  const isPrivileged = await doesUserHavePrivAsync(uid, 'instantTagAction', null);
  if (!hasVoted && (isPrivileged || voteCount === (maxVoteCount - 1))) {
    // check with user, if it's the deciding vote
    const actionName = ContentActionType.nameFromForce(actionType);
    if (!window.confirm(`Your vote is the deciding vote to ${actionName} - Are you sure about this?`)) {
      return false;
    }
  }

  // start transaction
  await toggleContentActionVote(uid, actionType, contentId, selectedReasons, isPrivileged);
  return true;
}

function renderFeedback() {
  // get started
  const { currentFeedback, renderedFeedbackContentId } = thisState.get;

  if (!currentFeedback) {
    if (renderedFeedbackContentId) {
      // hide currently shown feedback
      hideFeedback(renderedFeedbackContentId);
      thisState.setState({ renderedFeedbackContentId: null });
    }

    // nothing to do here!
    return;
  }

  // show feedback container (if not showing already)
  const { actionType, contentId } = currentFeedback;
  const $contentObj = getContentEl(contentId);
  const $feedbackCont = $contentObj.find('.reasons');
  const justOpened = contentId !== renderedFeedbackContentId;
  if (justOpened) {
    // just opened it up
    $feedbackCont.slideDown(DefaultSlideDelay);
  }

  // get configuration
  // const actionName = ContentActionType.nameFromForce(actionType);
  // const maxVoteCount = getContentActionVoteCount(actionType);
  // const privilegeName = getContentActionVotingPrivilege(actionType);



  // get more context data
  const { uid } = authState;
  const content = getContentById(contentId);
  const { contentType, moderationStatus, isFeatured } = content;

  // get database data
  // const hasVoted = contentReviewCache.getHasVoted(actionType, contentId, uid);
  // const voteCount = contentReviewCache.getVoteCount(actionType, contentId);

  // remember the currently rendered feedback
  thisState.setState({
    renderedFeedbackContentId: contentId
  });


  // #######
  // render reason checkboxes
  // #######

  const $checkboxParent = $feedbackCont.find('.input-fields-wrapper .checkboxes');
  const [isFirstRender, $checkboxTempl] = getOrCreateTemplateElement($checkboxParent, '.checkbox-field');
  if (isFirstRender) {
    // render for the first time: generate checkboxes
    ReasonsForNegativeActions.forEach(reason => {
      const $checkCont = $checkboxTempl.clone();
      const $check = $checkCont.find('input[type="checkbox"]'); // the actual checkbox
      $check.prop('disabled', true);
      $check.val(reason.id);
      $checkCont.find('.checkbox-label').text(reason.label);
      $checkboxParent.append($checkCont);
    });
  }

  // show currently selected reasons
  const reasonsProp = `rendered-reasons-${actionType}-${contentId}`;
  if (justOpened || !thisState.get[reasonsProp]) {
    // important: render reasons ONLY if just opened, or if not ready before!
    const isLoaded = contentReviews.getFeedbackReasons(actionType, contentId) !== undefined;
    const { feedbackInteractable } = thisState.get;
    thisState.setState({
      [reasonsProp]: isLoaded
    });
    ReasonsForNegativeActions.forEach(reason => {
      const $check = $checkboxParent.find(`input[value="${reason.id}"]`);
      $check.prop('disabled', !feedbackInteractable || !isLoaded);
      const $checkCont = $check.parent();
      const reasonCount = contentReviews.getFeedbackReasonCount(actionType, contentId, reason.id);
      $check.prop('checked', !!reasonCount); // set checked if it has at least one vote
      $checkCont.find('.checkbox-label').text(reason.label + (reasonCount && ` (${reasonCount} votes)` || ''));
    });
  }


  // get all the reason checkboxes
  const $checks = $checkboxParent.find('[type="checkbox"]');

  // confirm button
  const $confirmBtn = $feedbackCont.find('.confirm-button');
  $confirmBtn.off('click').on('click', async () => {
    // cast vote!
    const selectedReasons = Array.from($checks).
      map(check => $(check)).
      filter($check => $check.prop('checked')).
      map($check => parseInt($check.val()));

    tryCastVote(uid, actionType, contentId, selectedReasons);

    // hide feedback container
    thisState.setState({ currentFeedback: null });
  });

  // cancel button
  const $cancelBtn = $feedbackCont.find('.cancel-button');
  $cancelBtn.off('click').on('click', () => {
    // hide feedback container
    thisState.setState({ currentFeedback: null });
  });
}


function renderReviewFeedbackButton($actionContainer, actionType, contentId, voteCount) {
  // review reasons button
  const $reviewReasonsBtn = $actionContainer.find('.review-reasons-button');
  $reviewReasonsBtn.off('click').on('click', () => {
    toggleFeedbackContainer(actionType, contentId, false);
  });

  // only show button if there is at least one vote
  if (!voteCount) {
    $reviewReasonsBtn.hide();
  }
  else {
    $reviewReasonsBtn.show();
  }
}

function renderActionButton($btnCont, actionType, contentId) {
  // get configuration
  const actionName = ContentActionType.nameFromForce(actionType);
  const maxVoteCount = getContentActionVoteCount(actionType);
  const privilegeName = getContentActionVotingPrivilege(actionType);
  const requiresFeedback = doesContentActionRequireFeedback(actionType);
  const $actionContainer = requiresFeedback && $btnCont.parent() || $btnCont;   // actions with feedback have an extra layer

  // get more context data
  const { uid } = authState;
  const content = getContentById(contentId);
  const { type: contentType, moderationStatus, isFeatured } = content;
  const btnText = ActionButtonTexts[actionName](moderationStatus);

  // if (content.type === 1) {
  // }
  const hasVoted = contentReviews.getHasVoted(actionType, contentId, uid);
  const voteCount = contentReviews.getVoteCount(actionType, contentId);

  if (actionName === 'Publish') {
    // perfLog('RENDER', actionName, voteCount);
  }

  // don't show button if not allowed
  const actionPossible = isContentActionAllowed(actionType, moderationStatus, isFeatured, contentId, contentReviews);

  // console.error('renderActionButton', contentId, hasVoted, voteCount, actionPossible, $actionContainer);

  if (!actionPossible) {
    $actionContainer.hide();
    return;
  }
  $actionContainer.show();

  if (requiresFeedback) {
    // render reviewfeedback button (if any)
    renderReviewFeedbackButton($actionContainer, actionType, contentId, voteCount);
  }


  // get the actual button
  const $btn = $btnCont.find('a');

  // set text
  if (voteCount === NotLoaded) {
    // still loading
    $btn.text(`${btnText} ?/${maxVoteCount}`);
  }
  else {
    // loaded!
    $btn.text(`${btnText} ${voteCount || 0}/${maxVoteCount}`);

    // show vote count lines
    renderVoteLines($btnCont, voteCount, maxVoteCount);

    // more button decorations
    let classNames = $btn.attr('data-classes');
    if (!classNames) {
      // keep a copy of the original classnames
      $btn.attr('data-classes', classNames = $btn.attr('class') + ' ');
    }

    if (!uid || !doesUserHavePriv(uid, privilegeName, contentType)) {
      classNames += 'disabled ';
    }

    if (hasVoted) {
      classNames += 'active ';
    }

    // set CSS styles + classes
    $btn.attr('class', classNames);

    // add click handler
    $btn.off('click').on('click', async evt => {
      if (doesUserHavePriv(uid, privilegeName, contentType)) {
        if (requiresFeedback && !hasVoted) {
          // trying to cast a vote for action that requires feedback: toggle feedback container
          toggleFeedbackContainer(actionType, contentId, true);
        }
        else {
          // send out vote
          // perfLog('VOTE', actionName, voteCount);
          //const result = await tryCastVote(uid, actionType, contentId, null);
          tryCastVote(uid, actionType, contentId, null);
          // perfLog('VOTE FINISHED', actionName, voteCount);


          // if (result && requiresFeedback) {
          if (requiresFeedback) {
            // retract feedback container
            hideFeedback(contentId);
          }
        }
      }
    });
  }
}

function renderPublishButton($btn, contentId) {
  return renderActionButton($btn, ContentActionType.Publish, contentId);
}

function renderUnpublishButton($btn, contentId) {
  return renderActionButton($btn, ContentActionType.Unpublish, contentId);
}

function renderFeatureButton($btn, contentId) {
  return renderActionButton($btn, ContentActionType.Feature, contentId);
}

function renderStopFeatureButton($btn, contentId) {
  return renderActionButton($btn, ContentActionType.StopFeature, contentId);
}

function renderReviewStatus($statusText, contentId) {
  const lastStatus = contentReviews.getLastLogMessage(contentId);
  // console.log('renderReviewStatus', lastStatus);
  $statusText.text(lastStatus && lastStatus.getStatusText() || '');
}

function refreshContentModeratorButtons($contentObj, contentId, initialRender = false) {
  const contentData = getContentById(contentId);
  if (!contentData) {
    return;
  }
  // const $updateBtn = $contentObj.find('.update-button');
  const $publishBtn = $contentObj.find('.publish-button-container');
  const $dontPublishBtn = $contentObj.find('.unpublish-button-container');
  const $featureBtn = $contentObj.find('.feature-button-container');
  const $stopFeatureBtn = $contentObj.find('.stop-feature-button-container');
  // const $publishd = $contentObj.find('.approved');
  const $statusText = $contentObj.find('.status-text');


  // render all buttons
  renderPublishButton($publishBtn, contentId);
  renderUnpublishButton($dontPublishBtn, contentId);
  renderFeatureButton($featureBtn, contentId);
  renderStopFeatureButton($stopFeatureBtn, contentId);

  // render status text
  renderReviewStatus($statusText, contentId);

  if (contentData.type === 1) {
    //debugger
    //console.log('refreshContentModeratorButtons', contentId, initialRender);
  }

  if (initialRender) {
    // TODO: the setTimeout call is a hack, because when initially rendering, it cannot actually hide the feedbackCont because it is not added to the DOM yet
    setTimeout(() => hideFeedback(contentId));
  }
  else {
    // update feedback view
    renderFeedback();
  }

  // toggle update-button
  // let updateDisabled;
  // if (initialRender) {
  //   updateDisabled = !validateAuthorUrl($authorObjectInput.val()) || !validateAuthorUrl($videoInput.val());
  // }
  // else {
  //   updateDisabled = true;
  // }

  // $updateBtn.prop('disabled', updateDisabled);

  //   switch (moderationStatus) {
  //     case ModerationStatus.ReviewedPublished:
  //       $statusNotReviewed.hide();
  //       break;
  //     case ModerationStatus.ReviewedNotPublished:
  //       $statusNotReviewed.hide();
  //       break;

  //     case ModerationStatus.NotReviewed:
  //     default:
  //       $statusNotReviewed.show();
  //       break;
  //   }

  // const { moderationStatus } = contentData;
  // if (moderationStatus >= ModerationStatus.ReviewedPublished) {
  //   $publishd.show();
  // }
  // else {
  //   $publishd.hide();
  // }
}

const emailHandlerInitClass = 'email-handler-done';

/**
 * Render submitting user email data to mods, when in dev/admin mode.
 */
function setupUserEmailRender($userEmailEl, uid) {
  // console.log('addEmailHandler', uid)
  if ($userEmailEl.hasClass(emailHandlerInitClass)) {
    return;
  }

  $userEmailEl.addClass(emailHandlerInitClass);

  // make sure the email toggles with the developer mode
  let unsubscribe;
  const renderEmail = ($el, on) => {
    // console.log('renderEmail', on, $el);
    if (on) {
      // start rendering data
      if (uid) {
        unsubscribe = usersPrivateContainer.addListener(uid, 
          () => renderEmail($el, true));
        // usersPrivate.onUpdate(() => renderEmail($el, true));
        const priv = usersPrivateContainer.getDocNowOrQuery(uid);
        if (priv !== NotLoaded) {
          if (priv && priv.email) {
            $el.text(priv.email);
          }
          else {
            $el.text('(user info unavailable)');
          }
        }
        else {
          $el.text('loading... ' + uid + ', ' + on + ', ');
        }
      }
      else {
        $el.text('(user info unavailable)');
      }
    }
    else {
      // stop listening on data
      usersPrivateContainer.stopListen(uid);
      if (unsubscribe) {
        unsubscribe();
      }
    }
  };
  decorateDebugFeature($userEmailEl, renderEmail);
}

// ##################################################################################################################
// renderModeratorTools
// Is called initially on every video and every time video data changes.
// ##################################################################################################################

const moderatorInitClass = 'moderator-init';
const contentReviewUnsubscribeCbs = {};

export function renderModeratorTools(contentId) {
  const $contentObj = getContentEl(contentId);
  const contentData = getContentById(contentId);

  if (!contentData) {
    console.error('tried to call renderModeratorTools for (but could not find) contentId =', contentId);
    return;
  }

  let {
    moderationStatus,
    isFeatured,
    url,
    uid
  } = contentData;

  // ##################################################################################################################
  // show-moderator-ui
  // ##################################################################################################################
  const $modToggleBtn = $contentObj.find('.show-moderator-ui');

  $modToggleBtn.off('click').on('click', () => {
    moderatorTools.toggleModeratorTools($contentObj, contentId);
  });

  const $userEmailEl = $contentObj.find('.email-user-temp');

  // handle email
  setupUserEmailRender($userEmailEl, uid);
  if (!moderatorTools.isModerating) {
    return;
  }

  // ##################################################################################################################
  // start rendering
  // ##################################################################################################################

  // show author video URL
  const $authorObjectInput = $contentObj.find('.author-object-input');
  const $videoInput = $contentObj.find('.video-url-input');

  const isInitialRender = !$contentObj.hasClass(moderatorInitClass);

  // re-render when review data changed
  if (isInitialRender) {
    // only call this code once per call
    $contentObj.addClass(moderatorInitClass);

    // make sure, things are visible as they should be
    // $contentObj.find('moderator-toggle').show();

    // add listener
    if (!contentReviewUnsubscribeCbs[contentId]) {
      contentReviewUnsubscribeCbs[contentId] = contentReviews.addListener(contentId, () => {
        const $contentObj = getContentEl(contentId);
        // debugger;
        if ($contentObj.length) {  // check if element is in DOM
          // only re-render, if still in DOM
          // TODO: do not call directly, use setState instead, and do NOT pass contentData directly
          renderModeratorTools(contentId);
        }
        else {
          // unsubscribe listener
          console.debug('unsubscribe listener', contentId);
          contentReviewUnsubscribeCbs[contentId]();
          contentReviewUnsubscribeCbs[contentId] = null;
        }
      });
    }
  }
  refreshContentModeratorButtons($contentObj, contentId, isInitialRender);


  // user url
  $userEmailEl.text();

  // ##################################################################################################################
  // video URL input
  // ##################################################################################################################
  $videoInput.val(url);
  $videoInput.off('input').on('input', () => {
    // update button style depending on whether we have video URL or not
    refreshContentModeratorButtons($contentObj, contentId);
  });


  // ##################################################################################################################
  // author URL input
  // ##################################################################################################################
  $authorObjectInput.val(contentData.author_about_video_url || '');
  //authorObjectInput.placeholder = 'You must provide author Video URL before you can approve';
  $authorObjectInput.off('input').on('input', () => {
    // update button style depending on whether we have author URL or not
    refreshContentModeratorButtons($contentObj, contentId);
  });


  // ##################################################################################################################
  // featured-btn
  // ##################################################################################################################
  // const $featuredBtn = $contentObj.find('.featured-btn');

  // $featuredBtn.off('click').on('click', () => {
  //   db.collection('videos').doc(contentId).update({
  //     isFeatured: !isFeatured
  //   });
  // });

  // add debug functionality as well (if dev mode is enabled)
  debugDecorateContent(contentId, $contentObj);
}


export const moderatorTools = new ModeratorTools();

export function initModeratorTools() {
  if (moderatorTools.isModerating) {
    $('.approval-div').show();
  }
  else {
    $('.approval-div').hide();
  }

  moderatorTools.invalidateView();
}