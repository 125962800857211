import {
  contentCollection,
  getContentById,
  getContentDoc
} from '../../api/content/contentObjects';

import postUpdate from 'src/postUpdate';
import { setContentUnlistedCache } from '../../api/content/contentObjects';

import { startWebflowAnimation } from 'src/renderUtil/animUtil';
import { createContentEl, renderContentEl, initRenderContentEl2 } from './contentObjects';
import EmptyObject from '../../util/EmptyObject';
import { getContentColumnWidth } from '../../util/responsive';
import { DefaultSlideDelay } from '../../util/domUtil';
import { buildYoutubeEmbedIframe } from 'src/web-apis/youtube/youtube';
import { onPopupOpen, onPopupClosed } from 'src/renderUtil/popups';
import ContentType from 'src/api/content/ContentType';


// export function renderContentEditorForm(contentType) {
//   const contentEditor = contentEditors[contentType];
//   if (!contentEditor) {
//     console.error('tried to render invalid content object of invalid type - no form registered for type', contentType);
//     return false;
//   }
// }

let contentPreview;

function getOrCreateDefaultContentPreview() {
  if (!contentPreview) {
    contentPreview = new ContentPreview('.submitted-object');
  }
  return contentPreview;
}

class ContentPreview {
  previewContentId;
  snapshotListenerUnsubscribe;

  // ####################################
  // element book-keeping
  // ####################################

  constructor(selector) {
    this.$editorCont = $(selector);
    // this.$editorCont = $($('.submitted-object')[0]); // take first submitted-object

    // $contentCont contains the actual content object
    this.$contentCont = this.$editorCont.find('.content-container');

    // $scrolls = scrollbar container surrounding $contentCont
    this.$scrolls = this.$editorCont.find('.objects-scroll-light');

    // the preview for embedded videos (as of now)
    this.$contentPreviewCont = this.$editorCont.find('.content-preview');

    this.$loader = this.$editorCont.find('.preview-loader');

    this.$closePreviewBtn = $('.anim-close-panels');

    // TODO: move to the right spot, and do proper unmount/disconnect logic
    this.$closePreviewBtn.on('click', () => {
      YoutubeVideoEmbed.tearDownActivePreview();
    });
  }

  getContentEl() {
    return this.$contentCont.children().first();
  }

  getOrCreateContentEl = (contentId, contentData) => {
    let $contentEl = this.getContentEl();
    if (!$contentEl.length || $contentEl.attr('data-content-id') !== contentId) {
      this.$contentCont.empty();
      $contentEl = createContentEl(contentId, contentData, true);

      // take care of second round of initialization right away
      initRenderContentEl2(contentId, contentData, $contentEl);

      this.$contentCont.append($contentEl);

      // TODO: set scroll bar height correctly
      // setTimeout(() => {
      //   const hOffset = `40px + ${videoH}px`;  // top bar is 40px, and then we need to add the video height on top of that

      //   // NOTE: we are using a custom --vh property as a hackfix for mobile (see browserHacks.js for more)
      //   const scrollStyle = `
      // height: calc(100vh - ${hOffset})
      // height: calc(var(--vh, 1vh) * 100 - ${hOffset});`;

      //   this.$scrolls.attr('style', scrollStyle);
      // }, 200);
    }
    else {
      // already created -> only update content
      renderContentEl($contentEl, contentId, contentData);
    }
    this.$contentCont.show();
    return $contentEl;
  }

  // ####################################
  // element book-keeping
  // ####################################

  stopListening = () => {
    if (this.snapshotListenerUnsubscribe) {
      this.snapshotListenerUnsubscribe();
      this.snapshotListenerUnsubscribe = null;
    }
  }

  startRenderLoader() {
    let $contentEl = this.getContentEl();
    if ($contentEl) {
      $contentEl.remove();
    }

    this.$loader.removeClass('hidden');

    // fade in preview panel (if not showing already)
    startWebflowAnimation('show-object');
  }

  startRenderContentPreview = (contentId, contentData, config) => {
    // fade in preview panel (if not showing already)
    startWebflowAnimation('show-object');

    return this.renderContentPreview(contentId, contentData, config);
  }

  renderContentPreview = (contentId, contentData, config) => {
    // if (!this.$inputForm.is(':visible')) {
    // TODO: this is not working yet
    //   // stop listening + stop rendering
    //   this.stopListening();
    //   return;
    // }

    if (this.previewContentId !== contentId) {
      // rendering a new item!
      this.previewContentId = contentId;

      // register new listener
      this.stopListening();
      let listenCount = 0;
      this.snapshotListenerUnsubscribe = contentCollection.doc(contentId).onSnapshot(newDoc => {
        if (++listenCount > 1) {
          const newData = newDoc.data();
          setContentUnlistedCache(contentId, newData);
          this.renderContentPreview(contentId, newData, config);
        }
      });
    }

    if (!contentData) {
      // not ready yet
    }
    else if (this.$contentCont.length) {
      // render data

      // if (config && config.hasLoader) 
      {
        // hide loader, when starting to render
        this.$loader.addClass('hidden');
      }

      // show video in place
      const $contentEl = this.getOrCreateContentEl(contentId, contentData);
      decoratePreviewForContentType($contentEl, contentId, contentData, config);

      // const $videoEl = $(videoEl);
      postUpdate($contentEl);
      return true;
    }
    else {
      // no element to render to
      console.warn("could not display render editor - no element found");
      postUpdate();
      return false;
    }
  }
}



// ##################################################################################################################
// Custom preview rendering
// ##################################################################################################################


const customPreviesByContentType = {
  [ContentType.Video]: ($contentObj, contentId, content, config) => {
    if (!YoutubeVideoEmbed.isActive(contentId)) {
      const preview = new YoutubeVideoEmbed($contentObj, contentId, content);
      preview.initRender(config);
    }
    else {
      // preview.render(config);
    }
  }
};

function decoratePreviewForContentType($contentObj, contentId, contentData, config) {
  // add decorations based on contentType
  const customPreview = customPreviesByContentType[contentData.type];
  customPreview && customPreview($contentObj, contentId, contentData, config);
}


// ##################################################################################################################
// Content input: preview + add new content
// ##################################################################################################################

class YoutubeVideoEmbed {
  static videoPreviews = {};
  static activePreviewId;

  constructor($contentObj, contentId, content) {
    this.$contentObj = $contentObj;
    this.contentId = contentId;
    this.content = content;

    this.$previewCont = $contentObj.find('.preview-cont');
    this.$imgLink = this.$previewCont.find('.video');
    this.$previewCont.addClass('sticky-top');

    YoutubeVideoEmbed.videoPreviews[contentId] = this;
  }

  initRender(config) {
    this.config = config;
    if (this.isAutoplay()) {
      // start video right away!
      this.startVideo();
    }
    else {
      // when clicking any of the "start video" buttons -> start playing
      this.$contentObj.find('.video,.videobutton').off('click').on('click', evt => {
        evt.preventDefault();
        if (this.isActive()) {
          // don't do anything
          return;
        }

        // start playing video
        this.startVideo();
      });
    }
  }

  isActive = () => {
    return YoutubeVideoEmbed.activePreviewId === this.contentId;
  }

  isAutoplay = () => {
    const { autoplay = false } = this.config || EmptyObject;
    return autoplay;
  }

  // // TODO: set video aspect ratio correctly
  // _getVideoHeight() {
  //   // dynamically determine the height: 100vh - the top of the content element, assuming 16:9 aspect ratio

  //   const aspect = 0.5625; // == 9/16

  //   // const top = this.$contentCont[0].getBoundingClientRect().top;
  //   const videoW = this.$contentObj.innerWidth();
  //   const videoH = aspect * videoW;
  //   return videoH;
  // }

  _buildEmbed() {
    const w = getContentColumnWidth();
    let h = w / 16 * 9; // keep 16:9 aspect ratio

    const autoPlay = 1;   // always autoplay, once the video element is actually showing
    const showRelatedVideos = 0;    // don't show related videos at the end of the video
    console.debug('embedding video', this.content);

    const { url } = this.content;
    return buildYoutubeEmbedIframe(url, { w, h, autoPlay, showRelatedVideos });
  }

  startVideo = () => {
    // always stop active video first
    YoutubeVideoEmbed.tearDownActivePreview();

    // embed!
    const $embed = this.$iframe = this._buildEmbed();
    this.$imgLink.remove();
    this.$previewCont.append($embed);

    // set active
    YoutubeVideoEmbed.activePreviewId = this.contentId;

    // add popup events
    this._pausedOnPopup = false;
    this._unsubscribeVideoEvents();
    this.popupUnsubscribes = [
      onPopupOpen(sender => {
        this.pause();
        this._pausedOnPopup = true;
      }),
      onPopupClosed(sender => {
        if (this._pausedOnPopup) {
          this.resume();
        }
      })
    ];
  }

  stopVideo = () => {
    if (!this.isActive()) {
      return;
    }

    this.$previewCont.empty();
    this.$imgLink.remove();
    this.$previewCont.append(this.$imgLink);

    this._unsubscribeVideoEvents();
  }

  _unsubscribeVideoEvents() {
    if (!this.popupUnsubscribes) {
      return;
    }
    this.popupUnsubscribes.forEach(cb => cb());
    this.popupUnsubscribes = null;
  }

  /**
   * @see https://codepen.io/briangelhaus/pen/meeLRO
   */
  sendCommand(func) {
    this.$iframe &&
      this.$iframe[0].contentWindow.postMessage(`{"event":"command","func":"${func}","args":""}`, '*');
  }

  pause = () => {
    return this.sendCommand('pauseVideo');
  }

  resume = () => {
    this._pausedOnPopup = false;
    return this.sendCommand('playVideo');
  }

  static isActive = (contentId) => {
    return YoutubeVideoEmbed.activePreviewId === contentId;
  }

  /**
   * We have video previews that need to be stopped explicitely (removing DOM node is not enough???)
   */
  static tearDownActivePreview = () => {
    // stop video
    const {
      activePreviewId,
      videoPreviews
    } = YoutubeVideoEmbed;

    const preview = activePreviewId && videoPreviews[activePreviewId] || null;
    preview && preview.stopVideo();


    // tear it all down
    videoPreviews[YoutubeVideoEmbed.activePreviewId] = null;
    YoutubeVideoEmbed.activePreviewId = null;
  }
}

// ###########################################################################
// init
// ###########################################################################

export function startRenderContentPreviewLoader() {
  getOrCreateDefaultContentPreview().startRenderLoader();
}


export async function startRenderContentPreview(contentId, config) {
  let contentData = getContentById(contentId);
  if (!contentData) {
    // TODO: clean up this mess
    // load (+ override)
    const doc = await getContentDoc(contentId).get();
    contentData = doc.data();
    setContentUnlistedCache(contentId, contentData);
  }
  if (!contentData) {
    console.error('tried to render invalid content object of invalid id', contentId);
    return false;
  }

  await getOrCreateDefaultContentPreview().startRenderContentPreview(contentId, contentData, config);
  return true;
}