import tagReviews from 'src/api/tags/tagReviews';

import {
  TagActionType,
  AvailableTagActions,
  TagMaxVotes,
  TagActionPrivileges,
  TagRenderContext,
  TagActionVerb,
  isTagSearchContext
} from 'api/tags/TagConfig';
import { getContentEl } from 'src/renderUtil/contentObjects';
import {
  decorateClasses, DefaultSlideDelay
} from 'util/domUtil';
import { renderVoteLines } from 'src/renderUtil/voteLines';
import { authState } from 'src/auth';
import { doesUserHavePriv, doesUserHavePrivAsync } from 'api/privileges';
import { toggleVoteTag } from 'api/tags/tagVoting';
import { showNotLoggedInUserActionWarning } from 'src/renderUtil/userActions';
import { addTagDevTools } from '../devtools/tag-tools';
import tagContainer from 'src/api/tags/tagContainer';
import VideoEditorPopup from 'src/render/tags/VideoEditorPopup';
import { clearTagSearch } from 'src/render/tags/wikiSearch';
import { getContentById } from 'src/api/content/contentObjects';
import { renderTagToggleECMButtons } from 'src/renderUtil/ecm';

function shouldRenderReviewButton(contentId, tagName, tagAction, tagRenderContext) {
  // // moderators can always see all buttons, except for buttons on proposed tags?
  // const tagRenderContextName = TagRenderContext.nameFromForce(tagRenderContext);
  // const availableActions = AvailableTagActions[tagRenderContextName];

  // if (!availableActions) {
  //   return;
  // }

  // if (tagRenderContext === TagRenderContext.Proposed && tagAction === TagActionType.Nay) {
  //   const { uid } = authState;
  //   const hasVotedYea = tagReviews.getHasVoted(contentId, tagName, TagActionType.Yea, uid);
  //   if (hasVotedYea) {
  //     // special case: when proposed, hide Nay button if we already voted for Yea
  //     return false;
  //   }
  // }

  // // action is available or is moderator
  // return availableActions.includes(tagAction) || authState.isModerator();
  const content = getContentById(contentId);
  if (!content) {
    // sanity check
    return false;
  }

  // for now: no more voting! So hide "add" button if added and hide "remove" button if not added yet
  const adding = tagAction === TagActionType.Yea;
  const exists = content.tags?.includes(tagName);
  if (adding === exists) {
    return false;
  }

  
  return true;
}

function renderTagReviewButton($tagEl, selector, contentId, tagName, tagAction, tagRenderContext) {
  tagAction = TagActionType.valueFromForce(tagAction);
  const $btnCont = $tagEl.find(selector);
  // console.log('renderTagReviewButton', tagName, $tagEl, selector, contentId, tagAction, tagRenderContextName, availableActions);

  // check if tag action is available, and hide if not
  if (!shouldRenderReviewButton(contentId, tagName, tagAction, tagRenderContext)) {
    // $btnCont.hide();
    $btnCont.text(`(hidden) ${tagAction} ${tagRenderContext}`);
    return;
  }

  // get config
  const actionName = TagActionType.nameFromForce(tagAction);
  const maxVoteCount = TagMaxVotes[actionName];

  // get data
  const { uid } = authState;
  const hasVoted = tagReviews.getHasVoted(contentId, tagName, tagAction, uid);
  const voteCount = tagReviews.getVoteCount(contentId, tagName, tagAction);

  // show!
  $btnCont.show();
  const $addTagBtn = $btnCont.find('a');

  // vote lines
  // console.log('renderVoteLines', $btnCont, voteCount, maxVoteCount)
  renderVoteLines($btnCont, voteCount, maxVoteCount);

  // decorate
  decorateClasses($addTagBtn, {
    active: hasVoted,

    // disable if logged in but insufficient privs (enable if not logged in!)
    disabled: uid && !doesUserHavePriv(uid, TagActionPrivileges[actionName])
  });

  // "its' related" button click handler
  $addTagBtn.off('click').on('click', async (evt) => {
    evt.preventDefault();

    if (showNotLoggedInUserActionWarning()) {
      return;
    }

    const isPrivileged = await doesUserHavePrivAsync(uid, 'instantTagAction');

    // console.log(isPrivileged, uid, hasVoted, voteCount, maxVoteCount);

    if (isPrivileged || voteCount === maxVoteCount - 1) {
      // check with user, if it's the deciding vote
      // const actionVerb = TagActionVerb[actionName];

      let msg;
      if (tagAction === TagActionType.Yea) {
        msg = `When tagging content you are helping others discover it. Are you sure this content is related to "${tagName}"?. Click OK to continue`;
      }
      else {
        msg = `Are you sure you want to remove "${tagName}"?`;
      }
      if (!window.confirm(msg)) {
        return;
      }
    }

    // add/remove tag for given content (video)
    await toggleVoteTag(uid, contentId, tagName, tagAction, isPrivileged);

    if (isTagSearchContext(tagRenderContext)) {
      // reset search input + result list
      clearTagSearch(tagRenderContext, getContentEl(contentId));
    }
  });
}

export function resetRenderTagControls(tagRenderContext, $tagEl, tagName) {
  const $tagActionCont = $tagEl.find('.voting-buttons-ctn');
  const $ecmControlsCont = $tagEl.find('.ecm-buttons-ctn');

  switch (tagRenderContext) {
    case TagRenderContext.Added:
    case TagRenderContext.ECM:
    case TagRenderContext.ECMSearch: {
      $ecmControlsCont.show();
      $tagActionCont.hide();
      break;
    }
    case TagRenderContext.Proposed:
    case TagRenderContext.ContentSearch:
    case TagRenderContext.WorldList:
    case TagRenderContext.WorldSearch: {
      // hide ECM controls entirely
      // $ecmToggleBtn.hide();
      // $ecmControlsCont.hide();
      $ecmControlsCont.hide(); // only show icon, but hide controls
      break;
    }
    default: {
      break;
    }
  }
}

export function initRenderTagEvents(tagRenderContext, $tagEl, tagName) {
  switch (tagRenderContext) {
    case TagRenderContext.Added:
    case TagRenderContext.Proposed:
    case TagRenderContext.ContentSearch:
    case TagRenderContext.ECM:
    case TagRenderContext.ECMSearch: // NOTE: ECM + ECMSearch do not currently have any control buttons
    case TagRenderContext.WorldList:
    case TagRenderContext.WorldSearch:
      {
        // toggle between: ecm controls + tag review buttons
        const $ecmToggleBtn = $tagEl.find('.add-ecm-button');
        const $tagActionCont = $tagEl.find('.voting-buttons-ctn');
        const $ecmControlsCont = $tagEl.find('.ecm-buttons-ctn');

        // toggle on click
        $ecmToggleBtn.on('click', () => {
          $ecmControlsCont.slideToggle(DefaultSlideDelay);
          $tagActionCont.slideToggle(DefaultSlideDelay);

          renderTagToggleECMButtons(tagRenderContext, $tagEl, tagName);
        });
        break;
      }
  }
}


function initRenderTagVideoBtn(tagRenderContext, $tagEl, tagName) {
  const $btn = $tagEl.find('.tag-video-btn');
  $btn.off('click').on('click', async evt => {
    evt.preventDefault();

    const tag = await tagContainer.getTagData(tagName);
    const url = tag?.videoUrl;
    if (url) {
      $btn.attr('href', url);
      // show video in popup
      // see: https://github.com/dimsemenov/Magnific-Popup/issues/42
      $.magnificPopup.open({
        items: {
          src: url
        },
        type: 'iframe'
      });
    }
    else {
      VideoEditorPopup.open('videoUrl',
        'There isn\'t a related video for this tag. You can submit a video yourself, once you have become an approved content contributor.',
        {
          async getData() {
            return tagContainer.queryDoc(tagName);
          },

          async setData(newData) {
            return tagContainer.setTagData(tagName, newData);
          }
        }
      );
    }
  });
}

export function initRenderTagControls(tagRenderContext, tagName, $tagEl, contentId) {
  // hook up external link
  const externalUrl = `https://wikipedia.org/wiki/${tagName}`;
  const $urlEl = $tagEl.find('.external-url');
  $urlEl.attr('href', externalUrl);

  initRenderTagEvents(tagRenderContext, $tagEl, tagName);
  initRenderTagVideoBtn(tagRenderContext, $tagEl, tagName);
  resetRenderTagControls(tagRenderContext, $tagEl, tagName);
}

/**
 * All kinds of buttons at the bottom of each tag button
 * TODO: move out of `moderation` file (nothing to do with moderation!)
 */
export function renderTagModerationControls(tagRenderContext, $tagEl, tagName, contentId) {
  if (contentId) {
    // render review buttons ("Related" and "Not related") and their vote counts
    renderTagReviewButton($tagEl, '.related-tag-button-ctn', contentId, tagName, TagActionType.Yea, tagRenderContext);
    renderTagReviewButton($tagEl, '.unrelated-tag-button-ctn', contentId, tagName, TagActionType.Nay, tagRenderContext);

    // dev tools
    addTagDevTools($tagEl, contentId, tagName, tagRenderContext);
  }
}