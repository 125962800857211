import State from 'src/util/State';
import { SaveChangesButton } from 'src/render/userSettings/SubmitButton';
import { serializeForm2Object, populateForm } from 'src/util/formUtil';
import { onUIDChanged, authState } from 'src/auth';
import reelHubSettingsContainer from 'src/api/reel/reelHubSettingsContainer';
import { NotLoaded } from 'src/db';
import EventHandlerList from 'src/util/EventHandlerList';


export default class ReelHubSettingsRenderer {
  dataHandlers = new EventHandlerList();

  constructor(reelId, $parent) {
    this.reelId = reelId;
    this.state = new State();
    this.$cont = $parent.find('.hub-ctn');
    this.$hubForm = this.$cont.find('form').eq(0);

    this.saveBtn = new SaveChangesButton(this.$cont, this.state, this.submit);

    this.initDataBinding();
  }

  initDataBinding() {
    onUIDChanged((_uid) => {
      this.clear();

      this.state.setState({ busy: true });

      this.dataHandlers.subscribeNew(
        // usersPrivateContainer.onDoc(this.reelId, this.handleHubDataChanged),
        reelHubSettingsContainer.onDoc(this.reelId, this.handleHubDataChanged)
      );
    }, false, false);
  }

  clear = () => {
    populateForm(this.$hubForm, {});
  }

  handleHubDataChanged = (_uid) => {
    let data = reelHubSettingsContainer.getDocById(this.reelId);
    // const privateData = usersPrivateContainer.getDocById(this.reelId);
    const hasHubDataLoaded = data !== NotLoaded;
    const hasAllLoaded = hasHubDataLoaded; // && privateData !== NotLoaded;

    if (hasAllLoaded) {
      // merge private email in here (if necessary)
      // data = data || {};
      // data.email = data.email || privateData.email;

      this.state.setState({ busy: false });
    }

    populateForm(this.$hubForm, data);
  }

  submit = async () => {
    const hubData = serializeForm2Object(this.$hubForm);
    hubData.uid = authState.uid;
    // const email = hubData?.email;

    return Promise.all([
      // update hub data
      reelHubSettingsContainer.setDoc(this.reelId, hubData)
    ]);
  }
}