import firebase from 'firebase/app';
import NanoEvents from 'src/util/NanoEvents';
import EmptyArray from 'src/util/EmptyArray';
import db from 'src/db';
import NotLoaded from 'src/NotLoaded';

import { UserRole, roleFrom } from '../roles';
import FirestoreContainer from '../../firebase/FirestoreContainer';
import { trackUserDataUpdate } from '../third-party/mixpanel';
import { perfLog } from '../../util/perf';
import { getLocalStorageItem } from '../../util/localStorage';

const userCollection = db.collection('users');
const DefaultUserRole = UserRole.User;

/**
 * Enables access to private user data.
 */
class UsersPrivateContainer extends FirestoreContainer {
  constructor() {
    super('usersPrivate');
  }
}

// TODO: integrate this with FirestoreContainer
class UserContainer extends FirestoreContainer {
  constructor() {
    super('users');
    this.enableCache();
  }

  getUser(uid) {
    return this.getUserNow(uid);
  }

  getUserNow(uid) {
    return uid && this.getDocNowOrQuery(uid) || null;
  }

  async getUserAsync(uid, ignoreCache = false) {
    return this.queryDoc(uid, ignoreCache);
  }

  async doesUserHaveRoleAsync(uid, roleOrName) {
    const role = roleFrom(roleOrName);
    await this.getUserAsync(uid);        // load user

    return this.doesUserHaveRole(uid, roleOrName);
  }

  async isUserModeratorAsync(uid) {
    return this.doesUserHaveRoleAsync(uid, 'Moderator');
  }

  doesUserHaveRole(uid, roleOrName) {
    const role = roleFrom(roleOrName);
    const user = this.getUser(uid);
    if (user === NotLoaded) {
      return NotLoaded;
    }
    return user && user.displayRole >= role || false;
  }

  isUserModerator(uid) {
    return this.doesUserHaveRole(uid, 'Moderator');
  }
}


/**
 * If user already exists, get user data from users collection.
 * Else, save user to users collection.
 */
export async function getOrSaveUserData(user) {
  const { uid } = user;
  // const snaps = await Promise.all([
  //   //await db.collection('usersPrivate').doc(user.uid).get()
  // ]);
  // let userInfo = snaps[0];
  let userPublic = await userContainer.getUserAsync(uid, true);

  let {
    displayName,
    email,
    photoURL
  } = user;


  if (!userPublic ||
    !userPublic.createdAt ||

    // we want to capture user-data again after this date
    userPublic.createdAt.seconds < 1568977168 ||

    !userPublic.role) {
    // this user is not in DB yet -> store in DB.

    if (!userPublic || !userPublic.role) {
      // hackfix: weird bugs...
      // potentially weird bug -> save to DB and inform user that something went wrong
      const userDoc = await userContainer.doc(uid).get();
      const userData = userDoc?.data?.();
      if (userDoc && userDoc.data()) {
        alert('Unable to load user data; please refresh the page :(');
      }

      const cached = getLocalStorageItem('auth');
      await db.collection('userUpdates').add({
        title: 'assigning new role',
        uid: uid || null,
        cached: cached || null,
        userInfo: userPublic || null,
        loadAgain: (await db.collection('users').doc(uid).get()).data() || null
      });
      // debugger;
      // return;
    }

    const createdAt = userPublic?.createdAt || updatedAt || firebase.firestore.Timestamp.fromDate(new Date());
    const updatedAt = firebase.firestore.Timestamp.fromDate(new Date());
    displayName = userPublic?.displayName || displayName || '';  // don't update displayName if already existing
    const displayRole = userPublic?.displayRole || DefaultUserRole;
    const role = userPublic?.role || DefaultUserRole;
    userPublic = {
      createdAt,
      updatedAt,
      displayName: userPublic?.displayName || '',
      _displayName: displayName, // don't actually use the `displayName`, but store it anyway
      photoURL,
      displayRole,
      role
    };

    const userPrivate = {
      createdAt,
      updatedAt,
      email
    };

    await Promise.all([
      _updatePublicUserData(user.uid, userPublic, email, createdAt),
      db.collection('usersPrivate').doc(user.uid).set(userPrivate),
    ]);
    perfLog('Finished saving user data');
  }

  // userCache._init();

  return userPublic;
}

export async function updatePublicUserData(authState, userPublic) {
  const {
    uid,
    userInfo: {
      createdAt
    }
  } = authState;
  const email = authState.getEmail();
  
  return _updatePublicUserData(uid, userPublic, email, createdAt);
}

async function _updatePublicUserData(uid, userPublic, email, createdAt) {
  perfLog('Saving user data', uid, userPublic);
  trackUserDataUpdate(uid, {
    '$email': email,
    '$createdAt': createdAt.toDate()
  });
  return db.collection('users').doc(uid).set(userPublic, { merge: true });
}


export const userContainer = new UserContainer();
export const usersPrivateContainer = new UsersPrivateContainer();


// [debug-global]
// used for some snippets
window.getUserByEmail = async function (email) {
  if (!email) {
    throw new Error('invalid input. Make sure, email and privilege name actually exist.');
  }
  const snap = await db.collection('usersPrivate').where('email', '==', email).get();
  if (!snap.docs.length) {
    throw new Error('user does not exist: ' + email);
  }
  return snap.docs[0];
}