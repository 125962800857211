import { getQueryStringArg } from '../pages';
import notificationsContainer from '../api/messaging/notificationsContainer';
import EmptyArray from '../util/EmptyArray';
import { startWebflowAnimation } from '../renderUtil/animUtil';
import { isMessagingEnabled } from '../api/messaging/messaging';
import { prepareTemplateElement, cloneTemplateElement } from '../util/domUtil';


let initializedPanel = false;

class NotificationRenderer {
  initRender() {
    if (initializedPanel) {
      return;
    }
    initializedPanel = true;

    this.$panel = $('.notifications-panel');

    // TODO: properly manage list + list-element templates
    // this.$list = $('<div class="notifications-list"></div>'); 
    // $('.notifications').append(this.$list);
    const $list = this.$list = this.$panel.find('.notifications-list');
    if ($list.length) {
      prepareTemplateElement($list, '.notification-ctn');
    }


    this.renderTopMenu();
    this.renderMessageList();

    notificationsContainer.onUpdate(this.renderMessageList);
  }

  renderTopMenu() {
    // TODO: properly render buttons
  }

  renderMessageList = () => {
    let notifications = (notificationsContainer.getSortedArray() || EmptyArray);

    this.$list.empty();

    for (const notification of notifications) {
      const { payload: { notification: { title, body, link } } } = notification;

      const $el = cloneTemplateElement(this.$list, '.notification-ctn');
      $el.removeClass('hidden');

      const $titleEl = $el.find('.notification-title');
      const $bodyEl = $el.find('.notification-message');
      const $linkEl = $el;
      $titleEl.text(title);
      $bodyEl.text(body);
      $linkEl.attr('href', link || '#');


      this.$list.append($el);
    }
  }
}


function initNotificationsPanel() {
  // render the bell button
  const $notificationBtn = $('.notification-bell');
  $notificationBtn.removeClass('hidden');

  $notificationBtn.on('click', () => {
    notificationRenderer.initRender();
  });

  // render the panel by default, if `notifications` is in the query string
  if (getQueryStringArg('notifications')) {
    startWebflowAnimation('notification-settings-panel');
    notificationRenderer.initRender();
  }
}

export function initNotificationsRender() {
  if (!isMessagingEnabled()) {
    // nothing to do here
    return;
  }

  // show animation panel button
  $('.notification-bell').removeClass('hidden');

  initNotificationsPanel();
}

const notificationRenderer = new NotificationRenderer();

export default notificationRenderer;